import { ReactQuery } from '@codeleap/common'
import { QueryKeys } from '../queryKeys'
import { retrieve } from './views'

export const useProfile = () => {
  return ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: retrieve,
    refetchOnWindowFocus: false,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
    retry: 3,

    onError() {
      QueryKeys.me.setData(null)
    },
  })
}
