import { LocalStorage as _LocalStorage } from '@codeleap/web'

export const LocalStorageKeys = {
  ONBOARDING_FINISHED: '@Onboarding.finished',
  SESSION_IS_DEV: '@Session.isDevelopment',
  THEME: '@Theme.colorScheme',
  DELETION_REQUEST: '@AccountDeletion',
  TESTER: '@CodeleapTester',
  SERVER: '@server',
  LOCALE: '@locale',
  PERSIST_AUTH: '@auth.has_user',
  IN_REAUTHENTICATION: '@auth.in_reauthentication',
  RICH_TEXT_ITEM: '@richTextItem',
  LAST_TOKEN: '@lastToken',

  MANUSCRIPTS_TABLE_LIMIT: '@manuscriptsTableLimit',
  ARCHIVE_TABLE_LIMIT: '@archiveTableLimit',
  IN_TRAY_TABLE_LIMIT: '@inTrayTableLimit',
  MANUSCRIPTS_TABLE_SORTING: '@manuscriptsTableSorting',
  ARCHIVE_TABLE_SORTING: '@archiveTableSorting',
  IN_TRAY_TABLE_SORTING: '@inTrayTableSorting',
}

export const LocalStorage = new _LocalStorage(LocalStorageKeys)
