import { Theme } from '@/app'

export type ChartColumnColors = Partial<Record<'color' | 'darkerColor' | 'icon' | 'label', string>>

const defaultStatus:ChartColumnColors = {
  color: Theme.colors.light.neutral3,
  darkerColor: Theme.colors.light.neutral9,
  icon: 'archive',
  label: '',
}

export const statusChartColors: {[p: string]: ChartColumnColors} = {
  'accepted': {
    color: Theme.colors.light.positive1,
    darkerColor: Theme.colors.light.positive2,
    icon: 'check-circle',
    label: 'Accepted',
  },
  'under_review': {
    color: Theme.colors.light.alert1,
    darkerColor: Theme.colors.light.alert2,
    icon: 'under-review',
    label: 'Reviews Submitted',
  },
  'revision_requested': {
    color: Theme.colors.light.alert1,
    darkerColor: Theme.colors.light.alert2,
    icon: 'eyeglass-2',
    label: 'Revision Requested',
  },
  'rejected': {
    color: Theme.colors.light.destructive1,
    darkerColor: Theme.colors.light.destructive2,
    icon: 'x-circle',
    label: 'Rejected',
  },
  'revision_completed': {
    color: Theme.colors.light.info1,
    darkerColor: Theme.colors.light.info2,
    icon: 'shield-check',
    label: 'Revision Completed',
  },
  'revision_in_drafts': { ...defaultStatus, label: 'Revision In Drafts' },
  'saved_in_drafts': { ...defaultStatus, label: 'Saved In Drafts' },
  'rejected_transfer': { ...defaultStatus, label: 'Rejected Transfer' },
  'rejected_resubmit': { ...defaultStatus, label: 'Rejected Resubmit' },
  'submitted_for_review': { ...defaultStatus, label: 'Manuscript submitted' },
  'pending_review': { ...defaultStatus, label: 'Reviewers invited' },
  'reviewers_invited_accepted': { ...defaultStatus, label: 'Reviewers accepted invitation' },
  'review_submitted': { ...defaultStatus, label: 'Required reviews completed' },
  'withdraw': { ...defaultStatus, label: 'Withdrawn' },
} as const
