import { SelectItemProps } from "@/components"
import { Permission, PermissionLevel } from "@/types"


type Action =
  | { type: 'SET_INITIAL_PERMISSIONS'; payload: Permission[] }
  | { type: 'ADD_PERMISSION' }
  | { type: 'REMOVE_PERMISSION'; payload: { permission: Permission, initialPermissions: Permission[] } }
  | { type: 'UPDATE_PERMISSION_LEVEL'; payload: { id: number; level: PermissionLevel; value: boolean } }
  | { type: 'SET_PERMISSIONS'; payload: Permission[] }
  | { type: 'SET_DELETED_PERMISSIONS'; payload: Permission[] }
  | { type: 'UPDATE_PERMISSION_JOURNAL'; payload: { permission: Permission; item: SelectItemProps } }

type State = {
  permissions: Permission[]
  deletedPermissions: Permission[]
}

export const permissionsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_INITIAL_PERMISSIONS':
      return {
        permissions: action.payload,
        deletedPermissions: []
      }
    case 'ADD_PERMISSION':
      return {
        ...state,
        permissions: [...state.permissions, { id: state.permissions.length + 1, journal: null, role_publisher: true } as Permission],
      }
    case 'REMOVE_PERMISSION':
      return {
        ...state,
        permissions: state.permissions.filter(p => p.id !== action.payload.permission.id),
        deletedPermissions: action.payload.initialPermissions.find((vl) => vl.id === action.payload.permission.id)
          ? [...state.deletedPermissions, action.payload.permission]
          : state.deletedPermissions
      }
    case 'UPDATE_PERMISSION_LEVEL':
      return {
        ...state,
        permissions: state.permissions.map(p => p.id === action.payload.id ? { ...p, [action.payload.level]: action.payload.value } : p),
      }
    case 'SET_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
      }
    case 'SET_DELETED_PERMISSIONS':
      return {
        ...state,
        deletedPermissions: action.payload,
      }
    case 'UPDATE_PERMISSION_JOURNAL':
      return {
        ...state,
        permissions: state.permissions.map(p => p.id === action.payload.permission.id ? { ...p, journal: { title: action.payload.item.label, id: action.payload.item.value } } : p),
      }
    default:
      return state
  }
}
