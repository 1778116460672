import { Text } from '.'

type StatusTagProps = {
  label: 'Accept' | 'Reject' | 'Revise'
}

export const StatusTag = (props: StatusTagProps) => {
  const { label } = props
  let backgroundColor = ''

  if (label === 'Accept') {
    backgroundColor = 'backgroundColor:positive3'
  } else if (label === 'Revise') {
    backgroundColor = 'backgroundColor:warning3'
  } else if (label === 'Reject') {
    backgroundColor = 'backgroundColor:destructive2'
  }

  return <Text text={label} variants={[backgroundColor, 'padding:0.25', 'border-radius:nanoish', 'p3', 'color:neutral1']} style={{ width: 'fit-content' }} />
}
