/* eslint-disable max-lines */
import { React, AppForms, Theme, Settings, variantProvider } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { AnyFunction, onUpdate, useForm, useI18N, useState } from '@codeleap/common'
import {
  Button,
  Checkbox,
  Text,
  TextInput,
  View,
  AuthFormComponents,
  Select,
  PlacesAutocomplete,
  PhoneTextInput,
} from '@/components'
import { Navigation, debounce, getRegionShortName, useKeydown, ProfileUtils, findRegionById } from '@/utils'
import { useMediaQuery } from '@codeleap/web'
import { SocialProviderTypes, User } from '@/services/api/session'
import { Profile } from '@/types'

type SignupFormProps = {
  isModal?: boolean
  title?: string
  visible?: boolean
  modalName?: any
  step: number
  setStep: AnyFunction
  provider?: SocialProviderTypes
  formValues?: User
  social?: SocialProviderTypes
  journalData?: Partial<Profile & { journal_name: string }>
}

type HeaderProps = {
  step: number
  isMobile: boolean
  journalName: string
}

type FooterProps = {
  step: number
  decreaseStep: AnyFunction
  handleSubmit: AnyFunction
  handleSocial: AnyFunction
  openLogin: AnyFunction
  formValidation: {
    isProfileFormValid: boolean
    isAboutYouFormValid: boolean
    isAboutYourWorkFormValid: boolean
  }
}

const Header = (props: HeaderProps) => {
  const { step, isMobile, journalName } = props
  const { t } = useI18N()

  const texts = {
    0: {
      title: t('SignUp.title'),
      description: t('SignUp.description', {
        journal_name: journalName,
      }),
    },
    1: {
      title: 'Tell us about yourself',
      description: 'Please provide personal information to help us get to know you and ensure a efficient experience.',
    },
    2: {
      title: 'Tell us about your work',
      description: "We'd like to learn more about your professional background and academic experiences. ",
    },
  }

  return (
    <View variants={['fullWidth', 'column']}>
      <Text
        text={texts[step]?.title}
        variants={['h1', 'marginBottom:1', isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />
      {texts[step]?.description && (
        <Text
          text={texts[step]?.description}
          variants={[
            'p1',
            `marginBottom:${isMobile ? 4 : 5}`,
            isMobile && 'marginHorizontal:auto',
            isMobile && 'textCenter',
          ]}
        />
      )}
    </View>
  )
}

const Footer = (props: FooterProps) => {
  const { step, decreaseStep, handleSubmit, handleSocial, openLogin, formValidation } = props
  const { isAboutYouFormValid, isAboutYourWorkFormValid, isProfileFormValid } = formValidation
  const { t } = useI18N()

  return (
    <View variants={['column', 'alignCenter', 'fullWidth', 'marginTop:4']}>
      {step === 0 ? (
        <Button
          text={'Next'}
          disabled={!isProfileFormValid}
          variants={['fullWidth', 'large']}
          debugName={'Signup modal next'}
          onPress={handleSubmit}
        />
      ) : (
        <View variants={['fullWidth', 'row', 'alignCenter', 'gap:2']}>
          <Button
            text={'Previous'}
            disabled={step === 1}
            variants={['fullWidth', 'large', 'flat']}
            debugName={'Signup modal previous'}
            onPress={decreaseStep}
          />
          {step === 2 ? (
            <Button
              text={'Get Started'}
              variants={['fullWidth', 'large']}
              debugName={'Signup modal get started'}
              onPress={handleSubmit}
              disabled={!isAboutYourWorkFormValid}
            />
          ) : (
            <Button
              text={'Next'}
              variants={['fullWidth', 'large']}
              debugName={'Signup modal next step 1'}
              onPress={handleSubmit}
              disabled={!isAboutYouFormValid}
            />
          )}
        </View>
      )}
      <View variants={['fullWidth', 'marginTop:3', 'column']}>
        {step === 0 && (
          <>
            <AuthFormComponents.SocialProviders onPress={handleSocial} />
            <AuthFormComponents.RedirectModal
              text={t('SignUp.txt1')}
              textAction={t('SignUp.txt2')}
              onPress={openLogin}
            />
          </>
        )}
      </View>
    </View>
  )
}

const Step1 = ({ form, isSocial }) => {
  const { profileForm } = form
  const { titles } = APIClient.Session.useSession()

  return (
    <View variants={['column', 'gap:1']}>
      <Select {...profileForm.register('title')} variants={['left']} options={titles} />
      <TextInput {...profileForm.register('first_name')} />
      <TextInput {...profileForm.register('last_name')} />
      <TextInput {...profileForm.register('email')} leftIcon={{ name: 'mail' }} disabled={isSocial} />
      {isSocial ? null : (
        <>
          <TextInput {...profileForm.register('password')} leftIcon={{ name: 'key' }} visibilityToggle />
          <TextInput {...profileForm.register('repeatPassword')} leftIcon={{ name: 'key' }} visibilityToggle />
        </>
      )}
      <View variants={['column', 'gap:2']}>
        <Checkbox
          {...profileForm.register('remember')}
          onValueChange={(val) => {
            APIClient.Session.toggleRememberMe(val)
            profileForm.setFieldValue('remember', val)
          }}
          variants={['left']}
        />
        <Checkbox
          {...profileForm.register('acceptance')}
          label={
            <Text css={step1Styles.termsWrapper}>
              I have read and agree to the{' '}
              <a href={Settings.ContactINFO.TermsAndPrivacy} target='_blank'>
                <Text css={step1Styles.termsLink} text='terms and conditions' />
              </a>
              ,{' '}
              <a href={Settings.ContactINFO.PrivacyPolicy} target='_blank'>
                <Text css={step1Styles.termsLink} text='privacy policy' />
              </a>{' '}
              and{' '}
              <a href={Settings.ContactINFO.CookiePolicy} target='_blank'>
                <Text css={step1Styles.termsLink} text='cookie policy' />
              </a>
              .
            </Text>
          }
          variants={['left', 'alignStart']}
        />
      </View>
    </View>
  )
}

const step1Styles = variantProvider.createComponentStyle(theme => ({
  termsWrapper: {
    display: 'inline',
    ...theme.spacing.marginLeft(1),
  },
  termsLink: {
    display: 'inherit',
    textDecoration: 'underline',
  },
}), true)

const Step2 = ({ form }) => {
  const { aboutYouForm } = form
  const { genders, regions } = APIClient.Session.useSession()

  return (
    <View variants={['column', 'gap:1']}>
      <Select {...aboutYouForm.register('region')} variants={['left']} options={regions} />
      <PhoneTextInput
        {...aboutYouForm.register('telephone_number')}
        placeholder='Enter your phone number'
        regionValue={aboutYouForm?.values?.region}
        isOptional
      />
      <PhoneTextInput
        {...aboutYouForm.register('mobile_number')}
        placeholder='Enter your phone number'
        regionValue={aboutYouForm?.values?.region}
      />
      <Select {...aboutYouForm.register('gender')} variants={['left']} options={genders} />
      <TextInput {...aboutYouForm.register('degree')} />
    </View>
  )
}

const Step3 = ({ form, regionShortName }) => {
  const { aboutYourWorkForm } = form
  const { scientificTags } = APIClient.Session.useSession()

  const filterOptions = (inputValue: string) => {
    return scientificTags.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
  }

  const loadOptions = async (inputValue: string) => {
    return filterOptions(inputValue)
  }

  const handleAddressValue = (address, postcode) => {
    aboutYourWorkForm.setFormValues({ address, postcode })
  }

  return (
    <View variants={['column', 'gap:1']}>
      <TextInput {...aboutYourWorkForm.register('position')} />
      <TextInput {...aboutYourWorkForm.register('department')} />
      <TextInput {...aboutYourWorkForm.register('organisation')} />
      <Select
        searchable
        multiple
        variants={['left']}
        loadOptionsOnMount
        loadOptions={loadOptions}
        {...aboutYourWorkForm.register('tags')}
      />
      <PlacesAutocomplete
        handleAddressValue={handleAddressValue}
        textInputProps={{ ...aboutYourWorkForm.register('address') }}
        restrictions={{
          componentRestrictions: {
            country: regionShortName ? [regionShortName] : null,
          },
        }}
      />
      <TextInput {...aboutYourWorkForm.register('postcode')} variants={['description:p3']} />
    </View>
  )
}

export const SignupForm = ({
  isModal = true,
  step,
  setStep,
  formValues,
  provider,
  social,
  journalData,
}: SignupFormProps) => {
  const isFocused = useAppSelector((state) => state?.AppStatus?.modals?.signup)
  const [signUpSource, setSignUpSource] = useState('email')
  const [userId, setUserId] = useState(null)
  const edit = APIClient.Session.useEdit()
  const { profile, regions } = APIClient.Session.useSession()

  const isMobile = useMediaQuery(Theme.media.down('mid'), { getInitialValueInEffect: false })
  const formsConfig = signUpSource === 'email' ? AppForms.signup() : AppForms?.socialSignup()
  const profileForm = useForm(formsConfig, {})
  const aboutYouForm = useForm(AppForms?.aboutYou, {})
  const aboutYourWorkForm = useForm(AppForms?.aboutYourWork, {})

  const forms = { profileForm, aboutYouForm, aboutYourWorkForm }

  const invalidForms = !profileForm.isValid && !aboutYouForm.isValid && !aboutYourWorkForm.isValid

  const region = findRegionById({ regions: regions, value: aboutYouForm?.values?.region })

  const isSocial = signUpSource !== 'email'

  const regionShortName = getRegionShortName(region?.title)

  const steps = [
    { key: 'step1', children: <Step1 form={forms} isSocial={isSocial} /> },
    { key: 'step2', children: <Step2 form={forms} /> },
    { key: 'step3', children: <Step3 form={forms} regionShortName={regionShortName} /> },
  ]

  onUpdate(() => {
    if (formValues) profileForm.setFormValues(formValues)
    if (provider) setSignUpSource(provider)
  }, [formValues, provider])

  onUpdate(() => {
    if (social) {
      handleSocial(social)
    }
  }, [social])

  const handleSocial = APIClient.Session.useSocialLogin(
    (userSocial, provider) => {
      if (userSocial) {
        const userData = {
          ...userSocial,
          first_name: userSocial?.first_name ? userSocial?.first_name : '',
          last_name: userSocial?.last_name ? userSocial?.last_name : '',
        }

        AppStatus.set('loading')
        profileForm.setFormValues(userData)
        setSignUpSource(provider)
        AppStatus.set('done')
        Navigation.navigate('Manuscripts.List')
      }
    },
    'signup',
    journalData,
  )

  const { t } = useI18N()

  const { signup } = APIClient.Session.useSignup()

  const handleSubmit = async (userValues = null) => {
    const profileFormValues = profileForm?.values
    const aboutYouFormValues = aboutYouForm?.values
    const aboutYourWorkFormValues = aboutYourWorkForm?.values

    if (step === 0) {
      if (profile?.id) {
        setStep(1)
        return
      }

      const { email, ...rest } = profileFormValues

      const values = {
        ...rest,
        email: email.toLowerCase(),
        ...journalData,
        cookie_policy: true,
        privacy_policy: true,
        terms_and_conditions: true,
      }

      const response = await signup(values, signUpSource as any)

      setUserId(response?.user.id)

      if (response?.user) setStep(1)
    } else if (step === 1) {
      setStep(2)
    } else if (step === 2) {
      const { telephone_number, mobile_number, ...aboutYouFormRest } = aboutYouFormValues

      const telephoneNumber = ProfileUtils.cleanPhoneNumberMask(telephone_number)
      const mobileNumber = ProfileUtils.cleanPhoneNumberMask(mobile_number)

      //@ts-ignore
      await edit.editProfile({
        id: userId,
        ...profileFormValues,
        email: profileFormValues.email?.toLowerCase(),
        ...aboutYouFormValues,
        phone: telephoneNumber,
        mobile: mobileNumber,
        title: profileFormValues.title,
        ...aboutYouFormRest,
        ...aboutYourWorkFormValues,
      })

      APIClient.Journals.updateReviewerPermission(journalData.current_journal)

      AppStatus.setModal(['signup', false])

      if (journalData?.invite || journalData?.current_role === 'reviewer') {
        Navigation.navigate('Invites.List')
      } else {
        Navigation.navigate('Manuscripts.List')
      }

      setTimeout(() => {
        AppStatus.authFinished()
      }, 500)
      setStep(0)
    }
  }

  function decreaseStep() {
    setStep(step - 1)
  }

  const openLogin = () => {
    if (isModal) {
      AppStatus.transitionModals(['signup', 'login'])
    } else {
      Navigation.navigate('Auth.Login')
    }
  }

  useKeydown(
    () => {
      debounce(() => {
        if ((!profileForm.isValid && step == 0) || (invalidForms && step == 2)) return
        if (aboutYouForm.isValid && step == 1) {
          setStep(2)
          return
        }
        handleSubmit?.()
      }, 2000)
    },
    [isFocused, isModal, profileForm?.values, step],
    'enter',
  )

  return (
    <View variants={['flex', 'column']}>
      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        {steps.map(({ key, children }, index) => {
          return (
            <View variants={['fullWidth', 'column']} key={key} style={{ display: index === step ? 'block' : 'none' }}>
              <Header step={step} isMobile={isMobile} journalName={journalData?.journal_name} />
              {children}
              <Footer
                step={step}
                decreaseStep={decreaseStep}
                handleSubmit={handleSubmit}
                handleSocial={handleSocial}
                openLogin={openLogin}
                formValidation={{
                  isProfileFormValid: profileForm.isValid,
                  isAboutYouFormValid: aboutYouForm.isValid,
                  isAboutYourWorkFormValid: aboutYourWorkForm.isValid,
                }}
              />
            </View>
          )
        })}
      </View>
    </View>
  )
}
