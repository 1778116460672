import { SelectComposition, SelectPresets } from '@codeleap/web'
import { Theme, themeSpacing, variantProvider } from '../theme'
import { assignTextStyle } from './Text'

const createSelectStyle = variantProvider.createVariantFactory<SelectComposition>()

const defaultStyles = SelectPresets

const placeholderSize = 120
const STATUS_WIDTH = 240

export const AppSelectStyles = {
  ...defaultStyles,
  default: createSelectStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
    },
    innerWrapper: {
      minHeight: theme.values.itemHeight.default,
      maxHeight: theme.values.itemHeight.default,
      borderRadius: theme.borderRadius.tiny,
      ...theme.spacing.paddingHorizontal(2),
      ...theme.border.neutral5({ width: 1 }),
      paddingVertical: theme.spacing.value(0),
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifySpaceBetween,
      backgroundColor: theme.colors.neutral1,
      position: 'relative',
      cursor: 'pointer',

      '*': {
        fontFamily: theme.typography.base.fontFamily,
      },
    },
    'innerWrapper:searchable': {
      cursor: 'text',
    },
    'innerWrapper:focus': {
      ...theme.border.primary3({ width: 1 }),
    },
    'innerWrapper:error': {
      ...theme.border.destructive2({ width: 1 }),
    },
    'innerWrapper:disabled': {
      ...theme.border.neutral2({ width: 1 }),
      cursor: 'not-allowed',
    },
    input: {
      ...assignTextStyle('p1')(theme).text,
      flex: 1,
      width: '100%',
      color: theme.colors.neutral10,
      borderWidth: 0,
      margin: theme.spacing.value(0),
      padding: theme.spacing.value(0),
      height: '100%',
    },
    'input:focus': {
      caretColor: theme.colors.primary3,
      border: 'none',
    },
    'input:disabled': {
      color: theme.colors.neutral5,
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
    },
    'input:error': {
      color: theme.colors.destructive2,
    },
    'inputContainer': {
      flex: 1,
      width: '95%',
      border: 'none',
      height: '100%',
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifySpaceBetween,
      padding: theme.spacing.value(0),
      [theme.media.down('midish')]: {
        width: '90%',
      },
      [theme.media.down('smallish')]: {
        width: '85%',
      },
    },
    'inputContainer:focus': {
      border: 'none',
    },
    placeholder: {
      color: theme.colors.neutral7,
      position: 'absolute',
    },
    'placeholder:disabled': {
      color: theme.colors.neutral5,
    },
    'valueWrapper': {
      padding: theme.spacing.value(0),
      margin: theme.spacing.value(0),
      height: '100%',
      width: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    'valueMultiple': {
      width: 'auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    iconIcon: {
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
      color: theme.colors.neutral7,
    },
    'iconIcon:focus': {
      color: theme.colors.primary3,
    },
    'iconIcon:disabled': {
      color: theme.colors.neutral5,
      cursor: 'not-allowed',
    },
    'iconIcon:error': {
      color: theme.colors.destructive2,
    },
    iconTouchableWrapper: {
      ...theme.spacing.padding(0),
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
      backgroundColor: theme.colors.neutral1,
    },
    'iconTouchableWrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
    },
    leftIconTouchableWrapper: {
      ...theme.spacing.marginRight(2),
    },
    rightIconTouchableWrapper: {
      ...theme.spacing.marginLeft(2),
    },
    errorMessage: {
      ...theme.spacing.paddingLeft(2),
    },
    'dropdownIcon': {
      cursor: 'pointer',
    },
    'clearIcon': {
      cursor: 'pointer',
    },
    'itemsWrapper': {
      ...theme.presets.column,
      ...theme.spacing.gap(1),
    },
    itemWrapper: {
      width: '100%',
      height: '100%',
      minHeight: theme.values.itemHeight.default,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      overflow: 'hidden',
      borderRadius: theme.borderRadius.tiny,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.neutral1,

      transition: 'all 0.2s',

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'itemWrapper:focused': {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'itemWrapper:selected': {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'itemWrapper:selectedFocused': {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'itemText': {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral10,
      textAlign: 'left',
      wordBreak: 'break-word',
    },
    'itemText:selected': {
      ...assignTextStyle('h5')(theme).text,
    },
    'itemRightIcon': {
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
      color: theme.colors.neutral10,
    },
    listPortal: {
      zIndex: 99999,
    },
    listWrapper: {
      borderRadius: theme.borderRadius.tiny,
      width: '100%',
      cursor: 'default',

    },
    list: {
      ...theme.spacing.paddingVertical(0),
      ...theme.spacing.padding(2),
      maxHeight: theme.values.itemHeight.default * 6.5,
      overflowY: 'auto',
    },
    listPlaceholder: {
      minHeight: theme.values.itemHeight.default,
      ...theme.presets.column,
      ...theme.presets.center,
      ...theme.spacing.gap(2),
    },
    listPlaceholderIcon: {
      width: placeholderSize,
      ...theme.presets.center,
    },
    listPlaceholderText: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.neutral6,
      textAlign: 'center',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ...theme.presets.fullWidth,
      ...theme.presets.center,
    },
    listPlaceholderNoItems: {
      minHeight: theme.values.itemHeight.default,
      ...theme.presets.column,
      ...theme.presets.center,
      ...theme.spacing.gap(2),
    },
    listPlaceholderNoItemsIcon: {
      width: placeholderSize,
    },
    listPlaceholderNoItemsText: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.neutral6,
      textAlign: 'center',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ...theme.presets.fullWidth,
      ...theme.presets.center,
    },
    loadingIndicator: {
      minHeight: theme.values.itemHeight.default,
      ...theme.presets.center,
    },
  })),
  noError: createSelectStyle((theme) => ({
    errorMessage: {
      display: 'none',
    },
  })),
  filePreview: createSelectStyle((theme) => ({
    'itemWrapper:selected': {
      backgroundColor: theme.colors.primary4,

      '&:hover': {
        backgroundColor: theme.colors.primary4,
      },
    },
    'itemText:selected': {
      color: theme.colors.neutral1,
      fontWeight: 'normal',
    },
    'itemRightIcon': {
      color: theme.colors.neutral1,
    },
  })),
  strongOption: createSelectStyle((theme) => ({
    'itemWrapper:selected': {
      backgroundColor: theme.colors.primary4,

      '&:hover': {
        backgroundColor: theme.colors.primary4,
      },
    },
    'itemText:selected': {
      color: theme.colors.neutral1,
      fontWeight: 'normal',
    },
    'itemRightIcon': {
      color: theme.colors.neutral1,
    },
  })),
  status: createSelectStyle((theme) => ({
    wrapper: {
      minWidth: STATUS_WIDTH,
      zIndex: 2,
    },
    innerWrapper: {
      minHeight: 28,
      maxHeight: 28,
      borderRadius: theme.spacing.value(0.5),
      border: 0,
      ...theme.spacing.paddingHorizontal(0.5),
      ...theme.spacing.paddingVertical(0.25),
      backgroundColor: theme.colors.neutral2,
    },
    itemWrapper: {
      cursor: 'pointer',
    },
    'itemsWrapper': {
      ...theme.spacing.gap(1),
    },
    listWrapper: {
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.padding(2),
    },
    list: {
      padding: theme.spacing.value(0),
      border: 0,
    },
    value: {
      ...assignTextStyle('p2')(theme).text,
    },
    listPortal: {
      top: theme.values.itemHeight.tiny,
      zIndex: 99999,
    },
    iconIcon: {
      color: theme.colors.primary5,
    },
    dropdownIcon: {
      ...theme.presets.hidden,
    },
    placeholder: {
      ...assignTextStyle('p2')(theme).text,
      color: theme.colors.neutral8,
    },
  })),
  submitPublicationNewVersion: createSelectStyle((theme) => ({
    list: {
      overflowY: 'visible',
      maxHeight: 'auto',
    },
  })),
  rejectButtonsSelect: createSelectStyle((theme) => ({
    listWrapper: {
      minWidth: 300,
      ...Theme.spacing.marginBottom(2),
      borderRadius: theme.borderRadius.small,
      [theme.media.down('mid')]: {
        minWidth: 250,
      },
      [theme.media.down('small')]: {
        minWidth: 200,
      },
    },
    itemWrapper: {
      borderRadius: theme.borderRadius.tiny,
      ':hover': {
        backgroundColor: theme.colors.primary5,
      },
    },
    'itemWrapper:selected': {
      ':hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    itemText: {
      color: theme.colors.neutral1,
      fontWeight: '400',
    },
    'itemText:selected': {
      color: theme.colors.neutral1,
      fontWeight: '400',
    },
    errorMessage: {
      display: 'none',
    },
  })),
  'bg:alert3': createSelectStyle((theme) => ({
    innerWrapper: {
      backgroundColor: theme.colors.alert3,
    },
  })),
  'bg:alert4': createSelectStyle((theme) => ({
    innerWrapper: {
      backgroundColor: theme.colors.alert4,
    },
  })),
  'bg:destructive2': createSelectStyle((theme) => ({
    innerWrapper: {
      backgroundColor: theme.colors.destructive2,
    },
  })),
  'width-auto': createSelectStyle((theme) => ({
    innerWrapper: {
      maxWidth: theme.spacing.value(56),

      [theme.media.up('xxlarge')]: {
        maxWidth: theme.spacing.value(78),
      },

      [theme.media.down('small')]: {
        maxWidth: theme.spacing.value(45),
      },
    },
  })),
  borderless: createSelectStyle((theme) => ({
    innerWrapper: {
      border: 'none',
    },
    'innerWrapper:focus': {
      border: 'none',
    },

    'innerWrapper:error': {
      border: 'none',
    },
    'innerWrapper:disabled': {
      border: 'none',
    },

    'inputContainer': {
      border: 'none',
    },
    'inputContainer:focus': {
      border: 'none',
    },

    'input:error': {
      border: 'none',
    },

    'input:disabled': {
      border: 'none',
    },

  })),
}
