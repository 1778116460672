import { AppStatus } from '@/redux'
import { Button } from '.'
import { Toaster } from './Toaster'
import { Publication } from '@/types'

type BlindfoldedToasterProps = {
    publication?: Publication['id']
    showRightContent?: boolean
}

export const BlindfoldedToaster = (props: BlindfoldedToasterProps) => {

  const {
    showRightContent = true,
  } = props

  const RightContent = () => {

    if (!showRightContent) return null

    return (
      <Button
        variants={[`bg:info2`, 'blindfoldedToaster']}
        text={`Blinded review files`}
        debugName={`Open blindfolded journal flow`}
        onPress={() => AppStatus.setModal(['blindfoldedJournalFlow', true, { data: { publication: props?.publication }}])}
      />
    )
  }

  return (
    <Toaster
      icon='eye-off'
      variants={['bg:info1', 'blindfolded']}
      title={`Blinded review process.`}
      description={`This journal requires an edited version of the manuscript excluding author's names to progress with the review process.`}
      isVisible={true}
      RightContent={RightContent}
    />
  )
}
