import { AppImages, variantProvider } from '@/app'
import { View, Toaster, ActivityIndicator } from '@/components'

export const Scanning = () => {

  return (
    <View variants={['column', 'gap:3']}>
      <Toaster
        title="Verification in progress - please don't close the window, this can take a few moments."
        description="We are searching for any viruses or malwares that may be contained in the files you've submitted."
        isVisible={true}
        icon={'shield'}
        variants={['neutral2']}
      />

      <View variants={['fullWidth', 'center']} style={styles.loaderWrapper}>
        <ActivityIndicator
          debugName='Malware Verification: Loader'
          animation={AppImages.loadingAnimation}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  loaderWrapper: {
    maxWidth: theme.spacing.value(10),
    width: '100%',
    height: theme.spacing.value(10),
    ...theme.spacing.marginVertical(4),
    ...theme.presets.alignSelfCenter,
    boxShadow: `0 0px 10px #0000001A`,
    borderRadius: theme.borderRadius.rounded,
  },
}), true)
