import React from 'react'
import { TypeGuards, useCallback, useNestedStylesByKey } from '@codeleap/common'
import { Text, Icon, BulkCheckbox } from '@/components'
import { assignTextStyle, variantProvider } from '@/app'
import { TableColumnProps } from './types'

const _Icon = ({ icon, item, style = {}}) => {
  const _icon = TypeGuards.isFunction(icon) ? icon(item) : icon

  return <Icon name={_icon} debugName={`${_icon} table`} style={{ ...styles.icon, ...style }} renderEmptySpace />
}

export const TableColumn = ({ col, item, index }: TableColumnProps) => {
  const { key, component, text, leftIcon, rightIcon, mainColumn, showBulkSelection, styles: _styles } = col

  const MainContent = useCallback(() => {
    const _text = TypeGuards.isFunction(text) ? text(item) : item?.[key]

    if (TypeGuards.isFunction(component)) return component({ ...item, index })
    if (_text) return <Text css={[styles.itemText, mainColumn && styles['itemText:main']]} text={_text} />
    return null
  }, [component, text])

  const bulkStyles = useNestedStylesByKey('bulkSelection', styles)

  return (
    <div style={styles.wrapper}>
      {showBulkSelection && <BulkCheckbox item={item} col={col} styles={bulkStyles} />}
      {leftIcon && <_Icon item={item} icon={leftIcon} style={_styles?.leftIcon} />}
      <MainContent />
      {rightIcon && <_Icon item={item} icon={rightIcon} style={_styles?.rightIcon} />}
    </div>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing.value(2),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemText: {
    ...theme.presets.fullWidth,
    ...theme.presets.textCenter,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    [theme.media.down('small')]: {
      ...assignTextStyle('p3')(theme).text,
    },
  },
  'itemText:main': {
    ...theme.presets.textLeft,
    wordBreak: 'break-all',
    textOverflow: 'initial',
    whiteSpace: 'initial',
  },
  icon: {
    minWidth: 20,
    ...theme.sized(2.5),
  },
  bulkSelectionBox: {
    borderRadius: theme.borderRadius.tiny / 2,
    minWidth: 20,
    ...theme.sized(2.5),
  },
  bulkSelectionCheckmark: {
    minWidth: 16,
    ...theme.sized(2),
  },
  bulkSelectionErrorMessage: {
    display: 'none',
  },
  'bulkSelectionBox:checked': {
    backgroundColor: theme.colors.primary5,
    ...theme.border.primary5({ width: 1 }),
  },
}), true)
