import { Button, Link } from '@/components'
import { APIClient } from '@/services'
import { Publication } from '@/types'
type ButtonProps = {
  publication: Publication['id']
}

const DownloadSummary = (props: ButtonProps) => {
  const summary = APIClient.Publications.usePublicationSummary(props.publication)

  return <Button
    debugName={'Download manuscript'}
    variants={['small', 'center', 'paddingHorizontal:2', 'flat', 'loadingLeft']}
    icon='download'
    styles={{ text: { fontWeight: '600' } }}
    text={'Download PDF'}
    onPress={summary.download}
    loading={summary.isDownloading}
    loadingShowText={summary.isDownloading}
  />

}

const ViewSummary = (props: ButtonProps) => {
  const summary = APIClient.Publications.usePublicationSummary(props.publication)
  const button = <Button debugName={'View manuscript'}
    variants={['small', 'center', 'paddingHorizontal:2', 'flat', 'loadingLeft']}
    icon='eye'
    styles={{ text: { fontWeight: '600' } }}

    text={'View PDF'}
    onPress={summary?.url ? null : summary.warnUnavailable}
  />

  if (!summary.url) return button
  return <Link openNewTab to={summary.url} variants={['noUnderline']}>
    {button}
  </Link>
}

export const SummaryButtons = {
  DownloadSummary,
  ViewSummary,
}
