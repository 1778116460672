import { AppImages, variantProvider } from '@/app'
import { Image } from '@/components'
import { Icon, View } from '@codeleap/web'

export const ImagePlaceholder = ({ absolute = false, style = {}, showLogo = true, size = 73 }) => {
  return (
    <View css={[styles.imageWrapper, absolute ? { position: 'absolute', inset: 0, zIndex: 0 } : {}, style]} variants={['bg:neutral2', 'center']}>
      {showLogo ? <Icon name='logo-hat' size={size} variants={['color:neutral8', 'noFill']} style={{ position: 'absolute' }} /> : null}
      <Image source={AppImages?.placeholder_bg} objectFit='cover' style={styles?.image}/>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  imageWrapper: {
    position: 'relative',
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
  },
  image: {
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
  },
}), true)
