import { capitalize } from '@codeleap/common'
import faker from '@faker-js/faker'
import { IsDevEnv } from './Settings'

const fakeEmail = `tester+${faker.animal.type()}_${faker.datatype.number({ min: 0, max: 100 })}@codeleap.co.uk`
const fakeName = `${faker.name.firstName()} ${faker.name.lastName()}`
// const fakeFirstName = faker.name.firstName()
// const fakeLastName = faker.name.lastName()
const fakeOrganisation = faker.company.companyName()
// const fakeNumber = faker.phone.phoneNumber()
// const fakeDegree = faker.name.jobArea()
// const fakePosition = faker.name.jobTitle()
// const fakeDepartment = faker.name.jobType()
// const fakeAddress = faker.address.streetName() + faker.address.cityName()
// const fakerPostcode = faker.address.zipCode()
// const fakeOrcid = faker.datatype.number({ min: 1000000000000000, max: 9999999999999999 })

export const fakeProfile = {
  email: IsDevEnv ? fakeEmail : '',
  password: IsDevEnv ? 'pppppp' : '',
  repeatPassword: IsDevEnv ? 'pppppp' : '',
  avatar: IsDevEnv ? null : null,
  full_name: IsDevEnv ? fakeName : '',
  // first_name: IsDevEnv ? fakeFirstName : '',
  // last_name: IsDevEnv ? fakeLastName : '',
  organisation: IsDevEnv ? fakeOrganisation : '',
  // mobile: IsDevEnv ? fakeNumber : '',
  // phone: IsDevEnv ? fakeNumber : '',
  // degree: IsDevEnv ? fakeDegree : '',
  // position: IsDevEnv ? fakePosition : '',
  // department: IsDevEnv ? fakeDepartment : '',
  // address: IsDevEnv ? fakeAddress : '',
  // postcode: IsDevEnv ? fakerPostcode : '',
  // orcid: IsDevEnv ? fakeOrcid : '',
}

const _testers = {
  default: {
    email: 'tester@codeleap.co.uk',
    password: 'pppppp',
  },
  random: fakeProfile,
}

const mailList = ['paulo', 'gabriel', 'felipe', 'victor', 'vini', 'fernando', 'leo', 'lucas', 'kenzo'].sort((a, b) => a.localeCompare(b))

mailList.forEach(person => {
  _testers[person] = {
    email: `${person}@codeleap.co.uk`,
    password: 'pppppp',
    name: capitalize(person) + ' Codeleap',
  }
})

export const testers = _testers

export const testerOptions = Object.keys(_testers).map((testerId) => {
  return {
    value: testerId,
    label: capitalize(testerId),
  }
})
