import React, { PropsWithChildren, useMemo } from 'react'
import { OptionsDropDownStyles } from '@/app/stylesheets'
import { OptionsDropDownComposition } from '@/app/stylesheets/OptionsDropdown'
import { Button, View, Touchable, Text, Tooltip } from '@/components'
import { ComponentVariants, getNestedStylesByKey, PropsOf, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { Icon, IconProps, TooltipProps } from '@codeleap/web'
import { AppIcon } from '@/app'

export type OptionsDropDownProps = PropsWithChildren & ComponentVariants<typeof OptionsDropDownStyles>
  & Omit<PropsOf<typeof Touchable>, 'styles' | 'variants'>
  & {
    styles?: StylesOf<OptionsDropDownComposition>
    icon?: AppIcon
    iconProps?: Partial<IconProps>
    text?: string
    options: PropsOf<typeof Button>[]
    optionPress?: (param: { [x: string]: any }) => void
    tooltipProps?: Partial<TooltipProps>
  }

export const OptionsDropDown = ({
  variants = [],
  styles = {},
  style,
  responsiveVariants = {},
  icon,
  children,
  text,
  iconProps,
  options,
  optionPress,
  tooltipProps,
  ...rest
}: OptionsDropDownProps) => {
  const variantStyles = useDefaultComponentStyle<'u:OptionsDropDown', typeof OptionsDropDownStyles>('u:OptionsDropDown', {
    variants,
    rootElement: 'outerWrapper',
    styles,
    responsiveVariants,
  })

  const getStyles = (key: OptionsDropDownComposition) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const renderOptions = useMemo(() => {
    return (
      <>
        {options?.map((option, index) => (
          <Button
            styles={{ text: variantStyles.optionText }}
            key={index}
            css={[getStyles('option')]}
            {...option}
            onPress={() => option.onPress(optionPress)}
          />
        ))}
      </>
    )
  }, [options])

  return (
    <View
      {...rest}
      onClick={(e) => {
        // e.stopPropagation()
        e.preventDefault()
        // e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <Tooltip
        content={tooltipProps?.content ?? renderOptions}
        side='bottom'
        styles={getStyles('tooltip')}
        closeOnClickOutside={true}
        openOnPress={true}
        openOnHover={false}
        debugName={`Options DropDown`}
        arrowProps={{ style: getStyles('tooltipArrow') }}
        {...tooltipProps}
      >
        <View>
          {children}
          <View style={variantStyles.textIconWrapper}>
            {icon ? (
              <Icon
                name={icon}
                debugName={`Options DropDown Icon`}
                style={getStyles('icon')}
                {...iconProps}
              />
            ) : null}
            {text ? (
              <Text
                text={text}
                style={getStyles('text')}
              />
            ) : null}
          </View>
        </View>
      </Tooltip>
    </View>
  )
}

