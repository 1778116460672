import React from 'react'
import { variantProvider } from '@/app'
import { View, Text, Image, Touchable, Skeleton, Icon, ImagePlaceholder } from '@/components'
import { APIClient } from '@/services'
import { Journal } from '@/types'
import { PropsOf } from '@codeleap/common'
import { JournalSelector } from './JournalSelector'
import { Link } from 'gatsby'
import { formatMaximumNumber } from '@/utils'

export type JournalMenuItemProps = Partial<PropsOf<typeof Touchable>> & {
  journalId?: Journal['id']
}

export const JournalMenuItem = ({ journalId, css, ...rest }: JournalMenuItemProps) => {
  const { data: journal } = APIClient.Journals.useRetrieve({ id: journalId })

  const profile = APIClient.Session.useProfile()

  const stats = [
    {
      label: 'Submitted',
      value: journal?.my_submission_count ? formatMaximumNumber(journal?.my_submission_count, 999) : '0',
    },
    {
      label: 'Under review',
      value: journal?.under_review_articles ? formatMaximumNumber(journal?.under_review_articles, 999) : '0', // update
    },
    {
      label: 'Accepted',
      value: journal?.my_publication_count ? formatMaximumNumber(journal?.my_publication_count, 999) : '0',
    },
  ]

  const hasJournal = !!journal?.id

  const journalLink = <View variants={['alignCenter', 'row', 'gap:1']} component={Link} to={`/journals/view/${journal?.id}`} style={styles.details}>
    <Text variants={['p1', 'semiBold', 'color:primary5']}>
      Go to journal details
    </Text>

    <Icon name='arrow-right' variants={['size:2', 'color:primary5', 'noFill']} />

  </View>

  return <>
    <Skeleton enabled={profile?.isLoading} css={styles.image}>
      <JournalSelector />
      <View variants={['gap:2', 'marginBottom:3', 'padding:2', 'bg:neutral2', 'border-radius:tiny', 'column', 'alignCenter']}>
        <View variants={['row', 'gap:1']}>
          <View style={styles.imageWrapper}>
            {hasJournal && journal?.image ? <Image source={journal?.image} css={styles.image} /> : <ImagePlaceholder style={[styles.image, styles?.imagePlaceholder]}/>}
          </View>
          <View variants={['column', 'gap:1']}>
            {
              stats.map((stat, i) => (
                <View key={i} variants={['column', 'bg:neutral1', 'border-radius:nanoish', 'padding:1']} style={styles.box}>
                  <Text text={String(stat.value)} variants={['p1', 'semiBold', 'neutral-10']} style={{ lineHeight: 'initial' }}/>
                  <Text text={stat.label} variants={['p3']} style={{ lineHeight: 'initial' }}/>
                </View>
              ))
            }

          </View>
        </View>
        {
          hasJournal ? journalLink : <Text variants={['p2', 'color:neutral8']}>
              No details available
          </Text>
        }
      </View>
    </Skeleton>
  </>
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.center,
  },
  rightWrapper: {
    ...theme.presets.column,
    marginLeft: theme.spacing.value(1),
  },
  image: {
    // width: 150,
    // aspectRatio: 180 / 240,
    maxWidth: 156,
    maxHeight: 212,
    objectFit: 'contain',
    borderRadius: theme.spacing.value(0.5),
    marginVertical: 'auto',
  },
  imagePlaceholder: {
    width: 156,
    height: 212,

  },
  imageWrapper: {
    overflow: 'hidden',
    height: 'fit-content',
    alignSelf: 'center',
    borderRadius: 8,
    ...theme.border.neutral3({ width: 2 }),

  },
  text: {
    display: 'block',
    display: '-webkit-box',
    maxWidth: 200,
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  details: {
    textDecoration: 'none',
  },
  box: {
    height: 65,
  },
}), true)
