import { Profile } from '@/types'

const fullName = (profile: Profile) => profile?.last_name
  ? `${profile?.first_name} ${profile?.last_name}`
  : profile?.first_name

const cleanPhoneNumberMask = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '')
}

export const ProfileUtils = {
  fullName,
  cleanPhoneNumberMask,
}
