import React, { useRef, useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { AutocompletePrediction } from 'react-places-autocomplete'
import { TextInput, View, Text, TextInputProps, Touchable, ViewProps } from '@codeleap/web'
import { Settings, variantProvider } from '@/app'
import { useBooleanToggle } from '@codeleap/common'
import { retrievePostCode } from '@/utils'

type PlaceRowProps = {
  place?: AutocompletePrediction
  handlePlaceSelected?: (place: AutocompletePrediction) => void
}

type PlacesAutocompleteProps = ViewProps<'div'> & {
  itemsWrapperProps?: ViewProps<'div'>
  textInputProps?: TextInputProps
  restrictions?: Partial<google.maps.places.AutocompletionRequest>
  handleAddressValue: (address: string, postcode: string) => void
}

function getPlaceTitle(place: AutocompletePrediction) {
  const title = place?.structured_formatting?.main_text
  const description = place?.structured_formatting?.secondary_text ? `, ${place?.structured_formatting?.secondary_text}` : ''

  return `${title}${description}`
}

const PlaceRow = (props: PlaceRowProps) => {
  const { place, handlePlaceSelected } = props

  const mainText = getPlaceTitle(place)

  return (
    <Touchable
      debugName={`PlaceRow:${place?.place_id}`}
      onPress={() => handlePlaceSelected(place)}
      variants={['border-radius:tiny']}
      style={styles.placesRowWrapper}
    >
      <View variants={['row', 'alignCenter', 'justifyStart', 'paddingVertical:1.75', 'paddingHorizontal:2', 'fullWidth']} >
        <Text text={mainText} variants={['p1', 'color:neutral8']} />
      </View>
    </Touchable>
  )
}

export const PlacesAutocomplete = (props: PlacesAutocompleteProps) => {
  const { itemsWrapperProps, textInputProps, handleAddressValue, restrictions, ...rest } = props

  const [isFocused, toggleFocused] = useBooleanToggle(false)
  const [inputValue, setInputValue] = useState('')

  const containerRef = useRef(null)

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: Settings.ApiCredentials.GoogleMaps.ApiKey,
  })

  const hasPredictions = placePredictions?.length > 0

  const handlePlaceSelected = async (place) => {
    const mainText = getPlaceTitle(place)

    const postcode = await retrievePostCode({ place, placesService })

    getPlacePredictions({ input: mainText })
    setInputValue(mainText)
    handleAddressValue(mainText, postcode)
    toggleFocused(false)
  }

  return (
    <View ref={containerRef} variants={['column', 'relative']} {...rest}>
      <TextInput
        rightIcon={{ name: 'chevron-down' }}
        onFocus={() => toggleFocused(true)}
        onBlur={(event) => {
          if (!containerRef.current.contains(event.relatedTarget)) {
            toggleFocused(false)
          }
        }}
        onChange={(evt) => {
          setInputValue(evt.target.value)
          getPlacePredictions({
            input: evt.target.value,
            types: ['address'],
            ...restrictions,
          })
        }}
        {...textInputProps}
        value={inputValue || textInputProps?.value}
      />
      {isFocused && hasPredictions && (
        <View variants={['border-radius:small', 'backgroundColor:neutral1', 'padding:2', 'column']} style={styles.wrapper} {...itemsWrapperProps}>
          {placePredictions.map((place) => {
            return (
              <PlaceRow key={place?.place_id} place={place} handlePlaceSelected={handlePlaceSelected} />
            )
          })}
        </View>
      )}
    </View>
  )
}

const MAX_HEIGHT = 500

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.effects.light,
    ...theme.presets.fullWidth,
    position: 'absolute',
    top: '80%',
    zIndex: 99,
    maxHeight: MAX_HEIGHT,
    overflowY: 'auto',
    border: `1px solid ${theme.colors.neutral4}`,
  },
  placesRowWrapper: {
    '&:hover': {
      backgroundColor: theme.colors.neutral2,
    },
  },
}), true)
