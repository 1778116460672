import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'

export type PlaceholderComposition = 'wrapper'
 | 'iconWrapper'
 | 'icon'
 | 'text'
 | 'image'
 | 'button'
 | 'textsWrapper'

const createPlaceholderStyle = variantProvider.createVariantFactory<PlaceholderComposition>()

export const PlaceholderStyles = {
  default: createPlaceholderStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.presets.center,
      ...theme.spacing.gap(2),
      ...theme.spacing.paddingVertical(10),
    },
    iconWrapper: {
      width: theme.spacing.value(7),
      height: theme.spacing.value(7),
      ...theme.presets.center,
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.rounded,
    },
    icon: {
      ...theme.sized(3),
    },
    image: {
      width: '60%',
    },
    textsWrapper: {
      ...theme.presets.column,
    },
    text: {
      ...theme.presets.textCenter,
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
    },
    button: { marginTop: theme.spacing.value(1) },
  })),
}
