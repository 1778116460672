import { ToolbarStyles } from '@/app/stylesheets'
import { useDefaultComponentStyle } from '@codeleap/common'
import { View } from '@codeleap/web'
import { useToolBarContext } from '../ToolbarContext'
import { ToolbarComponents } from '.'

export const Content = () => {
  const { toolbarProps, ...ctx } = useToolBarContext()
  const { responsiveVariants, variants, styles } = toolbarProps

  const variantStyles = useDefaultComponentStyle<'u:Toolbar', typeof ToolbarStyles>('u:Toolbar', {
    responsiveVariants,
    variants,
    styles,
  })

  return (
    <View style={variantStyles.toolbarWrapper}>
      {ctx?.options.map((group, key) => {
        return (
          <View key={key} style={variantStyles.toolbarGroup}>
            {group.map((option) => {
              const isActive = option?.isActive?.({ editor: ctx?.editor }) || ctx?.editor?.isActive?.(option.id)
              const optionStyles = {
                wrapper: { ...variantStyles.option, ...(isActive && variantStyles['option:active']) },
                'wrapper:disabled': variantStyles['option:disabled'],
              }
              const optionComponentProps = { option, styles: optionStyles, ...ctx }
              return option?.custom ?
                option.custom({ defaultBtn: (props) => <ToolbarComponents.Option {...optionComponentProps} {...props}/>, ...optionComponentProps })
                : <ToolbarComponents.Option key={option.id} {...optionComponentProps}/>
            })}
          </View>
        )
      })}
    </View>
  )

}
