import { AppImages, Settings, variantProvider } from '@/app'
import { View, Image, Text } from '@/components'
import { Profile } from '@/types'
import { onUpdate } from '@codeleap/common'
import { format } from 'date-fns'

export const JournalData = ({ profile }: { profile: Profile }) => {
  onUpdate(() => {
    // Making sure the image actually loads. Do I hate it? yes. Do I have a better solution? no.
    const image = new window.Image()
    image.src = profile?.publisher_data?.logo

  }, [profile?.publisher_data?.logo])

  return (
    <View style={{ height: '100%', width: '100%' }} variants={['justifySpaceBetween', 'column']} >
      <Image source={AppImages.Logo} style={styles.logo} variants={['marginLeft:4', 'marginTop:4']}/>

      <View style={styles.titleWrapper} variants={['justifySpaceBetween', 'paddingVertical:2', 'paddingHorizontal:4', 'column']}>
        <Text text={profile?.publisher_data?.title} variants={['h2', 'color:neutral1']}/>
        <Text text={'Publisher Update'} variants={['h4', 'color:neutral1']}/>
        <Image
          source={profile?.publisher_data?.logo}
          variants={['absolute']}
          style={styles.publisherLogo}
          loading='eager'
        />
      </View>

      <View variants={['marginLeft:4', 'marginBottom:4', 'column', 'gap:2']}>

        <Text text={`Presented by: ${profile?.full_name}`}/>
        <Text text={`Date: ${format(new Date(), 'yyyy-MM-dd')}`}/>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  logo: {
    width: 200,
    aspectRatio: 2255 / 542,
  },
  titleWrapper: {
    backgroundColor: theme.colors.neutral10,
    minHeight: 150,
  },
  publisherLogo: {
    aspectRatio: Settings.CropPickerConfigs.default.aspect,
    height: 400,
    top: 250,
    right: 250,
  },
}), true)
