import { AppIcon, variantProvider } from '@/app'
import { OptionsDropDown, OptionsDropDownProps, View, Button, ActivityIndicator, Text } from '@/components'
import { Publisher } from '@/types'
import { APIClient } from '@/services'
import { useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { capitalize, ellipsis, useBooleanToggle } from '@codeleap/common'
import { Navigation } from '@/utils'

export type PublisherSelectorProps = Partial<OptionsDropDownProps> & {}

export const PublisherSelector = (props: PublisherSelectorProps) => {
  const { updateProfile, profile } = APIClient.Session.useSession()
  const [visible, toggle] = useBooleanToggle(false)
  const publishers = APIClient.Publishers.usePublisherOptions()
  const queryClient = useQueryClient()

  const handleChange =
    async (publisher: Publisher) => {
      try {
        toggle()
        if (publisher.id === profile?.current_publisher) return

        await updateProfile.mutateAsync({
          id: profile?.id,
          current_publisher: publisher.id,
          current_journal: null,
          current_role: 'author',
        })

        queryClient.clear()

        Navigation.navigate('Manuscripts.List')
      } catch {

      }
    }

  const options = useMemo(() => {
    if (publishers.loading) { //*
      return (
        <View variants={['center']}>
          <ActivityIndicator debugName='RoleSelector:Loading' />
        </View>
      )
    } else if (!publishers?.loading && !publishers.options?.length) {
      return (
        <View variants={['center', 'marginHorizontal:auto', 'padding:1']}>
          <Text variants={['p2', 'wrap']} text='No publishers found'/>
        </View>
      )
    }

    return publishers.options.map((publisher) => {
      const selected = profile?.current_publisher === publisher.id

      return (
        <Button
          variants={['roleSelector', 'text:left']}
          text={ellipsis(publisher.title, 20)}
          debugName={`Publisher:${publisher.id}`}
          onPress={() => handleChange(publisher)}
          selected={selected}
          rightIcon={selected && 'check'}
          key={publisher.id}
        />
      )

    })

  }, [publishers.loading, publishers.options, handleChange, profile?.current_publisher])

  return (
    <OptionsDropDown
      debugName='RoleSelector'
      variants={['optionText:primary5', 'optionLines:none', 'textIcon:reverse', 'textIcon:spacing1']}
      tooltipProps={{
        visible,
        toggle,
        styles: {
          'content:bottom': styles.tooltipBottom,
          arrow: styles.tooltipArrow,
        },
        content: options,
        closeOnClickOutside: true,
      }}
      text={capitalize(profile?.publisher_data?.title ?? '')}
      icon={'chevron-down' as AppIcon}
      options={[]}
      {...props}
    />
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    tooltipBottom: {
      zIndex: 99999,
      width: 220,
      maxHeight: 350,
      overflow: 'auto',
      ...theme.presets.elevated,
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.marginRight('110px'),
    },
    tooltipArrow: {
      display: 'none',
      width: 0,
      height: 0,
    },
  }),
  true,
)
