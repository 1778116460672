import { api } from '@/app'
import { Disclaimer, Publication } from '@/types'

const BASE_URL = '/publisher/accept_disclaimer/'

export const sendAcceptedDisclaimers = async (article: Publication['id'], acceptedDisclaimers: Disclaimer['id'][]) => {
  const response = await api.post(BASE_URL, { article, disclaimers: acceptedDisclaimers })
  return response.data
}

export * from './questions'
export * from './views'
