import { onUpdate } from '@codeleap/common'
import { useMemo, useState } from 'react'

export function useTable() {
  const [tableWidth, setTableWidth] = useState<number>(0)

  onUpdate(() => {
    const el = document.getElementById('table-container')
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) setTableWidth(entry.contentRect.width)
    })

    if (el) observer.observe(el)

    return () => {
      if (el) observer.unobserve(el)
    }
  }, [])

  const mainColumnWidth = useMemo(() => {
    const el = document.getElementById('body')
    let val = tableWidth
    if (el) {
      const [row] = el?.children
      const cells = row.children
      for (const cell of cells) {
        if (cell.id === 'fixed') {
          const cellWidth = window.getComputedStyle(cell)?.width
          val -= Number(cellWidth.replace('px', ''))
        }
      }
    }
    return val
  }, [tableWidth])

  return { mainColumnWidth }
}
