import { APIClient } from '@/services'
import { findRegionById, findRegionIndex } from '../address'
import { PhoneProps } from './usePhoneOptions'

type UseRegionPhoneMaskParams = {
  options: PhoneProps[]
  regionValue: number
}

export const useRegionPhoneMask = (props: UseRegionPhoneMaskParams) => {
  const { options, regionValue } = props
  const { regions } = APIClient.Session.useSession()

  const defaultMask = 'xx-xxxx-xxxx'

  const region = findRegionById({ regions: regions, value: regionValue })

  const countriesPhoneIndex = findRegionIndex({
    regionName: region ? region?.title : 'United Kingdom', countries: options,
  })

  const regionMask = options[countriesPhoneIndex]?.mask

  const defaultMaskingOptions = {
    mask: regionMask ? regionMask : defaultMask,
    formatChars: {
      'x': '[0-9]',
      '0': '[0-9]',
    },
  }

  return {
    region,
    regionMask,
    defaultMaskingOptions,
  }
}
