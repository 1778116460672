import { variantProvider } from '@/app'
import {
  ActionIcon,
  ExpandableView,
  JournalFieldEditor,
  JournalTab,
  JournalURLDomainInput,
  PicturePicker,
  Text,
  TextInput,
  View,
} from '@/components'
import { PropsOf } from '@codeleap/common'
import { ModalComposition, ModalProps } from '@codeleap/web'

const portalImageResolutionText = 'Recommended image resolution: 1720 x 400 pixels'
const journalImageResolutionText = 'Recommended image resolution: 900 x 1200 pixels'

type ModalHeaderProps = Partial<Omit<ModalProps, 'children'>> & {
  id: string
  variantStyles: PropsOf<ModalComposition>
  onPressClose: () => void
}

type PicturePickerProps = ModalHeaderProps & {
  label?: string
}

const PicturePickerHeader = (props: PicturePickerProps) => {
  const { label, onPressClose } = props

  return (
    <View variants={['fullWidth', 'marginBottom:3', 'column']}>
      <View variants={['row', 'justifySpaceBetween', 'marginBottom:0.5']}>
        <Text text={'Crop Image'} variants={['h3', 'color:neutral10']} />
        <ActionIcon
          debugName='close:modal'
          icon={'x'}
          onPress={onPressClose}
          variants={['minimal']}
          styles={{
            touchableWrapper: styles.touchableWrapper,
          }}
        />
      </View>
      <Text text={label} />
    </View>
  )
}

export function PortalPreferencesTab({ tab, form }) {
  return (
    <JournalTab currentTab={tab} tab='preferences' style={{ gap: 40 }}>
      <ExpandableView title='General' initialState contentVariants={['fullWidth', 'column', 'gap:3']}>
        <PicturePicker
          field='cover'
          label='Portal cover'
          renderDescription={() => <Text text={portalImageResolutionText} variants={['p4', 'marginTop:0.5']} />}
          modalProps={{
            renderHeader: (props) => {
              return <PicturePickerHeader {...props} label={portalImageResolutionText} />
            },
          }}
        />

        <View variants={['row', 'gap:6', 'fullWidth', 'alignStart', 'justifyStart']}>
          <PicturePicker
            field='image'
            label='Journal Logo'
            renderDescription={() => (
              <Text text={journalImageResolutionText} variants={['p4', 'marginTop:0.5']} style={styles.description} />
            )}
            modalProps={{
              renderHeader: (props) => {
                return <PicturePickerHeader {...props} label={journalImageResolutionText} />
              },
            }}
          />
          <PicturePicker
            field='publisher_logo'
            label='Publisher Logo'
            renderDescription={() => (
              <Text text={journalImageResolutionText} variants={['p4', 'marginTop:0.5']} style={styles.description} />
            )}
            modalProps={{
              renderHeader: (props) => {
                return <PicturePickerHeader {...props} label={journalImageResolutionText} />
              },
            }}
          />
        </View>

        <View variants={['column', 'fullWidth']}>
          <TextInput {...form.register('title')} />
          <JournalFieldEditor
            field='description'
            placeholder='Description'
            label='Description'
            renderErrorSpace
            excludeIds={['textStyles', 'highlight']}
          />
          <JournalURLDomainInput />
        </View>
      </ExpandableView>

      <ExpandableView title='Journal portal instructions' initialState contentVariants={['fullWidth', 'column']}>
        <JournalFieldEditor
          field='aims_scope'
          placeholder='Aims and Scope'
          label='Aims and Scope'
          renderErrorSpace
          excludeIds={['textStyles', 'highlight']}
        />
        <TextInput {...form.register('issn')} />
        <JournalFieldEditor
          field='editorial_board'
          placeholder='Editorial Board'
          label='Editorial Board'
          renderErrorSpace
          excludeIds={['textStyles', 'highlight']}
        />
      </ExpandableView>

      <ExpandableView title='Journal portal footer' initialState contentVariants={['fullWidth', 'column']}>
        <JournalFieldEditor
          field='portal_footer'
          placeholder='Portal footer'
          excludeIds={['textStyles', 'highlight']}
        />
      </ExpandableView>
    </JournalTab>
  )
}

const DESCRIPTION_MAX_WIDTH = 185

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    touchableWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    description: {
      maxWidth: DESCRIPTION_MAX_WIDTH,
    },
  }),
  true,
)
