import { QueryManager } from '@codeleap/common'
import { navigate } from 'gatsby'
import { useMediaQuery } from '@codeleap/web'
import { View, Text, Table, StateAction } from '@/components'
import { useTableProps } from '@/utils'
import { Theme, variantProvider, React } from '@/app'
import { Dashboard } from '..'
import { journalsTypeFilterData } from '../filters'
import { differenceInDays, parse } from 'date-fns'
import { JournalSubmissions } from '@/types'

type JournalDataProps = {
  journalTypeFilter: { journalsTypeSorter?: Record<'value' | 'label', string>}
  setJournalTypeFilter: StateAction<JournalDataProps['journalTypeFilter']>
  startDate: string
  endDate: string
  dashboardJournals: ReturnType<QueryManager<JournalSubmissions>['use']>
  tableProps: ReturnType<typeof useTableProps>
}

function getMonthSums(dates) {
  const sumByMonth = {}

  for (const dateStr in dates) {
    const dateParts = dateStr?.split(' - ')
    const month = dateParts[1].toLowerCase()
    if (!sumByMonth[month]) {
      sumByMonth[month] = 0
    }
    sumByMonth[month] += dates[dateStr]
  }

  return sumByMonth
}

function createColumn({ key, label, normalizedKey = null }) {
  const keyParts = key.split(' - ')[1].split('/')
  const _normalizedKey = normalizedKey ? normalizedKey : `${keyParts[0]}/${keyParts[1]}/${keyParts[2]}`

  return {
    key: key,
    label: label,
    text: (item) => {
      const sums = getMonthSums(item.dates)
      return sums[_normalizedKey?.toLowerCase?.()] || '0'
    },
  }
}

export const JournalTable = (props: JournalDataProps) => {
  const { dashboardJournals, tableProps, endDate, startDate, setJournalTypeFilter, journalTypeFilter } = props

  const isTablet = useMediaQuery(Theme.media.down('largeish'))
  const isMobile = useMediaQuery(Theme.media.down('small'))

  const { isLoading } = tableProps

  const columns = () => {
    if (!dashboardJournals?.items) {
      return []
    }

    const dates = dashboardJournals?.items[0]?.dates
    const daysRange = Math.abs(differenceInDays(
      parse(startDate, 'dd/MM/yyyy', new Date()),
      parse(endDate, 'dd/MM/yyyy', new Date()),
    ))

    const isDays = daysRange <= 12
    const isWeeks = daysRange <= 84

    if (isDays) {
      return Object.entries(dates).map(([key]) => {
        const keyParts = key.split(' - ')[1].split('/')
        const label = `${keyParts[0]}/${keyParts[1]}`
        return createColumn({ key, label })
      })
    } else if (isWeeks) {
      return Object.entries(dates).map(([key], index) => {
        return createColumn({ key, label: `WK${index + 1}` })
      })
    } else {
      return Object.entries(dates).map(([key]) => {
        const keyParts = key.split(' - ')[1].split('/')
        const label = `${keyParts[0]}`
        return createColumn({ key, label, normalizedKey: keyParts[0] })
      })
    }
  }

  return (
    <>
      <View variants={['column', 'gap:2', 'justifyCenter', 'alignStart']}>
        <Text variants={['h4', 'color:primary5']} text='Journals overview' />
        <Dashboard.SortModal
          modalProps={{
            selectedItems: journalTypeFilter,
            setSelectedItems: setJournalTypeFilter,
            responsiveVariants: { midish: ['fullscreen', 'fullHeight'] },
          }}
          filterData={journalsTypeFilterData}
          text={'Total submissions'}
          modalTitle={'Journals filter'}
          sentenceText={journalTypeFilter?.journalsTypeSorter?.label}
        />
      </View>
      <View variants={['column', 'fullWidth', 'marginBottom:4']} style={!isLoading && isTablet ? styles.overflow : null}>
        <Table
          {...tableProps}

          showPlaceholder
          placeholderProps={{ icon: 'license', variants: ['table'], title: 'No data found' }}
          onRowPress={(item) => navigate('/journals/' + item.id)}
          columnMap={[
            {
              key: 'title',
              label: 'JOURNAL TITLE',
              leftIcon: 'file',
              text: ({ title }) => title,
              mainColumn: isMobile ? false : true,
            },
            ...columns?.(),
          ]}
        />
      </View>

    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  overflow: {
    overflowX: 'scroll',
  },
}), true)
