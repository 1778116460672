import { JournalQuestion } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from '../../queryClient'
import { api } from '@/app'

const BASE_URL = 'publisher/questions/'

async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<JournalQuestion>>(BASE_URL, {
    params,
  })

  return response.data
}

type QuestionFilters = {
    journal?: string
}

export const publisherQuestionsManager = new QueryManager<JournalQuestion>({
  itemType: {} as JournalQuestion,
  name: 'publisherQuestions',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: QuestionFilters) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
})
