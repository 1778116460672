import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'

export type SubHeaderComposition = 'wrapper' | 'title'

const createCardBaseStyle =
  variantProvider.createVariantFactory<SubHeaderComposition>()

export const SubHeaderStyles = {
  default: createCardBaseStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignCenter,
      ...theme.spacing.marginBottom(5),
    },
    title: {
      ...assignTextStyle('h1')(theme).text,
      color: theme.colors.neutral10,
    },
  })),
}
