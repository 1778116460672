import { React } from '@/app'
import { useAppSelector } from '@/redux'
import { AuthModal, BeforeForgotPasswordForm, AfterForgotPasswordForm } from '@/components'
import { useState } from '@codeleap/common'

export const ForgotPasswordModal = (props) => {
  const isBeforeForgotOpen = useAppSelector(store => store.AppStatus.modals.beforeForgot)
  const isAfterForgotOpen = useAppSelector(store => store.AppStatus.modals.afterForgot)

  const [step, setStep] = useState(isAfterForgotOpen ? 2 : 1)

  const [resendEmail, setResendEmail] = useState(null)

  const toggleForm = (email) => {
    setResendEmail(email)
    setStep(2)
  }

  if (!isBeforeForgotOpen && !isAfterForgotOpen) return null

  return (
    <AuthModal isOpen={isBeforeForgotOpen || isAfterForgotOpen} step={step} name={isBeforeForgotOpen ? 'beforeForgot' : 'afterForgot'}>
      {
        step === 1
          ? <BeforeForgotPasswordForm isModal onRequest={toggleForm}/>
          : <AfterForgotPasswordForm isModal email={resendEmail} />
      }
    </AuthModal>
  )
}
