import { ExpandableView, View, Text, ActionIcon, AttachmentsDropzone, Attachment, StateAction } from '@/components'
import { variantProvider } from '@/app'
import { APIClient } from '@/services'
import { useRef } from 'react'
import { downloadFileFromURL, formatFileName, humanizedFileSize } from '@/utils'
import { NamePreview } from './NamePreview'
import { fileSizeValidator } from '@/app/forms'

type ReviewAttachmentsDropzoneProps = {
  attachments: Attachment[]
  setAttachments: StateAction<ReviewAttachmentsDropzoneProps['attachments']>
  tableAttachments?: Attachment[]
  setTableAttachments?: StateAction<ReviewAttachmentsDropzoneProps['tableAttachments']>
  attachmentsToBeDeleted: Attachment[]
  setAttachmentsToBeDeleted: StateAction<ReviewAttachmentsDropzoneProps['attachmentsToBeDeleted']>
  publicationAttachmentsToBeDeleted: Attachment[]
  setPublicationAttachmentsToBeDeleted: StateAction<ReviewAttachmentsDropzoneProps['publicationAttachmentsToBeDeleted']>
  disabled: boolean
  reviewers: any[]
}

export const ReviewAttachmentsDropzone = (props: ReviewAttachmentsDropzoneProps) => {

  const {
    attachments,
    setAttachments,
    tableAttachments,
    setTableAttachments,
    setAttachmentsToBeDeleted,
    publicationAttachmentsToBeDeleted,
    setPublicationAttachmentsToBeDeleted,
    disabled,
    reviewers,
  } = props

  const ref = useRef()

  const { isEditor, isPublisher, isAuthor, profile } = APIClient.Session.useSession()

  const onDropAttachment = (file) => {
    setAttachments((state) => {
      if (!!Object.keys?.(state)?.length) {
        return [...state, ...file]
      } else {
        return file
      }
    })
  }

  const onDownloadAttachment = ({ attachment }) => {
    downloadFileFromURL(attachment.file)
  }

  const onDeleteAttachment = (media) => {

    setAttachments((state) => {
      const items = { ...state }
      const index = state?.findIndex(item => JSON.stringify(item) === JSON.stringify(media))
      delete items[index]
      return Object.values(items)
    })

    if (!media?.editor_media) {
      setAttachmentsToBeDeleted((state) => {
        if (state) {
          return ([...state, media?.item])
        } else {
          return [media?.item]
        }
      })
    } else {
      setPublicationAttachmentsToBeDeleted((state) => {
        if (state) {
          return ([...state, media?.id])
        } else {
          return [media?.id]
        }
      })
    }

  }

  const renderAttachmentItem = ({ item, index, ...props }) => {
    const currentReviewer = reviewers.find((reviewer) => {
      if (item.editor_media) {
        return reviewer?.editor
      } else {
        return reviewer?.reviewer === item?.id
      }
    })

    const isLocal = item instanceof File

    const isReviewer = reviewers.find(reviewer => reviewer?.reviewer === item?.id)

    const name = isLocal ? item?.name : formatFileName(item?.file)

    const isValidSize = fileSizeValidator(item)

    const fileSize = humanizedFileSize(isLocal ? item.size : item?.file_size)

    const reviewer = isLocal || !isReviewer ? {
      full_name: 'Editor',
      avatar: profile?.avatar,
    } : item

    return (
      <View variants={['justifySpaceBetween', 'borderBottom', 'paddingVertical:2', 'alignStart', 'marginBottom:3', 'gap:2', 'column', 'fullWidth']}>

        <View variants={['gap:1', 'row', 'alignCenter', 'fullWidth']}>

          <NamePreview
            reviewer={reviewer}
            showMoreInfoIcon={false}
            index={index}
            color={currentReviewer?.color}
            position={currentReviewer?.position}
            amount={reviewers?.length}
          />

          <View variants={['gap:1', 'alignCenter']}>
            {
              !isLocal && (
                <ActionIcon
                  variants={['small', 'minimal']}
                  icon='download'
                  debugName='attachaments download'
                  onPress={() => onDownloadAttachment({ attachment: item })}
                />

              )
            }
            {(isEditor || isPublisher) && <ActionIcon
              icon='x'
              debugName='attachments delete'
              onPress={() => disabled ? null : onDeleteAttachment(item)}
              variants={['small', 'destructive:minimal', disabled && 'icon:disabled']}
            />}
          </View>

        </View>

        <View variants={['column', 'fullWidth', 'alignStart', 'gap:0.5']}>
          <View variants={['row', 'fullWidth', 'justifySpaceBetween', 'alignStart', 'gap:2']}>
            <Text
              variants={[`p1`]}
              style={{ wordBreak: 'break-all' }}
              text={name}
            />
            <Text variants={[`p1`, 'noWrap']} text={fileSize} />
          </View>

          {isValidSize ? <Text variants={['p3', 'color:destructive2']} text={isValidSize?.message} /> : null}
        </View>
      </View>
    )
  }

  if (!attachments.length && isAuthor) {
    return null
  }

  return (
    <ExpandableView styles={{ contentInnerWrapper: { paddingTop: 0 }}} initialState title='Attachments'>

      <AttachmentsDropzone
        ref={ref}
        showTable={false}
        showDropzone={false}
        showFileCategory
        currentAttachments={attachments}
        setCurrentAttachments={setAttachments}
        tableAttachments={tableAttachments}
        setTableAttachments={setTableAttachments}
        onDelete={onDeleteAttachment}
        enableCategorySelector={false}
        showSecondaryAddMoreBtn={isEditor || isPublisher}
        addMoreButtonProps={{
          disabled: disabled,
          variants: ['gap:1', 'flat', 'marginHorizontal:auto'],
          onDrop: onDropAttachment,
        }}
      >
        {attachments?.length ? (
          <View style={styles.scroll}>
            {attachments?.map?.((item, index) => renderAttachmentItem({ item, index }))}
          </View>
        ) : <View variants={['marginBottom:2']} />}

      </AttachmentsDropzone>

    </ExpandableView>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.spacing.paddingRight(1),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: 100,
    maxHeight: 300,
  },
}), true)
