import { TypeGuards, useState } from '@codeleap/common'
import { Dispatch, SetStateAction, useMemo } from 'react'

type useConditionalStateType = <T>(
  initialValue: T | undefined,
  setter: Dispatch<SetStateAction<T>> | any,
  options?: { fallbackValue?: T; hook?: (initialValue: T) => [T, (Dispatch<SetStateAction<T>> | any)] | any }
) => [T, Dispatch<SetStateAction<T>> | any]

export const useConditionalState: useConditionalStateType = (initialValue, setter, options) => { // it does not look like it is gonna work, but it kinda does
  const { fallbackValue, hook } = options
  const [vl, setVl] = useState(fallbackValue)
  const hookReturn = hook?.(fallbackValue)

  if (!TypeGuards.isNil(initialValue) && TypeGuards.isFunction(setter)) {
    return [initialValue, setter]
  }

  return hook ? hookReturn : [vl, setVl]
}
