import { List, ManageEditorsProps } from '@/components'
import { APIClient } from '@/services'
import { Journal, Publication } from '@/types'
import { PropsOf, useState } from '@codeleap/common'

export type useManageEditorsProps = {
  journal: Journal['id']
  publication: Publication
}

export const useManageEditors = (props: useManageEditorsProps) => {
  const {
    journal,
    publication,
  } = props

  const [editorsSearch, setEditorsSearch] = useState('')

  const { profile } = APIClient.Session.useSession()

  const { editors, listProps, tableProps } = APIClient.Publications.useEditors({
    journal,
    search: editorsSearch,
  })

  const publicationEditors = publication?.editors?.filter((e) => e.role_permission === 'editor')

  const manageEditorsModalProps: ManageEditorsProps = {
    listProps: listProps as unknown as PropsOf<typeof List>,
    editors: editors?.items,
    publicationEditors,
    publication: publication?.id,
    profileId: profile?.id,
    searchInputProps: {
      placeholder: 'Search for editors',
      onSearchChange: setEditorsSearch,
      debounce: 1000,
      value: editorsSearch,
    },
  }

  return {
    editors,
    listProps,
    tableProps,
    editorsSearch,
    setEditorsSearch,
    manageEditorsModalProps,
  }
}
