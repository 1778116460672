import { NavigateOptions } from '@reach/router'
import { ROUTES } from '@/app'
import { navigate as navigateGatsby } from 'gatsby'
import { TypeGuards } from '@codeleap/common'

// @ts-ignore
type CreateRoutes<T, Prefix extends string = ''> = {
  [K in keyof T & string]: T[K] extends string
  ? `${Prefix}${Prefix extends '' ? '' : '.'}${K}`
  : CreateRoutes<T[K], `${Prefix}${Prefix extends '' ? '' : '.'}${K}`>
}[keyof T]

export type RoutePath = string & CreateRoutes<typeof ROUTES>

function getRoutePath(path: RoutePath): string {
  let route = ROUTES

  if (path?.includes('.')) {
    const indexesAccess = path?.split('.')

    for (const index of indexesAccess) {
      route = route?.[index]
    }
  } else {
    route = route?.[path]
  }

  return (route ?? path) as string
}

export type NavigateOption<T> = {
  route?: string | number
  params?: Record<string, string>
  routeParams?: Record<string, string>
} & NavigateOptions<T>

const defaultNavigateOptions = {
  route: null,
  params: null,
}

function navigate<S = any>(route: RoutePath, options: NavigateOption<S> = defaultNavigateOptions) {
  const {
    route: routeParam = null,
    params = null,
    routeParams = null,
    ...navigateParams
  } = options

  let path = getRoutePath(route)

  if (!TypeGuards.isNil(routeParam)) {
    path = `${path}/${String(routeParam)}`
  }

  if (!TypeGuards.isNil(routeParams)) {
    Object.keys(routeParams)?.forEach((paramKey) => {
      const value = routeParams?.[paramKey]

      if (value == null) {
        path = path.replace(`/{{${paramKey}}}`, '')
      } else {

        path = path.replace(`{{${paramKey}}}`, value)
      }

    })
  }

  if (!TypeGuards.isNil(params)) {
    let searchParams = ''

    Object.keys(params)?.forEach((paramKey, index) => {
      const newParam = `${paramKey}=${encodeURIComponent(params?.[paramKey])}`
      const separator = index === 0 ? '' : '&'

      searchParams = `${searchParams}${separator}${newParam}`
    })

    path = `${path}?${searchParams}`
  }

  navigateGatsby(path, navigateParams)
}

function to<T extends {} = {}>(to: string, options?: NavigateOptions<T>) {
  navigateGatsby(String(to), options)
}

function getStackRoutes(baseKey: keyof typeof ROUTES) {
  const routes = []

  function traverse(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        routes.push(obj[key])
      } else if (typeof obj[key] === 'object') {
        traverse(obj[key])
      }
    }
  }

  if (ROUTES[baseKey] && typeof ROUTES[baseKey] === 'object') {
    traverse(ROUTES[baseKey])
  } else if (typeof ROUTES[baseKey] === 'string') {
    routes.push(ROUTES[baseKey])
  }

  return routes
}

export const Navigation = {
  navigate,
  to,
  getRoutePath,
  getStackRoutes,
}
