import { DashboardSortModal } from './SortModal'
import { DashboardDatePicker } from './DatePicker'
import { DashboardHorizontalBarChart } from './HorizontalBarChart'
import { DashboardOverviewCard } from './OverviewCard'

import { JournalData } from './Sections/JournalData'
import { statusChartColors } from './chartColors'
import { Chart } from './Sections/Chart'
import { JournalTable } from './Sections/JournalTable'
import { Export } from './Sections/Export'

export const Dashboard = {
  SortModal: DashboardSortModal,
  DatePicker: DashboardDatePicker,
  HorizontalBarChart: DashboardHorizontalBarChart,
  OverviewCard: DashboardOverviewCard,
}

export const DashboardChartColors = {
  statusChartColors,
}

export const DashboardSections = {
  JournalData,
  JournalTable,
  Chart,
  Export,
}

export * from './filters'
