import { ExpandableView, JournalFieldEditor, JournalOverlay, JournalTab, TemplateVariables, Text, useJournalCrudContext, View } from '@/components'
import { Email, EmailCode } from '@/types'
import { useMemo } from 'react'
import { emailFieldProps } from '../CrudContext/defaultSettings'

type RenderSectionProps = {
  emails: Email[]
  title: string
}

function RenderSection({ emails, title }: RenderSectionProps) {
  return (
    <ExpandableView title={title} initialState contentVariants={['fullWidth', 'column']}>
      <TemplateVariables variants={['marginBottom:3']}/>
      {emails.map((email, index) => {
        const { field, from, to } = emailFieldProps[email.code]

        return <View key={`${email.code}-${index}`} variants={['column', 'gap:1']}>
          <Text variants={['p2']} text={email.title}/>
          <View variants={['column']}>
            {from && <Text variants={['p3']} text={`From: ${from}`}/>}
            {to && <Text variants={['p3']} text={`To: ${to}`}/>}
          </View>
          <JournalFieldEditor field={field as any} key={`${email.code}-${index}`} renderErrorSpace validate/>
        </View>
      })}
    </ExpandableView>

  )
}

export function EmailTemplateTab({ tab }) {
  const { emails } = useJournalCrudContext()

  const notificationsEmails = useMemo(() => {
    const codes: EmailCode[] = [
      'reviewer_invitation',
      'revision_decision',
      'rejected_decision',
      'editor_assigment',
      'manuscript_transferred',
      'reviews_complete',
      'review_invite_accepted',
      'completed_review',
      'manuscript_submission',
      'rejected_resubmission_decision',
      'accepted_decision',
    ]
    return emails?.filter((email) => codes.includes(email?.code))
  }, [emails])

  const remindersEmails = useMemo(() => {
    const codes: EmailCode[] = ['revise_reminder', 'late_review_reminder']
    return emails?.filter((email) => codes.includes(email?.code))
  }, [emails])

  if (!emails) return <JournalOverlay/>
  return (
    <JournalTab currentTab={tab} tab='email' style={{ gap: 40 }}>
      <RenderSection emails={notificationsEmails} title='Email notifications'/>
      <RenderSection emails={remindersEmails} title='Email reminders'/>
    </JournalTab>
  )
}
