import { Modal, Text, View, Button, TextInput, TextEditor, TextEditorExtensions, ToolBar } from '@/components'
import { useEditor } from '@tiptap/react'
import { onUpdate, useForm } from '@codeleap/common'
import { AppForms } from '@/app'
import { DisclaimerItemProps, DisclaimerModalProps } from '@/types'
import Placeholder from '@tiptap/extension-placeholder'

export const DisclaimerModal = (props: DisclaimerModalProps) => {

  const {
    onSave,
    item = null,
    order,
    journal,
    toggle,
    visible,
  } = props

  const type = !!item ? 'edit' : 'create'
  const form = useForm(AppForms.journalDisclaimerForm)

  const editor = useEditor({
    content: '',
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => form.setFieldValue('description', editor.getHTML()),
  })

  const onSubmit = () => {

    const data: DisclaimerItemProps = {
      id: item?.id,
      created_datetime: new Date().toISOString(),
      title: form?.values?.title,
      body: form?.values?.description,
      order,
    }

    onSave?.({ type, data })
    toggle()

    onClearForm()
  }

  const onCloseModal = () => {
    const isFormDirty = form?.values?.title?.length || !editor?.isEmpty
    if (type === 'create' && isFormDirty) {
      onClearForm()
    }
    toggle()
  }

  const onClearForm = () => {
    form.setFormValues({ title: '', description: '' })
    editor?.commands?.setContent('')
  }

  onUpdate(() => {
    if (item && type === 'edit') {
      form.setFormValues({ title: item?.title, description: item?.body })
      if (item?.body && !editor?.getText()) {
        editor?.commands?.setContent(item?.body)
      }
    }

    if (type === 'create') {
      onClearForm()
    }
  }, [item, type])

  const shoudDisableEditSubmit = [
    form?.values?.title === item?.title && form?.values?.description === item?.body,
    (editor?.isEmpty || form?.values?.title.length === 0),
  ].some(x => x)

  const submitDisabled = item ? shoudDisableEditSubmit : !form.isValid

  return (
    <Modal
      toggle={onCloseModal}
      debugName={`Journal ${journal} disclaimer modal - ${type} disclaimer action`}
      variants={['centered', 'disclaimerModal']}
      showClose={false}
      visible={visible}
    >
      <Text variants={[`h3`, 'marginBottom:3']} text={`Author disclaimer`} />

      <TextInput {...form.register('title')} />

      <View variants={['fullWidth', 'column']}>
        <Text text={'Description'} variants={['p2', 'color:neutral7', 'paddingBottom:1']} />
        <TextEditor
          editor={editor}
          toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'fileComponent']} variants={['disclaimerToolbar']} />}
          variants={['disclaimerModalTextEditor']}
          {...form.register('description')}
        />
      </View>

      <View variants={['fullWidth', 'marginTop:3', 'marginBottom:4', 'center', 'gap:2']}>
        <Button
          text={`Save`}
          icon={'save'}
          debugName={`On ${type} disclaimer`}
          onPress={onSubmit}
          disabled={submitDisabled}
          variants={['large']}
        />
        <Button
          text={`Cancel`}
          debugName={`Disclaimer Modal - close modal`}
          onPress={onCloseModal}
          variants={['flat', 'large']}
        />
      </View>
    </Modal>
  )
}
