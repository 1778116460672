import React from 'react'
import { AppImages, I18N, Theme, variantProvider } from '@/app'
import { Text, View, ActionIcon, Modal, Image } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'

type OrganisationInfoModalProps = {
  onClose?: () => void
}

export const Editor = (props: OrganisationInfoModalProps) => {
  const isOpen = useAppSelector(store => store.AppStatus.modals.editor)

  return (
    <Modal
      debugName={'Organisation Info Modal'}
      visible={isOpen}
      showClose={false}
      variants={['centered', 'center', 'boxPadding:0']}
      onClose={() => AppStatus.setModal('editor')}
      zIndex={9999}
      styles={{
        box: styles.box,
      }}
      {...props}
    >
      <View variants={['relative']}>
        <Image
          source={AppImages.Editor}
          objectFit='cover'
          style={styles.image}
        />
        <ActionIcon
          name={'x'}
          variants={['minimal', 'neutral1']}
          iconProps={{
            size: Theme.values.iconSize[3],
          }}
          style={styles.icon}
          onPress={() => AppStatus.setModal('editor')}
        />
      </View>
      <View variants={['column', 'gap:2', 'padding:4']}>
        <Text variants={['h1', 'alignCenter', 'textCenter', 'marginTop:2']} text={I18N.t('modals.editor.title')} />
        <Text variants={['marginTop:2']} text={I18N.t('modals.editor.description')} />
        <Text variants={['textLeft']}>
          &#8226; {I18N.t('modals.editor.list')}
        </Text>
      </View>
    </Modal>
  )
}

const imageSize = 480
const MODAL_WIDTH = 480
const styles = variantProvider.createComponentStyle((theme) => ({
  box: {
    width: MODAL_WIDTH,
  },
  image: {
    width: imageSize,
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}), true)
