import { ListComposition } from '@codeleap/web'
import { Theme, variantProvider } from '../theme'

export type PaginationButtonsComposition =
  | 'wrapper'
  | 'itemWrapper'
  | 'itemWrapper:selected'
  | 'text'
  | 'text:selected'
  | 'text:hover'
  | `list${Capitalize<ListComposition>}`

const createPaginationButtonStyle = variantProvider.createVariantFactory<PaginationButtonsComposition>()

const ITEM_SIZE = Theme.values.itemHeight.small

export const AppPaginationButtonStyles = {
  default: createPaginationButtonStyle((theme) => ({
    listWrapper: {
      minHeight: 'auto',
    },
    wrapper: {
      ...theme.spacing.marginTop(5),
      ...theme.presets.center,
    },
    itemWrapper: {
      width: ITEM_SIZE,
      height: ITEM_SIZE,
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.nano,
      '&:hover': {
        backgroundColor: theme.colors.primary4,
        color: theme.colors.neutral1,
      },
    },
    'itemWrapper:selected': {
      backgroundColor: theme.colors.primary5,
    },
    text: {
      color: theme.colors.primary5,
    },
    'text:hover': {
      color: theme.colors.neutral1,
    },
    'text:selected': {
      color: theme.colors.neutral1,
    },
  })),
}
