import { AppStatus, useAppSelector } from '@/redux'
import { ActionIcon, ActivityIndicator, Button, ExpandableView, Modal, RenderRichText, Text, View } from '@/components'
import { variantProvider } from '@/app'
import { APIClient } from '@/services'
import { downloadFileFromURL, formatFileName, humanizedFileSize } from '@/utils'
import { useState } from 'react'
import { onUpdate } from '@codeleap/common'
import { PlagiarismChecker } from '../Publication/Plagiarism'
import { Publication } from '@/types'

export const ReplicaOverviewModal = ({ publication }: { publication?: Publication }) => {

  const { visible, data: { article, ...modalParams }} = useAppSelector(state => state.AppStatus.modals.replicaOverview)
  const { isEditor, isPublisher, file_categories } = APIClient.Session.useSession()

  const [replica, setReplica] = useState([])
  const [replicaMedia, setReplicaMedia] = useState([])
  const [loadingComment, setLoadingComment] = useState(true)

  onUpdate(() => {
    if (article) {
      APIClient.Replica.retrieveVersion({ article, version: modalParams?.version || publication?.version })
        .then((response) => {
          setReplica(response?.results[0])
          APIClient.Replica.retrieveReplicaMedia({ reply: response?.results[0]?.id }).then((media) => setReplicaMedia(media))
        })
        .finally(() => setLoadingComment(false))
    }
  }, [visible, article, publication])

  const media = replicaMedia?.results
  const comment = replica?.comment

  const toggle = () => {
    AppStatus.setModal(['replicaOverview', false])
    setTimeout(() => AppStatus.setModal(['replicaOverview', false, { data: {}}]), 500)
  }

  const AttachmentItem = ({ item }) => {

    const filename = formatFileName(item?.file)
    const currentCategory = file_categories?.find(cat => cat?.value === item?.file_category)
    return (
      <View variants={['fullWidth', 'justifySpaceBetween', 'alignCenter', 'padding:1', 'borderBottom', 'gap:1']}>
        <Text variants={['p1', 'color:neutral8', 'ellipsis', 'breakWord', 'marginRight:auto']} style={{ maxWidth: 330, minWidth: 330 }} text={filename} />
        <View variants={['fullWidth', 'marginLeft:auto', 'textLeft', 'marginLeft:auto']} style={{ minWidth: 200, maxWidth: 200 }}>
          <Text variants={['p1', 'ellipsis']} text={currentCategory?.title || ''} />
        </View>

        <View variants={['gap:4', 'alignCenter', 'justifySpaceBetween']} style={{ maxWidth: 140, minWidth: 140 }}>
          <Text variants={['p1', 'color:neutral8', 'marginLeft:auto']} text={humanizedFileSize(item?.file_size)} />
          <ActionIcon
            icon={'download'}
            variants={['transparent', 'neutral10', 'iconSize:2']}
            onPress={() => downloadFileFromURL(item?.file)}
            debugName={`Download file ${item?.file_name} `}
          />
        </View>
      </View>
    )
  }

  const Comment = () => {

    if (loadingComment) {
      return (
        <View style={styles.commentWrapperScroll} variants={['center']}>
          <ActivityIndicator debugName={'Loading Indicator - Review Comment'} />
        </View>
      )
    }

    return (
      <View style={styles.commentWrapperScroll}>
        {comment ? <RenderRichText content={comment} /> : <Text variants={['p3', 'color:neutral8']} text='No comments provided' />}
      </View>
    )
  }

  return (
    <Modal
      toggle={toggle}
      debugName='Manuscript Review Overview Modal'
      variants={['centered', 'replicaOverviewModal']}
      visible={visible}
      showClose={false}
    >

      <View variants={['column', 'fullWidth', 'gap:4']}>
        <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}>
          <Text variants={[`h3`]} text={`Manuscript version ${modalParams?.version}`} />
          <ActionIcon
            icon='x'
            debugName='Manuscript Review Overview Modal - close modal icon on press'
            variants={['filterIcon', 'primary5', 'iconSize:3']}
            onPress={toggle}
          />
        </View>

        {modalParams?.version > 1 ? (
          <View variants={['fullWidth', 'backgroundColor:neutral2', 'padding:2', 'column', 'border-radius:small']}>
            <Text variants={[`p2`, 'marginBottom:1', 'color:neutral8', 'semiBold']} text={`Author comments`} />
            <Comment />
          </View>
        ) : null}

        <View variants={['fullWidth', 'column', 'gap:4']}>
          <ExpandableView title='Attachments' initialState>
            <View style={styles.scroll}>
              {media?.map?.((item) => <AttachmentItem key={item?.id} item={item} />)}
            </View>
          </ExpandableView>

          <ExpandableView title='Plagiarism check' initialState>
            <PlagiarismChecker
              id={publication?.id}
            />
          </ExpandableView>

        </View>
      </View>

      {(isEditor || isPublisher) && (
        <Button
          text='Submit decision'
          icon='eyeglass-2'
          debugName='Open decision from version modal'
          variants={['large', 'marginHorizontal:auto', 'marginTop:auto']}
          onPress={() => AppStatus.transitionModals(['replicaOverview', 'reviewsOverview'])}
        />
      )}
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  commentWrapperScroll: {
    minHeight: 150,
    maxHeight: 180,
    overflowY: 'auto',
    [theme.media.down('xxlarge')]: {
      maxHeight: 150,
    },
    [theme.media.down('mid')]: {
      maxHeight: 100,
    },
  },
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.spacing.paddingRight(1),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: 150,
    maxHeight: 260,
    [theme.media.down('xxlarge')]: {
      maxHeight: 200,
    },
    [theme.media.down('mid')]: {
      maxHeight: 170,
    },
  },
}), true)
