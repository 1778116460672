import { AppStatus } from '@/redux'
import { Disclaimer, Journal, JournalQuestion } from '@/types'
import { api, AppForms, OSAlert } from '@/app'
import {
  getJournalDomain,
  Navigation,
  serializeObject,
  useFileState,
  compressBase64Image,
  getBase64Size,
  base64ToFile,
} from '@/utils'
import { onUpdate, useForm, waitFor } from '@codeleap/common'
import { useFileInput } from '@codeleap/web'
import React, { createContext, useContext } from 'react'
import { ImageField, JournalCrudProviderData, JournalCrudProviderProps } from './types'
import { journalsDisclaimerManager, journalsManager } from '../../../services/api/journals'
import {
  getRecommendationQuestion,
  hasDisclaimersChanged,
  hasJournalDataChanged,
  hasQuestionsChanged,
  hasRecommendationQuestionChanged,
  usePublishers,
} from './utils'
import { APIClient } from '@/services'
import {
  defaultAuthorDisclaimer,
  defaultQuestions,
  defaultRecommendationQuestion,
  emailFieldProps,
} from './defaultSettings'

const JournalCrudContext = createContext<JournalCrudProviderData>({} as JournalCrudProviderData)

const DEBUG = false
const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20mb

export const JournalCrudProvider = (props: JournalCrudProviderProps) => {
  const { children, journalId } = props

  const isCreate = !journalId || journalId?.length < 1

  const { profile } = APIClient.Session.useSession()
  const journal = !isCreate
    ? journalsManager.useItem({
      id: journalId,
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    })
    : ({} as ReturnType<typeof journalsManager.useItem>)
  const createJournal = journalsManager.useCreate()
  const updateJournal = journalsManager.useUpdate()
  const deleteJournal = journalsManager.useDelete()

  const updateDisclaimers = journalsDisclaimerManager.useUpdate()
  const createDisclaimers = journalsDisclaimerManager.useCreate()

  const previousDisclaimers = !!journalId
    ? journalsDisclaimerManager.useItem({
      id: journalId,
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    })
    : ({} as ReturnType<typeof journalsDisclaimerManager.useItem>)

  const urlDomain = journal?.data?.url_domain

  const publishers = usePublishers()
  const emails = APIClient.Email.useEmails()

  const form = useForm(AppForms.journalForm)

  const cover = useFileState()
  const image = useFileState()
  const publisherLogo = useFileState()

  const [loaded, setLoaded] = React.useState(isCreate)

  const [disclaimers, setDisclaimers] = React.useState<Disclaimer[]>([])
  const [questions, setQuestions] = React.useState<Journal['questions']>([])
  const [recommendationQuestion, setRecommendationQuestion] = React.useState<JournalQuestion>({} as JournalQuestion)

  const clearForm = () => {
    form.reset()
    cover.delete()
    image.delete()
    publisherLogo.delete()

    setDisclaimers([])
    setQuestions([])
    setRecommendationQuestion({} as JournalQuestion)
  }

  const onPickImage = async (field: ImageField, fileInput: ReturnType<typeof useFileInput>) => {
    const files = await fileInput.openFilePicker()

    if (!files.length) return

    const [f] = files

    const compressedBase64Image = await compressBase64Image(f?.preview)
    const imageSize = getBase64Size(compressedBase64Image)

    if (imageSize > MAX_FILE_SIZE) {
      OSAlert.error({
        title: 'File is Too large',
        body: 'File is too large. Maximum size is 20MB.',
      })
      return
    }

    const newFile = base64ToFile(compressedBase64Image, f.file)

    form.setFieldValue(field as any, compressedBase64Image)
    if (field === 'cover') cover.set(newFile)
    else if (field === 'image') image.set(newFile)
    else if (field === 'publisher_logo') publisherLogo.set(newFile)
  }

  const onDeleteImage = async (field: ImageField) => {
    form.setFieldValue(field as any, null)
    if (field === 'cover') cover.delete()
    else if (field === 'image') image.delete()
    else if (field === 'publisher_logo') publisherLogo.delete()
  }

  const handleSubmit = async ({ disclaimers, ...values }, files) => {
    window?.scrollTo(0, 0)
    AppStatus.set('loading')

    if (!!values?.url_domain && values?.url_domain !== urlDomain && isCreate) {
      const validDomain = await journalsManager.actions.checkURLDomain(getJournalDomain(values?.url_domain))

      if (!validDomain) {
        AppStatus.set('idle')
        OSAlert.error({
          title: 'Invalid Domain',
          body: 'URL Domain is already in use',
        })
        return
      }
    }

    const data: Journal = serializeObject(values as Journal, (key, value) => {
      if (key == 'cover' || key == 'image' || key == 'publisher_logo') {
        if (!value && !files[key]) return null
        if (!!value && !!files[key] && value !== files[key]) return files[key]
        return value
      }

      if (key == 'url_domain' && isCreate) return getJournalDomain(value)

      return value
    })

    delete data.questions

    if (DEBUG) logger.log('Journal Submit', { data, isCreate })

    try {
      let currentJournalId = journalId

      if (isCreate) {
        const response = await createJournal.create(data)
        currentJournalId = response?.id
        await APIClient.Session.update({
          id: profile.id,
          current_publisher: response.publisher.id,
          current_journal: currentJournalId,
        })
        await waitFor(2000)
      } else {
        await updateJournal.update(data)
      }

      const _hasQuestionsChanged = hasQuestionsChanged(values?.questions || [], [...questions, recommendationQuestion])

      const _hasRecommendationQuestionChanged = hasRecommendationQuestionChanged(
        getRecommendationQuestion(values?.questions),
        recommendationQuestion,
      )

      if (_hasQuestionsChanged || _hasRecommendationQuestionChanged) {
        const _questions = questions.map((question) => ({
          ...(typeof question?.id == 'string' ? {} : { id: question?.id }),
          question: question?.question,
          type: 'number',
        }))

        const isRecommendationQuestionValid =
          !!recommendationQuestion?.question && !!recommendationQuestion?.choices?.length

        if (isRecommendationQuestionValid) {
          _questions.push({ question: recommendationQuestion?.question, type: 'multiple-choice' })
        }

        const response = await api.post('publisher/questions/update_many/', _questions, {
          params: {
            journal: currentJournalId,
          },
        })

        const recommendationQuestionData = getRecommendationQuestion(response?.data) ?? ({} as JournalQuestion)

        if (isRecommendationQuestionValid) {
          const choicesData = recommendationQuestion?.choices?.map((c) => ({
            ...(typeof c.id == 'string' ? {} : { id: c.id }),
            question: c.question,
            label: c.label,
            value: c.value,
          }))

          await api.post('publisher/choices/update_many/', choicesData, {
            params: {
              question: recommendationQuestionData?.id,
            },
          })
        }
      }

      if (hasDisclaimersChanged(disclaimers, previousDisclaimers?.data || [])) {
        await updateDisclaimers.update({ journal: currentJournalId, data: disclaimers })
        //We may not night that piece, but keep it here in case it breaks again
        // const newDisclaimers = disclaimers?.filter((d) => TypeGuards.isUndefined(d?.id))
        // if (newDisclaimers?.length) {
        //   createDisclaimers.create({ journal: currentJournalId, data: newDisclaimers })
        // }
      }

      await journalsManager.refresh()
      await previousDisclaimers?.refresh?.()

      Navigation.navigate('Journals.List', { route: 'view/' + currentJournalId })

      await waitFor(500)

      clearForm()

      AppStatus.set('done')
    } catch (err) {
      AppStatus.set('idle')
      logger.error('CRUD journal error', err)
    }
  }

  const onDelete = React.useCallback(async (data: Journal) => {
    try {
      AppStatus.set('loading')

      await deleteJournal.delete(data)

      journalsManager.refresh()

      Navigation.navigate('Journals.List')

      await waitFor(500)

      clearForm()

      AppStatus.set('done')
    } catch (err) {
      AppStatus.set('idle')
      logger.error('Delete Journal', err)
    }
  }, [])

  const handleDelete = () => {
    OSAlert.ask({
      title: 'Are you sure you want to delete?',
      body: 'Deleting this journal will result in the loss of all your current work.',
      options: [
        { text: 'Cancel', onPress: () => null, variants: ['flat'] },
        { text: 'Delete', onPress: () => onDelete(journal?.data), variants: ['destructive'] },
      ],
    })
  }

  onUpdate(() => {
    if (!!journal?.data && !loaded && !isCreate) {
      const data = serializeObject(journal?.data, (key, value) => {
        if (key == 'publisher') return Number(value?.id)
        return value
      })

      if (DEBUG) logger.log('Journal initial state', { data })

      form.setFormValues(data)

      const _questions = journal?.data?.questions?.filter((q) => q.type !== 'multiple-choice')
      const _recommendationQuestion = getRecommendationQuestion(journal?.data?.questions)

      setQuestions(_questions)
      setRecommendationQuestion(_recommendationQuestion)
      setLoaded(true)
    }
  }, [journal?.data?.id, loaded])

  onUpdate(() => {
    if (isCreate) {
      if (publishers?.options?.length == 1) {
        const publisher = publishers?.options?.[0]
        if (publisher?.item?.logo) {
          form.setFieldValue('publisher_logo', publisher?.item?.logo)
        }
      }
      if (!!emails?.length) {
        const templates = emails?.reduce((acc, e) => ({ ...acc, [emailFieldProps[e.code]?.field]: e.body }), {})
        form.setFormValues({ ...form.values, ...templates })
      }
      setQuestions(defaultQuestions as JournalQuestion[])
      setRecommendationQuestion(defaultRecommendationQuestion as JournalQuestion)
      setDisclaimers(defaultAuthorDisclaimer)
    }
  }, [publishers?.options, emails])

  onUpdate(() => {
    if (previousDisclaimers?.data) {
      setDisclaimers(previousDisclaimers?.data)
    }
  }, [previousDisclaimers?.data])

  const submitDisabled = React.useMemo(() => {
    const isValid = form?.isValid || false
    if (!isValid || !recommendationQuestion?.choices?.[0]?.value) return true

    const hasDataChanged = hasJournalDataChanged(journal?.data, form?.values as any)
    const journalRecommendationQuestion = getRecommendationQuestion(journal?.data?.questions)
    const _hasRecommendationQuestionChanged = hasRecommendationQuestionChanged(
      journalRecommendationQuestion,
      recommendationQuestion,
    )
    const _hasQuestionsChanged = hasQuestionsChanged(journal?.data?.questions || [], [
      ...questions,
      recommendationQuestion,
    ])
    const _hasDisclaimersChanged = hasDisclaimersChanged(disclaimers, previousDisclaimers?.data)

    return !(
      hasDataChanged ||
      _hasQuestionsChanged ||
      _hasRecommendationQuestionChanged ||
      recommendationQuestion?.choices?.[0]?.value ||
      _hasDisclaimersChanged
    )
  }, [
    journal?.data,
    form?.values,
    form?.isValid,
    questions,
    disclaimers,
    previousDisclaimers?.data,
    recommendationQuestion,
    isCreate,
  ])

  const providerData: JournalCrudProviderData = {
    handleDelete,
    handleSubmit,
    onPickImage,
    onDeleteImage,
    isCreate,
    form,
    journal,
    loaded,
    journalId,
    publishers,
    submitDisabled,
    files: {
      cover,
      image,
      publisherLogo,
    },
    manager: {
      create: createJournal,
      del: deleteJournal,
      update: updateJournal,
    },
    questions,
    setQuestions,
    recommendationQuestion,
    setRecommendationQuestion,
    setDisclaimers,
    disclaimers,
    previousDisclaimers,

    emails,
  }

  return <JournalCrudContext.Provider value={providerData}>{children}</JournalCrudContext.Provider>
}

export const useJournalCrudContext = () => {
  return useContext(JournalCrudContext)
}
