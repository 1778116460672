import { JournalCardComposition, JournalCardStyles } from '../../app/stylesheets/JournalCard'
import { CardBase, CardBaseProps, Image, ImagePlaceholder, RenderRichText, Text, View } from '@/components'
import { Journal, RolePermissionsObj } from '@/types'
import { useGetStyles } from '@/utils'
import { arePropsEqual, ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import React from 'react'

type JournalCardProps = {
  journal: Journal
  styles?: StylesOf<JournalCardComposition>
  style?: React.CSSProperties
  showTag?: boolean
} & Omit<CardBaseProps, 'debugName' | 'styles' | 'variants'> &
  ComponentVariants<typeof JournalCardStyles>

function JournalCardComponent({ journal, ...rest }: JournalCardProps) {
  const { variants, responsiveVariants, styles, showTag = false } = rest

  const variantStyles = useDefaultComponentStyle<'u:JournalCard', typeof JournalCardStyles>('u:JournalCard', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  return (
    <CardBase {...rest} debugName={`journal card ${journal?.id}`} styles={getStyles('card')}>
      <View css={getStyles('imageWrapper')}>
        {journal?.image ? (
          <Image source={journal?.image} alt={journal?.title} css={getStyles('image')} objectFit='cover' />
        ) : (
          <ImagePlaceholder />
        )}
      </View>
      <View css={getStyles('innerWrapper')}>
        <Text css={getStyles('title')} text={journal?.title} />
        {journal?.title?.length < 65 ? <RenderRichText content={journal?.description} styles={getStyles('description')} /> : null}
        {showTag ? (
          <View css={getStyles('tagWrapper')}>
            <Text css={getStyles('tag')} text={RolePermissionsObj?.[journal?.role_permission]} />
          </View>
        ) : null}
      </View>
    </CardBase>
  )
}

export const JournalCard = React.memo(JournalCardComponent, (prev, next) => arePropsEqual(prev, next, {
  check: ['journal'],
})) as React.FC<JournalCardProps>
