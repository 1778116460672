import { Permission, PermissionLevel, PermissionLevels } from '@/types'
import {
  View,
  Text,
  Checkbox,
  SelectItemProps,
  SelectModal,
  Button,
  SelectModalProps,
} from '@/components'
import { OSAlert, variantProvider } from '@/app'
import { EmptyPlaceholder } from '@codeleap/web'

export type PermissionFormCardProps = {
  title: string
  permission: Permission
  onJournalChange?: (permission: Permission, value: SelectItemProps) => void
  journalSelectModalProps?: SelectModalProps
  onLevelChange?: (props: { level: PermissionLevel; value: boolean; permission: Permission }) => void
  onRemove?: (permission: Permission) => void
}

const levels = [
  'role_publisher',
  'role_managing_editor',
  'role_editor_chiefe',
  'role_editor'] as PermissionLevel[]

export const PermissionFormCard = ({
  title,
  permission,
  onRemove,
  onLevelChange,
  onJournalChange,
  journalSelectModalProps,
}: PermissionFormCardProps) => {
  const { role_editor, role_editor_chiefe, role_managing_editor, role_publisher } = permission
  const { listProps, ...journalModalProps } = journalSelectModalProps
  const handleDeletePermission = () => {
    OSAlert.ask({
      title: 'Are you sure you want to remove this permission?',
      body: 'Removing this user will result in the loss of all its privileges as part of the Journals.',
      options: [
        { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
        { text: 'Remove', onPress: () => onRemove?.(permission), variants: ['large'] },
      ],
    })
  }

  const isInvalid = !role_editor && !role_editor_chiefe && !role_managing_editor && !role_publisher

  return (
    <View style={styles?.permissionCard}>
      <Text text={title} variants={['h5', 'color:neutral8']} />

      {!!journalSelectModalProps && (
        <SelectModal
          {...journalModalProps}
          listProps={{
            ...listProps,
            ListEmptyComponent: () => (
              <EmptyPlaceholder icon='file' variants={['table']} title='No Journals found.' />
            ),
          }}
          placeholder='Select a journal'
          value={{
            label: permission?.journal?.title,
            value: permission?.journal?.id,
          }}
          onValueChange={(item) => onJournalChange(permission, item as SelectItemProps)}
        />
      )}

      <View css={styles.checkpointWrapper}>
        {levels.map(level => (
          <Checkbox
            key={level}
            label={PermissionLevels[level]}
            checkboxOnLeft
            variants={['left']}
            style={styles.checkpoint}
            value={!!permission[level]}
            debugName={`Permission:Checkbox${level}`}
            onValueChange={() => onLevelChange?.({
              level,
              permission,
              value: !permission[level],
            })
            }
          />
        ))}
      </View>
      {isInvalid ? <Text variants={['p3', 'color:destructive2']} text='Please delete the permission if the user no longer has any permissions.' /> : null}
      <Button
        text='Delete'
        icon='trash'
        onPress={handleDeletePermission}
        css={styles.deleteButton}
        variants={['destructive:flat']}
        debugName={`PermissionDelete:${permission.id}`}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  permissionCard: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.spacing.padding(3),
    borderRadius: theme.borderRadius.medium,
    border: `1px solid ${theme.colors.neutral5}`,
    ...theme.spacing.gap(3),
  },
  deleteButton: {
    maxWidth: 100,
  },
  checkpointWrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.wrap,
    ...theme.spacing.gap(2),
  },
  checkpoint: {
    flex: 1,
  },
}), true)
