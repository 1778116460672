import { ImageComposition, includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'
import { CardBaseComposition } from './CardBase'
import { assignTextStyle } from './Text'
import { RenderRichTextComposition } from '@/components'

type State = '' | ':pressable'

export type JournalCardComposition =
  | `card${Capitalize<CardBaseComposition>}`
  | `wrapper${State}`
  | `innerWrapper${State}`
  | `imageWrapper${State}`
  | `image${Capitalize<ImageComposition>}`
  | `title${State}`
  | `description${Capitalize<RenderRichTextComposition>}${State}`
  | `tag`
  | `tagWrapper`

const createJournalCardStyle = variantProvider.createVariantFactory<JournalCardComposition>()

const journalCardPresets = includePresets((styles) => createJournalCardStyle(() => ({ wrapper: styles })))

const IMAGE_WIDTH = 100
const CARD_HEIGHT = 135
const DESCRIPTION_LINE_HEIGHT = 18
const DESCRIPTION_MAX_LINES = 1

export const JournalCardStyles = {
  ...journalCardPresets,
  default: createJournalCardStyle((theme) => ({
    cardWrapper: {
      ...theme.border.neutral3({ width: 2 }),
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.padding(0),
      ...theme.presets.fullWidth,
    },
    cardInnerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.spacing.gap(0),
      ...theme.presets.row,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.spacing.gap(1),
      ...theme.spacing.padding(2),
      maxHeight: CARD_HEIGHT,
      overflow: 'hidden',
    },
    imageWrapper: {
      height: 'auto',
      minWidth: IMAGE_WIDTH,
      maxWidth: IMAGE_WIDTH,
      flex: 1,
      overflow: 'hidden',
      maxHeight: CARD_HEIGHT,
      minHeight: CARD_HEIGHT,
      borderTopLeftRadius: theme.borderRadius.small,
      borderBottomLeftRadius: theme.borderRadius.small,
      position: 'relative',
      ...theme.border.neutral3({ width: 2, directions: ['right'] }),

    },
    image: {
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      textIndent: -9999,
      zIndex: 1,
    },
    title: {
      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      ...assignTextStyle('h5')(theme).text,
      lineHeight: '20px',
      minHeight: 40,
      ...theme.spacing.margin('0 auto'),
      color: theme.colors.neutral10,

      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    descriptionWrapper: {
      maxHeight: DESCRIPTION_LINE_HEIGHT * DESCRIPTION_MAX_LINES,
      overflow: 'hidden',
    },
    descriptionText: {
      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      ...assignTextStyle('p3')(theme).text,
      lineHeight: DESCRIPTION_LINE_HEIGHT + 'px',
      minHeight: DESCRIPTION_LINE_HEIGHT * DESCRIPTION_MAX_LINES,
      maxHeight: DESCRIPTION_LINE_HEIGHT * DESCRIPTION_MAX_LINES,
      color: theme.colors.neutral7,
      ...theme.spacing.margin(0),

      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tagWrapper: {
      ...theme.spacing.padding(1),
      ...theme.spacing.marginRight('auto'),
      background: theme.colors.neutral2,
      borderRadius: theme.borderRadius.nanoish,
    },
    tag: {
      ...assignTextStyle('p3')(theme).text,
      lineHeight: '14px',
    },
  })),
}

