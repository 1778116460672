import { api } from '@/app'
import { Profile, ProfileRoleSelector } from '@/types'
import { ReactQuery, onUpdate } from '@codeleap/common'
import { useState } from 'react'

const BASE_URL = 'profiles/role_selector/'

export async function list() {
  const response = await api.get<ProfileRoleSelector>(BASE_URL)
  return response.data
}

export const useRoleSelector = (profile: Profile) => {
  const q = ReactQuery.useQuery({
    queryKey: ['role_selector'],
    queryFn: list,
    enabled: !!profile,
    initialData: null,
  })

  return { roles: q.data, refresh: q.refetch }
}
