import { api } from '@/app'
import { useFlatlistProps } from '@/utils'
import { queryClient } from '../queryClient'
import { Publication, Review } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'

const BASE_URL = 'article/review/'

type ListParams = Partial<UsePaginationTypes.PaginationParams> & {
  article: Publication['id']
  version?: Publication['version']
}

export async function listReviews(params?: ListParams) {
  const response = await api.get<PaginationResponse<Review>>(BASE_URL, {
    params: { ...params },
  })

  return response.data
}
const retrieveReview = async (id: Review['id']) => {
  const response = await api.get<Review>(`${BASE_URL}${id}/`)
  return response.data
}

type UpdateProps = {
  id: Review['id']
  data: Partial<Review>
}

const updateReview = async ({ id, data }: UpdateProps) => {
  const response = await api.patch<Review>(`${BASE_URL}${id}/`, data)
  return response.data
}

export const reviewsManager = new QueryManager({
  itemType: {} as Review,
  name: 'reviews',
  queryClient: queryClient.client,
  listItems: async (limit, offset, filter) => {
    const response = await listReviews({ limit, offset, ...filter })
    return response
  },
  retrieveItem: async (id: Review['id']) => {
    const response = await retrieveReview(id)
    return response || null
  },
  updateItem: async (data: UpdateProps) => {
    const response = await updateReview(data)
    return response
  },
})

type UseReviewsParams = Partial<ListParams> & {}

export const useReviews = (params: UseReviewsParams) => {

  const reviews = reviewsManager.use({
    filter: params,
    listOptions: { queryOptions: { refetchOnWindowFocus: false }},
  })

  const reviewsListProps = useFlatlistProps(reviews, {
    noMoreItemsText: null,
  })

  return {
    reviews,
    reviewsListProps,
  }
}
