import { api } from '@/app'
import { Gender, Title } from '@/types'
import { PaginationResponse } from '@codeleap/common'

const BASE_URL = 'profiles/'

export async function retrieveGender(): Promise<Gender[]> {
  const response = await api.get<PaginationResponse<Gender>>(`${BASE_URL}gender/`)

  return response.data?.results
}

export async function retrieveTitles(): Promise<Title[]> {
  const response = await api.get<PaginationResponse<Title>>(`${BASE_URL}title/`)

  return response.data?.results
}
