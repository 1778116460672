import { APIClient } from '@/services'

export const useRolePermissions = () => {
  const { permissions } = APIClient.Session.useSession()
  const profilePermissions = permissions?.data?.[0]

  const isPublisher = profilePermissions?.role_publisher || false
  const isManaging = profilePermissions?.role_managing_editor || false
  const isChiefEditor = profilePermissions?.role_editor_chiefe || false
  const isEditor = profilePermissions?.role_editor || false

  return {
    isEditor,
    isPublisher,
    isManaging,
    isChiefEditor,
  }
}
