import { createForm } from '@codeleap/common'
import { aboutYou, aboutYourWork } from './auth'

export const aditionalInfo = () => {
  const aboutYouForm = aboutYou()
  const aboutYourWorkForm = aboutYourWork()

  return createForm('aboutYou', {
    organisation: {
      ...aboutYourWorkForm.config.organisation,
      defaultValue: null,
    },
    tags: aboutYourWorkForm.config.tags,

    gender: aboutYouForm.config.gender,
    telephone_number: aboutYouForm.config.telephone_number,
    mobile_number: aboutYouForm.config.mobile_number,
    region: aboutYouForm.config.region,
    degree: aboutYouForm.config.degree,

    address: aboutYourWorkForm.config.address,
    postcode: aboutYourWorkForm.config.postcode,
    position: aboutYourWorkForm.config.position,
    department: aboutYourWorkForm.config.department,

    orcid: {
      type: 'text',
      label: 'ORCID',
      masking: {
        type: 'custom',
        options: {
          placeholder: 'Enter your ORCID',
          mask: '0000-0000-0000-000X',
          formatChars: {
            'X': '[A-Za-z0-9]',
            '0': '[0-9]',
          },
        },
      },
    },
  })
}
