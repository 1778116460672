import { TableRowProps } from './types'
import { TableColumn } from './TableColumn'

export const TableRow = (props: TableRowProps) => {
  const { item, columnMap, onPress = null, mainColumnWidth } = props

  const onPressRow = !!onPress ? () => onPress(item) : null

  const isSelected = !!columnMap.find((col) => col?.showBulkSelection)?.bulkValues?.find(({ id }) => id === item?.id)

  return (
    <tr onClick={onPressRow} className={!!onPress && 'pressable'}>
      <hr className='topSeparator' />
      {columnMap?.map?.((col, index) => (
        <td
          className={`${col?.mainColumn && 'main'} ${isSelected && 'selected'}`}
          key={item?.id + '-table-col-' + index}
          id={col.mainColumn ? 'main' : 'fixed'}
          style={{ maxWidth: col.mainColumn ? mainColumnWidth : null }}
        >
          <TableColumn col={col} item={item} index={index} />
        </td>
      ))}
      <hr className='bottomSeparator' />
    </tr>
  )
}
