import { OSAlert, Settings } from '@/app'
import { UploadFiles } from '@/redux'
import { APIClient } from '@/services'
import { AnyFunction } from '@codeleap/common'

const splitFilesIntoChunks = (files: File[]): File[][] => {
  const chunks = []
  let currentChunk = []
  let currentSize = 0

  files.forEach(file => {
    if (currentSize + file.size > Settings.FilePickerConfigs.Attachments.max) {
      chunks.push(currentChunk)
      currentChunk = []
      currentSize = 0
    }
    currentChunk.push(file)
    currentSize += file.size
  })

  if (currentChunk.length) {
    chunks.push(currentChunk)
  }

  return chunks
}

const scanFiles = async (files: File[], signal?: AbortSignal, onError?: AnyFunction) => {
  const fileChunks = splitFilesIntoChunks(files)
  const scanIds: string[] = []

  try {
    const sendChunk = async (chunk: File[]) => {
      const response = await APIClient.Articles.scanFiles(chunk, signal)
      scanIds.push(response.scan_id)
      return response.data
    }

    await Promise.all(fileChunks.map(sendChunk))

    return scanIds
  } catch (error) {
    UploadFiles.clear()
    onError?.()
    OSAlert.error({
      title: 'Error sending files to scan',
      body: 'Try again later.',
    })
  }
}

export const FileUtils = {
  scanFiles,
}
