import { FileCategory, Publication } from '@/types'
import { formatFileName } from './misc'
import { Attachment } from '@/components'

type HandleFocusBlurParams = {
  fields: string[]
  prefix?: string
  hasMultipleForms?: boolean
}

type FindCategoryParams = {
  categories: FileCategory[]
  key: string
}

type _Attachment = Attachment & {
  category?: number
}

type FilterFilesByCategoryParams = {
  attachments: _Attachment[]
  categoryValue: number
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const previewTitle = (publication: Publication) => publication?.title || publication?.short_title || formatFileName(publication?.file)

const focusAndBlurElement = (element: HTMLElement) => {
  element.focus({ preventScroll: true })
  element.blur()
}

const handleFocusBlur = ({ fields, prefix, hasMultipleForms }: HandleFocusBlurParams) => {
  fields?.forEach(field => {
    const elements = document.querySelectorAll<HTMLElement>(`[id="${prefix}:${field}"]`)
    const inputElement = document.getElementById(`${prefix}:${field}`) as HTMLElement | null

    if (hasMultipleForms) {
      elements.forEach(element => focusAndBlurElement(element))
    } else if (inputElement) {
      focusAndBlurElement(inputElement)
    }
  })
}

const findCategory = ({ categories, key }: FindCategoryParams) => categories?.find(category => !!category?.[key])

const filterFilesByCategory = ({ attachments, categoryValue }: FilterFilesByCategoryParams) => {
  return attachments?.filter(file => {
    if (file?.category !== undefined) {
      return file?.category === categoryValue
    } else {
      return file?.file_category === categoryValue
    }
  })
}

const validateEmail = (email: string) => {
  return emailRegex.test(email)
}

export const PublicationUtils = {
  previewTitle,
  handleFocusBlur,
  findCategory,
  filterFilesByCategory,
  validateEmail,
  emailRegex,
}
