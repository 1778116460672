import { api } from '@/app'

const BASE_URL = '/article/upload_blindfolded/'

type PostBlindfoldedFilesProps = {
    files: { [key: number]: File }
}

export const PostBlindfoldedFiles = async (props: PostBlindfoldedFilesProps) => {
  const response = await api.post(BASE_URL, { ...props.files }, { multipart: true })
  return response.status.toString().startsWith('2')
}

