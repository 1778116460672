import { api } from '@/app'
import { FileCategory, Publication, PublicationMedia } from '@/types'
import { ExternalRequestClientConfig, QueryManager, TypeGuards } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = '/article/media/'

type MediaCretionType = {file: File; file_category: FileCategory[]}

export async function listMedia(publication: Publication['id']) {
  const response = await api.get(BASE_URL, {
    params: { article: publication },
  })

  return response.data
}

export async function createMedia(media, params?: ExternalRequestClientConfig) {
  const response = await api.post(BASE_URL, {
    data: {
      ...media?.data,
    },
    file: media?.file,
  }, {
    multipart: true,
    ...params,
  })

  return response.data
}

export async function retrieveMedia(id: Publication['id']) {
  const response = await api.get(`${BASE_URL}${id}`)

  return response.data
}

export async function removeMedia(mediaId: PublicationMedia['id']) {
  await api.delete(`${BASE_URL}${mediaId}/`)
  return mediaId
}

export async function updateMedia(media: PublicationMedia, params?: ExternalRequestClientConfig) {

  const response = await api.patch(`${BASE_URL}${media.id}/`, {
    data: {
      ...media,
      category: media.file_category,
      article: media.article,
    },
  }, {
    multipart: true,
    params: {
      ...params,
      article: media.article,
    },
  })

  return response.data

}

export const mediaManager = new QueryManager({
  itemType: {} as PublicationMedia,
  name: 'media',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await listMedia(filter.publication)
    return response
  },
  /*@ts-ignore*/
  createItem: async (data: {files: MediaCretionType[]; publication: Publication['id']}) => {

    const { files, publication } = data

    const sendFile = async (file: MediaCretionType) => {

      const fileSize = TypeGuards.isUndefined(file?.file?.size) ? file?.size : file?.file?.size
      const _file = TypeGuards.isUndefined(file?.file) ? file : file?.file

      const requestData = {
        data: { article: publication, file_size: fileSize, file_category: file?.file_category },
        file: _file,
      }

      const response = await createMedia(requestData)
      return response
    }

    const responses = []

    for (const file of files) {
      const fileResponse = await sendFile(file)
      responses.push(fileResponse)
    }

    return responses
  },

  updateItem: async (data: PublicationMedia[]) => {
    const onUpdate = async (requestData: PublicationMedia) => {

      const response = await updateMedia(requestData)
      return response
    }

    const responses = []

    for (const file of data) {
      const fileResponse = await onUpdate(file)
      responses.push(fileResponse)
    }

    return responses
  },

  retrieveItem: async (id: Publication['id']) => {
    const response = await retrieveMedia(id)
    return response || null
  },

  deleteItem: async (data: PublicationMedia['id'][]) => {

    const responses = []
    for (const id of data) {
      const response = await removeMedia(id)
      responses.push(response)
    }
    return responses
  },
})
