import { APIClient } from '@/services'
import { Table, View, Text, ActionIcon, historyMessages, usePublicationForm } from '@/components'
import { useTableProps, DateUtils, debounce } from '@/utils'
import { Publication, PublicationHistory as IPublicationHistory } from '@/types'
import { Badge } from '@codeleap/web'
import { AnyFunction } from '@codeleap/common'

export type PublicationHistoryProps = {
  publication: Publication['id']
  setCurrentTab: AnyFunction
}

export const PublicationHistory = (props: PublicationHistoryProps) => {

  const { setCurrentTab } = props
  const { profile } = APIClient.Session.useSession()
  const { publicationQuery } = usePublicationForm()

  const badges = APIClient.Badges.useBadges(profile)

  const history = APIClient.Publications.publicationHistoryManager.use({
    filter: {
      article: props?.publication,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,

      },
    },
  })

  const _historyMessages = historyMessages()

  const tableProps = useTableProps(history)

  const onRowPress = async (item = {}) => {
    debounce(async () => {
      const currentReply = item?.reply && item?.reply[item?.reply?.length - 1]
      await _historyMessages[item?.changes?.type]?.onPress?.({
        reply: currentReply?.id,
        article: props?.publication,
        publicationID: props?.publication,
        setCurrentTab,
        ...item,
      })
      await history.list.query.refetch()
      await publicationQuery.refetch()
      await badges.refresh()
      await tableProps.reset()
    }, 850)()
  }

  return (
    <Table
      {...tableProps}
      debugName='History List'
      onRowPress={onRowPress}
      showPlaceholder
      isLoading={history?.list?.query?.isLoading}
      placeholderProps={{ icon: 'history', variants: ['table'], title: 'No records found.' }}
      columnMap={[
        {
          label: 'HISTORY RECORDS',
          component: (item: IPublicationHistory) => {
            const rowData = _historyMessages[item?.changes?.type]

            return (
              <View variants={['fullWidth', 'gap:1', 'alignCenter', 'justifySpaceBetween', 'row', 'paddingHorizontal:2', 'relative']}>
                <View variants={['alignCenter', 'row', 'gap:1']}>
                  <ActionIcon debugName='PublicationHistory:Item:Icon' name={rowData?.icon} variants={['neutral2', 'historyIcon']} />
                  {rowData?.name(item)}
                </View>
                <Text text={DateUtils.formatDate(item?.created_datetime, 'dd/MM/yy, h:mm a')} variants={['neutral-6', 'noWrap']} />
                {item?.badges?.length ? <Badge variants={['border', 'position:leftTop']} style={{ left: 8 }} badge /> : null}
              </View>
            )
          },
          mainColumn: true,
        },

      ]}
    />
  )
}
