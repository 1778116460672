import { InvitationReviewer, Publication } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { api } from '@/app'
import { queryClient } from '../../queryClient'
import { useTableProps } from '@/utils'

const BASE_URL = 'article/invites/'

type ListFilterProps = {
    article: number
}

export async function inviteReviewers(id: number, reviewers: Partial<InvitationReviewer>[], data: Record<'message' | 'subject', string>) {
  const sendInvite = async (reviewer: InvitationReviewer) => {
    const response = await api.post('article/invite/', { ...reviewer, article: id, ...data })
    return response
  }

  const responses = await Promise.all(reviewers.map(sendInvite))

  return responses
}

export async function acceptInvite(id: string) {
  const res = await api.post('article/accept_invite/', { invite: id })
  return res.status.toString().startsWith('2')
}

export async function declineInvite(id: string) {
  await api.post('article/decline_invite/', { invite: id })
}

export async function listInvites(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<InvitationReviewer>>(BASE_URL, {
    params,
  })

  return response.data
}

export const reviewInvitesManager = new QueryManager<InvitationReviewer>({
  itemType: {} as InvitationReviewer,
  name: 'invites',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFilterProps) => {
    const response = await listInvites({ limit, offset, ...filter })
    return response
  },

})

type UseReviewInvitesProps = Partial<UsePaginationTypes.PaginationParams> & {
  article: Publication['id']
}

export const useReviewInvites = (props: UseReviewInvitesProps, infinity = false) => {
  const {
    article,
    ...paginationProps
  } = props

  const reviewInvites = reviewInvitesManager.use({
    filter: {
      ...paginationProps,
      article,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: infinity ? 3000 : false,
      },
    },
  })

  const tableProps = useTableProps(reviewInvites, {
    limit: paginationProps?.limit,
    resetPages: false,
    compareVersionLists: false,
  })

  return {
    reviewInvites,
    tableProps,
  }
}
