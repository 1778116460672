/* eslint-disable max-len */
import { React, variantProvider } from '@/app'
import { View, TextInput, Text, usePublicationForm, SelectForm } from '@/components'
import { APIClient } from '@/services'
import { getSelectValues } from '@/utils'
import { useMemo } from '@codeleap/common'

export const ArticleForm = () => {

  const { forms, sectionBreakdown, sections, setSections, subcategoriesFiltered, publicationLoaded, isPublicationEditable, canAuthorEdit, validateDraftForm } = usePublicationForm()
  const { categories, subcategories, regions, article_types, isEditor, isPublisher } = APIClient.Session.useSession()

  const regionValue = useMemo(() => getSelectValues(forms.publication.values.region, regions, true), [forms.publication.values.region, regions?.length])
  const articleTypeValue = useMemo(() => getSelectValues(forms.publication.values.article_type, article_types, true), [forms.publication.values.article_type, article_types?.length])
  const categoryValue = useMemo(() => getSelectValues(forms.publication.values.category, categories, true), [forms.publication.values.category, categories?.length])
  const subCategoryValue = useMemo(() => getSelectValues(forms.publication.values.subcategory, subcategories, true), [forms.publication.values.subcategory, subcategories?.length])

  const onValueChange = (key: string, vl: string) => {
    setSections(prev => ({ ...prev, [key]: vl }))
  }

  const onCategoryChange = (category: number) => {
    forms.publication.setFormValues({
      ...forms.publication.values,
      category,
      subcategory: category !== forms.publication.values.category ? null : forms.publication.values.subcategory,
    })
  }

  const getFieldError = (fieldName, form) => {
    return form.fieldErrors?.[fieldName] !== '' ? validateDraftForm(fieldName, form) : null
  }

  if (!publicationLoaded) return null

  return (
    <View variants={['gap:2', 'column']}>
      <TextInput
        // _error={validateDraftForm('title', forms.publication)}
        {...forms.publication.register('title')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('short_title', forms.publication)}
        {...forms.publication.register('short_title')}
        disabled={!isPublicationEditable}
      />
      <View style={styles.grid}>

        {regions?.length ? <SelectForm
          {...forms.publication.register('region')}
          loadInitialValue
          options={regions}
          value={regionValue}
          _error={getFieldError('region', forms.publication)}
          disabled={!isPublicationEditable}
          fieldName='region'
        /> : null}

        {article_types?.length ? <SelectForm
          {...forms.publication.register('article_type')}
          loadInitialValue
          options={article_types}
          value={articleTypeValue}
          _error={getFieldError('article_type', forms.publication)}
          disabled={!isPublicationEditable}
          fieldName='article_type'
        /> : null}

        {categories?.length ? <SelectForm
          {...forms.publication.register('category')}
          loadInitialValue
          options={categories}
          value={categoryValue}
          onValueChange={(e) => onCategoryChange(e)}
          _error={getFieldError('category', forms.publication)}
          disabled={!isPublicationEditable}
          fieldName='category'
        /> : null}

        {subcategoriesFiltered?.length ? (
          <SelectForm
            {...forms.publication.register('subcategory')}
            loadInitialValue
            placeholder={'Select a subcategory'}
            loadingMessage='Select a subcategory'
            options={subcategoriesFiltered}
            value={subCategoryValue}
            disabled={!isPublicationEditable}
            _error={subcategoriesFiltered?.length >= 1 && !subCategoryValue ? getFieldError('subcategory', forms.publication) : null}
            fieldName='subcategory'
          />
        ) : null}
      </View>

      <TextInput {...forms.publication.register('keywords')} disabled={!isPublicationEditable} variants={['fullWidth', 'resizable:multiline']} />

      <View variants={['flex', 'gap:1', 'column', 'marginTop:1']}>
        <Text variants={['p2', 'semiBold', 'color:neutral7']} text='Section Breakdown' />
        <View variants={['fullWidth', 'column', 'gap:2', 'paddingHorizontal:2', 'paddingVertical:1', 'border-radius:tiny', `border-color:${(isEditor || isPublisher) ? 'neutral2' : 'neutral5'}`, 'border-width:small', 'border-style:solid']}>
          {sectionBreakdown?.breakdown?.length && sectionBreakdown.breakdown?.map(section => (
            <TextInput
              key={section[0]}
              label={section[0]}
              value={sections[section[0]]}
              onChangeText={(t) => onValueChange(section[0], t)}
              multiline
              required={false}
              debugName={'Manuscript section: ' + section[0]}
              variants={['noError', 'fullWidth', 'publicationSections']}
              disabled={!canAuthorEdit}
            />
          ))}
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  grid: {
    display: 'grid',
    // prevent grid from overflowing horizontally when large texts are typed on the text input
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    columnGap: theme.spacing.value(5),
    rowGap: theme.spacing.value(3),
    [theme.media.down('small')]: {
      // prevent grid from overflowing horizontally when large texts are typed on the text input
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },
}), true)
