import { api, IsDevEnv } from '@/app'
import { Journal, JournalQuestion, Publisher } from '@/types'
import { serializeObject } from '@/utils'
import { deepEqual, ReactQuery, useMemo } from '@codeleap/common'

const DEBUG = false

const prepareQuestions = (arr: JournalQuestion[]) => {
  const copy = [...arr]

  return copy
    .filter(q => !!q)
    .map(q => ({
      id: q.id,
      question: q.question,
    }))
    .sort((a, b) => a?.id - b?.id)
}

export const hasQuestionsChanged = (initial: JournalQuestion[], toCompare: JournalQuestion[]) => {
  const _initialQuestions = prepareQuestions(initial)
  const _questions = prepareQuestions(toCompare)

  const changed = JSON.stringify(_initialQuestions) !== JSON.stringify(_questions)

  if (DEBUG) {
    logger.log({
      _initialQuestions,
      _questions,
      changed,
    })
  }

  return changed
}

export const hasRecommendationQuestionChanged = (initial: JournalQuestion, toCompare: JournalQuestion) => {
  if (!initial && !toCompare?.id) return false

  const _toCompare = serializeObject(toCompare, (key, value) => {
    if (key == 'choices') {
      return value?.filter(choice => !!choice?.value).sort((a, b) => Number(a?.id) - Number(b?.id))
    }

    return value
  })

  const _initial = serializeObject(initial, (key, value) => {
    if (key == 'choices') {
      return value?.sort((a, b) => Number(a?.id) - Number(b?.id))
    }

    return value
  })

  const changed = JSON.stringify(_initial) !== JSON.stringify(_toCompare)

  if (DEBUG) {
    logger.log({
      initial,
      toCompare,
      changed,
    })
  }

  return changed
}

export const hasDisclaimersChanged = (current, previous) => {
  const changed = !deepEqual(current, previous)

  return changed
}

export const getRecommendationQuestion = (questions: JournalQuestion[]) => {
  return questions?.find(q => q?.type === 'multiple-choice')
}

export const hasJournalDataChanged = (initial: Journal, toCompare: Journal) => {
  const journalData = serializeObject(initial, (key, value) => {
    if (key == 'publisher') return Number(value?.id)
    if (key == 'questions' || key == 'number_of_articles') return null
    return value
  })

  const values = serializeObject(toCompare, (key, value) => {
    if (key == 'questions' || key == 'number_of_articles') return null
    return value
  })

  const changed = !deepEqual(journalData, values)

  if (DEBUG) {
    logger.log({
      journalData,
      values,
      changed,
    })
  }

  return changed
}

export const usePublishers = () => {
  const publishers = ReactQuery.useQuery<Publisher[]>({
    queryKey: ['list-my-publishers'],
    queryFn: async () => {
      const response = await api.get('publisher/')
      return response.data?.results
    },
    refetchOnWindowFocus: false,
  })

  const options = useMemo(() => {
    if (!publishers?.data) return []

    return publishers?.data?.map?.(publisher => ({
      label: publisher?.title,
      value: publisher?.id,
      item: publisher,
    }))
  }, [publishers?.data])

  return {
    options,
    hasPublishers: options?.length >= 2,
  }
}
