import { Modal, View, Button, TextInput } from '@/components'
import { useToolBarContext } from '../ToolbarContext'

export const LinkModal = () => {
  const { linkModalVisible, toggleLinkModal, linkForm: form, editor } = useToolBarContext()

  const handleClose = () => {
    toggleLinkModal(false)
    setTimeout(() => {
      form.reset(['values'])
    }, 500)
  }

  const handleSubmit = () => {
    const { link, text } = form.values
    if (!link) {
      editor.chain().focus().extendMarkRange('link')
        .unsetLink()
        .run()
    } else {
      const linkPrefix = /^https?:\/\//i.test(link) ? '' : 'http://'
      console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
      editor.chain().focus().extendMarkRange('link')
        .setLink({ href: `${linkPrefix}${link}` })
        .insertContent(text || link)
        .insertContent(' ')
        .focus()
        .run()
    }
    handleClose()
  }

  return (
    <Modal
      visible={linkModalVisible}
      toggle={handleClose}
      showClose={true}
      closable={true}
      closeOnEscape={true}
      variants={[]}
      title='Add Link'
    >
      <View variants={['column', 'alignStart', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'flex', 'gap:2']}>
          <TextInput
            {...form.register('text')}
            variants={['noError']}
          />
          <TextInput
            {...form.register('link')}
            variants={['noError']}
          />
        </View>

        <View variants={['fullWidth', 'alignCenter', 'justifyEnd', 'gap:1']}>
          <Button debugName='LinkModal:cancel' text={'Cancel'} onPress={handleClose}/>
          <Button debugName='LinkModal:save' text={'Save'} onPress={handleSubmit}/>
        </View>
      </View>
    </Modal>
  )
}
