import { ListComposition, ListPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createListStyle = variantProvider.createVariantFactory<ListComposition>()

const defaultStyles = ListPresets

export const AppListStyles = {
  ...defaultStyles,
  default: createListStyle((theme) => {
    return {
      ...defaultStyles,
      wrapper: {
        minHeight: '50vh',
        ...theme.presets.column,
        ...theme.presets.fullWidth,
        ...theme.presets.relative,
      },
      innerWrapper: {
        ...theme.presets.fullWidth,
      },
      refreshControl: {
        backgroundColor: theme.colors.neutral1,
        borderRadius: theme.borderRadius.rounded,
        zIndex: 2,
        ...theme.presets.absolute,
        left: '50%',
        transform: 'translateX(-50%)',
        pointerEvents: 'none',
        ...theme.spacing.padding(0.5),
      },
    }
  }),
  hiddenSeparator: createListStyle(theme => ({
    separator: {
      ...theme.presets.fullWidth,
      height: theme.values.innerSpacing.value,
    },
  })),
  lineSeparators: createListStyle(theme => ({
    separator: {
      height: theme.values.pixel,
      backgroundColor: theme.colors.neutral5,
    },
  })),
  bare: createListStyle(theme => ({
    wrapper: {
      minHeight: 'auto',
      height: '100%',
    },
  })),
  'height-auto': createListStyle(theme => ({
    wrapper: {
      flexGrow: 0,
      minHeight: 'auto',
    },
  })),
  'announcements-list': createListStyle(theme => ({
    wrapper: {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      overflowX: 'hidden',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
    },
    innerWrapper: {
      ...theme.spacing.paddingHorizontal(3),

      [theme.media.down('midish')]: {
        ...theme.spacing.paddingBottom(4),
      },
    },
  })),
}
