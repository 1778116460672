import { React } from '@/app'
import { List } from '@/components'
import { APIClient } from '@/services'
import {
  IconPlaceholder,
  PropsOf,
  QueryManager,
  QueryManagerItem,
  onUpdate,
  useMemo,
  usePagination,
  deepEqual,
} from '@codeleap/common'
import { ListProps, PaginationIndicator } from '@codeleap/web'

export type GetFlatlistPropsOptions = {
  noMoreItemsText?: string
  forceLoading?: boolean
  forceHasNextPage?: boolean
  fetchNextPage?: boolean
}

export const useFlatlistProps = <
  TItem extends QueryManagerItem,
  T extends QueryManager<TItem, any, any, any>,
>(hookReturn: ReturnType<T['use']>, options?: GetFlatlistPropsOptions) => {
  const {
    noMoreItemsText = 'No more items to show',
    forceHasNextPage = false,
    forceLoading = false,
    fetchNextPage = true,
  } = (options || {})

  const listQuery = hookReturn.list.query

  const hasMore = hookReturn.list.query.isLoading || hookReturn.list.query.hasNextPage

  const showPaginationIndicator = hookReturn.list.query.isFetchingNextPage || !hasMore

  const ListPaginationIndicator = React.useCallback(({ isEmpty = false }) => {
    if (!showPaginationIndicator || isEmpty) return null

    return (
      <PaginationIndicator
        isFetching={hookReturn.list.query.isFetchingNextPage || forceHasNextPage}
        noMoreItemsText={noMoreItemsText}
        hasMore={hasMore}
      />
    )
  }, [showPaginationIndicator, hasMore])

  return {
    data: hookReturn.items as TItem[],
    fetchNextPage: () => {
      if (!hasMore || !hookReturn?.items?.length || !fetchNextPage) return
      hookReturn.getNextPage?.()
    },
    keyExtractor: (item) => item?.id,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    loading: forceLoading || hookReturn.list.query.isLoading,
    ListFooterComponent: ListPaginationIndicator,
    isFetchingNextPage: hookReturn.list.query.isFetchingNextPage,
    ListLoadingIndicatorComponent: () => null,
    hasNextPage: hasMore,
    isLoading: hookReturn.list.query.isLoading,
    placeholder: {
      loading: (listQuery.isFetching || listQuery?.isLoading) && !listQuery?.isRefreshing,
    },
    showPaginationIndicator,
  }
}

type PaginationFlatListProps<TData = any> = Pick<
  ListProps<PropsOf<typeof List>, TData>,
  'onRefresh' | 'data' | 'renderItem' | 'placeholder' |
  'keyExtractor' | 'ListFooterComponent' | 'ListLoadingIndicatorComponent'
>

export function getFlatListProps<
  P extends ReturnType<typeof usePagination>
>(pagination: P): PaginationFlatListProps<P['items'][number]> & 'refreshing' {

  const listQuery = pagination.queries.list

  return {
    ...listQuery,
    data: pagination?.items,
    renderItem: () => null,
    keyExtractor: (item) => pagination.params.keyExtractor(item).toString(),
    refreshing: listQuery.isRefreshing,
    // @ts-ignore
    placeholder: {
      loading: listQuery.isFetching || listQuery?.isLoading || listQuery?.loading || listQuery?.isFetchingNextPage,
      icon: 'placeholderNoItems-select' as IconPlaceholder,
    },
    onRefresh: () => listQuery.refresh(),
    ListLoadingIndicatorComponent: () => {
      const limited = pagination?.items?.length <= pagination?.options?.limit

      if (!pagination?.items?.length || limited) return null

      return <PaginationIndicator
        hasMore={listQuery.hasNextPage}
        noMoreItemsText={`No more ${pagination.itemName}`}
        isFetching={listQuery.isFetchingNextPage}
      />
    },
  }
}

type TablePropsOptions = {
  limit?: number
  showEmpty?: boolean
  resetPages?: boolean
  compareVersionLists?: boolean
}

export function useTableProps<
  TItem extends QueryManagerItem,
  T extends QueryManager<TItem, any, any, any>,
>(hookReturn: ReturnType<T['use']>, options?: TablePropsOptions) {
  const { limit = 10, showEmpty = false, resetPages = true, compareVersionLists = true } = options || {}

  const { roleHasChanged } = APIClient.Session.useSession()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [items, setItems] = React.useState<TItem[]>([])
  const [pages, setPages] = React.useState([])
  const [_reset, setReset] = React.useState(false)

  const query = useMemo(() => hookReturn.list.query, [hookReturn])
  const isUpToDate = deepEqual(items, hookReturn?.items)

  const numberOfItems = pages[0]?.count || 0
  const numberOfPages = Math.ceil(numberOfItems / limit) || 1

  const findPage = (page: number) => pages.find((p) => p.page === page)
  const findPageNumber = (page) => {
    if (!page.previous) return 1
    return page.next ? Number(page.next.match(/offset=(\d+)/)?.[1]) / limit : numberOfPages
  }

  const fetchPage = async (page: number) => {
    if (page < 1 || page > numberOfPages) return
    if (!findPage(page)) {
      await query.fetchNextPage({ pageParam: { offset: (page - 1) * limit, limit }})
    }
    setCurrentPage(page)
  }

  const resetQueries = async () => {
    query.remove()
    setItems([])
  }

  const reset = async () => {
    query.remove()
    setItems([])
    if (resetPages) {
      setPages([])
    }
    setReset(true)
    setTimeout(() => setReset(false))
  }

  onUpdate(() => {
    const _pages = query.data?.pages ?? []
    for (const page of _pages) {
      const pageNumber = findPageNumber(page)
      if (!findPage(pageNumber)) setPages((prev) => [...prev, { page: pageNumber, ...page }])
      // if (page.results.length > limit) resetQueries()
    }
    const results = findPage(currentPage)?.results
    if (results?.length) {
      setItems(results)
    } else {
      if (showEmpty) {
        setItems([])
      } else {
        setItems(items || [])
      }
    }
  }, [query, _reset])

  onUpdate(() => {
    const firstPage = findPage(1)
    const firstQueryPage = query.data?.pages?.find((p) => findPageNumber(p) === 1)
    const isEquals = firstPage?.results?.[0]?.id === firstQueryPage?.results?.[0]?.id

    if (!isEquals && firstPage !== undefined) {
      setPages([])
      setCurrentPage(1)
    }
  }, [query, _reset])

  onUpdate(() => {
    if (roleHasChanged) resetQueries()
  }, [roleHasChanged])

  onUpdate(() => {
    resetQueries()
    setCurrentPage(1)
  }, [limit])

  onUpdate(() => {
    if (compareVersionLists && !isUpToDate) {
      reset()
    }
  }, [isUpToDate, compareVersionLists])

  return {
    reset,
    items,
    numberOfItems,
    numberOfPages,
    currentPage,
    setCurrentPage,
    query,

    fetchPage,
    fetchNextPage: () => fetchPage(currentPage + 1),
    fetchPreviousPage: () => fetchPage(currentPage - 1),

    resetQueries,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    hasNextPage: currentPage < numberOfPages,
    isLoading: query.isLoading || query.isFetching || query.isFetchingNextPage,
  }
}
