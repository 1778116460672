import { Text, TextEditor, TextEditorExtensions, ToolBar, View, useJournalCrudContext } from '@/components'
import { useEditor } from '@tiptap/react'
import { JournalCrudProviderData } from '../CrudContext/types'
import Placeholder from '@tiptap/extension-placeholder'
import { onUpdate, useRef, useState } from '@codeleap/common'

type JournalFieldEditorProps = {
  field: keyof JournalCrudProviderData['form']['values']
  validate?: boolean
  placeholder?: string
  label?: string
  renderErrorSpace?: boolean
  excludeIds?: string[]
}

export const JournalFieldEditor = (props: JournalFieldEditorProps) => {
  const { field, placeholder, validate, renderErrorSpace, label, excludeIds = [] } = props
  const { form, isCreate } = useJournalCrudContext()
  const loaded = useRef(false)

  const editor = useEditor({
    content: form?.values?.[field],
    extensions: [
      ...TextEditorExtensions,
      Placeholder.configure({ placeholder: placeholder, emptyEditorClass: 'placeholder' }),
    ],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => form.setFieldValue(field, editor.getHTML()),
  })

  onUpdate(() => {
    if (form?.values?.[field] && editor && !loaded.current && isCreate) {
      editor?.commands?.setContent(form.values[field], true)
      loaded.current = true
    }
  }, [form?.values?.[field]])

  const isMaxValid = editor?.getHTML().length <= 10000
  const isMinValid = editor?.getHTML().length >= 22

  const hasError = validate ? !isMaxValid || !isMinValid : false
  const renderSpace = renderErrorSpace && !hasError

  return (
    <View variants={['column', renderSpace && 'marginBottom:3']}>
      {label && <Text variants={['p2', 'color:caption', 'marginBottom:1']} text={label} />}
      <TextEditor
        {...form.register(field)}
        editor={editor}
        toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'fileComponent', ...excludeIds]} />}
        variants={['editor:adaptiveHeight']}
        _error={hasError}
        validate={validate && (isMaxValid || isMinValid) ? false : form.register(field)?.validate}
      />
      {validate && !isMinValid && <Text variants={['p3', 'color:destructive2']} text={'Minimum of 15 characters'} />}
      {validate && !isMaxValid && <Text variants={['p3', 'color:destructive2']} text={'Maximum of 10000 characters'} />}
    </View>
  )
}
