import { includePresets } from '@codeleap/common'
import { TableRowComposition } from './TableRow'
import { variantProvider } from '@/app/theme'
import { ActivityIndicatorComposition, EmptyPlaceholderComposition } from '@codeleap/web'
import { PaginationButtonsComposition } from '../PaginationButtons'

export type TableComposition =
  | 'wrapper'
  | 'outerWrapper'
  | 'innerWrapper'
  | 'overflowWrapper'
  | `row${Capitalize<TableRowComposition>}`
  | `activityIndicator${Capitalize<ActivityIndicatorComposition>}`
  | `placeholder${Capitalize<EmptyPlaceholderComposition>}`
  | `paginationButtons${Capitalize<PaginationButtonsComposition>}`

const createTableListStyle = variantProvider.createVariantFactory<TableComposition>()

const tableListPresets = includePresets((styles) => createTableListStyle(() => ({ wrapper: styles })))

export const TableStyles = {
  ...tableListPresets,
  default: createTableListStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
    },
    outerWrapper: {
      ...theme.presets.fullWidth,
      maxWidth: '100%',
    },
    overflowWrapper: {
      ...theme.presets.fullWidth,
      overflowX: 'auto',
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    activityIndicatorWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.center,
      ...theme.spacing.marginVertical(15),
    },
    placeholderWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.center,
      ...theme.spacing.marginVertical(15),
    },
  })),
  'overflow:none': createTableListStyle((theme) => ({
    overflowWrapper: {
      overflowX: 'unset',
      display: 'unset',
      flex: 'unset',
      overflowY: 'unset',
    },
  })),
}

export * from './TableRow'
