import { api, IsDevEnv, LocalStorage } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { onMount, onUpdate, useState } from '@codeleap/common'

const PRESS_NUMBER_TO_CHANGE_SERVER = 10
const DEBUG = IsDevEnv

export function useToggleDevMode(readFromStorage?: boolean) {
  const [numberOfPresses, setPresses] = useState(0)
  const isDevMode = useAppSelector(store => store.AppStatus.isDev)
  const [loaded, setLoaded] = useState(false)

  const loadState = async () => {
    try {
      const isDev = LocalStorage.getItem('SESSION_IS_DEV')
      AppStatus.toggleDevMode(isDev === 'true')

      setLoaded(true)

      if (DEBUG) {
        logger.log('Session mode', { isDeveloperMode: isDev, apiURL: api.axios.defaults.baseURL })
      }
    } catch (error) {
      logger.error('Session mode error', error)
    }
  }

  onMount(() => {
    if (!readFromStorage) return
    loadState()
  })

  onUpdate(() => {
    if (numberOfPresses === PRESS_NUMBER_TO_CHANGE_SERVER) {
      setPresses(0)
      AppStatus.toggleDevMode()
      
      if (DEBUG) {
        logger.info('toggle session mode')
      }
    }
  }, [numberOfPresses])

  return {
    onPress: () => setPresses(n => n + 1),
    isDevMode,
    loaded,
    loadState,
  }
}
