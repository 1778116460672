import { PlaceholderStyles } from '@/app/stylesheets'
import { PlaceholderComposition } from '@/app/stylesheets/Placeholder'
import { View, Text, Icon, Button, ImageProps, Image } from '@/components'
import {
  ComponentVariants,
  IconPlaceholder,
  PropsOf,
  StylesOf,
  useDefaultComponentStyle,
} from '@codeleap/common'
import { ButtonProps, IconProps } from '@codeleap/web'

export type PlaceholderProps = ComponentVariants<typeof PlaceholderStyles> &
  Omit<PropsOf<typeof View>, 'styles' | 'variants'> & {
    styles?: StylesOf<PlaceholderComposition>
    icon?: IconPlaceholder
    image?: ImageProps['source']
    texts: string[]
    iconProps?: PropsOf<IconProps>
    imageProps?: Partial<PropsOf<typeof Image>>
    buttonProps?: PropsOf<ButtonProps>
  }

export const Placeholder = ({
  variants = [],
  styles = {},
  style,
  responsiveVariants = {},
  icon,
  image,
  texts,
  iconProps,
  imageProps,
  buttonProps = null,
  ...otherProps
}: PlaceholderProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:Placeholder',
    typeof PlaceholderStyles
  >('u:Placeholder', {
    variants,
    rootElement: 'wrapper',
    styles,
    responsiveVariants,
  })

  return (
    <View css={[variantStyles.wrapper, style]} {...otherProps}>
      <View css={[variantStyles.iconWrapper]}>
        {icon ? (
          <Icon
            {...iconProps}
            name={icon}
            style={variantStyles.icon}
            debugName='Placeholder Icon'
          />
        ) : null}
        {image ? (
          <Image {...imageProps} source={image} css={[variantStyles.image]} />
        ) : null}
      </View>

      {texts.length > 0 ? (
        <View css={[variantStyles.textsWrapper]}>
          {texts.map((text, index) => (
            <Text key={`Text:${index}`} text={text} css={[variantStyles.text]} />
          ))}
        </View>
      ) : null}

      {buttonProps ? <Button css={variantStyles.button} {...buttonProps} /> : null}
    </View>
  )
}
