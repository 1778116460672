import { LocalStorage } from '@/app'
import { TypeGuards } from '@codeleap/common'

export const ReauthenticationStore = {
  set(is: boolean) {
    LocalStorage.replaceItem('IN_REAUTHENTICATION', is)
  },
  get() {
    const is = LocalStorage.getItem('IN_REAUTHENTICATION', false)

    const isReauthentication = TypeGuards.isString(is) ? is === 'true' : false

    return {
      isReauthentication,
    }
  },
  reset() {
    LocalStorage.removeItem('IN_REAUTHENTICATION')
  }
}
