import { Journal, Member, Permission, Profile, ProfileRoles, Publisher } from '@/types'
import {
  PaginationResponse,
  QueryManager,
  ReactQuery,
  TypeGuards,
  UsePaginationTypes,
} from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api } from '@/app'

const BASE_URL = 'profiles/permission/'

type ListParams = {
  target?: Profile['id']
  journal?: Journal['id']
  member?: Member['id']
}

export async function list(params?: Partial<UsePaginationTypes.PaginationParams> & ListParams) {
  const response = await api.get<Permission[]>(BASE_URL, {
    params,
  })
  return response.data
}

async function create(newPermission: Permission) {
  const response = await api.post(BASE_URL, newPermission)
  return response.data
}

async function update(permission: Permission & { id: Permission['id'] }) {
  const response = await api.patch<Permission>(
    `${BASE_URL}${permission.id}/`,
    permission,
  )
  return response.data
}

async function remove(permission: Permission) {
  await api.delete(`${BASE_URL}${permission.id}/`)
  return permission
}

async function retrieve(permissionId: Permission['id']) {
  const response = await api.get<Permission>(`${BASE_URL}${permissionId}/`)
  return response.data
}

export const permissionsManager = new QueryManager<Permission>({
  itemType: {} as Permission,
  name: 'permissions',
  queryClient: queryClient.client,

  createItem: async (data: Permission) => {
    const response = await create(data)
    return response
  },

  //@ts-ignore
  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  retrieveItem: async (id: Permission['id']) => {
    const response = await retrieve(id)
    return response || null
  },

  updateItem: async (data: Permission) => {
    const response = await update(data)
    return response
  },

  deleteItem: async (data: Permission[]) => {
    const onDelete = async (permission) => {
      const response = await remove(permission)
      return response
    }
    const response = Promise.all(data.map(onDelete))
    return response
  },
})

export type UsePermissionsProps = Partial<ListParams> & {
  enabled?: boolean
}

export const usePermissions = (props: UsePermissionsProps) => {
  const { enabled, ...filter } = props
  const q = ReactQuery.useQuery({
    queryKey: ['permissions'],
    queryFn: () => list({ ...filter }),
    enabled,
    refetchOnWindowFocus: false,
    initialData: null,
  })

  const permissions = q.data
  const profile = permissions?.[0]?.profile

  const isLoading = q.isFetching || q.isLoading

  return { permissions, profile, isLoading }
}
