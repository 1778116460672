import { useIsomorphicEffect } from '@codeleap/common'
import React from 'react'

export type FirebaseInstance = any

let initialized = false

type FirebaseFunction<T> = (fb: FirebaseInstance) => T

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: 'AIzaSyBT_foi7ILVmArA6rV7N6-0IAJ3n38HLzo',
  authDomain: 'x-eworkflow.firebaseapp.com',
  projectId: 'x-eworkflow',
  storageBucket: 'x-eworkflow.appspot.com',
  messagingSenderId: '384702319918',
  appId: '1:384702319918:web:7db8757af576811f7c3ebf',
  measurementId: 'G-6JNJVDDBCP',
}

export let cachedFirebaseInstance: FirebaseInstance = null
export async function getFirebase() {
  const fbApp = await import('firebase/compat/app')
  await import('firebase/compat/auth')
  await import('firebase/compat/analytics')

  const _firebase = fbApp.default

  if (!initialized) {
    _firebase.initializeApp(config)
    cachedFirebaseInstance = _firebase
    initialized = true
  }

  return _firebase
}

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth
  return firebaseAuth
}
