import { JournalSubmissionsDetails } from '@/types'

export const journalsTypeFilterData = [
  {
    id: 'journalsTypeSorter',
    options: [
      {
        value: 'publications',
        label: 'Total submissions',
        itemProps: {
          rightIcon: 'check',
        },
      },
      {
        value: 'submission_first_decision',
        label: 'Average time to first decision',
        itemProps: {
          rightIcon: 'check',
        },
      },
      {
        value: 'submission_last_decision',
        label: 'Average time to last decision',
        itemProps: {
          rightIcon: 'check',
        },
      },
    ],
  },
]

export const getPresetDateFilterData = (isDisabled = false) => {
  const icon = isDisabled ? null : 'check'

  return (
    [
      {
        id: 'presetDateSorter',
        options: [
          {
            value: 7,
            label: 'Last 7 days',
            itemProps: {
              rightIcon: icon,
              disabled: isDisabled,
            },
          },
          {
            value: 30,
            label: 'Last 30 days',
            itemProps: {
              rightIcon: icon,
              disabled: isDisabled,
            },
          },
          {
            value: 90,
            label: 'Last 90 days',
            itemProps: {
              rightIcon: icon,
              disabled: isDisabled,
            },
          },
          {
            value: 365,
            label: 'Last 1 year',
            itemProps: {
              rightIcon: icon,
              disabled: isDisabled,
            },
          },
        ],
      },
    ]

  )
}

export const getOverviewData = (details: JournalSubmissionsDetails) => {
  return (
    [
      {
        value: details?.others?.total_submissions?.value,
        percentage: details?.others?.total_submissions?.percentage,
        description: 'Total submissions',
      },
      {
        value: details?.others?.submission_first_decision?.value,
        percentage: details?.others?.submission_first_decision?.percentage,
        description: 'Between the submission and the first decision',
        isDays: true,
      },
      {
        value: details?.others?.submission_last_decision?.value,
        percentage: details?.others?.submission_last_decision?.percentage,
        description: 'Between the submission and the final decision',
        isDays: true,
      },
    ]
  )
}
