import { AvatarComposition, StylesOf, includePresets } from '@codeleap/common'
import { TCSS, assignTextStyle, variantProvider } from '..'

export type RowParts = 'wrapper' | 'icon' | 'label' | 'description' | 'innerWrapper'
export type ListRowComposition = 'outerWrapper' | 'wrapper:disabled' | RowParts
export type ListHeaderComposition = 'wrapper' | 'title'

const createListRowStyle =
  variantProvider.createVariantFactory<ListRowComposition, { avatar?: StylesOf<AvatarComposition> }>()

const createListHeaderStyle =
  variantProvider.createVariantFactory<ListHeaderComposition>()

const rowPresets = includePresets((styles) => createListRowStyle(() => ({ wrapper: styles })))
const headerPresets = includePresets((styles) => createListHeaderStyle(() => ({ wrapper: styles })))

export const ListRowStyles = {
  ...rowPresets,
  default: createListRowStyle((theme) => ({
    outerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.justifyStart,
      ...theme.presets.alignCenter,
      ...theme.spacing.padding(2),
      ...theme.spacing.gap(3),
      ...theme.border.neutral3({ style: 'solid', width: 1, directions: ['bottom'] }),
      [theme.media.down('small')]: {
        ...theme.spacing.paddingHorizontal(0),

      },
    },
    wrapper: {
      ...theme.presets.row,
      ...theme.presets.center,
      ...theme.spacing.gap(theme.values.innerSpacing.X),
    },
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
      flexShrink: 0,
      color: theme.colors.neutral8,
      [theme.media.down('mid')]: {
        width: theme.values.iconSize[2],
        height: theme.values.iconSize[2],
      },
    },
    innerWrapper: {
      display: 'flex',
      ...theme.presets.column,
      ...theme.presets.alignStart,
      ...theme.presets.justifyStart,
      gap: theme.spacing.gap(0.5),
    },
    label: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral8,
      maxWidth: '30ch',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',

      [theme.media.down('xlarge')]: {
        maxWidth: '25ch',
      },
      [theme.media.down('xlarge')]: {
        maxWidth: '20ch',
      },
      [theme.media.down('mid')]: {
        ...assignTextStyle('p3')(theme).text,
        maxWidth: '28ch',
      },

      [theme.media.down('small')]: {
        maxWidth: '12ch',
      },
      [theme.media.up('xxlarge')]: {
        maxWidth: '60ch',
      },
    },
    description: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral6,
      [theme.media.down('mid')]: {
        ...assignTextStyle('p4')(theme).text,
      },
    },
    avatar: {
      wrapper: {
        height: theme.values.itemHeight.default,
        width: theme.values.itemHeight.default,
        [theme.media.down('mid')]: {
          height: theme.values.itemHeight.small,
          width: theme.values.itemHeight.small,
        },
      },
      image: {
        height: theme.values.itemHeight.default,
        width: theme.values.itemHeight.default,
        [theme.media.down('mid')]: {
          height: theme.values.itemHeight.small,
          width: theme.values.itemHeight.small,
        },
      }},
  })),
}

export const ListHeaderStyles = {
  ...headerPresets,
  default: createListHeaderStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.justifyStart,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(3),
      ...theme.spacing.paddingVertical(1),
      [theme.media.down('small')]: {
        ...theme.spacing.paddingHorizontal(0),
      },
    },
    title: {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.neutral7,
      textTransform: 'uppercase',
      minWidth: theme.values.itemHeight.large,

    },
  })),
  'only:right': createListHeaderStyle((theme) => ({
    wrapper: {
      ...theme.spacing.paddingRight(7),
    },
  })),
  'only:left': createListHeaderStyle((theme) => ({
    wrapper: {
      ...theme.spacing.paddingLeft(2),
    },
  })),
  iconRight: createListHeaderStyle((theme) => ({
    wrapper: {
      ...theme.spacing.paddingRight((theme.values.innerSpacing.value + theme.values.itemHeight.default) / 8),
    },
  })),
}
