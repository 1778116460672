import { PossibleReviewer, Publication } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes, deepEqual } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api } from '@/app'

const BASE_URL = 'article/'

export function verifyPossibleReviewerExistence(reviewers: Partial<PossibleReviewer>[], initialState: PossibleReviewer[]) {
  if (!reviewers?.length) return
  return reviewers.reduce<{toUpdate: Partial<PossibleReviewer>[]; new: Partial<PossibleReviewer>[]}>((acc, curr) => {
    if (!curr?.email) return acc
    if (curr?.id) {
      const originalReviewer = initialState.find(el => el.id === curr.id)
      const hasChanges = !deepEqual(curr, originalReviewer)

      return {
        ...acc,
        toUpdate: hasChanges ? [...acc.toUpdate, curr] : [...acc.toUpdate],
      }
    } else {
      return { ...acc, new: [...acc.new, curr] }
    }
  }, { toUpdate: [], new: [] })
}

async function listPossibleReviewers(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<PossibleReviewer>>(`${BASE_URL}possible_reviewer/`, { params })

  return response.data
}
async function retrievePossibleReviewer(id: PossibleReviewer['id']) {
  const response = await api.get<PossibleReviewer>(`${BASE_URL}possible_reviewer/${id}`)

  return response.data
}
async function createPossibleReviewers(publicationId: Publication['id'], possibleReviewers: PossibleReviewer[]) {
  const response = await api.post<PossibleReviewer[]>(
    `${BASE_URL}possible_reviewer/`,
    { article: publicationId, possible_reviewers: possibleReviewers },
  )

  return response.data
}
async function updatePossibleReviewer(data: PossibleReviewer) {
  const response = await api.patch<PossibleReviewer>(`${BASE_URL}possible_reviewer/${data.id}/`, data)

  return response.data
}
async function removePossibleReviewer(possibleReviewer: PossibleReviewer) {
  await api.delete(`${BASE_URL}possible_reviewer/${possibleReviewer.id}/`)

  return possibleReviewer
}

export const possibleReviewersManager = new QueryManager<PossibleReviewer>({
  itemType: {} as PossibleReviewer,
  name: 'possibleReviewers',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: {article: Publication['id']}) => {
    const response = await listPossibleReviewers({ limit, offset, ...filter })
    return response
  },
  /*@ts-ignore*/
  createItem: async (data: {publicationId: number; possibleReviewers: PossibleReviewer[]}) => {
    const response = await createPossibleReviewers(data.publicationId, data.possibleReviewers)
    return response
  },

  retrieveItem: async (id: PossibleReviewer['id']) => {
    const response = await retrievePossibleReviewer(id)
    return response || null
  },
  /*@ts-ignore*/
  updateItem: async (data: PossibleReviewer[]) => {
    const responses = []
    for (const reviewer of data) {
      const resp = await updatePossibleReviewer(reviewer)
      responses.push(resp)
    }

    return responses
  },

  deleteItem: async (data: PossibleReviewer) => {
    const response = await removePossibleReviewer(data)
    return response
  },
})
