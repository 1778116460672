import { createForm, yup } from '@codeleap/common'

export const reviewForm = createForm('reviewForm', {
  content: {
    type: 'text',
    placeholder: 'Content',
    validate: yup
      .string()
      .required('This is a required field')
      .min(6, 'Minimum of 6 characters')
      .max(500, 'Maximum of 500 characters'),
  },
})
