import { Alert, usePublicationForm } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'

export const ConfirmPublicationStatusModal = () => {
  const { visible, status } = useAppSelector(state => state.AppStatus.modals.confirmPublicationStatus)
  const { updatePublication } = usePublicationForm()

  return (
    <Alert
      zIndex={99}
      title='Are you sure you want to change this manuscript status?'
      toggle={() => AppStatus.setModal('confirmPublicationStatus')}
      visible={visible}
      onAction={() => updatePublication(status)}
      type='warn'
      variants={['title:text-center']}
      body='By changing the status you might trigger another actions of the platform system.'
      actionButtonProps={{
        text: 'Change status',
        debugName: 'delete account confirm',
        variants: ['fullWidth', 'large'],
      }}
      dismissButtonProps={{
        text: 'Cancel',
        debugName: 'delete account cancel',
        variants: ['fullWidth', 'flat', 'large'],
      }}
    />
  )
}
