import { React, variantProvider } from '@/app'
import { ActivityIndicator, View } from '.'

export const LoadingScreen = ({ isScreen = true }) => {
  return (
    <View style={isScreen ? styles.screenWrapper : styles.wrapper}>
      <ActivityIndicator debugName='AuthenticatedScreen:loading' />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  screenWrapper: {
    width: '100vw',
    height: '100svh',
    ...theme.presets.absolute,
    ...theme.presets.whole,
    ...theme.presets.flex,
    ...theme.presets.center,
    zIndex: 5,
  },
  wrapper: {
    ...theme.presets.fullWidth,
    ...theme.presets.flex,
    ...theme.presets.center,
  },
}), true)
