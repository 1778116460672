import React from 'react'
import { TextInput } from '@/components'
import { useJournalCrudContext } from './CrudContext'
import { JOURNAL_URL_DOMAIN } from '@/app'

export const JournalURLDomainInput = () => {
  const { form, isCreate } = useJournalCrudContext()

  return (
    <TextInput
      {...form.register('url_domain')}
      value={isCreate ? form.values.url_domain : JOURNAL_URL_DOMAIN + form.values.url_domain}
      onChangeText={text => {
        let value = null

        value = text?.split('/')[text?.includes('your_domain') ? 3 : 2]

        form.setFieldValue('url_domain', JOURNAL_URL_DOMAIN + (value ?? '')?.toLowerCase())
      }}
      disabled={!isCreate}
    />
  )
}
