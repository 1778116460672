import { api } from '@/app'
import { JournalSubmissions, JournalSubmissionsDetails } from '@/types'
import { PaginationResponse, QueryManager, ReactQuery, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from './queryClient'

const BASE_URL = 'publisher/dashboard/'

type ListFilterProps = {
  journal: string
  date_start: string
  date_end: string
}

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<JournalSubmissions>>(BASE_URL, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  })

  return response.data
}

export const dashboardManager = new QueryManager<JournalSubmissions>({
  itemType: {} as JournalSubmissions,
  name: 'dashboard',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFilterProps) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
})

export const useJournalsDetails = (params) => {
  const query = ReactQuery.useQuery({
    queryKey: ['journalsDetails', params],
    queryFn: async () => {
      const response = await api.get(`${BASE_URL}`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      })
      return response.data
    },
    refetchOnWindowFocus: false,
  })

  return {
    ...query,
    details: query?.data?.details as JournalSubmissionsDetails,
  }
}

export type DashboardExportParams = {
  type: 'csv' | 'xlsx' | 'pdf' | 'pptx'
  filters: ListFilterProps
  files?: File[]
}

export async function getExportUrl(params: DashboardExportParams) {
  const form = new FormData()

  params.files?.forEach((file) => {
    const noExt = file.name.split('.')[0]
    form.append(noExt, file)
  })

  const response = await api.post<string>(`publisher/dashboard_export/`, form, {
    params: {
      ...params.filters,
      type: params.type,
    },
    // baseURL: 'http://localhost:8081/',
  })

  const url = response.data

  return url
}
