import { AppImages, Breakpoint, TCSS, Theme } from '@/app'
import { ComponentVariants, onUpdate, PropsOf, TypeGuards, useDefaultComponentStyle, useState } from '@codeleap/common'
import { Image, Touchable } from '@/components'
import { LogoStyles, LogoComposition } from '../app/stylesheets/Logo'
import { StylesOf, useMediaQuery } from '@codeleap/web'
import { useToggleDevMode } from '@/utils'

type LogoProps = {
  styles?: StylesOf<LogoComposition>
  style?: TCSS
  switchServerOnPress?: boolean
  breakpoint?: Breakpoint
  responsive?: boolean
  image?: any
} & ComponentVariants<typeof LogoStyles> & Omit<PropsOf<typeof Touchable>, 'variants'|'styles'>

export function Logo(props: LogoProps) {
  const { responsiveVariants, variants, styles, switchServerOnPress, image = null, breakpoint = 'mid', responsive = true } = props

  const mediaQuery = Theme.media.down(breakpoint)
  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  let logoImage = AppImages.Logo

  if (responsive) {
    logoImage = isMobile ? AppImages.LogoMobile : AppImages.LogoHeader

  }

  const { onPress } = useToggleDevMode()

  const variantStyles = useDefaultComponentStyle<'u:Logo', typeof LogoStyles>('u:Logo', {
    responsiveVariants,
    rootElement: 'wrapper',
    styles,
    variants,
  })

  const _image = <Image
    source={image ?? logoImage}
    css={[variantStyles.image]}
  />

  return <Touchable onPress={switchServerOnPress ? onPress : props.onPress}
    debugName={'Click on Logo'} css={[variantStyles.wrapper, props?.style]}>
    {_image}
  </Touchable>

}
