import { api } from '@/app'
import { Announcement } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from './queryClient'

const BASE_URL = 'crm/announcements/'

export const retrieve = async (id: number): Promise<Announcement> => {
  const response = await api.get(`${BASE_URL}${id}/`)
  return response.data
}

export const announcementsManager = new QueryManager<Announcement>({
  itemType: {} as Announcement,
  name: 'Announcement',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Announcement>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })
    return response.data
  },

  retrieveItem: async (id: Announcement['id']) => {
    const response = await retrieve(id)
    return response || null
  },

  actions: {
    async markRead(manager, id: number) {
      const response = await api.get(`crm/read_announcement/`, { params: { id }})
      manager?.refreshItem(id)
      return response.data
    },
  },
})
