import { React } from '@/app'
import { useAppSelector } from '@/redux'
import { AuthModal, LoginForm } from '@/components'

export const LoginModal = (props) => {
  const modal = useAppSelector(store => store.AppStatus.modals.login)

  if (!modal?.visible) return

  return (
    <AuthModal isOpen={modal?.visible} name='login'>
      <LoginForm journalData={modal?.data} isModal/>
    </AuthModal>
  )
}
