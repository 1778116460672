import { TouchableComposition, TouchablePresets } from '@codeleap/web'
import { variantProvider } from '..'

const createTouchableStyle = variantProvider.createVariantFactory<TouchableComposition>()

export const AppTouchableStyles = {
  ...TouchablePresets,
  default: createTouchableStyle((theme) => ({
    wrapper: {
      ...theme.presets.center,
      cursor: 'pointer',
      userSelect: 'none',
    },
    'wrapper:disabled': {
      cursor: 'default',
    },
  })),
  border: createTouchableStyle((theme) => ({
    wrapper: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.colors.neutral5,
    },
  })),
}
