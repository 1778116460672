import { FileCategory } from '@/types'
import { DropzoneInnerFilePreviewProps, stopPropagation } from '@codeleap/web'
import { useState } from 'react'
import { View, Text, ActionIcon, Icon, Select, Attachment } from '@/components'
import { humanizedFileSize } from '@/utils'
import { AppIcon, variantProvider } from '@/app'
import { ComponentVariants, StylesOf, useDefaultComponentStyle, useNestedStylesByKey } from '@codeleap/common'
import { FilePreviewComposisition, FilePreviewStyles } from '@/app/stylesheets/FilePreview'

export const FilePreview = (
  props: Partial<DropzoneInnerFilePreviewProps> & {
    setAttachments: (param: React.SetStateAction<Attachment[]>) => void
    fileCategories: FileCategory[]
    index: number
    styles?: StylesOf<FilePreviewComposisition>
    category?: number
    leftIcon: AppIcon
  } & ComponentVariants<typeof FilePreviewStyles>,
) => {
  const {
    setAttachments,
    fileCategories,
    variants,
    responsiveVariants,
    hasErrors,
    errors,
    index,
    styles,
    file,
    onRemove,
    category: _category = null,
    leftIcon = 'grip-vertical',
  } = props

  const variantStyles = useDefaultComponentStyle<'u:FilePreview', typeof FilePreviewStyles>('u:FilePreview', {
    variants,
    styles,
    responsiveVariants,
  })

  const fileRightIconStyles = useNestedStylesByKey('fileRightIcon', variantStyles)

  const [category, setCategory] = useState<number>(_category)

  const handleChangeCategory = (newCategory: number) => {
    setCategory(newCategory)
    setAttachments((prev) => {
      prev[index] = {
        file: prev[index]?.file,
        file_category: newCategory,
      }
      return prev
    })
  }

  const handleDelete = (e) => {
    stopPropagation(e)
    onRemove?.()
  }

  return (
    <View onClick={stopPropagation} css={[variantStyles.fileWrapper, hasErrors && variantStyles['fileWrapper:error']]}>
      <View css={[variantStyles.fileNameWrapper]}>
        <Icon name={leftIcon} debugName='Teste' css={variantStyles.fileLeftIcon} />
        <Text text={file?.name} css={variantStyles.fileName} />
        {hasErrors && errors?.map((error) => <Text text={error.message} css={variantStyles.fileError} />)}
      </View>
      <View css={variantStyles.actionWrapper}>
        <View css={variantStyles.wrapperSelect}>
          <Select
            debugName='FilePreview: Select'
            value={category}
            options={fileCategories}
            variants={['fullWidth', 'noError', 'filePreview']}
            placeholder={'Select a category'}
            onValueChange={handleChangeCategory}
            loadInitialValue
            id={`file_preview_input_select:${file?.name}`}
          />
        </View>
        <Text text={humanizedFileSize(file?.size)} css={variantStyles.fileSize} />
        <ActionIcon
          onClick={handleDelete}
          debugName='DropzoneFilePreview:RightIcon'
          name={props.fileRightIcon}
          styles={fileRightIconStyles}
        />
      </View>
    </View>
  )
}

