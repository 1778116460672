import { usePublicationForm } from '../Publication'
import { useState, useRef, createRef, useImperativeHandle } from 'react'
import { useUnmount } from '@codeleap/common'
import { Select } from '..'
import { SelectProps } from '@codeleap/web'

type SelectFormProps = SelectProps & {
  fieldName: string
  errorMessage?: string
}

const useFormController = (fieldName) => {
  const { selectRefs } = usePublicationForm()
  const [showError, setShowError] = useState(false)

  const selectRef = useRef(null)

  if (!selectRefs[fieldName]) {
    selectRefs.current[fieldName] = createRef()
  }

  useImperativeHandle(selectRefs.current[fieldName], () => ({
    showError: () => {
      setShowError(true)
    },
  }), [showError, setShowError])

  useUnmount(() => {
    selectRefs.current[fieldName] = null
  })

  return {
    selectRef,
    showError,
  }
}

export const SelectForm = (props: SelectFormProps) => {
  const { fieldName, errorMessage, value } = props
  const controller = useFormController(fieldName)

  const errorMsg = errorMessage ? errorMessage : 'This is a required field'
  const error = (controller.showError && !value) || (controller.showError && (Array.isArray(value) && value.length === 0)) ? errorMsg : null

  return (
    <Select
      {...props}
      _error={error}
    />
  )
}
