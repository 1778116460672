import { PropsOf, useBooleanToggle } from '@codeleap/common'
import {
  Button,
  List,
  Modal,
  Select,
  TextInput,
  Touchable,
  View,
} from '@/components'
import React, { useState } from 'react'
import { SearchInput } from '@codeleap/web'
import { assignTextStyle, variantProvider } from '@/app'

export type SelectItemProps = {
  label: string
  value: string
}

type SearchInputProps = PropsOf<typeof SearchInput>

type SelectProps = PropsOf<typeof Select>

export type SelectModalProps = Pick<
  SelectProps,
  'multiple' | 'searchable' | 'options' | 'placeholder'
> & {
  modalProps?: PropsOf<typeof Modal>
  searchInputProps?: Omit<SearchInputProps, 'debugName'>
  listProps?: Partial<PropsOf<typeof List>>
  buttonProps?: Partial<PropsOf<typeof Button>>
  wrapperProps?: Partial<PropsOf<typeof Touchable>>
  onValueChange?: (value: SelectItemProps | SelectItemProps[]) => void
  value?: SelectItemProps | SelectItemProps[]
}

export const SelectModal = ({
  value,
  multiple,
  modalProps,
  searchable,
  onValueChange,
  searchInputProps,
  buttonProps,
  listProps,
  options,
  wrapperProps,
  ...selectProps
}: SelectModalProps) => {
  const arrayValue = value as SelectItemProps[]
  const singleValue = value as SelectItemProps

  const [visible, toggle] = useBooleanToggle(false)
  const [selectedOption, setSelectedOption] = useState<SelectItemProps>(
    value as SelectItemProps,
  )
  const [selectedOptions, setSelectedOptions] =
    useState<SelectItemProps[]>(arrayValue)

  const checkIsSelected = (item: SelectItemProps) => multiple
    ? selectedOptions?.some((v) => v.value === item.value)
    : selectedOption?.value === item.value

  const handlePress = (item: SelectItemProps) => {
    if (multiple) {
      setSelectedOptions((state) => {
        let result = []
        if (checkIsSelected(item)) {
          result = state.filter((v) => v.value !== item.value)
        } else {
          result = [...state, item].filter(Boolean)
        }

        onValueChange?.(result)
        return result
      })
    } else {
      setSelectedOption(item)
      onValueChange(item)
    }
  }

  const renderItem = ({ item: _item }) => {
    const item = _item as SelectItemProps
    const isSelected = checkIsSelected(item)

    return (
      <Button
        debugName={item.value}
        text={item.label}
        variants={['fullWidth']}
        onPress={() => handlePress(item)}
        rightIcon={isSelected && 'check'}
        styles={{
          text: {
            ...styles.itemText,
            ...(isSelected && styles['itemText:selected']),
          },
          wrapper: {
            ...styles.itemWrapper,
            ...(isSelected && styles['itemWrapper:selected']),
          },
          rightIcon: styles.itemRightIcon,
        }}
      />
    )
  }

  const handleSave = () => {
    toggle?.()
  }

  const getValue = () => {
    if (!multiple) return singleValue?.label
    return selectedOptions
      ?.filter((v) => arrayValue?.some((f) => f.value === v.value))
      ?.map((v) => v.label)
      ?.join(', ')
  }

  return (
    <>
      <Touchable
        debugName='SelectModal:Touchable'
        onPress={toggle}
        {...wrapperProps}
      >
        <TextInput
          debugName='SelectModal:TextInput'
          value={getValue()}
          styles={{ wrapper: { pointerEvents: 'none' }}}
          rightIcon={{
            icon: 'chevron-down',
            debugName: 'SelectModal:TextInput:rightIcon',
          }}
          {...selectProps}
        />
      </Touchable>

      <Modal
        renderHeader={() => null}
        footer={
          <View css={styles.footer}>
            <Button
              text='Save'
              debugName='SelectModal:Save'
              css={styles.button}
              onPress={handleSave}
              {...buttonProps}
            />
          </View>
        }
        visible={visible}
        toggle={toggle}
        variants={['selectModal']}
        {...modalProps}
      >
        {searchable && (
          <SearchInput
            debugName='SelectModal:Search'
            placeholder='Search'
            {...searchInputProps}
          />
        )}
        <List
          debugName='SelectModal:List'
          renderItem={renderItem}
          {...listProps}
          data={options as unknown as string[]}
        />
      </Modal>
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    header: {},
    itemWrapper: {
      width: '100%',
      height: '100%',
      minHeight: theme.values.itemHeight.default,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.neutral1,

      transition: 'all 0.2s',

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'itemWrapper:selected': {
      backgroundColor: theme.colors.primary1,

      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
    },
    'itemWrapper:selectedFocused': {
      backgroundColor: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.primary3,
      },
    },
    itemText: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral10,
      textAlign: 'left',
    },
    'itemText:selected': {
      ...assignTextStyle('h5')(theme).text,
    },
    itemRightIcon: {
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
      color: theme.colors.neutral10,
    },
    footer: {
      ...theme.presets.justifyCenter,
      ...theme.presets.fullWidth,
    },
    button: {
      maxWidth: 300,
      ...theme.presets.fullWidth,
      ...theme.spacing.marginTop(2),
    },
  }),
  true,
)
