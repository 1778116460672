import { OSAlert, React, variantProvider } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { IconProps, SectionFilters, SectionFiltersProps } from '@codeleap/web'
import { useMemo, useState } from 'react'
import { ActionIcon, Modal, Text, Toaster, View, usePublicationForm } from '..'
import { APIClient } from '@/services'
import { Publication } from '@/types'

type RejectAndTransferManuscriptProps = {
  publicationAuthor?: string
  publication?: Publication
}

export const RejectAndTransferManuscriptModal = (props: RejectAndTransferManuscriptProps) => {
  const { publicationAuthor, publication } = props

  const { visible } = useAppSelector(state => state.AppStatus.modals.rejectAndTransferManuscriptModal)
  const { publicationQuery } = usePublicationForm()
  const allJournals = APIClient.Journals.journalsManager.useList({ filter: { limit: 100 }}) // add infinity scroll

  const historyList = APIClient.Publications.publicationHistoryManager.useList({
    filter: {
      article: publication?.id,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: false,
    },
  })

  const [journal, setJournal] = useState([])

  const data = useMemo(() => {
    const journals: SectionFiltersProps['data'] = [{
      id: 'journals-list',
      options: [],
      selectedItemProps: { rightIcon: 'check' as IconProps['name'] },
    }]
    allJournals?.items?.map?.(journal => {
      if (journal?.id !== publication?.journal?.id) {
        journals[0]?.options?.push({
          value: journal?.id,
          label: journal?.title,
        })
      }
    })
    return !journals[0]?.options?.length ? [] : journals
  }, [allJournals])

  const selectedJournal = journal?.['journals-list']

  const hasJournalsToTransfer = data[0]?.options?.length

  const toggle = () => AppStatus.setModal(['rejectAndTransferManuscriptModal', !visible])

  const onCancelTransfer = () => {
    toggle()
    setJournal([])
    AppStatus.setModal('reviewsOverview')
  }

  const onTransferManuscript = async () => {
    AppStatus.set('loading')

    try {
      const response = await APIClient.Publications.onRejectAndTransferManuscript({ article: publication?.id, journal: selectedJournal?.value })
      if (response) {
        await publicationQuery.refetch()
        await historyList.query.refetch()
        AppStatus.set('done')
        toggle()
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Something went wrong',
        body: 'Looks like we’ve hit a problem - sorry about that! We’ll investigate and try to stop it from happening again.',
      })
    }
  }

  const onConfirmTransferModal = async () => {
    toggle()
    OSAlert.ask({
      title: 'Reject and transfer manuscript?',
      body: `Only editors with access to the destination journal will be able to see this manuscript. This action is permanent and can't be undone.`,
      options: [
        {
          text: 'Cancel',
          onPress: () => AppStatus.setModal(['rejectAndTransferManuscriptModal', true]),
          variants: ['flat', 'large'],
        },
        {
          text: 'Reject and transfer',
          onPress: onTransferManuscript,
          variants: ['large', 'bg:alert4'],
        },
      ],
    })
  }

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      variants={['rejectAndtransferManuscriptModal', 'centered']}
      showClose={false}
    >

      <Text variants={['h3', 'color:neutral9']} text={`Reject and transfer manuscript`}/>

      <Toaster
        title={publication?.title}
        description={publicationAuthor}
        variants={['status', 'bordered', 'marginVertical:4']}
        isVisible={true}
      />

      <View variants={['column']}>
        <Text variants={['p2', 'semiBold']} text={'Current journal:'}/>
        <Text variants={['p1', 'color:neutral8']} text={publication?.journal?.title}/>
      </View>

      <View variants={['marginTop:4', 'gap:1', 'column', 'fullWidth']}>
        <Text variants={['p2', 'semiBold']} text={`Transfer to:`}/>

        {hasJournalsToTransfer ? (
          <SectionFilters
            toggle={toggle}
            title='Sorting'
            applyButtonText='Transfer'
            clearButtonText='Cancel'
            variants={['transferManuscriptFilter']}
            filterOnOptionPress={true}
            draftItems={journal}
            setDraftItems={setJournal}
            onApplyItems={toggle}
            onClearItems={toggle}
            data={data}
            clearFilterButtonProps={{
              disabled: false,
              onPress: onCancelTransfer,
              variants: ['large', 'text:semiBold', 'text:neutral10'],
            }}
            applyFilterButtonProps={{
              onPress: onConfirmTransferModal,
              variants: ['large', 'border-radius:tiny', 'noWrap', 'bg:alert4', 'text:semiBold'],
              text: 'Reject and transfer',
              disabled: !selectedJournal,
              icon: 'x-circle',
            }}
          />
        ) : (
          <View variants={['padding:2', 'column', 'center', 'border-radius:tiny']} style={styles.emptyPlaceholderWrapper}>
            <ActionIcon
              icon='clipboard'
              variants={['backgroundColor:neutral2', 'border-radius:rounded', 'neutral9', 'size:3']}
              debugName={'Journal Disclaimers - List empty placeholder icon'}
              onPress={null}
            />
            <Text
              variants={[`p1`, 'marginTop:2', 'color:neutral7']}
              responsiveVariants={{ mid: ['textCenter'] }}
              text={`There are no available journals to transfer.`}
            />
          </View>
        ) }
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  emptyPlaceholderWrapper: {
    minHeight: 200,
    ...theme.border.neutral3({ width: theme.values.borderWidth.small }),
    ...theme.spacing.marginBottom(4),
  },
}), true)
