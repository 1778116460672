import { api } from '@/app'
import { Publication, PublicationHistory, Review } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { historyMessages } from '@/components'
import { APIClient } from '@/services'
import { useTableProps } from '@/utils'

const BASE_URL = `article/history/`

const list = async (params: UsePaginationTypes.PaginationParams) => {
  const response = await api.get<PaginationResponse<PublicationHistory>>(BASE_URL, {
    params,
  })
  return response.data
}

const retrieve = async (id: Publication['id']) => {
  const response = await api.get<PublicationHistory>(`${BASE_URL}${id}/`)
  return response.data
}

export const publicationHistoryManager = new QueryManager({
  itemType: {} as PublicationHistory,
  name: 'history',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  retrieveItem: async (id: Publication['id']) => {
    const response = await retrieve(id)
    return response || null
  },
})

type UseHistoryProps = Partial<UsePaginationTypes.PaginationParams> & {
  article: Publication['id']
}

export const usePublicationHistory = (props: UseHistoryProps) => {
  const history = APIClient.Publications.publicationHistoryManager.use({
    filter: {
      article: props?.article,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        cacheTime: 0,
      },
    },
  })

  const tableProps = useTableProps(history)

  return {
    history,
    tableProps,
  }
}
