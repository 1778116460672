import React from 'react'
import { View, SortModalComponent, Button, SortComponentProps } from '@/components'
import { AnyFunction, TypeGuards } from '@codeleap/common'

type DashboardSortModalProps = Partial<SortComponentProps> & {
  isPresetDate?: boolean
  renderFooterComponent?: JSX.Element
  showFooterSeparator?: boolean
  disableButton?: boolean
  handleApply?: AnyFunction
  showClearBtn?: boolean
}

function DefaultFooter(props) {
  const { handleApply, disableButton, onClear, showClearBtn } = props

  return (
    showClearBtn ? (
      <View
        variants={['row', 'fullWidth', 'marginHorizontal:4', 'marginTop:3', 'marginBottom:4', 'gap:2']}
        responsiveVariants={{ midish: ['marginTop:auto'] }}
      >
        {showClearBtn && (
          <Button
            debugName={'Clear filter journal modal'}
            text={'Clear all'}
            onPress={onClear}
            style={{ height: 47 }}
            variants={['fullWidth', 'flat']}
            {...props}
          />
        )}

        <Button
          debugName={'Apply filter journal modal'}
          text={'Filter'}
          onPress={handleApply}
          disabled={disableButton}
          style={{ height: 47 }}
          variants={['fullWidth']}
          {...props}
        />
      </View>
    ) : (
      <Button
        debugName={'Apply filter journal modal'}
        text={'Filter'}
        onPress={handleApply}
        disabled={disableButton}
        style={{ height: 47 }}
        variants={['marginHorizontal:4', 'marginTop:3', 'marginBottom:4', 'fullWidth']}
        responsiveVariants={{ midish: ['marginTop:auto'] }}
        {...props}
      />
    )
  )
}

export const DashboardSortModal = (props: DashboardSortModalProps) => {
  const {
    renderFooterComponent,
    showFooterSeparator = false,
    disableButton,
    handleApply,
    children,
    showClearBtn = false,
    ...rest
  } = props

  const Footer = renderFooterComponent || DefaultFooter

  return (
    <SortModalComponent
      variants={['dashboard']}
      sectionFiltersVariants={['dashboard']}
      icon={null}
      hideSentenceTextValue
      buttonProps={{
        rightIcon: 'chevron-down',
      }}
      renderFooterComponent={(params) => {
        const { onApply, shouldDisableActions } = params
        const _disableButton = TypeGuards.isNil(disableButton) ? shouldDisableActions : disableButton
        // const _handleApply = handleApply || onApply
        const _handleApply = () => {
          if (handleApply) {
            handleApply()
            onApply()
            return
          }

          onApply()

        }

        return (
          <>
            {showFooterSeparator && <View variants={['fullWidth', 'separator', 'bg:neutral2', 'marginTop:2']} />}
            <Footer
              handleApply={_handleApply}
              disableButton={_disableButton}
              showClearBtn={showClearBtn}
              {...params}
            />
          </>
        )
      }}
      {...rest}
    >
      {children}
    </SortModalComponent>
  )
}
