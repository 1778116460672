import { Touchable } from '@codeleap/web'

export const Option = ({ option, styles, ...props }) => {
  return (
    <Touchable
      debugName={`TextEditor:toggle${option.id}`}
      onPress={() => option.action(props)}
      styles={styles}
      {...props}
    >
      {option.icon}
    </Touchable>
  )
}
