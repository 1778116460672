import { Settings, variantProvider } from '@/app'
import {
  View,
  Image,
  Text,
  Icon,
  Button,
  ActionIcon,
  useJournalCrudContext,
  CropPicker,
  Touchable,
} from '@/components'
import { ImageField } from '../CrudContext/types'
import React, { useMemo } from 'react'
import { CropPickerProps, ModalProps, useCropPicker, useFileInput } from '@codeleap/web'
import { AnyFunction, TypeGuards } from '@codeleap/common'

type PicturePickerProps = {
  label: string
  field: ImageField
  renderDescription?: AnyFunction
  modalProps?: Partial<ModalProps>
  cropPickerProps?: CropPickerProps
}

const DEBOUNCE = 2000

export const PicturePicker = (props: PicturePickerProps) => {
  const { label, field, renderDescription, modalProps, cropPickerProps } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const Description = () => {

    if (TypeGuards.isFunction(renderDescription)) {
      return renderDescription()
    }

    return null
  }

  const { onDeleteImage, onPickImage, form } = useJournalCrudContext()

  const fileInput = useFileInput()

  const handlePickImage = () => {
    onPickImage(field, fileInput)
  }

  // @ts-ignore
  const image = form?.values?.[field]?.preview ?? form?.values?.[field]
  const isCover = field === 'cover'
  const objectFit = isCover ? 'cover' : 'contain'
  const { width: WIDTH, height: HEIGHT, aspect } = Settings.CropPickerConfigs[isCover ? 'cover' : 'default']
  // @ts-ignore
  const cropPicker = useCropPicker({ ref: fileInput?.ref, aspect, ruleOfThirds: true })

  const _styles = useMemo(() => {
    const IMAGE_WIDTH = isCover ? '100%' : WIDTH

    return {
      imageWrapper: {
        height: HEIGHT,
        minWidth: IMAGE_WIDTH,
        maxWidth: IMAGE_WIDTH,
        maxHeight: HEIGHT,
        minHeight: HEIGHT,
      },
      touchableWrapper: {
        height: HEIGHT,
      },
    }
  }, [isCover])

  async function onConfirmCrop() {
    setIsLoading(true)
    await cropPicker.onConfirmCrop()
    setIsLoading(false)
  }

  return (
    <>
      <View variants={['column', 'gap:1', 'alignStart', 'justifyStart']}>
        <View variants={['column']}>
          <Text text={label} variants={['p2']} />
          <Description />
        </View>
        <Touchable debugName={'PicturePicker touchable'} onPress={handlePickImage} css={[styles.touchableWrapper, _styles.touchableWrapper]}>
          <View css={[styles.picture, _styles.imageWrapper]}>
            {!!image ? (
              <Image source={image} objectFit={objectFit} css={styles.image} />
            ) : (
              <Icon debugName='createStory:plus' name={'plus'} variants={['primary', 'size:4']} />
            )}
          </View>
          <ActionIcon
            debugName={`Picture picker${field}`}
            onPress={handlePickImage}
            icon='camera'
            style={styles.actionIcon}
            variants={['flat', 'border-radius:rounded']}
          />
        </Touchable>

        {!!image ? (
          <Button
            debugName='PicturePicker:delete'
            text='Delete'
            variants={['flat', 'flat:destructive', 'marginTop:2']}
            onPress={() => onDeleteImage(field)}
          />
        ) : null}
      </View>

      <CropPicker
        debugName={'Journal cropPicker:' + field}
        ref={fileInput.ref as any}
        accept='image/png, image/jpeg'
        modalProps={{
          dismissOnBackdrop: false,
          ...modalProps,
          renderHeader: (props) => {
            const Header = modalProps?.renderHeader
            return <Header {...props} isLoading={isLoading} />
          },
        }}
        targetCrop={{
          ruleOfThirds: true,
          aspect,
          disabled: isLoading,
        }}
        confirmButtonProps={{
          disabled: isLoading,
          loading: isLoading,
          debounce: DEBOUNCE,
          onPress: onConfirmCrop,
        }}
        disabled={isLoading}
        handle={cropPicker}
        {...cropPickerProps}
      />
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    touchableWrapper: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    picture: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: theme.colors.primary1,
      borderRadius: theme.borderRadius.tiny,
      ...theme.presets.center,
    },
    image: {
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
    },
    actionIcon: {
      position: 'absolute',
      bottom: -8,
      right: -8,
      ...theme.effects.elevated,
    },
  }),
  true,
)
