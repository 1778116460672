import { createForm, yup } from '@codeleap/common'

export const journalDisclaimerForm = createForm('journalDisclaimerForm', {
  title: {
    type: 'text',
    placeholder: 'Title',
    validate: yup.string().max(256).required(),
  },
  description: {
    type: 'text',
    placeholder: 'Type you message here',
    validate: yup.string().max(20000).required(),
  },
})
