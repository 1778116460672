import { api } from '@/app'
import { Journal, Publication, Replica, ReplicaMedia } from '@/types'
import { ExternalRequestClientConfig, PaginationResponse, QueryManager, ReactQuery, UsePaginationTypes } from '@codeleap/common'
import { QueryKeys } from './queryKeys'
import { queryClient } from './queryClient'

const REPLICA_BASE_URL = 'article/reply/'
const REPLICA_MEDIA_BASE_URL = 'article/reply_media/'

type PostReplicaProps = {
  comment: string
  article: Publication['id']
}

type ListReplicaMediaProps = {
  reply?: Replica['id']
}

async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Journal>>(`${REPLICA_BASE_URL}/`, {
    params,
  })

  return response.data
}

async function listMedia(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Journal>>(`${REPLICA_MEDIA_BASE_URL}`, {
    params,
  })

  return response.data
}

export async function retrieveVersion({ version, article }) {
  const response = await api.get<Replica>(REPLICA_BASE_URL, { params: { version, article }})
  return response.data
}

export async function retrieveReplica({ reply, review, version, article }) {
  const response = await api.get<Replica>(`${REPLICA_BASE_URL}${reply}/`, { params: { review, version, article }})
  return response.data
}

export async function retrieveReplicaMedia({ reply }) {
  const response = await api.get<ReplicaMedia>(REPLICA_MEDIA_BASE_URL, { params: { reply }})
  return response.data
}

export async function createMedia(props, params?: ExternalRequestClientConfig) {
  const response = await api.post(REPLICA_MEDIA_BASE_URL, props?.media, { multipart: true, ...params })
  return response.data
}

export const PostReplica = async (props: PostReplicaProps) => {
  const response = await api.post(REPLICA_BASE_URL, { ...props })
  return response.data
}

export const updateReplica = async (props) => {
  const { id, ...data } = props
  const response = await api.patch(`${REPLICA_BASE_URL}${id}/`, { ...data })
  return response.data
}

export const ResendReplica = async (params) => {
  const response = await api.post('article/resend_to_reviewers/', { ...params })
  return response
}

export const PostReplicaMedia = async (props: Pick<ReplicaMedia, 'reply'> & { files?: ReplicaMedia[] }) => {
  const { reply, files } = props

  const sendFile = async (file: any, index: number) => {

    const isLocal = file instanceof File
    const category = isLocal ? file?.category : file?.file?.category || file?.file_category

    const media = {
      data: {
        reply,
        file_size: isLocal ? file?.size : file?.file?.size,
        category,
      },
      file: isLocal ? file : file?.file,
    }

    const response = await createMedia({ media })
    return response
  }

  const responses = await Promise.all(files.map(sendFile))

  return responses
}

export const useReviewReplica = (params) => {
  return ReactQuery.useQuery({
    queryKey: [`review-replica-${params?.reply}`],
    queryFn: async () => await retrieveReplica(params),
    refetchOnWindowFocus: false,
    refetchOnMount(q) {
      return false
    },
    retry: false,
    enabled: true,
  })
}

export const useReviewReplicaMedia = (params) => {
  return ReactQuery.useQuery({
    queryKey: [`review-replica-media-${params?.reply}`],
    queryFn: async () => await retrieveReplicaMedia(params),
    refetchOnWindowFocus: false,
    refetchOnMount(q) {
      return false
    },
    retry: false,
    enabled: true,
  })
}

export const replicaManager = new QueryManager<Replica>({
  itemType: {} as Replica,
  name: 'journals',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFiltersProps) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  createItem: async (data: PostReplicaProps) => {
    const response = await PostReplica(data)
    return response
  },

  updateItem: async (data) => {
    const response = await updateReplica(data)
    return response
  },
})

export const replicaMediaManager = new QueryManager<Replica>({
  itemType: {} as Replica,
  name: 'journals',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListReplicaMediaProps) => {
    const response = await listMedia({ limit, offset, ...filter })
    return response
  },

  createItem: async (data: PostReplicaProps) => {
    const response = await PostReplicaMedia(data)
    return response
  },
})
