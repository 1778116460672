import { React, variantProvider, Theme, AppImages } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { onUpdate } from '@codeleap/common'
import { Icon, Overlay, View, ActivityIndicator } from '@/components'
import { Logo } from './Logo'
import { AppStatusStates } from '@/redux/AppStatus'
import { SectionBreakdownAnimation } from './Animations'

const indicatorSize = 60

export const LogoLoading = (props) => {
  const { status = 'splash' } = props

  return (
    <View css={[styles.splashWrapper, { opacity: status === 'splash' ? 1 : 0 }]}>
      <Logo variants={['splash']} responsive={false} />
      <ActivityIndicator animation={AppImages.loadingAnimation} css={styles.splashLoading} />
    </View>
  )
}

export const AppStatusOverlay = () => {
  const { status } = useAppSelector((store) => store.AppStatus)

  onUpdate(() => {
    if (status === 'done') {
      setTimeout(() => {
        AppStatus.set('idle')
      }, 2000)
    }
  }, [status])

  const visibilityStyle = (appStatus: AppStatusStates) => {
    const isStatusVisible = status === appStatus
    return ({
      css: {
        transform: `scale(${isStatusVisible ? 1 : 0})`,
        transition: 'transform 0.3s ease',
      },
      visible: isStatusVisible,
    })
  }

  const visibleOverlay = ['loading', 'done', 'sectionBreakdown'].includes(status)

  return (
    <>
      <Overlay visible={visibleOverlay} styles={{ wrapper: styles.overlay }} />
      <View css={[styles.wrapper]}>
        <View css={[styles.container, visibilityStyle('done')?.css]}>
          <Icon
            name='check'
            size={Theme.values.iconSize[4]}
            color={Theme.colors.light.primary3}
            style={visibilityStyle('done')?.css}
          />
        </View>

        <View css={[styles.container, visibilityStyle('loading')?.css]}>
          <ActivityIndicator css={visibilityStyle('loading')?.css} />
        </View>

        <SectionBreakdownAnimation css={visibilityStyle('sectionBreakdown')?.css} visible={visibilityStyle('sectionBreakdown')?.visible} />
      </View>

      <LogoLoading status={status} />
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  overlay: {
    zIndex: 1999,
  },
  container: {
    width: indicatorSize,
    height: indicatorSize,
    backgroundColor: theme.colors.neutral1,
    borderRadius: theme.borderRadius.rounded,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapper: {
    ...theme.presets.absolute,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 2000,
    pointerEvents: 'none',
    transition: 'transform 0.3s ease',
  },
  splashWrapper: {
    display: 'flex',
    ...theme.presets.column,
    ...theme.presets.fixed,
    ...theme.presets.whole,
    ...theme.presets.justifyCenter,
    ...theme.presets.alignCenter,
    zIndex: 2000,
    pointerEvents: 'none',
    transition: 'all 0.3s ease',
    backgroundColor: theme.colors.background,
  },
  splashLoading: {
    transform: `scale(1)`,
    transition: 'transform 0.3s ease',
  },

}), true)
