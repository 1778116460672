import { View, Text } from '@codeleap/web'
import { Extensions, NodeViewWrapper, ReactNodeViewRenderer, mergeAttributes, Editor } from '@tiptap/react'
import { Node, Extension } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import Heading from '@tiptap/extension-heading'
import Link from '@tiptap/extension-link'
import TipTapImage from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import Paragraph from '@tiptap/extension-paragraph'

import { create } from 'zustand'
import { textStyles } from '@/app/textStyles'
import { css } from '@emotion/react'

type FileStore = {
  files: Record<string, File>
  setFiles: (files: Record<string, File>) => void
  clearFiles: () => void
}

export const FileStore = create<FileStore>((set) => {
  return {
    files: {},
    setFiles: (files: Record<string, File>) => {
      set(currentFiles => ({ files: { ...currentFiles.files, ...files }}))
    },
    clearFiles: () => {
      set({ files: {}})
    },
  }
})

const CustomImageExt = TipTapImage.extend({
  addAttributes() {
    return {
      src: { default: null },
      'data-uploaded': { default: null },
      width: { default: null },
      height: { default: null },
      alt: { default: null },
      title: { default: null },
    }
  },
  addOptions: {
    ...TipTapImage.options,
    sizes: ['inline', 'block', 'left', 'right'],
  },
  renderHTML({ HTMLAttributes }) {
    const { style } = HTMLAttributes
    return [
      'figure',
      { style },
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
    ]
  },

})

const FileComponent = (props) => {
  const attrs = props?.node?.attrs

  return (
    <NodeViewWrapper
      as='a'
      className='fileComponent'
      data-uploaded={attrs?.['data-uploaded']}
      href={attrs?.file}
    >

      <p>{attrs.text}</p>
    </NodeViewWrapper>
  )
}

const FileExt = Node.create({
  name: 'fileComponent',
  group: 'block',

  atom: true,
  addAttributes() {
    return {
      'data-uploaded': { default: null },
      file: { default: null },
      text: {
        default: null,
      },
    }
  },
  parseHTML() {
    return [{ tag: 'filecomponent' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['filecomponent', mergeAttributes(HTMLAttributes), ['p']]
  },

  /*@ts-ignore*/
  addCommands() {
    return {
      setAttachment: attributes => ({ commands }: Editor) => {
        return commands.insertContent({ type: 'fileComponent', attrs: attributes })
      },
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(FileComponent)
  },
})

const SizedParagraph = Paragraph.extend({
  name: 'sizedParagraph',

  addOptions() {
    return {
      levels: [1, 2, 3, 4, 5, 6],
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: false,
      },
    }
  },

  parseHTML() {
    // return this.options.levels.map((level) => ({
    //   tag: `p`,
    //   attrs: { classname: `p${level}` },
    // }))
    return this.options.levels.map(level => ([
      { tag: `p`, attrs: { classname: `p${level}` }},
      { tag: `li > p`, attrs: { classname: `p${level}` }},
    ]))
  },

  renderHTML({ node, HTMLAttributes }) {

    const level = node.attrs.level ?? 1

    const levelStyle = textStyles[`p${level}`]

    let style = ''

    if (level !== 1) {

      const styles = css({
        fontSize: `${levelStyle.size}px`,
        lineHeight: `${levelStyle.lineHeight}`,
        letterSpacing: `${levelStyle.letterSpacing}px`,
        fontWeight: `${levelStyle.weight}`,
      })?.styles

      style = styles?.split(';;')[0] + ';'

    }

    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { style }), 0]
  },

  addCommands() {
    return {
      toggleParagraph: attributes => ({ commands }) => {

        if (!this.options.levels.includes(attributes.level)) {
          return false
        }

        return commands.toggleNode(this.name, 'paragraph', attributes)
      },
    }
  },
})

export const TextEditorExtensions: Extensions = [
  Underline,
  Superscript,
  SubScript,
  TextStyle,
  CustomImageExt,
  FileExt,
  // SizedParagraph,
  Highlight.configure({ multicolor: true }),
  Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
  TextAlign.configure({ types: ['heading', 'paragraph', 'image'] }),
  FontFamily.configure({ types: ['textStyle'] }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  Link.extend({ inclusive: true }).configure({ // https://github.com/ueberdosis/tiptap/issues/2571
    protocols: ['mailto'],
    HTMLAttributes: { rel: 'noopener noreferrer', target: '_blank', onclick: 'event.stopPropagation(); window.open(event.currentTarget.href, "_blank"); return false;' },
    validate: (href) => /^(?:https?:\/\/|www\.)\S+/.test(href),
    openOnClick: true,
    autolink: true, // works
  }),

  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
]
