import React from 'react'
import { View, SegmentedControl, RenderRichText } from '@/components'
import { Journal } from '@/types'
import { variantProvider } from '@/app'
import { APIClient } from '@/services'
import { onUpdate } from '@codeleap/common'
import { EmptyPlaceholder } from '@codeleap/web'

const tabs: Partial<Record<keyof Journal, string>> = {
  author_instructions: 'Instructions for Authors',
  reviewer_instructions: 'Instructions for Reviewers',
  contact_info: 'Contact',
}

export function JournalInfoTabs({ journal, showAll }: { journal: Journal; showAll?: boolean }) {
  const { isAuthor, isReviewer, isEditor, isPublisher } = APIClient.Session.useSession()

  const options = React.useMemo(() => {
    return Object.keys(tabs).reduce((acc, key) => {
      if (journal?.[key]) {
        const option = { label: tabs[key], value: key }
        const authorTabs = isAuthor && option.value !== 'reviewer_instructions'
        const reviewerTabs = isReviewer && option.value !== 'author_instructions'
        if (showAll || isEditor || isPublisher || authorTabs || reviewerTabs) return [...acc, option]
      }
      return acc
    }, [])
  }, [journal, showAll, isAuthor, isReviewer, isEditor, isPublisher])

  const [tab, setTab] = React.useState(options[0]?.value)

  onUpdate(() => {
    if (!tab && options) {
      setTab(options[0]?.value)
    }
  }, [tab, options])

  if (!options?.length) return null

  return (
    <View variants={['column', 'gap:3']}>
      <View style={styles.tabs}>
        <SegmentedControl
          variants={['tab', 'noBreakline', 'fullWidth']}
          options={options}
          onValueChange={setTab}
          value={tab}
          debounce={0}
          debounceEnabled={false}
        />
      </View>

      {journal[tab] ? (
        <RenderRichText content={journal[tab]} />
      ) : (
        <EmptyPlaceholder icon='file' variants={['table']} title='No Information found.' />
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    tabs: {
      ...theme.presets.fullWidth,

      [theme.media.down('largeish')]: {
        overflowX: 'scroll',
        paddingBottom: theme.spacing.value(2),
      },
    },
  }),
  true,
)
