import React from 'react'
import { JournalCard, PaginationButtons, PaginationButtonsProps, View } from '@/components'
import { Navigation, useTableProps } from '@/utils'
import { Mapper } from '@codeleap/common'
import { EmptyPlaceholder } from '@codeleap/web'
import { variantProvider } from '@/app'
import { Journal } from '@/types'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'

type JournalListProps = Partial<ReturnType<typeof useTableProps>>

export function JournalList(props: JournalListProps) {
  const { items, isLoading, ...paginationProps } = props

  const { isEditor, isPublisher } = APIClient.Session.usePermissions()

  const paginationButtonsProps: PaginationButtonsProps = {
    pages: paginationProps?.numberOfPages,
    value: paginationProps?.currentPage,
    onValueChange: paginationProps?.setCurrentPage,
    onFetchNextPage: paginationProps?.fetchNextPage,
    onFetchPreviousPage: paginationProps?.fetchPreviousPage,
    onFetchPage: (page: number) => paginationProps?.fetchPage(page),
  }

  const handleChangeJournal = async (item: Journal) => {
    AppStatus.set('splash')
    // await APIClient.Session.update({ id: profile?.id, current_publisher: item?.publisher?.id, current_journal: item?.id })
    Navigation.navigate('Journals.List', { route: 'view/' + item?.id })
    AppStatus.set('idle')
  }

  const hasNoItems = (items ?? [])?.length <= 0 && paginationProps?.numberOfPages <= 1
  const emptyMessage =
    isEditor || isPublisher
      ? 'Here you will see the journals in which you have permission to manage.'
      : 'Here you will see the journals in which you submitted manuscripts.'

  if (isLoading) {
    return (
      <EmptyPlaceholder
        loading={true}
        styles={{
          wrapper: styles.emptyPlaceholder,
        }}
      />
    )
  }

  return (
    <View css={styles.wrapper}>
      {hasNoItems ? (
        <EmptyPlaceholder
          icon='book'
          styles={{ wrapper: styles.emptyPlaceholder }}
          variants={['table', 'marginVertical:auto']}
          title={emptyMessage}
        />
      ) : (
        <View css={[styles.innerWrapper, { gridTemplateColumns: `repeat(auto-fit, minmax(380px, ${items?.length > 2 ? '1fr' : '380px'}))` }]}>
          <Mapper
            data={items as Journal[]}
            renderItem={({ item }) => (
              <JournalCard journal={item} showTag={isEditor || isPublisher} onPress={() => handleChangeJournal(item)} />
            )}
          />
        </View>
      )}

      {!paginationProps?.numberOfPages || paginationProps?.hidePaginationButtons ? null : (
        <PaginationButtons {...paginationButtonsProps} />
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
    },
    innerWrapper: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(380px, 1fr))`,
      ...theme.spacing.gap(3),

      [theme.media.down('small')]: {
        gridTemplateColumns: '1fr',
      },
    },
    emptyPlaceholder: {
      paddingTop: theme.spacing.value(5),
      minHeight: 300,
    },
  }),
  true,
)
