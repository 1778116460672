import { assignTextStyle, createDefaultVariantFactory } from '@codeleap/common'
import { SectionFilterPresets, SectionFiltersComposition } from '@codeleap/web'

const createSectionFiltersStyle = createDefaultVariantFactory<SectionFiltersComposition>()

const defaultStyles = SectionFilterPresets

export const SectionFiltersStyles = {
  ...defaultStyles,
  default: createSectionFiltersStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
      ...theme.presets.column,
    },
    headerWrapper: {
      width: '100%',
      justifyContent: 'space-between',
      backgroundColor: 'blue',
    },
    headerTitle: {
      ...assignTextStyle('h3')(theme).text,
      ...theme.spacing.marginBottom(3),
      fontWeight: 'bold',
    },
    innerWrapper: {
      ...theme.spacing.paddingBottom(0),
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    labelWrapper: {
      ...theme.presets.flex,
      ...theme.presets.center,
    },
    label: {
      ...assignTextStyle('p2')(theme).text,
      ...theme.spacing.marginBottom(1),
    },
    description: {
      ...assignTextStyle('p3')(theme).text,
    },
    optionWrapper: {
      ...theme.presets.column,
    },
    optionInnerWrapper: {
      ...theme.presets.column,
    },
    itemOptionButtonWrapper: {
      ...theme.spacing.marginBottom(1),
      ...theme.spacing.padding(1.5),
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral1,
      minWidth: '100%',
      maxWidth: '100%',
      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:hover > svg:last-of-type > *': {
        stroke: `${theme.colors.neutral8} !important`,
      },
    },
    itemOptionButtonIcon: {
      color: theme.colors.neutral8,
      width: 20,
      height: 20,
    },
    itemOptionButtonRightIcon: {
      color: theme.colors.neutral1,
    },
    'itemOptionButtonWrapper:selected': {
      backgroundColor: theme.colors.primary5,
      '&:hover': {
        backgroundColor: theme.colors.primary5,
      },
      'p': {
        color: theme.colors.neutral1,
        fontWeight: '400',
      },
      'svg > *': {
        stroke: `${theme.colors.neutral1} !important`,
      },
      '&:hover > svg:last-of-type > *': {
        stroke: `${theme.colors.neutral1} !important`,
      },
    },
    itemOptionButtonText: {
      color: theme.colors.neutral8,
    },
    footerWrapper: {
      gap: theme.spacing.value(1),
      flexDirection: 'row-reverse',
    },
    applyButtonWrapper: {
      ...theme.spacing.marginBottom(3),
      ...theme.spacing.marginTop(1),
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
      maxHeight: theme.values.itemHeight.default,

    },
    applyButtonText: {
      ...assignTextStyle('p1')(theme).text,
      fontWeight: '600',
      color: theme.colors.neutral1,
    },
    clearButtonWrapper: {
      ...theme.spacing.marginBottom(3),
      ...theme.spacing.marginTop(1),
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.neutral2,
      maxHeight: theme.values.itemHeight.default,

      '&:hover > p ': {
        color: theme.colors.neutral1,
      },
    },
    'clearButtonWrapper:disabled': {
      '&:hover > p ': {
        color: theme.colors.neutral5,
      },
    },
    clearButtonText: {
      ...assignTextStyle('p1')(theme).text,
      fontWeight: '600',
      color: theme.colors.neutral10,
    },
  })),
  transferManuscriptFilter: createSectionFiltersStyle((theme) => ({
    innerWrapper: {
      borderRadius: theme.borderRadius.tiny,
      ...theme.spacing.padding(2),
      ...theme.spacing.paddingBottom(1),
      ...theme.border.neutral3({ width: theme.values.borderWidth.small }),
      minHeight: 200,
      maxHeight: 300,
    },
    label: {
      ...theme.spacing.marginBottom(0),
    },
    itemOptionButtonWrapper: {
      ...theme.spacing.marginBottom(1),
      justifyContent: 'flex-start',
      borderRadius: theme.borderRadius.tiny,
    },
    footerWrapper: {
      ...theme.presets.center,
      ...theme.spacing.marginTop(3),
      ...theme.spacing.marginBottom(1),
    },
    applyButtonWrapper: {
      maxWidth: 200,
    },
    clearButtonWrapper: {
      maxWidth: 100,
    },
  })),
  dashboard: createSectionFiltersStyle((theme) => ({
    wrapper: {
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    itemOptionButtonWrapper: {
      justifyContent: 'flex-start',
      ...theme.spacing.padding(2),
      '> p': {
        textAlign: 'left',
      },
    },
    label: {
      display: 'none',
    },
  })),
}
