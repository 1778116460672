export const IS_SSR = typeof window === 'undefined'

export const ROUTES = {
  // add a new route following the pattern of the others
  Home: '/',
  NotFound: '/404',
  Profile: {
    Edit: '/profile/edit',
  },
  Auth: {
    Login: '/auth/login',
    Signup: '/auth/signup',
    Reset: '/auth/reset',
  },
  About: '/about',
  Components: '/components',
  Manuscripts: {
    List: '/manuscripts',
    Details: '/manuscripts/{{id}}',
    Review: '/manuscripts/{{id}}/review',
    ReviewersAndInvites: '/manuscripts/{{id}}/reviewers_and_invites',
  },
  Dashboard: '/dashboard',
  Journals: {
    List: '/journals',
    Login: '/journals/{{id}}',
    View: {
      Details: '/journals/view/{{id}}',
      Form: '/journals/view/{{id}}/form',
    },
  },
  Permissions: {
    List: '/permissions',
    Details: '/permissions/{{id}}',
  },
  Invites: {
    List: '/invites',
  },
  Announcements: {
    Details: '/announcements/{{id}}',
  },
  InTray: '/in_tray',
  Archive: '/archive',
}

export const JOURNAL_URL_DOMAIN = 'app.eworkflow.com/journals/'
