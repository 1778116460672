import { yup } from '@codeleap/common'
import { I18N } from '../i18n'
import { setLocale } from 'yup'
import { humanizedFileSize } from '@/utils'
import { Settings } from '../Settings'

/**Set Form default values */
setLocale({
  mixed: {
    required: () => I18N.t('form.validations.required'),
  },
  string: {
    url: () => I18N.t('form.validations.url'),
    email: () => I18N.t('form.validations.email'),
    min: ({ min }) => I18N.t('form.validations.min', { min }),
    max: ({ max }) => I18N.t('form.validations.max', { max }),
    length: ({ length }) => I18N.t('form.validations.max', { max: length }),
  },
})

export function fileSizeValidator(file, maxSize = Settings.FilePickerConfigs.Attachments.max) { // 256MB
  return file.size >= maxSize && { code: 'file-size', message: 'File is larger than ' + humanizedFileSize(maxSize) }
}

export * from './posts'
export * from './auth'
export * from './reviews'
export * from './permissions'
export * from './publication'
export * from './invite'
export * from './journal'
export * from './journalDisclaimer'
export * from './reviewJournalQuestions'
export * from './author'
export * from './reviewer'
export * from './aditionalInfo'
export * from './unavailableDate'
