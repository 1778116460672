import { AppImages, variantProvider } from '@/app'
import { Animations, AnimationsProps } from './Animations'
import { ActivityIndicator } from '..'
import { ActivityIndicatorProps, View } from '@codeleap/web'

const animation = AppImages.section_breakdown_loading
export const sectionBreakdownTimer = 4500

const SectionBreakdownCP = (props: Partial<AnimationsProps & {visible?:boolean}> = {}) => {
  return (
    <Animations
      animation={animation}
      autoplay={props?.visible}
      loop
      {...props}
    />
  )
}

export const SectionBreakdownAnimation = (props: ActivityIndicatorProps & {visible?:boolean}) => (
  <View>
    <ActivityIndicator
      {...props}
      component={(moreProps) => <SectionBreakdownCP visible={props?.visible} {...moreProps}/>}
      style={styles.animation}
    />
  </View>
)

const styles = variantProvider.createComponentStyle((theme) => ({
  animation: {
    maxWidth: 950,
    maxHeight: 950,
    width: '100%',
    height: 'auto',
  },
}), true)
