import { Disclaimer, JournalQuestion } from '@/types'

export const defaultQuestions = [
  {
    'question': '<p>How innovative and impactful is the research presented in the manuscript?</p>',
    // 'id': 1,
  },
  {
    'question': '<p>How well-designed and executed are the methods employed in the research?</p>',
    // 'id': 2,
  },
  {
    'question': '<p>How clear and logically organized is the presentation of ideas and results?</p>',
    // 'id': 3,
  },
  {
    'question': '<p>To what extent does the manuscript contribute new knowledge or insights to the relevant field of study?</p>',
    // 'id': 4,
  },
  {
    'question': '<p>How relevant is the research to current issues or challenges in the field, and what potential impact could it have?</p>',
    // 'id': 5,
  },
] as Partial<JournalQuestion>[]

export const defaultRecommendationQuestion = {
  question: '<p>What is your recommendation for this manuscript?</p>',
  choices: [
    {

      'label': 'Accept',
      'value': 'Accept',
    },
    {

      'label': 'Revise',
      'value': 'Revise',
    },
    {

      'label': 'Reject',
      'value': 'Reject',
    },
  ],
} as Partial<JournalQuestion>

const readAndAgreed = 'I have read and agreed to the '

export const defaultAuthorDisclaimer = [
  {
    body: 'I understand that, as the Corresponding Author, I am responsible for completing the Authorship information on behalf of all authors listed on the manuscript.',
    title: 'Authorship responsibility agreement',

  },
  {
    body: 'All contributing authors listed on the manuscript are aware of and agree to the submission of this manuscript.',
    title: 'Authorship agreement',
  },
  {
    title: 'ICMJE Criteria for Authorship agreement',
    body: 'All authors have read and confirmed that they meet the ICMJE criteria for authorship.',

  },
  {
    body: 'Any persons named in the acknowledgements section of the manuscript have agreed to being so named.',
    title: 'Acknowledgements naming agreement',
  },
  {
    title: 'Generic and Trade Names agreement',
    body: "Articles must give a balanced view of therapeutic options. Authors’ use of generic names will contribute to this impartiality. If trade names are used, those of several companies should be used rather than only that of a single support company. It is each author's responsibility to disclose in the Work any commercial support related directly or indirectly to the subject of the Work. It is also each author's responsibility to include the Food and Drug Administration clearance status of any device or drug requiring FDA approval discussed or described in their presentation or to describe the lack of FDA clearance for any “off label” uses discussed.",
  },
  {
    title: 'Unlabeled Uses of Products agreement',
    body: `I understand that, as the Corresponding Author, when an unlabeled use of a commercial product, or an investigational use not yet approved for any purpose is discussed during an educational activity, the presenter is required to disclose that the product is not labeled for the use under discussion or that the product is still investigational.`,
  },
  {
    title: 'US Government Employee agreement',
    body: `A work prepared by a government employee as part of his or her official duties is called a "work of the U.S.Government" and is not subject to copyright. If it is not prepared as part of the employee's official duties, it may be subject to copyright. If Government Worker is selected the following text will be placed on the article: “This article is written work prepared by employees of the Federal Government as part of their official duties is, under the U.S. Copyright Act, a “work of the United States Government” for which copyright protection under Title 17 of the United States Code is not available. As such, copyright does not extend to the contributions of employees of the Federal Government`,
  },
  {
    body: 'If your manuscript reports a clinical trial or research requiring registration, the name of the registry and the registration number/identifier of the trial are included on the title page (if applicable).',
    title: 'Clinical Trial Registration agreement',
  },
  {
    title: 'Statement of Non-duplication agreement',
    body: 'To the best of my knowledge and conviction: The content of the Manuscript, all or in part, has not been published and is not being considered for publication. All intellectual contributions, technical help, financial or material support, and all financial or other relationships that may constitute or lead to a conflict of interest have been acknowledged or disclosed in the Manuscript.',
  },
  {
    title: 'Statement of Originality agreement',
    body: 'The author warrants that this Work contains no libelous or unlawful statements and does not infringe on the rights of others. If excerpts (eg, text, figures, tables, illustrations, or audio/video files) from copyrighted works are included, a written release will be secured by the authors prior to submission, and credit to the original publication will be properly acknowledged. The author further warrants that he or she has obtained, prior to submission, written releases from patients whose names or likenesses are submitted as part of the Work.',
  },

] as Partial<Disclaimer>[]

export const emailFieldProps = {
  'reviewer_invitation': {
    field: 'reviewer_custom_email',
    from: '',
    to: '',
  },
  'revision_decision': {
    field: 'revision_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'rejected_decision': {
    field: 'rejected_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'editor_assigment': {
    field: 'editor_assigment_template',
    from: 'Editor in chief',
    to: 'Editor',
  },
  'manuscript_transferred': {
    field: 'manuscript_transferred_template',
    from: 'Editor',
    to: 'Author',
  },
  'reviews_complete': {
    field: 'reviews_complete_template',
    from: 'Editor',
    to: 'Author',
  },
  'review_invite_accepted': {
    field: 'review_invite_accepted_template',
    from: 'Editor',
    to: 'Reviewer',
  },
  'completed_review': {
    field: 'completed_review_template',
    from: 'Editor',
    to: 'Reviewer',
  },
  'manuscript_submission': {
    field: 'manuscript_submission_template',
    from: 'Editor',
    to: 'Author',
  },
  'rejected_resubmission_decision': {
    field: 'rejected_resubmission_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'accepted_decision': {
    field: 'accepted_decision_template',
    from: 'Editor',
    to: 'Author',
  },
  'revise_reminder': {
    field: 'revise_reminder_template',
    from: 'Editor',
    to: 'Author',
  },
  'late_review_reminder': {
    field: 'late_review_reminder_template',
    from: 'Editor',
    to: 'Reviewer',
  },
}
