import { api } from '@/app'
import { Publisher } from '@/types'
import { PaginationResponse, ReactQuery } from '@codeleap/common'

const BASE_URL = 'publisher/'

async function listPublisherOptions() {
  const response = await api.get<Publisher[]>(`${BASE_URL}options/`)

  return response.data
}

export async function retrieveEmailVariables(): Promise<{[x: string]: string}> {
  const response = await api.get<{[x: string]: string}>(`${BASE_URL}email_variables/`)

  return response?.data
}

export function usePublisherOptions() {
  const query = ReactQuery.useQuery({
    queryFn: listPublisherOptions,
    queryKey: ['publisherOptions'],
    initialData: [],

  })

  return {
    loading: query.isLoading,
    options: query.data,
  }
}
