import { assignTextStyle } from './Text'
import { variantProvider } from '../theme'
import { TagComposition, TagPresets } from '@codeleap/web'

const createTagStyles = variantProvider.createVariantFactory<TagComposition>('wrapper')

const defaultStyles = TagPresets

export const TagStyles = {
  ...defaultStyles,
  default: createTagStyles((theme) => {
    const DEFAULT_HEIGHT = 32
    const backgroundColor = theme.colors.primary1
    const color = theme.colors.neutral8
    const iconSize = theme.values.iconSize[2]

    return {
      wrapper: {
        width: 'fit-content',
        height: DEFAULT_HEIGHT,
        padding: theme.spacing.value(1),
        borderRadius: theme.borderRadius.tiny,
        backgroundColor,
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
      },
      'wrapper:disabled': {
        cursor: 'not-allowed'
      },
      text: {
        color,
        ...assignTextStyle('p3')(theme).text,
        lineHeight: 16,
        marginVertical: 'auto',
      },
      leftIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginRight: theme.spacing.value(0.5),
      },
      rightIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginLeft: theme.spacing.value(0.5),
      },
      rightBadgeWrapper: {
        ...theme.presets.relative,
        marginLeft: theme.spacing.value(0.5),
      },
      leftBadgeWrapper: {
        ...theme.presets.relative,
        marginRight: theme.spacing.value(0.5),
      },
    }
  }),
  'flat': createTagStyles((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.tiny * 0.5,
      backgroundColor: theme.colors.primary1,
    },
    leftIcon: {
      color: theme.colors.neutral7,
      height: theme.values.iconSize[1],
      width: theme.values.iconSize[1],
      marginRight: theme.spacing.value(0.5),
    },
    rightIcon: {
      color: theme.colors.neutral7,
      height: theme.values.iconSize[1],
      width: theme.values.iconSize[1],
      marginLeft: theme.spacing.value(0.5),
    },
  })),
}
