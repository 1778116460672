import { AuthorForm } from './Forms/AuthorForm'
import { SuggestReviewerForm } from './Forms/SuggestReviewerForm'
import { AttachmentsForm } from './Forms/AttachmentsForm'
import { CoverLetterForm } from './Forms/CoverLetterForm'
import { FilePreview } from './Forms/FilePreview'
import { ArticleForm } from './Forms/ArticleForm'
import { DetailsForm } from './Forms/DetailsForm'
import { DisclaimerForm } from './Forms/DisclaimerForm'
import { PublicationHeader } from './PublicationHeader'
import { PublicationToast } from './PublicationToast'
import { PublicationHistory } from './PublicationHistory'
import { PublicationBanner } from './PublicationBanner'
import { IdColumn } from './List/IdColumn'
import { NameColumn } from './List/NameColumn'
import { StatusColumn } from './List/StatusColumn'
import { JournalColumn } from './List/JournalColumn'
import { PlagiarismChecker } from './Plagiarism'

export const Publication = {
  AuthorForm,
  SuggestReviewerForm,
  FilePreview,
  ArticleForm,
  DetailsForm,
  AttachmentsForm,
  CoverLetterForm,
  DisclaimerForm,
  Header: PublicationHeader,
  Toast: PublicationToast,
  History: PublicationHistory,
  Banner: PublicationBanner,
  PlagiarismChecker,
}

export const PublicationColumns = {
  IdColumn,
  NameColumn,
  StatusColumn,
  JournalColumn,
}

export * from './Forms/usePublicationForm'
export { historyMessages } from './HistoryMessages'
