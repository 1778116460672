import { LocalStorage, LocalStorageKeys } from '@/app'
import { useMemo, useState } from 'react'

const optionsLabels = {
  status: 'Status',
  status_datetime: 'Last modified date',
  submission_datetime: 'Submission date',
  type: 'Manuscript type',
}

type UseSorterProps = {
  key: keyof typeof LocalStorageKeys
  options?: {
    initialValue?: any
    filters?: Array<keyof typeof optionsLabels>
  }
}

export function useSorter({ key, options = {}}: UseSorterProps) {
  const {
    initialValue = { sorter: null },
    filters = ['status', 'status_datetime', 'submission_datetime', 'type'],
  } = options

  const [sortBy, setSortBy] = LocalStorage.use(key, initialValue)
  const [draft, setDraft] = useState(sortBy)

  function isSelected(val) {
    return draft?.sorter?.value === val
  }

  const filterData = useMemo(() => {
    const options = filters.flatMap((f) => {
      return [
        {
          value: f,
          label: `${optionsLabels[f]} ascending`,
          itemProps: {
            icon: 'arrow-up',
            rightIcon: 'check',
            selected: isSelected(f),
          },
        },
        {
          value: `-${f}`,
          label: `${optionsLabels[f]} descending`,
          itemProps: {
            icon: 'arrow-down',
            rightIcon: 'check',
            selected: isSelected(`-${f}`),
          },
        },
      ]
    })

    return [{
      id: 'sorter',
      showDescriptionLabel: false,
      options,
    }]
  }, [sortBy, filters])

  return {
    sortBy,
    setSortBy,
    filterData,

    modalProps: {
      selectedItems: sortBy,
      setSelectedItems: setSortBy,
      draftItems: draft,
      setDraftItems: setDraft,
    },
  }
}
