import { Publication } from '@/types'
import { applyTextMaxLines, formatFileName } from '@/utils'
import { View, Text } from '@/components'
import { variantProvider } from '@/app'

export const NameColumn = ({
  title,
  organisation,
  file,
  permissions,
  ...props
}: Publication & { permissions: Partial<Record<'isEditor' | 'isPublisher', boolean>> }) => {
  const showAuthor = (permissions?.isEditor || permissions?.isPublisher) && props?.author
  return (
    <View variants={['column', 'gap:0.5', 'fullWidth']}>
      <Text
        text={title || formatFileName(file)}
        variants={['p1', 'color:neutral8']}
        // css={applyTextMaxLines(2, { lineHeight: '18px' })}
        css={[styles.nameColumnTitle]}
      />
      {(showAuthor || organisation) && (
        <Text variants={['p3', 'color:neutral7']} css={applyTextMaxLines(1)}>
          {showAuthor && `by ${props.author}`}
          {showAuthor && organisation && ' - '}
          {organisation}
        </Text>
      )}
    </View>
  )
}

const LINE_HEIGHT = 18
const MAX_LINES = 4

const styles = variantProvider.createComponentStyle((theme) => ({
  nameColumnTitle: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    lineHeight: LINE_HEIGHT + 'px',
    maxHeight: LINE_HEIGHT * MAX_LINES,
    ...theme.spacing.margin(0),

    WebkitLineClamp: MAX_LINES,
    WebkitBoxOrient: 'vertical',

    overflow: 'hidden',
    textOverflow: 'ellipsis',

    wordBreak: 'break-all',
  },
  subHeading: {
    overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    wordBreak: 'break-word',

  },
}), true)
