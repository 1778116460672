import { View, Text, Image } from '@/components'
import { StylesOf } from '@codeleap/web'

const TEXT_TAGS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  // 'span',
  'br',
  'strong',
]

export type RenderRichTextComposition = 'wrapper' | 'text' | 'image'

const convertCss = (css: string) => {
  const cssObj = {}

  const cssArr = css.split(';')

  cssArr.forEach((css) => {
    const [key, value] = css.split(':')

    const reactKey = key.split('-').map((part, index) => {
      if (index === 0) return part

      return part.charAt(0).toUpperCase() + part.slice(1)
    }).join('')

    if (reactKey !== '' && value) cssObj[reactKey] = value.replaceAll(' ', '')
  })

  return cssObj
}

const mountStyle = (element: HTMLElement) => {
  // @ts-ignore
  const elStyle = element?.attributes?.style

  if (elStyle && elStyle.value) {
    return convertCss(elStyle.value)
  }

  return {}
}

const mountTextComponent = (element: HTMLElement, tag: string, styles = {}) => {
  const variants = []
  const componentProps: any = {}

  if (tag !== 'p') variants.push(tag)
  if (tag === 'strong') variants.push('bold')

  const content = element.innerText

  if (content === '') variants.push('marginVertical:0.5')

  componentProps.text = content

  componentProps.style = { ...styles, ...mountStyle(element) }

  return <Text variants={variants} {...componentProps}/>
}

const mountImageComponent = (element: HTMLElement, styles = {}) => {
  const variants = ['marginVertical:2']
  const componentProps: any = {}

  // @ts-ignore
  componentProps.source = { uri: element?.attributes?.src?.value ?? '', width: 200, height: 200 }
  componentProps.style = { ...styles, ...mountStyle(element), maxWidth: 500, maxHeight: 500 }

  return <Image variants={variants} objectFit={'contain'} {...componentProps} />
}

type RenderComponentFromHtmlProps = {
  element: HTMLElement
  styles?: StylesOf<RenderRichTextComposition>
}

const RenderComponentFromHtml = (props: RenderComponentFromHtmlProps) => {
  const { element, styles } = props

  const tag = element.localName

  if (TEXT_TAGS.includes(tag)) {
    return mountTextComponent(element, tag, styles?.text)
  } else if (tag === 'img') {
    return mountImageComponent(element, styles?.image)
  } else if (tag === 'span' || tag === 'ol' || tag === 'ul' || tag === 'li') {
    if (tag === 'li') {
      return <element.localName>{element.textContent}</element.localName>
    }
    return <element.localName style={(tag === 'ol' || tag === 'ul') ? { marginLeft: 24 } : {} }>
      {Array.from(element.children).map((element, key) => (
        <RenderComponentFromHtml key={`RCHElement_2_${key}`} element={element} />
      ))}
    </element.localName>
  }

  logger.warn('Unhandled HTML tag', { tag }, 'RenderRichText')

  return null
}

type RenderRichTextProps = {
  content: string
  styles?: StylesOf<RenderRichTextComposition>
}

export const RenderRichText = ({ content, styles = {}}: RenderRichTextProps) => {

  if (!content && content === '') return null

  const isOnlyText = !TEXT_TAGS.find(tag => content.startsWith(`<${tag}`))

  if (isOnlyText) return <Text text={content} css={styles.text} />

  const div = document.createElement('div')

  div.innerHTML = content

  const elements = Array.from(div.children as NodeListOf<HTMLElement>)

  return (
    <View variants={['column']} css={styles?.wrapper}>
      {elements.map((element, key) => (
        <RenderComponentFromHtml key={`RCHElement_${key}`} element={element} styles={styles} />
      ))}
    </View>
  )
}
