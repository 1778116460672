import { Theme, variantProvider } from '@/app'
import { View } from '@/components'
import { PropsOf } from '@codeleap/common'
import { CSSProperties, ReactElement } from 'react'
import { motion } from 'framer-motion'
import { useAnimatedStyle } from '@codeleap/web'

export type SkeletonProps = Partial<PropsOf<typeof View>> & {
  enabled?: boolean
  width?: number
  height?: number
  borderRadius?: number
  style?: CSSProperties
  animated?: boolean
}

export const Skeleton = (props: SkeletonProps) => {
  const {
    children,
    borderRadius = Theme.borderRadius.small,
    enabled = false,
    width = 24,
    height = 8,
    style = {},
    animated = false,
    ...rest
  } = props

  if (animated && enabled) {
    return (
      <motion.div 
        style={{ ...styles.wrapper, borderRadius, ...style }} 
        animate={{ 
          backgroundColor: ['#e0e0e0', '#f0f0f0', '#e0e0e0'],
          transition: { duration: 1, repeat: Infinity, repeatType: 'reverse', delay: 1 },
        }}
      />
    )
  }

  return enabled ? (
    <View
      css={[
        styles.wrapper,
        { width, height, borderRadius },
        style
      ]}
      {...rest}
    />
  ) : children as unknown as ReactElement
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    backgroundColor: theme.colors.neutral3,
  },
}), true)
