import { AppIcon } from '@/app'
import { PublicationPlagiarismError, PublicationPlagiarismStatus } from '@/types'

type TextContent = { title: string; description: string; icon?: AppIcon }

export const statusData: Record<
  PublicationPlagiarismStatus, TextContent
> = {
  generating: {
    title: 'The plagiarism check being conducted by iThenticate is in progress...',
    description: 'Track the plagiarism analysis of your manuscript on the iThenticate platform.',

  },
  submitted: {
    title: 'The plagiarism check being conducted by iThenticate is in progress...',
    description: 'Track the plagiarism analysis of your manuscript on the iThenticate platform.',

  },
  not_submitted: {
    title: 'The plagiarism check being conducted by iThenticate is in progress...',
    description: 'Track the plagiarism analysis of your manuscript on the iThenticate platform.',

  },
  ready: {
    title: 'The plagiarism check made by iThenticate has been done.',
    description: 'Track the plagiarism analysis of your manuscript on the iThenticate platform.',
    icon: 'send',
  },
  error: {
    title: 'An error occurred while checking the plagiarism of your manuscript.',
    description: 'Our team is hard at work investigating the issue.',
    icon: 'alert-circle',
  },
}

export const plagiarismErrors: Record<PublicationPlagiarismError, TextContent> = {
  UNSUPPORTED_FILETYPE: {
    description: 'The uploaded filetype is not supported',
    title: 'Unsupported Filetype',
    icon: 'file-warning',
  },
  PROCESSING_ERROR: {
    description: 'An unspecified error occurred while processing the submissions',
    title: 'Processing Error',
    icon: 'alert-circle',

  },
  CANNOT_EXTRACT_TEXT: {
    description: 'The submisison does not contain text to generate a Similarity Report or the word count of the submissions is 0',
    title: 'Cannot Extract Text',
    icon: 'alert-circle',
  },
  TOO_LITTLE_TEXT: {
    description: 'The submission does not have enough text to generate a Similarity Report (a submission must contain at least 20 words)',
    title: 'Too Little Text',
    icon: 'text-cursor',
  },
  TOO_MUCH_TEXT: {
    description: 'The submission has too much text to generate a Similarity Report (after extracted text is converted to UTF-8, the submission must contain less than 2MB of text)',
    title: 'Too Much Text',
    icon: 'text-cursor',
  },
  TOO_MANY_PAGES: {
    description: 'The submission has too many pages to generate a Similarity Report (a submission cannot contain more than 800 pages)',
    title: 'Too Many Pages',
    icon: 'files',
  },
  FILE_LOCKED: {
    description: 'The uploaded file requires a password in order to be opened',
    title: 'File Locked',
    icon: 'file-lock',
  },
  CORRUPT_FILE: {
    description: 'The uploaded file appears to be corrupt',
    title: 'Corrupt File',
    icon: 'file-x',
  },
}
