import { fileSizeValidator } from '@/app/forms'
import { AttachmentsDropzone, Publication, usePublicationForm } from '@/components'
import { APIClient } from '@/services'
import { useCallback, useMemo } from '@codeleap/common'
import { EmptyPlaceholder } from '@codeleap/web'

export const AttachmentsForm = () => {
  const {
    attachments,
    setAttachments,
    setMediaDeleteArray,
    attachmentsMedia,
    setAttachmentsMedia,
    canAuthorEdit,
    isPublicationEditable,
  } = usePublicationForm()

  const { file_categories } = APIClient.Session.useSession()

  const categoriesWithoutMainDocument = useMemo(() => file_categories?.filter(c => !c.refers_to_main_document), [file_categories])

  const FilePreviewComponent = useCallback(
    (props) => {
      return (
        <Publication.FilePreview
          {...props}
          file={props?.file}
          setAttachments={setAttachments}
          fileCategories={categoriesWithoutMainDocument}
          disabled={!isPublicationEditable}
          variants={['ellipsis', 'media']}
        />
      )
    },
    [setAttachments, categoriesWithoutMainDocument],
  )

  const onRemoveAttachment = (media) => {
    if (media?.id) setMediaDeleteArray((prev) => [...prev, media.id])
  }

  if (!isPublicationEditable && !attachmentsMedia.length) {
    return <EmptyPlaceholder icon='file' variants={['table']} title='No Attachments found.' />
  }

  return (
    <AttachmentsDropzone
      showTable={!!attachmentsMedia?.length}
      showDropzone={!!canAuthorEdit}
      showDelete={canAuthorEdit}
      showDownload={!canAuthorEdit}
      canChangeCategory={canAuthorEdit}
      showFileCategory
      currentAttachments={attachments}
      setCurrentAttachments={setAttachments}
      tableAttachments={attachmentsMedia}
      setTableAttachments={setAttachmentsMedia}
      onDelete={onRemoveAttachment}
      disabled={!isPublicationEditable}
      dropzoneProps={{
        FilePreviewComponent,
        validator: fileSizeValidator,
      }}
    />
  )
}
