import { React, AppForms, Theme, variantProvider } from '@/app'
import { View, Text, Button, TextInput, Logo, AuthFormComponents } from '@/components'
import { useForm, onUpdate } from '@codeleap/common'
import { forwardRef, useImperativeHandle } from 'react'
import { APIClient } from '@/services'
import { AuthFormProps } from '@/types'
import { useMediaQuery } from '@codeleap/web'

const auths = {
  login: {
    form: AppForms.login,
    handler: 'useLogin',
  },
  signup: {
    form: AppForms.signup,
    handler: 'useSignup',
  },
  forgot: {
    form: AppForms.forgotPassword,
    handler: 'useSession',
  },
}

export const AuthForm = forwardRef((props: AuthFormProps, ref) => {
  const {
    fields,
    logo = null,
    onFormSwitch = null,
    onAuthSuccess = null,
    modal = false,
    visible = false,
    showSocial = true,
    type = 'login',
    buttons = [],
    Footer = null,
    title,
    step,
    setStep,
    subTitle = null,
    submitText = 'Continue',
    submitButtonVariants = [],
    setIsSubmitSuccessfull = null,
  } = props as AuthFormProps

  const { isLoggedIn } = APIClient.Session.useSession()
  const isMobile = useMediaQuery(Theme.media.down('mid'), { getInitialValueInEffect: false })

  const currentForm = auths[type]

  const form = useForm(
    currentForm.form(),
  )

  useImperativeHandle(ref, () => {
    return {
      setForm: (values) => {
        form.setFormValues(values)
      },
    }
  }, [])

  const authHandler = APIClient.Session[currentForm.handler]()

  const handleSubmit = async () => {
    if (type === 'forgot') {
      const response = await authHandler.requestPasswordReset(form.values.email)

      if (response) {
        setTimeout(() => {
          setIsSubmitSuccessfull(true)
        }, 2500)
      }
    } else {
      if (type === 'signup' && step === 1) {
        setStep(2)
        return
      }

      authHandler(form.values)
    }
  }

  const handleSocial = APIClient.Session.useSocialLogin((userSocial) => {
    if (userSocial) {
      onFormSwitch?.()
      ref?.current?.setForm?.(userSocial)
    }
  }, type)

  onUpdate(() => {
    if (isLoggedIn) {
      onAuthSuccess?.()
    }
  }, [isLoggedIn])

  if (!visible) return null

  return (
    <View variants={['flex', 'column']}>
      {!isMobile && (
        <Logo
          responsive={false}
          image={logo ?? null}
          debugName='AuthForm Logo'
          style={styles.logoImage}
        />
      )}
      
      {(step && !modal) && <Text variants={['h4', 'color:neutral8', 'fullWidth']} text={`Step ${step}/2`} />}
      <Text
        text={title}
        variants={['h1', 'marginBottom:1', isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />
      {!!subTitle && (
        <Text
          text={subTitle}
          variants={['p1', `marginBottom:${isMobile ? 4 : 5}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
        />
      )}
      <View variants={['column', type === 'forgot' ? 'marginBottom:2' : 'marginBottom:4']}>
        {fields.map((field, key) => {
          const custom = typeof field !== 'string'
          const name = custom ? field.name : field
          const Component = custom && field.component ? field.component : TextInput
          let extraProps = {}

          if (custom && field.extraProps) {
            extraProps = field.extraProps
          }

          return (
            <Component
              key={key}
              variants={custom && field.variants}
              // @ts-ignore
              {...form.register(name)}
              {...extraProps}
              onValueChange={(val) => {
                extraProps?.onValueChange?.(val)
                form.setFieldValue(name, val)
              }}
            />
          )
        })}
      </View>

      <View variants={['marginTop:5', 'marginBottom:3', 'column', 'gap:2']}>
        <Button
          onPress={handleSubmit}
          disabled={!form.isValid}
          debugName={`submit ${type}`}
          text={submitText}
          variants={['marginHorizontal:auto', 'fullWidth', 'large', ...submitButtonVariants]}
        />

        {/* @ts-ignore */}
        {buttons.map((_props, i) => <Button key={'button-modal' + i} {..._props}/>)}
      </View>
      {showSocial && <AuthFormComponents.SocialProviders onPress={handleSocial} />}
      {Footer}
    </View>
  )
})

const logoSize = 32

const styles = variantProvider.createComponentStyle((theme) => ({
  logoImage: {
    width: logoSize * 5,
    marginBottom: theme.spacing.value(5),
    alignSelf: 'flex-start',
  },
}), true)
