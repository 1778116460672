export const authorItems = [
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
  },
  {
    text: 'Journals',
    to: 'journals/',
  },
  {
    text: 'Archive',
    to: 'archive/',
  },
]

export const editorItems = [
  {
    text: 'In Tray',
    to: 'in_tray/',
  },
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
  },
  {
    text: 'Journals',
    to: 'journals/',
  },
  {
    text: 'Archive',
    to: 'archive/',
  },
]

export const publisherItems = [
  {
    text: 'In Tray',
    to: 'in_tray/',
  },
  {
    text: 'Dashboard',
    to: 'dashboard/',
  },
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
  },
  {
    text: 'Journals',
    to: 'journals/',
  },
  {
    text: 'Archive',
    to: 'archive/',
  },
  {
    text: 'Permissions',
    to: 'permissions/',
  },
  // {
  //   text: 'Invites',
  //   to: 'invites/',
  // },
]

export const reviewerItems = [
  {
    text: 'Manuscripts',
    to: 'manuscripts/',
  },
  {
    text: 'Invites',
    to: 'invites/',
  },
]
