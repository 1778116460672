import { FaRegFileAlt } from 'react-icons/fa'
import { ToolbarOption } from './types'
import { Theme } from '@/app'
import { ColorPicker, Icon } from '@codeleap/web'
import { FileStore } from './Extensions'

const iconSize = Theme.values.iconSize[1]
const iconColor = Theme.colors.light.neutral9
export const toolbarOptions: ToolbarOption[][] = [
  [
    {
      id: 'bold',
      icon: <Icon name='bold' size={iconSize}/>,
      action: ({ editor }) => editor?.chain().focus().toggleBold()
        .run(),
    },
    {
      id: 'italic',
      icon: <Icon name='italic' size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleItalic()
        .run(),
    },
    {
      id: 'underline',
      icon: <Icon name='underline' size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleUnderline()
        .run(),
    },

    {
      id: 'highlight',
      icon: <Icon name='highlighter' size={iconSize} color={iconColor}/>,
      custom: ({ defaultBtn, option, editor }) => (
        <ColorPicker
          key={option.id}
          openPickerComponent={({ toggle }) => defaultBtn({ onPress: toggle })}
          onConfirm={(color) => option.action({ editor, color })}
        />
      ),
      action: ({ editor, color }) => {
        const chain = editor?.chain().focus()
        !editor.isActive('highlight') ? chain.setHighlight({ color }).run() : chain.unsetHighlight().run()
      },
    },
    {
      id: 'textStyles',
      icon: <Icon name='font-color' size={iconSize} color={iconColor}/>,
      custom: ({ defaultBtn, option, editor }) => (
        <ColorPicker
          key={option.id}
          openPickerComponent={({ toggle }) => defaultBtn({ onPress: toggle })}
          onConfirm={(color) => option.action({ editor, color })}
        />
      ),
      action: ({ editor, color }) => {
        const chain = editor?.chain().focus()
        !editor.isActive('textStyles') ? chain.setColor(color).run() : chain.unsetColor().run()
      },
    },
  ],
  [
    {
      id: 'left',
      icon: <Icon name='align-left' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'left' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('left')
        .run(),
    },
    {
      id: 'center',
      icon: <Icon name='align-center' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'center' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('center')
        .run(),
    },
    {
      id: 'justify',
      icon: <Icon name='align-justify' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'justify' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('justify')
        .run(),
    },
    {
      id: 'right',
      icon: <Icon name='align-right' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'right' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('right')
        .run(),
    },
  ],
  [
    {
      id: 'h1',
      icon: <Icon name='heading-1' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 1 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 1 })
        .run(),
    },
    {
      id: 'h2',
      icon: <Icon name='heading-2' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 2 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 2 })
        .run(),
    },
    {
      id: 'h3',
      icon: <Icon name='heading-3' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 3 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 3 })
        .run(),
    },
    {
      id: 'p1',
      icon: <Icon name='paragraph-1' size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('paragraph'),
      action: ({ editor }) => editor?.chain().focus().setParagraph()
        .run(),
    },
  //   {
  //     id: 'p2',
  //     icon: <Icon name='paragraph-2' size={iconSize} color={iconColor}/>,
  //     isActive: ({ editor }) => editor?.isActive('sizedParagraph', { level: 2 }),
  //     action: ({ editor }) => editor?.chain().focus().toggleParagraph({ level: 2 })
  //       .run(),
  //   },
  //   {
  //     id: 'p3',
  //     icon: <Icon name='paragraph-3' size={iconSize} color={iconColor}/>,
  //     isActive: ({ editor }) => editor?.isActive('sizedParagraph', { level: 3 }),
  //     action: ({ editor }) => editor?.chain().focus().toggleParagraph({ level: 3 })
  //       .run(),
  //   },
  ],
  [
    {
      id: 'bulletList',
      icon: <Icon name='list' size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleBulletList()
        .run(),
    },
    {
      id: 'orderedList',
      icon: <Icon name='list-ordered' size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleOrderedList()
        .run(),
    },
  ],
  [
    {
      id: 'link',
      icon: <Icon name='link-2' size={iconSize} color={iconColor}/>,
      action: ({ toggleLinkModal, editor, linkForm }) => {
        const { from, to, empty } = editor.state.selection
        if (!empty) {
          const text = editor.state.doc.textBetween(from, to, ' ')?.trim()
          const validLink = editor.getAttributes('link').href?.trim()
          const selectionHasFullLink = editor.isActive('link')

          linkForm.setFormValues({
            text: text,
            link: selectionHasFullLink ? validLink || '' : '', // Set link only if the whole selection is linked
          })
        }
        return toggleLinkModal()
      },
    },
    {
      id: 'image',
      icon: <Icon name='image' size={iconSize} color={iconColor} />,
      action: async ({ editor, fileInputRef }) => {
        const selectedText = window.getSelection().toString().replace(/\s/g, '')
        const isValidURL = /^(?:https?:\/\/)\S+/.test(selectedText)

        const chain = editor.chain().focus()

        if (selectedText && isValidURL) {
          return chain.setImage({ src: selectedText }).run()
        }

        if (!selectedText) {
          const [image] = await fileInputRef?.current?.openFilePicker()
          if (image.file.type.startsWith('image/')) {
            const url = URL.createObjectURL(image.file)
            FileStore.getState().setFiles({ [url]: image.file })
            // save the image somewhere
            /*@ts-ignore*/
            return chain.setImage({ src: url, 'data-uploaded': true }).run()
          }
        }
      },
    },
    {
      id: 'fileComponent',
      icon: <FaRegFileAlt size={iconSize} color={iconColor}/>,
      action: async ({ editor, fileInputRef }) => {
        const chain = editor.chain().focus()
        const file = await fileInputRef?.current?.openFilePicker()

        const doc = file[0].file

        const url = URL.createObjectURL(doc)
        FileStore.getState().setFiles({ [url]: doc })

        const text = `File: ${doc.name}`

        return chain.setAttachment({
          file: url,
          text,
          'data-uploaded': true,
        }).run()
      },
    },
  ],
]
