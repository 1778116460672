import { variantProvider } from '@/app'
import { Button, Modal, View, Text, HTMLRender } from '..'
import { InvitationReviewer } from '@/types'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { AnyFunction } from '@codeleap/common'

type PreviewInviteReviewerProps = {
  visible: boolean
  data: {
    publication?: number
    reviewers?:Partial<InvitationReviewer>[]
    callback?: () => void
    canGoBack?: boolean
    full_name?: string
    email?: string
    message?: string
    subject?: string
    isManual?: boolean
    isAutomatic?: boolean
    description?: string
  }
  handleBack?: () => void
  onClose?: () => void
  onConfirm?: () => void
}

export const PreviewInviteReviewerModal = (props) => {
  const { visible, data, onConfirm, onClose, handleBack } = useAppSelector(state => state.AppStatus.modals.previewInviteReviewer as PreviewInviteReviewerProps)

  const handleSubmit = async () => {
    onClose?.()
    AppStatus.set('loading')
    try {
      if (!data?.isAutomatic) {
        if (data?.isManual) {
          const { full_name, email } = data

          await APIClient.Publications.inviteReviewers(data?.publication, [{ full_name, email }], {
            message: data?.message,
            subject: data?.subject,
          })
        } else {
          await APIClient.Publications.inviteReviewers(data?.publication, data?.reviewers, {
            message: data?.message,
            subject: data?.subject,
          })
        }
      }
      props?.onSubmit?.()
      onConfirm?.()
      data?.callback?.()
    } catch (e) {

    } finally {
      AppStatus.set('done')
    }
  }

  return (
    <Modal
      visible={visible}
      title='Email preview'
      toggle={() => onClose?.()}
      styles={{
        box: styles.box,
      }}
    >
      <View variants={['column', 'fullWidth', 'gap:4']}>
        {data?.description ? <Text text={data.description} variants={['p2', 'color:neutral7']}/> : null}
        {data?.subject ? <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text='Subject' />
          <Text variants={['p1', 'color:neutral8']} text={data.subject} />
        </View> : null}

        {data?.email || data?.reviewers?.length ? <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text='Email' />
          {!data?.isManual ? data?.reviewers?.map(r => <Text variants={['p1', 'color:neutral8']} text={r?.email}/>) : <Text variants={['p1', 'color:neutral8']} text={data?.email} />}
        </View> : null}

        <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text='Message' />
          <HTMLRender html={data?.message} />
        </View>
        <View variants={['gap:1', 'center']}>
          {data?.canGoBack ? (
            <Button
              debugName='Back to Invite Reviewer modal'
              text='Continue editing'
              variants={['large', 'border-radius:tiny', 'flat']}
              onPress={() => handleBack?.()}
            />) : null}
          <Button
            debugName='Send invite modal'
            text='Confirm Invite'
            icon='send'
            variants={['large', 'border-radius:tiny']}
            onPress={handleSubmit}/>
        </View>
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 720

const styles = variantProvider.createComponentStyle(() => ({
  box: {
    width: MODAL_WIDTH,
  },
}), true)
