import { variantProvider, React } from '@/app'
import { Toaster, Text, View, Icon, usePublicationForm } from '@/components'
import { PublicationStatus } from '@/types'
import { DateUtils } from '@/utils'

const ToastLeftContent = () => {
  const { publication } = usePublicationForm()
  const createdDate = publication?.created_datetime ? new Date(publication?.created_datetime) : null
  const formattedDate = createdDate ? DateUtils.formatDate(createdDate, 'dd/MM/yy') : ''
  return (
    <View variants={['column', 'alignCenter', 'gap:1']}>
      <View
        variants={['gap:0.25', 'alignCenter', 'bg:neutral2', 'paddingVertical:0.25', 'paddingHorizontal:0.5']}
        style={styles.toastLeftContent}
      >
        <Icon debugName='Toaster icon' name='parchment' style={styles.icon} />
        <Text text={publication?.id_slug} variants={['p3', 'color:neutral9', 'semiBold', 'textCenter']} style={styles.slug} />
      </View>
      <Text text={formattedDate} variants={['p3', 'neutral-8']} />
    </View>
  )
}

const ToastRightContent = (isEditor) => {
  const { publication } = usePublicationForm()
  return (
    <View variants={['column', 'center', 'gap:1']}>
      {isEditor ? (
        <View
          variants={['alignCenter', 'justifyCenter', 'bg:neutral2', 'border-radius:nano', 'paddingHorizontal:0.5', 'paddingVertical:0.25']}
        >
          <Text text={PublicationStatus?.[publication?.status]} variants={['p2', 'noWrap']} />
        </View>
      ) : (
        <View variants={['paddingVertical:0.25', 'paddingHorizontal:0.5', 'bg:neutral1']} style={styles.authorStatus}>
          <Text text={PublicationStatus?.[publication?.status]} variants={['p2', 'color:neutral8', 'noWrap']} />
        </View>
      )}
      <Text text={DateUtils.formatDate(publication?.status_datetime, 'dd/MM/yy')} variants={['p3', 'neutral-8', 'noWrap']} />
    </View>
  )
}

export const PublicationToast = ({ isEditor }) => {
  const { authorForms, publication } = usePublicationForm()
  return (
    <Toaster
      title={publication?.title || ''}
      description={isEditor ? authorForms?.[0]?.full_name : publication?.journal?.title || ''}
      LeftContent={ToastLeftContent}
      RightContent={() => ToastRightContent(isEditor)}
      variants={['status', !isEditor ? 'status:author' : null]}
      isVisible
    />
  )
}

const MAX_WIDTH = 100

const styles = variantProvider.createComponentStyle((theme) => ({
  icon: {
    color: theme.colors.neutral9,
    width: theme.values.iconSize[2],
    height: theme.values.iconSize[2],
    flexShrink: 0,
  },
  item: {
    maxHeight: theme.values.itemHeight.small,
    height: theme.values.itemHeight.small,
    borderRadius: theme.borderRadius.tiny,
  },
  toastLeftContent: {
    borderRadius: theme.spacing.value(0.5),
  },
  slug: {
    maxWidth: MAX_WIDTH,
    textWrap: 'wrap',
    wordWrap: 'break-word',
  },
  authorStatus: {
    borderRadius: theme.spacing.value(0.5),
  },
}), true)
