import { variantProvider } from '@/app'
import { Text, View, Icon } from '@/components'
import { PercentageValue, PublicationStatus } from '@/types'
import { ChartColumnColors } from './chartColors'
import { forwardRef, useMemo } from 'react'

type HorizontalBarChartProps = {
  columns: Record<string, PercentageValue>
  chartColumnsColor: { [p: string]: ChartColumnColors }
}

const order: keyof typeof PublicationStatus = [
  'accepted',
  'under_review',
  'revision_requested',
  'rejected',
  'revision_completed',
  'revision_in_drafts',
  'saved_in_drafts',
  'submitted_for_review',
  'pending_review',
  'reviewers_invited_accepted',
  'review_submitted',
  'rejected_transfer',
  'rejected_resubmit',
  'withdraw',
]

export const DashboardHorizontalBarChart = forwardRef((props: HorizontalBarChartProps, ref) => {
  const { columns, chartColumnsColor } = props

  const { highestValueNormalized } = useMemo(() => {
    const highestValue = Math.max(...Object.values(columns).map(item => item.value))
    const highestValueNormalized = highestValue < 1 ? 1 : highestValue

    return { highestValueNormalized }
  }, [columns])

  const columnsFormatted = useMemo(() => {
    return Object?.entries(columns)?.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))?.map(([key, value]) => {
      const columnsColorData = chartColumnsColor?.[key]

      return {
        status: columnsColorData?.label,
        color: columnsColorData?.color,
        darkerColor: columnsColorData?.darkerColor,
        icon: columnsColorData?.icon,
        value,
      }
    })
  }, [columns, chartColumnsColor])

  return (
    <View variants={['gap:1', 'column']} style={styles.wrapper} ref={ref}>
      {
        columnsFormatted?.map((_status) => {
          const width = ((_status?.value?.value / highestValueNormalized) * 100)
          const adjustedWidth = width < 37 ? width + 40 : width

          const roundedPercentage = Math.round(_status?.value?.percentage * 10) / 10

          return (
            <View variants={['fullWidth', 'column', 'gap:0.5', 'alignStart']}>
              <View
                style={{
                  width: `${adjustedWidth}%`,
                  backgroundColor: _status?.color, ...styles.barWrapper,
                  borderLeft: `4px solid ${_status?.darkerColor}`,
                }}
                variants={['row', 'justifySpaceBetween', 'alignCenter']}
                responsiveVariants={{ small: ['column', 'alignStart'] }}
              >
                <View variants={['alignCenter', 'gap:0.5', 'justifyStart']}>
                  <Icon debugName={'Chart icon'} name={_status?.icon} size={18} color={_status?.darkerColor} />
                  <Text text={`${_status?.status} (${_status?.value?.value < 1 ? '0' : _status?.value?.value})`} variants={['h5', 'color:neutral9']} />
                </View>
                <Text text={`${roundedPercentage} %`} variants={['h4', 'color:neutral9', 'bold']} />
              </View>
            </View>
          )
        })
      }
    </View>
  )
})

const HEIGHT = 32

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: '100%',
  },
  barWrapper: {
    minHeight: HEIGHT,
    ...theme.spacing.padding(1),
    borderRadius: 8,
  },
}), true)
