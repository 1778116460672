import { CodeleapQueryClient, ReactQuery } from '@codeleap/common'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { getFirebase } from '../firebaseApp'

const reactQueryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours,

    },
  },
})

if (!(typeof window === 'undefined')) {
  getFirebase().then(fb => {
    fb.auth().onAuthStateChanged((user) => {

      reactQueryClient.setDefaultOptions({
        queries: {
          enabled: !!user?.uid,
        },
      })
    })
  })
  const localStoragePersister = createSyncStoragePersister({
    storage: window?.localStorage || null,
  })

  persistQueryClient({
    queryClient: reactQueryClient,
    persister: localStoragePersister,

  })

}

const queryClient = new CodeleapQueryClient(reactQueryClient)

export { queryClient }
