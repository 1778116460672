import { variantProvider } from '@/app'
import { Text, View } from '@/components'

export function JournalColumn({ journal }) {
  return (
    <View css={styles.wrapper}>
      <Text css={styles.journal} text={journal?.title} />
    </View>
  )
}

const LINE_HEIGHT = 18
const MAX_LINES = 3

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      width: 150,
      ...theme.presets.flex,
      ...theme.presets.center,
      ...theme.presets.fullHeight,
    },
    journal: {
      whiteSpace: 'normal',

      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      lineHeight: LINE_HEIGHT + 'px',
      maxHeight: LINE_HEIGHT * MAX_LINES,
      ...theme.spacing.margin(0),

      WebkitLineClamp: MAX_LINES,
      WebkitBoxOrient: 'vertical',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  true,
)
