import { createSlice, TypeGuards } from '@codeleap/common'
import { api, LocalStorage, Settings } from '@/app'
import { DropzoneFile, DropzoneFileRejection } from '@codeleap/web'
import { Attachment } from '@/components'
import { FileScanResponse, ScanId } from '@/types'

export type UploadFileState = {
  scanId: ScanId
  scanCompleted: boolean
  scanResultFiles: FileScanResponse['results']
  isMiddleStepsVisible: boolean
  acceptedFiles: File[]
  rejectedFiles: DropzoneFileRejection[]
}

const initialState: UploadFileState = {
  scanId: null,
  scanCompleted: false,
  scanResultFiles: [],
  isMiddleStepsVisible: true,
  acceptedFiles: [],
  rejectedFiles: []
}

export const uploadFilesSlice = createSlice({
  name: 'UploadFiles',
  initialState,
  reducers: {
    clear: () => initialState,
    clearFiles: (state) => ({ ...state, acceptedFiles: [], rejectedFiles: [] }),
    setAcceptedFiles: (state, action: File[] | ((files: File[]) => File[])) => {
      if (TypeGuards.isFunction(action)) {
        state.acceptedFiles = action(state.acceptedFiles)
      } else {
        state.acceptedFiles = action
      }
      return state
    },
    setRejectedFiles: (state, action: DropzoneFileRejection[] | ((files: DropzoneFileRejection[]) => DropzoneFileRejection[])) => {
      if (TypeGuards.isFunction(action)) {
        state.rejectedFiles = action(state.rejectedFiles)
      } else {
        state.rejectedFiles = action
      }
      return state
    },
    setScanId: (_, scanId: ScanId) => ({ scanId }),
    toggleScanCompleted: (_, to: boolean) => ({ scanCompleted: to }),
    toggleMiddleStepsVisible: (_, to: boolean) => ({ isMiddleStepsVisible: to }),
    onCompleteScan: (state, { results }: { results: FileScanResponse['results'] }) => {
      state.scanId = null
      state.scanCompleted = false
      state.scanResultFiles = results
      return state
    },
  },
  asyncReducers: {

  },
})
