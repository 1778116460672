import { variantProvider } from '@/app'
import { ActivityIndicator, useJournalCrudContext, View } from '@/components'
import { memoize, onUpdate, useState } from '@codeleap/common'
import { useAnimatedStyle } from '@codeleap/web'

export const JournalOverlay = memoize(() => {
  const { loaded } = useJournalCrudContext()

  const [visible, setVisible] = useState(true)

  const animated = useAnimatedStyle(() => {
    return {
      opacity: visible ? 1 : 0,
      transition: {
        delay: 0.5,
      },
    }
  }, [visible])

  onUpdate(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false)
      }, 1000)
    }
  }, [loaded])

  return (
    <View style={styles.wrapper} animated animatedProps={animated}>
      <ActivityIndicator debugName='journalOverlay:indicator' />
    </View>
  )
})

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.center,
    ...theme.presets.whole,
    ...theme.presets.fixed,
    width: '100vw',
    height: '100svh',
    pointerEvents: 'none',
    background: theme.colors.neutral1,
    zIndex: 1000,
  },
}), true)
