import { AppForms, React, variantProvider } from '@/app'
import { Button, Modal, TextEditor, TextEditorExtensions, TextInput, ToolBar, View, Text, TemplateVariables } from '@/components'
import { onUpdate, useForm } from '@codeleap/common'
import { useEditor } from '@tiptap/react'
import { InvitationReviewer, Publication } from '@/types'
import { AppStatus, useAppSelector } from '@/redux'
import Placeholder from '@tiptap/extension-placeholder'

type InviteReviewerProps = {
  visible: boolean
  data: {
    publication?: number
    reviewers?: Partial<InvitationReviewer>[]
    callback?: () => void
    full_name?: string
    email?: string
    message?: string
    subject?: string
  }
}

export const InviteReviewerModal = (props: {publication: Publication}) => {
  const { publication } = props
  const emailTemplate = publication?.journal?.reviewer_custom_email
  const { visible, data } = useAppSelector((state) => state.AppStatus.modals.inviteReviewer as InviteReviewerProps)

  const editor = useEditor({
    content: emailTemplate,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => form.setFieldValue('message', editor.getHTML()),
  })

  const inviteForm = useForm(AppForms.createInvite, { initialState: { message: emailTemplate, subject: data?.subject }})

  const manualInviteForm = useForm(AppForms.createManualInvite, {
    initialState: {
      email: data?.email,
      full_name: data?.full_name,
      message: emailTemplate,
      subject: data?.subject,
    },
  })

  const isManual = data?.reviewers?.length === 0
  const form = isManual ? manualInviteForm : inviteForm

  onUpdate(() => {
    if (emailTemplate?.length && editor) {
      editor?.commands?.setContent(emailTemplate, true)
    }
  }, [emailTemplate])
  onUpdate(() => {
    if (visible && data) {
      manualInviteForm?.setFieldValue('subject', data?.subject)
      inviteForm?.setFieldValue('subject', data?.subject)
    }
  }, [visible, data])

  const resetForm = () => {
    inviteForm.setFormValues({ subject: '',
      message: emailTemplate,
    })
    manualInviteForm.setFormValues({
      email: '',
      full_name: '',
      subject: '',
      message: emailTemplate,
    })
    editor.commands.setContent(emailTemplate)
  }

  const handleBackToInviteModal = () => {
    AppStatus.setModal(['inviteReviewer', true, { data }])
    AppStatus.setModal(['previewInviteReviewer', false])
  }

  const onClosePreview = () => {
    AppStatus.setModal(['previewInviteReviewer', false])
    setTimeout(() => {
      AppStatus.setModal(['previewInviteReviewer', false, { data: null }])
      AppStatus.setModal(['inviteReviewer', false, { data: null }])
    }, 500)
  }

  const handleSubmit = async () => {
    const { full_name, email } = manualInviteForm.values
    const emailData = {
      ...data,
      full_name,
      email,
      isManual,
      message: form.values?.message,
      subject: form.values.subject,
      canGoBack: true,
    }

    AppStatus.setModal(['inviteReviewer', false, { data: {}}])
    setTimeout(() => {
      AppStatus.setModal(['inviteReviewer', false, { data: null }])
      AppStatus.setModal(['previewInviteReviewer', true, {
        data: emailData,
        onConfirm: resetForm,
        handleBack: handleBackToInviteModal,
        onClose: onClosePreview,
      }])
    }, 500)
  }

  const isMaxValid = editor?.getHTML().length <= 10000
  const isMinValid = editor?.getHTML().length >= 22

  return (
    <Modal
      visible={visible}
      title='Invite a reviewer'
      toggle={() => {
        AppStatus.setModal(['inviteReviewer', false, { data: null }])
        resetForm()
      }}
      styles={{
        box: styles.box,
      }}
    >
      <View variants={['column', 'fullWidth', 'gap:1']}>
        {isManual ? (
          <>
            <TextInput {...manualInviteForm.register('full_name')} />
            <TextInput {...manualInviteForm.register('email')} />
          </>
        ) : (
          <View variants={['column', 'gap:1', 'fullWidth', 'marginBottom:3']}>
            <Text variants={['p2', 'color:caption']} text='Recipients' />
            {data?.reviewers?.map(({ email }) => (
              <Text text={email} variants={['color:neutral8']} />
            ))}
          </View>
        )}
        <TextInput {...form.register('subject')} />
        <View variants={['column']}>
          <TemplateVariables variants={['marginBottom:3']}/>
          <TextEditor
            {...form.register('message')}
            editor={editor}
            toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'fileComponent']}/>}
            variants={['reviewerQuestionsForm']}
            _error={(!isMaxValid || !isMinValid)}
            validate={(isMaxValid || isMinValid) ? false : form.register('message')?.validate}
          />
          {!isMinValid && <Text variants={['p3', 'color:destructive2']} text={'Minimum of 15 characters'} />}
          {!isMaxValid && <Text variants={['p3', 'color:destructive2']} text={'Maximum of 10000 characters'} />}
        </View>
        <Button
          debugName='Send invite modal'
          text='Send invitation'
          icon='send'
          disabled={!form.isValid || editor?.getText()?.length < 15 || editor?.getHTML()?.length > 10000}
          variants={['marginHorizontal:auto', 'marginTop:1', 'large', 'border-radius:tiny']}
          onPress={handleSubmit}
        />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  () => ({
    box: {
      overflow: 'auto',
    },
  }),
  true,
)
