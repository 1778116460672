import { Settings } from '@/app'
import { Attachment, Button, useUploadFilesContext, View } from '@/components'
import { AppStatus } from '@/redux'
import { QueryKeys } from '@/services/api'
import { useMemo } from '@codeleap/common'

export const NewPublicationVersion = ({ publication }) => {
  const { setPublicationId, setCurrentModal } = useUploadFilesContext()
  const mainDocCategory = useMemo(() => {
    const fileCategories = QueryKeys.fileCategory.getData()
    const mainDocCategory = fileCategories?.find?.(cat => !!cat?.refers_to_main_document)
    return mainDocCategory
  }, [])

  function onUploadNewVersion(acceptedFiles: File[]) {
    AppStatus.setModal(['uploadFiles', false, null])
    const files = acceptedFiles.sort((a, b) => a.file_category === mainDocCategory.id ? - 1 : 1)
    AppStatus.setModal(['submitNewPublicationVersion', true, {
      data: {
        publication: publication?.id,
        files,
        onSubmit: (setAttachments) => {
          setAttachments(null)
        },
        dropzoneProps: {
          accept: Settings.FilePickerConfigs.CreatePublication.acceptFiles,
        },
      },
    }])
  }

  const validateOnCategoryChange = (files: Attachment[]) => {

    const fileWithoutCategory = files?.find(file => !file.file_category)
    const mainDocFiles = files?.filter(file => file?.file_category === mainDocCategory?.value)

    if (mainDocFiles.length === 0) {
      return { message: `There must be one file categorized as "${mainDocCategory?.label}"`, valid: false }
    }

    if (mainDocFiles.length > 1) {
      return { message: `There cannot be more than one "${mainDocCategory?.label}"`, valid: false }
    }

    if (fileWithoutCategory) {
      return { message: `All files must have a category selected`, valid: false }
    }

    return { message: ``, valid: true }
  }

  const validateOnChange = (file: File | Attachment) => {
    const extension = file.name.split('.').pop()?.toLowerCase()
    const isExtValid = Settings.FilePickerConfigs.CreatePublication.acceptedExtensions.includes(extension)

    if (!isExtValid) {
      return { message: `Main manuscript must be .docx, .pdf, or .doc`, valid: false }
    }

    return { message: ``, valid: true }
  }

  return (
    <View variants={['center']}>
      <Button
        text={`Send new version to editor`}
        debugName={`Send new publication version to editor button press!`}
        icon={'send'}
        disabled={publication?.status !== 'revision_requested'}
        variants={['sendNewPublicationVersionButton']}
        onPress={() => {
          AppStatus.setModal(['reviewsOverview', false])
          setPublicationId(publication?.id)
          setCurrentModal('decision')
          AppStatus.setModal(['uploadFiles', true, {
            onSubmit: onUploadNewVersion,
            validateOnCategoryChange,
            validateOnChange,
            showCategory: true,
            onSendError: () => AppStatus.setModal(['uploadFiles', false, null]),
            file_categories: [],
            initialStep: null,
          }])
        }}
      />
    </View>
  )
}
