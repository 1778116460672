import { React, AppForms, Theme } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { useForm, useI18N } from '@codeleap/common'
import { Button, Checkbox, Text, TextInput, View, AuthFormComponents, Link as LinkCP } from '@/components'
import { navigate } from 'gatsby'
import { Navigation, useKeydown } from '@/utils'
import { useMediaQuery, useSearchParams } from '@codeleap/web'
import { Profile } from '@/types'
import { QueryKeys } from '@/services/api/queryKeys'

type LoginFormProps = {
  isModal?: boolean
  title?: string
  visible?: boolean
  modalName?: any
  journalData?: Partial<Profile> & { invite?: string }
  canSignUp?: boolean
}

export const LoginForm = ({ isModal = true, canSignUp = true, journalData }: LoginFormProps) => {
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.login)
  const { t } = useI18N()
  const isMobile = useMediaQuery(Theme.media.down('mid'), { getInitialValueInEffect: false })

  const form = useForm(AppForms?.login, {})

  const [params] = useSearchParams({
    next: undefined,
  })

  const onLoginSuccess = async () => {
    if (params.next) {
      navigate(params.next)
      return
    }
    const role = await APIClient.Roles.list()

    if (isModal) {
      const id = QueryKeys.me.getData()?.id
      if (role?.reviewer && journalData?.current_role === 'reviewer' && id) {
        APIClient.Session.update({ current_role: 'reviewer', id })
        const invites = await APIClient.Publications.listInvites()
        if (!!invites.results?.length) {
          Navigation.navigate('Invites.List')
        }
      } else {
        AppStatus.setModal(['login', false])
        Navigation.navigate('Manuscripts.List')
      }
    } else {
      if (QueryKeys.me.getData()?.current_role === 'reviewer' || journalData?.invite) {
        Navigation.navigate('Invites.List')
        return
      }

      Navigation.navigate('Manuscripts.List')
    }
  }

  const handleSocial = APIClient.Session.useSocialLogin((userSocial) => {
    if (userSocial) {
      onLoginSuccess()
    }
  }, 'login', journalData, canSignUp)

  const login = APIClient.Session.useLogin()

  const handleSubmit = async () => {
    try {
      const successLogin = await login({
        ...form.values,
        email: form.values.email.toLowerCase(),
        current_journal: journalData?.current_journal,
        current_publisher: journalData?.current_publisher,
      })

      if (successLogin) {
        onLoginSuccess()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  const toSignup = () => {
    if (isModal) {
      AppStatus.setModal(['login', false])
      AppStatus.setModal(['signup', true, { data: journalData }])
    } else {
      navigate('/auth/signup/')
    }
  }

  const toForgot = () => {
    if (isModal) {
      AppStatus.transitionModals(['login', 'beforeForgot'])
    } else {
      navigate('/auth/reset/')
    }
  }

  useKeydown(() => {
    if (!form.isValid) return
    handleSubmit?.()
  }, [isFocused, isModal, form?.values], 'enter')

  return (
    <View variants={['flex', 'column']}>
      <Text
        text={t('Login.title')}
        variants={['h1', 'marginBottom:1', isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />
      <Text
        text={t('Login.description')}
        variants={['p1', `marginBottom:${isMobile ? 4 : 5}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />
      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'gap:1']}>
          <TextInput
            {...form.register('email')}
            leftIcon={{ name: 'mail' }}
          />
          <TextInput
            {...form.register('password')}
            visibilityToggle
            leftIcon={{ name: 'key' }}
          />
          <Checkbox
            {...form.register('remember')}
            onValueChange={(val) => {
              APIClient.Session.toggleRememberMe(val)
              form.setFieldValue('remember', val)
            }}
            variants={['left']}
          />
        </View>

        <View variants={['marginTop:2', 'marginBottom:3', 'column', 'gap:2']}>
          <Button
            onPress={handleSubmit}
            disabled={!form.isValid}
            debugName={`submit login`}
            text={t('Login.submit')}
            variants={['marginHorizontal:auto', 'fullWidth', 'large']}
          />
          <Button
            onPress={toForgot}
            debugName={`Reset password`}
            text={t('Login.forgot')}
            variants={['marginHorizontal:auto', 'fullWidth', 'large', 'flat']}
          />
        </View>

        <AuthFormComponents.SocialProviders onPress={handleSocial} />

        {isModal ? (
          <AuthFormComponents.RedirectModal
            text={t('Login.txt1')}
            textAction={t('Login.txt2')}
            onPress={toSignup}
          />
        ) : null}
      </View>
    </View>
  )
}
