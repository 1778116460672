/* eslint-disable @typescript-eslint/no-unused-vars */
import { React } from '@/app'
import { Button, Modal, View, Text } from '@/components'
import { DashboardExportParams } from '@/services/api/dashboard'
import { Profile } from '@/types'
import { useBooleanToggle, useState } from '@codeleap/common'

type ExportProps = {
  onExport: (e: DashboardExportParams['type']) => void
  isLoading: boolean
  profile: Profile
}

const extensions: DashboardExportParams['type'][] = ['csv', 'xlsx', 'pdf', 'pptx']

export const Export = ({ onExport, isLoading, profile }: ExportProps) => {
  const [visible, toggle] = useBooleanToggle(false)
  const [type, setType] = useState<DashboardExportParams['type'] | string>('')

  const handleClose = () => {
    toggle(false)
    setType('')

  }

  const handleExport = () => {
    handleClose()
    onExport(type as DashboardExportParams['type'])
  }

  return (
    <>
      <Button
        text='Export information'
        debugName='Export button'
        onPress={() => toggle(true)}
        variants={['flat', 'small', 'fontWeight:600', isLoading ? 'loadingLeft' : '']}
        icon='download'
        rightIcon={!isLoading ? 'chevron-down' : null}
        disabled={isLoading}
        loadingShowText
        loading={isLoading}
        debounce={1000}
      />

      <Modal
        title='Export Information'
        visible={visible}
        toggle={handleClose}
        closable={true}
        variants={['fullWidth']}
        styles={{ box: { minWidth: 420 }}}
      >
        <View variants={['column', 'gap:3']}>
          <View variants={['column', 'fullWidth', 'gap:0.5']}>
            <Text text='Exporting information from:' variants={['p3', 'color:neutral7', 'semiBold']} />
            <Text text={profile?.publisher_data?.title} variants={['h5', 'color:neutral10', 'semiBold']} />
          </View>

          <View variants={['column', 'gap:1']}>
            {extensions.map((ext) => {
              const isSelected = ext === type
              return <Button
                debugName={'ExportInformationModal:export'}
                text={ext}
                onPress={() => setType(ext)}
                variants={['large', 'text:uppercase', 'minimal', 'flat:hover', isSelected ? 'default' : '', 'text:left', 'fontWeight:400']}
                rightIcon={isSelected ? 'check' : ''}
                styles={isSelected ? { wrapper: { justifyContent: 'space-between' }} : { wrapper: { justifyContent: 'flex-start' }}}
              />
            })}
          </View>

          <Button
            debugName={'ExportInformationModal:export'}
            text='Export'
            onPress={handleExport}
            variants={['large']}
            disabled={!type}
          />
        </View>
      </Modal>
    </>
  )
}
