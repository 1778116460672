import { Theme } from '@/app';
import { View, Text, Icon } from '@/components'
import { Navigation, RoutePath } from '@/utils'
import { Mapper } from '@codeleap/common'
import { navigate } from 'gatsby'
import React from 'react'

type BreadcrumbProps = {
  paths: Array<{ label: string; route?: RoutePath; path?: string }>
}

export const Breadcrumb = React.memo((props: BreadcrumbProps) => {
  const { paths } = props

  return (
    <View variants={['row', 'fullWidth', 'gap:1', 'alignCenter', 'justifyStart']}>
      <Mapper
        data={paths}
        keyExtractor={item => item?.label}
        separator={
          <Icon
            name='chevron-right'
            size={Theme.values.iconSize[2]}
            color={Theme.colors.light.primary3}
            debugName='breadcrumb:icon'
          />
        }
        renderItem={({ item }) => (
          <Text
            variants={['p1', (!!item?.route || !!item?.path) && 'cursor:pointer']}
            text={item?.label}
            onPress={() => {
              if (!!item?.path) navigate(item.path)
              if (!!item?.route) Navigation.navigate(item.route)
            }}
          />
        )}
      />
    </View>
  )
}, () => true)
