import { APIClient } from '@/services'
import { List, SelectItemProps, SelectModalProps } from '@/components'
import { Journal, Permission, PermissionLevel, Publisher } from '@/types'
import { deepEqual, PropsOf, useMemo, useReducer, useState } from '@codeleap/common'
import { permissionsReducer } from '@/reducer'

export type UsePermissionFormProps = {
  initialPermissions?: Permission[]
  publisher: Publisher['id']
  onlyPublisher?: boolean
}

export const usePermissionForm = ({ publisher, initialPermissions, onlyPublisher = false }: UsePermissionFormProps) => {
  const [journalSearch, setJournalSearch] = useState('')
  const initialPermissionsState = {
    permissions: initialPermissions || [],
    deletedPermissions: [],
  }
  const [state, dispatch] = useReducer(permissionsReducer, initialPermissionsState)

  const { listProps } = APIClient.Journals.useJournals({
    publisher,
    title: journalSearch || null,
    only_publisher: onlyPublisher,
    limit: 50,
    listOptions: {
      noMoreItemsText: null,
    },
  })

  const journalOptions = useMemo(() => {
    const allJournals = listProps?.data?.map((journal: Journal) => ({
      label: journal.title,
      value: journal.id,
    })) || []

    const filteredJournals = []

    for (const journal of allJournals) {
      if (!filteredJournals.find((j) => j.value === journal.value)) {
        filteredJournals.push(journal)
      }
    }

    return filteredJournals
  }, [listProps?.data, listProps?.isLoading])

  const handleLevelChange = (props: { permission: Permission; level: PermissionLevel; value: boolean }) => {
    const { permission, level, value } = props
    dispatch({ type: 'UPDATE_PERMISSION_LEVEL', payload: { id: permission.id, level, value }})
  }

  const handleJournalChange = (permission: Permission, item: SelectItemProps) => {
    dispatch({ type: 'UPDATE_PERMISSION_JOURNAL', payload: { permission, item }})
  }

  const addPermission = () => {
    dispatch({ type: 'ADD_PERMISSION' })
  }

  const removePermission = (permission: Permission) => {
    dispatch({ type: 'REMOVE_PERMISSION', payload: { permission, initialPermissions }})
  }

  const isFormValid = () => {
    if (initialPermissions?.length === 0 && state.permissions.length === 0) return false
    if (deepEqual(state.permissions, initialPermissions)) return false
    return state.permissions.every(p => !!p.journal &&
      (p.role_publisher ||
        p.role_editor ||
        p.role_editor_chiefe ||
        p.role_managing_editor),
    )
  }

  const journalSelectModalProps: SelectModalProps = {
    listProps: listProps as unknown as PropsOf<typeof List>,
    options: journalOptions,
    searchable: true,
    searchInputProps: {
      placeholder: 'Search for publications',
      onSearchChange: setJournalSearch,
      debounce: 1000,
    },
  }

  const journalsLoading = journalSelectModalProps?.listProps?.isFetching || listProps.isLoading

  return {
    permissions: state.permissions,
    setPermissions: (permissions: Permission[]) => dispatch({ type: 'SET_PERMISSIONS', payload: permissions }),
    deletedPermissions: state.deletedPermissions,
    setDeletedPermissions: (permissions: Permission[]) => dispatch({ type: 'SET_DELETED_PERMISSIONS', payload: permissions }),
    setJournalSearch,
    handleLevelChange,
    handleJournalChange,
    addPermission,
    removePermission,
    isFormValid,
    journalSelectModalProps,
    journals: listProps?.data,
    journalsLoading,
  }
}
