import { Journal, Profile } from '@/types'
import { QueryManager, UsePaginationTypes } from '@codeleap/common'
import { api } from '@/app'
import { useFlatlistProps, useTableProps } from '@/utils'
import { queryClient } from '../queryClient'

const BASE_URL = 'article/editor/'

type ListFilterProps = {
    journal: Journal['id']
}

export async function listEditors(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get(BASE_URL, {
    params,
  })

  return response.data
}

export const editorsManager = new QueryManager<Profile>({
  itemType: {} as Profile,
  name: 'possibleReviewers',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFilterProps) => {
    const response = await listEditors({ limit, offset, ...filter })
    return response
  },

})

type UsePossibleReviewersProps = Partial<UsePaginationTypes.PaginationParams> & {
  journal: Journal['id']
  search?: string
}

export const useEditors = (props: UsePossibleReviewersProps) => {
  const {
    journal,
    search,
    ...paginationProps
  } = props

  const editors = editorsManager.use({
    filter: {
      ...paginationProps,
      journal,
      q: search,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  })

  const tableProps = useTableProps(editors, {
    limit: paginationProps?.limit,
  })

  const listProps = useFlatlistProps(editors, {
    noMoreItemsText: null,
  })

  return {
    editors,
    tableProps,
    listProps,
  }
}
