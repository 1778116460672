import { api } from '@/app'
import { Email, EmailCode } from '@/types'
import { ReactQuery, useMemo } from '@codeleap/common'

const BASE_URL = 'crm/email_templates/'

type FilterParams = {
  code?: EmailCode
}

export function useEmails(filters: FilterParams = {}) {
  const query = ReactQuery.useQuery<Array<Email>>({
    queryKey: ['emails'],
    queryFn: async () => {
      const response = await api.get(BASE_URL, { params: filters })
      return response?.data
    },
    refetchOnWindowFocus: false,
  })

  const emails = useMemo(() => !query?.data ? [] : query?.data, [query])

  return emails
}
