import { React } from '@/app'
import { View, CenterWrapper, Navigation, ActionIcon, PublisherSelector, RoleSelector } from '@/components'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { HeaderStyles, HeaderComposition } from '../../app/stylesheets/Header'
import { concatStyles } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'
import { HeaderItems } from './Items'
import { useMediaQueryDown } from '@/utils'

type HeaderProps = ComponentVariants<typeof HeaderStyles> & {
  styles?: StylesOf<HeaderComposition>
}

const BREAKPOINT = 'mid'

const HeaderComponent = (props: HeaderProps) => {
  const { responsiveVariants, styles, variants } = props
  const appStatus = useAppSelector(store => store.AppStatus.status)
  const drawer = useAppSelector(store => store.AppStatus.modals.drawer)

  const isMobile = useMediaQueryDown(BREAKPOINT)

  const variantStyles = useDefaultComponentStyle<'u:Header', typeof HeaderStyles>('u:Header', {
    responsiveVariants,
    styles,
    variants,
  })

  const headerStyles = React.useMemo(() => ({
    'wrapper': concatStyles([
      variantStyles?.wrapper,
      { zIndex: -5 },
    ]),
    'wrapperFloating': concatStyles([
      variantStyles?.wrapperFloating,
    ]),
  }), [])

  function toggle() {
    AppStatus.setModal('drawer')
  }

  return <>
    <Navigation.Container>
      <View
        variants={['row', 'justifySpaceBetween']}
        style={{ pointerEvents: appStatus === 'splash' ? 'none' : 'auto' }}
      >
        <CenterWrapper
          styles={{
            innerWrapper: headerStyles.wrapper,
            wrapper: headerStyles.wrapperFloating,
          }}
        >
          <View variants={['alignCenter', 'fullWidth', 'justifySpaceBetween', 'gap:2']} style={{ zIndex: 10 }}>
            {!drawer ? <ActionIcon
              icon={'arrow-right'}
              variants={['minimal', 'neutral10', 'noPaddingHorizontal']}
              onPress={toggle}
              debugName={'Header Menu Icon'}
            /> : null}

            {!isMobile && (
              <>
                <View variants={['row', 'alignCenter', 'gap:2', drawer ? 'marginLeft:47.5' : 'marginRight:auto']} >
                  <PublisherSelector />
                  <RoleSelector />
                </View>
                <View variants={['alignCenter']}>
                  <HeaderItems />
                </View>
              </>
            )}

          </View>
        </CenterWrapper>

      </View>
    </Navigation.Container>
  </>
}

export const Header = React.memo(HeaderComponent, () => true)
