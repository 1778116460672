import { PhoneProps } from './hooks'
import { Region } from '@/types'
import { APIClient } from '@/services'

type RetrievePostCodeParams = {
  placesService: google.maps.places.PlacesService
  place: google.maps.places.AutocompletePrediction
}

type FindRegionIndexParams = {
  regionName: string
  countries: PhoneProps[]
}

type FindRegionByIdParams = {
  regions: Region[]
  value: number
}

export async function retrievePostCode({ placesService, place }: RetrievePostCodeParams): Promise<string> {
  return new Promise((resolve) => {
    placesService?.getDetails({ placeId: place?.place_id },
      (placeDetails) => {
        const addressComponents = placeDetails?.address_components
        const postcodeComponent = addressComponents?.find(component => component.types.includes('postal_code'),
        )
        const postcode = postcodeComponent ? postcodeComponent.long_name : ''

        resolve(postcode)
      },
    )
  })
}

export function findRegionIndex({ regionName, countries }: FindRegionIndexParams) {
  return countries?.findIndex(country => country?.name === regionName)
}

export function findRegionById({ regions, value }: FindRegionByIdParams) {
  const region = regions?.find((region) => region?.id === value)
  return region || null
}

export function getRegionShortName(regionName: string) {
  const { regions: countries } = APIClient.Session.useSession()

  return countries?.find((_region) => _region?.label === regionName)?.short_name
}
