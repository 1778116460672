import React from 'react'
import { ComponentVariants, getNestedStylesByKey, PropsOf, StylesOf, TypeGuards, useBooleanToggle, useDefaultComponentStyle } from '@codeleap/common'
import { Button, View } from '@/components'
import { Link } from 'gatsby'
import { ButtonComposition, Collapse, CollapseComposition, CollapseProps, ViewProps } from '@codeleap/web'
import { NavigationContainer, useNavigation } from './useNavigation'
import { NavigationComposition, NavigationStyles } from '../../app/stylesheets/Navigation'
import { RoutePath, Navigation as NavigationRoute } from '@/utils'

type ButtonProps = PropsOf<typeof Button>

type NavigationButtonProps = Partial<Omit<ButtonProps, 'variants' | 'styles'>> & {
  to: string
  styles?: StylesOf<NavigationComposition>
} & ComponentVariants<typeof NavigationStyles>

const NavigationButton = (props: NavigationButtonProps) => {
  const {
    to,
    responsiveVariants = {},
    styles = {},
    variants = [],
    disabled,
    ...rest
  } = props

  const { basepath, matchPath, variants: containerVariants } = useNavigation()

  const isFocused = matchPath(to)

  const variantStyles = useDefaultComponentStyle<'u:Navigation', typeof NavigationStyles>('u:Navigation', {
    responsiveVariants,
    styles,
    variants: [
      ...containerVariants || [],
      ...variants || [],
    ],
  })

  const itemStyles: PropsOf<ButtonComposition> = getNestedStylesByKey('item', variantStyles)

  const _styles: PropsOf<ButtonComposition> = React.useMemo(() => ({
    ...itemStyles,
    wrapper: {
      ...itemStyles?.wrapper,
      ...(isFocused ? { ...itemStyles?.['wrapper:focused'], ...itemStyles?.['wrapper:selected'] } : {}),
    },
    text: {
      ...itemStyles?.text,
      ...(isFocused ? { ...itemStyles?.['text:focused'], ...itemStyles?.['text:selected'] } : {}),
    },
    icon: {
      ...itemStyles?.icon,
      ...(isFocused ? { ...itemStyles?.['icon:focused'], ...itemStyles?.['icon:selected'] } : {}),
    },
  }), [itemStyles, isFocused])

  return (
    <Button
      component={disabled ? null : Link}
      {...rest}
      disabled={disabled}
      to={basepath + to}
      styles={_styles}
    />
  )
}

type SectionProps = Partial<Omit<ButtonProps, 'variants' | 'styles'>> & {
  title?: string
  collapsible?: boolean
  initiallyCollapsed?: boolean
  collapseProps?: Partial<CollapseProps>
  children?: React.ReactNode
  styles?: StylesOf<NavigationComposition>
  collapseVariants?: CollapseProps['variants']
} & ComponentVariants<typeof NavigationStyles>

const Section = (props: SectionProps) => {
  const {
    title = null,
    collapsible = false,
    initiallyCollapsed = true,
    children,
    collapseProps = {},
    variants = [],
    collapseVariants = [],
    responsiveVariants = {},
    styles = {},
    ...rest
  } = props

  const containerVariants = useNavigation().variants

  const variantStyles = useDefaultComponentStyle<'u:Navigation', typeof NavigationStyles>('u:Navigation', {
    responsiveVariants,
    styles,
    variants: [
      ...containerVariants || [],
      ...variants || [],
    ],
  })

  const [open, toggle] = useBooleanToggle(!initiallyCollapsed)

  let rightIcon = undefined

  if (collapsible) {
    rightIcon = open ? 'chevron-up' : 'chevron-down'
  }

  const _titleStyles: PropsOf<ButtonComposition> = getNestedStylesByKey('title', variantStyles)
  const _collapseStyles: PropsOf<CollapseComposition> = getNestedStylesByKey('collapse', variantStyles)

  const titleStyles = React.useMemo(() => ({
    ..._titleStyles,
    wrapper: {
      ..._titleStyles?.wrapper,
      ...(collapsible ? _titleStyles?.['wrapper:collapsible'] : {}),
    },
    text: {
      ..._titleStyles?.text,
      ...(collapsible ? _titleStyles?.['text:collapsible'] : {}),
    },
  }), [_titleStyles, collapsible])

  const collapseStyles = React.useMemo(() => ({
    ..._collapseStyles,
    wrapper: {
      ..._collapseStyles?.wrapper,
      ...(collapsible ? _collapseStyles?.['wrapper:collapsible'] : {}),
    },
  }), [_collapseStyles, collapsible])

  return <View css={variantStyles.section}>
    {TypeGuards.isString(title) && (
      <Button
        text={title}
        rightIcon={rightIcon}
        onPress={collapsible ? toggle : undefined}
        {...rest}
        styles={titleStyles}
      />
    )}

    <Collapse
      open={collapsible ? open : true}
      variants={collapseVariants}
      {...collapseProps}
      styles={collapseStyles}
    >
      {children}
    </Collapse>
  </View>
}

export const Redirect = ({ to = '/', route = null }: { to?: string; route?: RoutePath }) => {
  React.useEffect(() => {
    if (TypeGuards.isString(route)) {
      NavigationRoute.navigate(route)
    } else {
      NavigationRoute.to(to)
    }
  }, [])

  return null
}

type GroupProps = Partial<Omit<ViewProps<'div'>, 'variants' | 'styles'>> & {
  children?: React.ReactNode
  styles?: StylesOf<NavigationComposition>
} & ComponentVariants<typeof NavigationStyles>

const Group = (props: GroupProps) => {
  const {
    children,
    variants = [],
    responsiveVariants = {},
    styles = {},
    style,
    ...rest
  } = props

  const containerVariants = useNavigation().variants

  const variantStyles = useDefaultComponentStyle<'u:Navigation', typeof NavigationStyles>('u:Navigation', {
    responsiveVariants,
    styles,
    variants: [
      ...containerVariants || [],
      ...variants || [],
    ],
  })

  return <View {...rest} css={[variantStyles.group, style]}>
    {children}
  </View>
}

export const Navigation = {
  Container: NavigationContainer,
  Button: NavigationButton,
  Section,
  Redirect,
  Group,
}
