import { AppStatus, useAppSelector } from '@/redux'
import { ActionIcon, List, Modal, Text, Touchable, View } from '..'
import { Theme, variantProvider } from '@/app'
import { Badge, EmptyPlaceholder, useMediaQuery } from '@codeleap/web'
import { Navigation, parseHTMLText, useFlatlistProps } from '@/utils'
import { APIClient } from '@/services'
import { Announcement } from '@/types'
import { onUpdate, useCallback, useState } from '@codeleap/common'

export const AnnouncementsList = () => {
  const { visible } = useAppSelector(state => state.AppStatus.modals.announcementsList)

  const { data: profile } = APIClient.Session.useProfile()
  const announcements = APIClient.Announcements.announcementsManager.use({
    listOptions: {
      queryOptions: {
        enabled: !!profile,
      },
    },
  })
  const { refresh } = APIClient.Badges.useBadges(profile)

  const listProps = useFlatlistProps(announcements)
  const isMobile = useMediaQuery(Theme.media.down('midish'))

  const toggle = () => AppStatus.setModal(['announcementsList', !visible])

  const renderItem = useCallback(({ item }: {item: Announcement}) => {

    const [previewDescription, setPreviewDescription] = useState(null)

    const onPressItem = () => {
      AppStatus.setModal(['announcementsList', false])
      if (!item.read) {
        APIClient.Announcements.announcementsManager.actions.markRead(item?.id)
        refresh()
      }
      Navigation.navigate('Announcements.Details', { routeParams: { id: item?.id }, state: { previousPath: window.location.href }})
    }

    const parsedDescription = item?.description && parseHTMLText(item?.description, true)

    onUpdate(() => {
      if (!previewDescription?.length && parsedDescription?.length) {
        let preview = ''
        parsedDescription?.slice(0, 2)?.map((item) => {
          preview = preview + ` ${item?.text}`
        })
        setPreviewDescription(preview)
      }
    }, [parsedDescription, previewDescription])

    return (
      <Touchable
        debugName={'Announcements item'}
        variants={['padding:2', 'border-radius:tiny', 'marginBottom:0.25', 'column', 'fullWidth', !item?.read ? 'bg:neutral2' : '']}
        onPress={onPressItem}
        style={styles.itemWrapper}
      >

        <View variants={['flex', 'fullWidth', 'justifyStart', 'gap:0.5']}>
          {!item?.read ? <Badge style={styles.badge} badge variants={['position:static', 'marginTop:0.8']} /> : null}
          <Text variants={['p2', 'color:neutral8']} text={item?.title}/>
        </View>

        <View variants={['fullWidth']}>
          <Text
            variants={['p3', 'color:neutral8']}
            text={previewDescription}
            ellipsizeMode='clip'
            style={styles.text}
          />
        </View>

      </Touchable>
    )
  }, [])

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      variants={['announcementsListModal', isMobile ? 'fullscreen' : '']}
      showClose={true}
      title={'Announcements'}
    >
      <List
        {...listProps}
        renderItem={renderItem}
        variants={['announcements-list']}
        showFooter={false}
        masonryProps={{
          overscanBy: 8,
        }}
        ListEmptyComponent={() => <EmptyPlaceholder icon='bell' variants={['table']} title='No announcement found.' />}

      />
    </Modal>
  )
}

const NOTIFICATION_BADGE_SIZE = 9

const styles = variantProvider.createComponentStyle((theme) => ({
  itemWrapper: {
    '&:hover': {
      backgroundColor: theme.colors.neutral2,
    },
  },
  text: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    ...theme.spacing.margin('0 auto'),
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  badge: {
    width: NOTIFICATION_BADGE_SIZE,
    height: NOTIFICATION_BADGE_SIZE,
  },
}), true)
