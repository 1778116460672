import { React } from '@/app'
import { useAppSelector } from '@/redux'
import { AuthModal, SignupForm } from '@/components'
import { useState } from '@codeleap/common'

export const SignupModal = (props) => {
  const { visible, formValues, provider, data } = useAppSelector(store => store.AppStatus.modals.signup)

  // const [step, setStep] = useState(1)
  const [step, setStep] = useState(0)

  if (!visible) return

  return (
    <AuthModal isOpen={visible} step={step + 1} name='signup'>
      <SignupForm
        isModal
        step={step}
        setStep={setStep}
        formValues={formValues}
        provider={provider}
        journalData={data}
      />
    </AuthModal>
  )
}
