import { FileScanResponse, Publisher, ScanId } from '@/types'
import {
  AnyFunction,
  ReactQuery,
  UsePaginationTypes,
} from '@codeleap/common'
import { api } from '@/app'

const BASE_URL = 'article/'

export async function scanFiles(files: File[], signal?: AbortSignal) {
  const response = await api.post(`${BASE_URL}scan_files/`, files, {
    multipart: true,
    signal,
  })
  return response.data
}

export async function checkScanStatus(scanIds: string[]) {
  const responses = []

  try {
    const checkStatus = async (id) => {
      const response = await api.get(`${BASE_URL}scan_status/`, { params: { scan_id: id } })
      responses.push(response.data)
      return
    }

    await Promise.all(scanIds.map(checkStatus))
    return responses
  } catch (error) { }
}

export type UseCheckScanStatusProps = {
  scan: ScanId[]
  enable?: boolean
  checkInterval?: number
  onComplete?: AnyFunction
}

export const useCheckFilesScan = ({ scan, enable = true, checkInterval = 5000, onComplete }: UseCheckScanStatusProps) => {
  const enablePolling = !!scan && enable
  const query = ReactQuery.useQuery({
    queryKey: ['checkFilesScan', scan],
    enabled: enablePolling,
    queryFn: () => checkScanStatus(scan),
    refetchInterval: checkInterval,
    onSuccess: (data: FileScanResponse[]) => {
      const completed = !data.find(file => file?.status !== 'completed')
      if (completed) {
        onComplete(data.flatMap(({ results }) => results))
      }
    },
  })

  const check = (query.data ?? {}) as FileScanResponse

  const isCompleted = check?.status === 'completed'

  return {
    ...check,
    isCompleted,
  }
}

