import { ActionIconComposition, DropzoneComposition, DropzonePresets } from '@codeleap/web'
import { assignTextStyle, variantProvider } from '..'
import { SelectComposition } from '@codeleap/common'

export type FilePreviewComposisition =
  'fileWrapper'
  | 'fileError'
  | 'fileNameWrapper'
  | 'fileName'
  | 'fileLeftIcon'
  | 'fileRightIconTouchableWrapper'
  | 'fileRightIconIcon'
  | 'fileImage'
  | 'fileSize'
  | 'actionWrapper'
  | 'wrapperSelect'
  | `fileRightIcon${Capitalize<ActionIconComposition>}`

const createFilePreviewStyle =
  variantProvider.createVariantFactory<FilePreviewComposisition>()

const SELECT_FILE_CATEGORY_WIDTH = 200

export const FilePreviewStyles = {
  ...DropzonePresets,
  default: createFilePreviewStyle((theme) => ({
    fileWrapper: {
      ...theme.presets.row,
      ...theme.presets.fullWidth,
      height: theme.spacing.value(6),
      paddingRight: theme.spacing.value(2),
      paddingLeft: theme.spacing.value(2),
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(1),

      [theme.media.down('small')]: {
        ...theme.presets.column,
        ...theme.presets.alignStart,
        height: 'auto',
      },
    },
    'fileWrapper:error': {
      border: `1.5px solid ${theme.colors.destructive1}`,
    },
    fileError: {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.destructive2,
      display: 'none',

      '&:last-of-type': {
        display: 'block',
      },
    },
    fileNameWrapper: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      [theme.media.down('small')]: {
        ...theme.presets.row,
      },
    },
    fileName: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.media.down('small')]: {
        textOverflow: 'unset',
        overflow: 'unset',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
      },
    },
    fileLeftIcon: {
      marginRight: theme.spacing.value(1),
      color: theme.colors.neutral7,
      ...theme.sized(2.5),
    },
    fileRightIconTouchableWrapper: {
      marginLeft: theme.spacing.value(1),
      background: 'none',
      ...theme.sized(2.5),
    },
    fileRightIconIcon: {
      color: theme.colors.neutral8,
    },
    fileImage: {
      ...theme.sized(4),
      borderRadius: theme.borderRadius.rounded,
      marginRight: theme.spacing.value(1),
      objectFit: 'cover',
    },
    fileSize: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
      whiteSpace: 'nowrap',
    },
    actionWrapper: {
      ...theme.spacing.gap(2),
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      [theme.media.down('small')]: {
        ...theme.presets.justifySpaceBetween,
        ...theme.presets.fullWidth,
        ...theme.presets.wrap,
      },
    },
    wrapperSelect: {
      minWidth: SELECT_FILE_CATEGORY_WIDTH,
      maxWidth: SELECT_FILE_CATEGORY_WIDTH,
      marginRight: 'auto',
    },
  })),
  ellipsis: createFilePreviewStyle((theme) => ({
    fileName: {
      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      whiteSpace: 'unset',
      minHeight: 23,
      wordBreak: 'break-all',

      [theme.media.down('small')]: {
        display: 'block', // Fix for not working -webkit-box
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'unset',
      },
    },
    fileNameWrapper: {
      display: 'grid', //wtf, it works!
    },
  })),
  media: createFilePreviewStyle((theme) => ({
    fileWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(2),
      ...theme.border.neutral3({ width: 1, directions: ['bottom'] }),
      borderRadius: 0,
      height: theme.spacing.value(10),
    },
    fileNameWrapper: {
      ...theme.presets.row,
      ...theme.presets.center,
      ...theme.presets.justifyStart,
    },
    fileRightIconIcon: {
      color: theme.colors.destructive2,
    },
    fileSize: {
      color: theme.colors.neutral8,
    },
    fileName: {
      color: theme.colors.neutral8,
    },
  })),
  hiddenSelect: createFilePreviewStyle((theme) => ({
    wrapperSelect: {
      ...theme.presets.hidden,
    },
  })),
  hiddenSize: createFilePreviewStyle((theme) => ({
    fileSize: {
      ...theme.presets.hidden,
    },
  })),
  noCategory: createFilePreviewStyle((theme) => ({
    wrapperSelect: {
      ...theme.presets.hidden,
    },
    fileSize: {
      ...theme.presets.hidden,
    },
    fileNameWrapper: {
      ...theme.presets.row,
      ...theme.spacing.marginRight('auto'),
    },
    fileName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 0,
    },
  })),
}
