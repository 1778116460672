import { React, variantProvider } from '@/app'
import { View, Text, Link } from '.'
import { useMemo } from '@codeleap/common'

export type PagePathProps = {
  path: string
  publication?: {
    id: number
    name: string
  }
  dynamicPath?: string
  pathReplacer?: (part: PathPart) => string
}

type PathPart = {
  path: string
  text: string
  isDynamic: boolean
  idx: number
}

export const PagePath = (props: PagePathProps) => {
  const { path, publication, dynamicPath = '', pathReplacer = (x) => x.path } = props

  const formattedPath = useMemo(() => {
    return path
      .split('/').filter(p => p !== '')
      .reduce<PathPart[]>((acc, part, idx) => {
        const prevPath = acc?.[idx - 1]?.path

        const isDynamic = part === dynamicPath

        let newPath = (prevPath || '') + '/' + part
        let text = part

        if (isDynamic) {
          newPath = prevPath + '/' + publication?.id
          text = publication?.name
        }

        return [...acc, { path: newPath, text: text?.replaceAll('_', ' '), isDynamic, idx }]
      }, [])
  }, [path, dynamicPath, publication?.id])

  return (
    <View variants={['row', 'gap:0.5']}>
      {formattedPath.map((part, index) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <Text text='>' variants={['p2', 'semiBold']} />
          )}

          <Link to={pathReplacer(part)} variants={['noUnderline']}>
            <Text text={part.text} style={styles.text} variants={['p2', 'semiBold', 'ellipsis', part.text !== publication?.name && 'capitalize']} />
          </Link>
        </React.Fragment>
      ))}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  text: {
    maxWidth: '40ch',
    [theme.media.down('small')]: {
      maxWidth: '20ch',
    },
    [theme.media.down('tiny')]: {
      maxWidth: '15ch',
    },
  },
}), true)
