import { View } from '@/components'

type StepPage = {
  children: React.ReactNode
  footer?: React.ReactNode
  jump?: boolean
}

export const StepPage = (props: StepPage) => {
  const { children, footer, jump } = props

  if (jump) return null
  return (
    <View variants={['column']}>
      {children}
      {footer}
    </View>
  )
}
