import { ActivityIndicator, Button, Select, View, usePublicationForm, Text, Icon } from '@/components'
import { OSAlert, React, Theme } from '@/app'
import { AnyFunction, onUpdate, useMemo, useState } from '@codeleap/common'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'
import { Publication } from '@/types'

type EvaluatePublicationButtonsProps = {
    publication?: Publication['id']
    onAcceptPublication?: AnyFunction
    onRevisePublication?: AnyFunction
    onRejectPublication?: AnyFunction
    onWithdrawPublication?: AnyFunction
    showAccept?: boolean
    showReject?: boolean
    showRevise?: boolean
    disabled?: boolean
}

export const EvaluatePublicationButtons = (props: EvaluatePublicationButtonsProps) => {

  const {
    showAccept = true,
    showReject = true,
    showRevise = true,
    disabled = false,
  } = props

  const { data: publication, query: publicationQuery } = APIClient.Publications.useRetrieve({ id: props?.publication, ignore_badges: true })
  const allJournals = APIClient.Journals.journalsManager.useList({})

  const { isEditor, isPublisher } = APIClient.Session.useSession()

  const { updateStatus, isAccepted, isRejected, isRejected_resubmit, isRejected_transfer, isWithdraw, isRevision_requested } = usePublicationForm()

  const canDecide = !!publication && (isEditor || isPublisher)
  const canAccept = canDecide && !isAccepted
  const canReject = canDecide && !isRejected
  const canWithdraw = canDecide && !isWithdraw
  const canRevise = canDecide && !isRevision_requested
  const canRejectAndTransfer = allJournals?.items?.find?.(journal => journal?.id !== publication?.journal?.id)

  const onOpenTransferManuscriptModal = async () => {
    AppStatus.setModal(['reviewsOverview', false])
    AppStatus.setModal(['rejectAndTransferManuscriptModal', true])
  }

  const onOpenRejectReplicaModal = () => {
    OSAlert.ask({
      title: 'Reject manuscript?',
      body: 'By rejecting the manuscript it will not published in the journal and the reviews cycle will end.',
      options: [
        {
          text: 'Cancel',
          onPress: () => {},
          variants: ['flat'],
          styles: { text: { color: Theme.colors.neutral10 }},
        },
        {
          text: 'Reject',
          onPress: onRejectReview,
        },
      ],
    })
  }

  const onRejectAndResubmit = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updateStatus('rejected_resubmit')
      if (updatedPublication) {
        await props?.onRejectPublication?.({ publication: updatedPublication })
        AppStatus.setModal(['reviewsOverview', false])
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Error',
        body: 'Error while performing reject and resubmit operation. Please try again later',
      })
    }
  }

  const RejectButtonColors = {
    rejected: 'destructive2',
    rejected_transfer: 'alert4',
    rejected_resubmit: 'alert3',
  }

  const isRejectAndResubmitDisabled = isRejected_resubmit || disabled
  const isRejectAndTransferDisabled = isRejected_transfer || disabled
  const isRejectDisabled = !canReject || disabled

  const rejectOptions = useMemo(() => [
    {
      value: 'rejected_resubmit',
      label: 'Reject and invite resubmission',
      itemProps: {
        disabled: isRejectAndResubmitDisabled,
        onPress: onRejectAndResubmit,
        style: { backgroundColor: Theme.colors.light[isRejectAndResubmitDisabled ? 'neutral2' : RejectButtonColors.rejected_resubmit] },
      },
    },
    {
      value: 'rejected_transfer',
      label: 'Reject and transfer',
      itemProps: {
        disabled: isRejectAndTransferDisabled,
        onPress: onOpenTransferManuscriptModal,
        style: { backgroundColor: Theme.colors.light[isRejectAndTransferDisabled ? 'neutral2' : RejectButtonColors.rejected_transfer] },
      },
    },
    {
      value: 'rejected',
      label: 'Reject',
      itemProps: {
        disabled: isRejectDisabled,
        onPress: onOpenRejectReplicaModal,
        style: { backgroundColor: Theme.colors.light[isRejectDisabled ? 'neutral2' : RejectButtonColors.rejected] },
      },
    },
  ], [canReject, canRejectAndTransfer, disabled, onRejectAndResubmit, onOpenRejectReplicaModal, onOpenTransferManuscriptModal])

  onUpdate(() => {
    if (!publication.status.startsWith('rejected')) {
      onSelectRejectStatus(rejectOptions.find(item => item.value === 'rejected'))
    }
  }, [publication.status])

  const rejectInitialState = useMemo(() => {
    return rejectOptions.find((item) => item.value === (publication.status.startsWith('rejected') ? publication.status : 'rejected'))
  }, [publication.status, rejectOptions])

  const [rejectStatus, onSelectRejectStatus] = useState(rejectInitialState)

  const RejectButtonLabel = () => (
    <View variants={['row', 'marginRight:2', 'gap:1', 'alignCenter']}>
      <Icon name={'x-circle'} variants={['small', 'neutral1']} debugName={'Reject Button - x icon'}/>
      <View variants={['gap:2']}>
        <Text variants={[`p1`, 'color:neutral1', 'bold']} text={rejectStatus.label}/>
        <Text variants={[`p1`, 'color:neutral1']} text={`|`}/>
      </View>
    </View>
  )

  const onChangeRejectStatus = (item) => {
    const currentItem = rejectOptions.find(option => option.value === item)
    onSelectRejectStatus(currentItem)
  }

  const onRejectReview = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updateStatus('rejected')
      if (updatedPublication) {
        await props?.onRejectPublication?.({ publication: updatedPublication })
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Error',
        body: 'Error while performing reject operation. Please try again later',
      })
    }
  }

  const onOpenAcceptReplicaModal = () => {
    OSAlert.ask({
      title: 'Accept manuscript?',
      body: 'By accepting the manuscript it will be published in the journal.',
      options: [
        {
          text: 'Cancel',
          onPress: () => {},
          variants: ['flat'],
          styles: { text: { color: Theme.colors.neutral10 }},
        },
        {
          text: 'Accept',
          onPress: onAcceptReview,
        },
      ],
    })
  }

  const onAcceptReview = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updateStatus('accepted')
      if (updatedPublication) {
        await props?.onAcceptPublication?.({ publication: updatedPublication })
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Error',
        body: 'Error while performing accept operation. Please try again later',
      })
    }
  }

  const onSubmitEditorReview = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updateStatus('revision_requested')
      if (updatedPublication) {
        await props?.onRevisePublication?.({ publication: updatedPublication })
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Error',
        body: 'Error while performing reject operation. Please try again later',
      })
    }
  }

  const onConfirmEditorReview = () => {
    OSAlert.ask({
      title: 'Send to author revision?',
      body: `The author will receive your feedback and submit a new version of the manuscript for your revision.`,
      options: [
        { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
        { text: 'Send', onPress: onSubmitEditorReview, variants: ['large'] },
      ],
    })
  }

  const onWithdrawPublication = async () => {
    AppStatus.set('loading')
    try {
      const updatedPublication = await updateStatus('withdraw')
      if (updatedPublication) {
        await props?.onWithdrawPublication?.({ publication: updatedPublication })
        AppStatus.setModal(['reviewsOverview', false])
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Error',
        body: 'Error while performing withdraw operation. Please try again later',
      })
    }
  }

  const onOpenWithdrawModal = () => (
    OSAlert.ask({
      title: 'Withdraw manuscript?',
      body: 'The submission process will be interrupted and the manuscript will be moved to the archive. You can rescind this decision later.',
      options: [
        { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
        { text: 'Continue', onPress: onWithdrawPublication, variants: ['large'] },
      ],
    })
  )

  if (publicationQuery.isLoading) {
    return (
      <View variants={['center', 'fullWidth']}>
        <ActivityIndicator debugName='Loading Publication'/>
      </View>
    )
  }

  return (
    <View variants={['gap:2', 'center', 'fullWidth', 'wrap']}>
      {showAccept && <Button
        variants={['large', 'backgroundColor:positive3', 'border-radius:tiny']}
        icon='check-circle'
        text={'Accept'}
        debugName={`Review Overview Modal - Accept Publication`}
        disabled={!canAccept || disabled}
        onPress={onOpenAcceptReplicaModal}
      />}
      {showRevise && <Button
        variants={['large', 'backgroundColor:warning3', 'border-radius:tiny']}
        icon='eyeglass-2'
        text={'Revise'}
        debugName={`Review Overview Modal - Revise Publication`}
        disabled={!canRevise || disabled}
        onPress={onConfirmEditorReview}
      />}
      {showReject && (
        <Select
          value={rejectStatus}
          onValueChange={onChangeRejectStatus}
          options={rejectOptions}
          formatOptionLabel={RejectButtonLabel}
          debugName={'Review Overview Modal - Reject Publication'}
          variants={['rejectButtonsSelect', `bg:${RejectButtonColors[rejectStatus?.value]}`]}
          selectedIcon={null}
          menuPlacement='top'
        />
      )}
      <Button
        variants={['large', 'withdrawButton']}
        icon='arrow-bar-left'
        text={'Withdraw'}
        debugName={`Review Overview Modal - Reject Publication`}
        disabled={!canWithdraw || disabled}
        onPress={onOpenWithdrawModal}
      />
    </View>
  )
}
