import { HasPermissionsProps, PermissionUtils } from '@/utils'
import { useMyPermissions, useSession } from './useSession'
import { useMemo } from 'react'

export const usePermissions = () => {
  const { permissions, profile, ...rest } = useSession()

  const hasPermission = (props: Omit<HasPermissionsProps, 'permissions' | 'current_role'>) => {
    return PermissionUtils.hasPermission({
      ...props,
      permissions: permissions?.data,
      current_role: profile?.current_role,
    })
  }

  return { hasPermission, permissions, ...rest }
}

export const useHasPermission = (props: Omit<HasPermissionsProps, 'permissions' | 'current_role'>) => {
  const { profile } = useSession()
  const permissions = useMyPermissions(props?.journals?.[0])

  const hasPermission = useMemo(() => {
    return PermissionUtils.hasPermission({
      ...props,
      permissions: permissions?.data,
      current_role: profile?.current_role,
    })
  }, [permissions?.dataUpdatedAt, profile?.current_role])

  return hasPermission
}
