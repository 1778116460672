import { SelectItemProps } from '@/components'
import { Permission, PermissionLevel } from '@/types'
import { TypeGuards } from '@codeleap/common'
import { DropzoneFileRejection } from '@codeleap/web'

type FilesToUpload = {
  acceptedFiles: File[]
  rejectedFiles: DropzoneFileRejection[]
}

export type UploadFileState = {
  manuscripts: FilesToUpload
  attachments: FilesToUpload
  version: FilesToUpload
  decision: FilesToUpload
  review: FilesToUpload
}

type Action =
  | { type: 'CLEAR_FILES' }
  | {
    type: 'SET_ACCEPTED_FILES'; payload: {
      files: FilesToUpload['acceptedFiles'] | ((files: File[]) => File[])
      fileType: keyof UploadFileState
      multiple: boolean
    }
  }
  | { type: 'SET_REJECTED_FILES'; payload: { files: FilesToUpload['rejectedFiles']; fileType: keyof UploadFileState } }

export const uploadFilesReducer = (state: UploadFileState, action: Action): UploadFileState => {
  switch (action.type) {
    case 'CLEAR_FILES':
      return {
        manuscripts: {
          acceptedFiles: [],
          rejectedFiles: [],
        },
        attachments: {
          acceptedFiles: [],
          rejectedFiles: [],
        },
        version: {
          acceptedFiles: [],
          rejectedFiles: [],
        },
        decision: {
          acceptedFiles: [],
          rejectedFiles: [],
        },
        review: {
          acceptedFiles: [],
          rejectedFiles: [],
        },
      }
    case 'SET_ACCEPTED_FILES':
      let newAcceptedFiles = []
      if (TypeGuards.isFunction(action.payload?.files)) {
        newAcceptedFiles = action.payload.files(state?.[action.payload?.fileType]?.acceptedFiles || [])
      } else {
        newAcceptedFiles = action.payload.multiple &&
         !TypeGuards.isUndefined(state?.[action.payload.fileType]) &&
          !TypeGuards.isUndefined(action.payload?.files) ?
          [...state?.[action.payload?.fileType]?.acceptedFiles, ...action.payload?.files] : action.payload?.files || []
      }
      return {
        ...state,
        [action.payload.fileType]: {
          ...state[action.payload.fileType],
          acceptedFiles: newAcceptedFiles,
        },
      }
    case 'SET_REJECTED_FILES':
      let newRejectedFiles = []
      if (TypeGuards.isFunction(action.payload?.files)) {
        newRejectedFiles = action.payload.files(state?.[action.payload?.fileType]?.rejectedFiles || [])
      } else {
        newRejectedFiles = action.payload.files || []
      }
      return {
        ...state,
        [action.payload.fileType]: {
          ...state[action.payload.fileType],
          rejectedFiles: newRejectedFiles,
        },
      }
    default:
      return state
  }
}
