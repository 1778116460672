import { React, variantProvider, Settings, AppImages } from '@/app'
import { View, CenterWrapper, Footer, Header, Image } from '@/components'
import { CenterWrapperProps } from './CenterWrapper'
import SEO, { SEOProps } from './SEO'
import { ImageComposition, StylesOf } from '@codeleap/common'
import { useUpdateLocale } from '@/utils'
import { APIClient } from '@/services'

export type PageProps = {
  showHeader?: boolean
  showFooter?: boolean
  showBackgroundImage?: boolean
  backgroundImageSource?: string
  backgroundImageStyle?: StylesOf<ImageComposition>
  pageTitle?: string
  appendNameToPageTitle?: boolean
  centerContent?: boolean
  SEOProps?: Partial<SEOProps>
  className?: string
} & Partial<CenterWrapperProps>

const defaultProps: Partial<PageProps> = {
  showHeader: true,
  showFooter: true,
  appendNameToPageTitle: false,
  centerContent: true,
  showBackgroundImage: false,
  backgroundImageSource: AppImages.LogoImage,
  SEOProps: {},
}

export const Page: React.FC<PageProps> = (props) => {
  const allProps = {
    ...defaultProps,
    ...props,
  }

  const {
    showHeader,
    showFooter = true,
    pageTitle,
    appendNameToPageTitle,
    centerContent,
    showBackgroundImage,
    backgroundImageSource,
    backgroundImageStyle,
    SEOProps,
    className,
    children,
    ...centerWrapperProps
  } = allProps

  const { isLoggedIn } = APIClient.Session.useSession()

  const appendedTitle = appendNameToPageTitle ? ` | ${Settings.AppName}` : ''
  const SEOTitle = pageTitle && `${pageTitle} ${appendedTitle}`

  const imageStyles = backgroundImageStyle ?? styles.backgroundImage

  const ContentWrapper = showBackgroundImage ? View : React.Fragment
  const InnerWrapper = centerContent ? CenterWrapper : React.Fragment

  const contentWrapperProps = showBackgroundImage && {
    variants: ['column', 'fullWidth', 'absolute'],
  }

  const innerWrapperProps = centerContent && {
    ...centerWrapperProps,
    styles: {
      innerWrapper: styles.innerWrapper,
      ...centerWrapperProps?.styles,
    },
  }

  useUpdateLocale()

  const contentProps = showBackgroundImage ? contentWrapperProps : {}

  const innerProps = centerContent ? {
    variants: ['column'],
    ...innerWrapperProps,
  } : {}

  return (
    <View variants={['column']} style={styles.wrapper} className={className}>
      <SEO title={SEOTitle} {...SEOProps} />

      {showBackgroundImage && <Image source={backgroundImageSource} objectFit='cover' style={imageStyles} />}

      <ContentWrapper {...contentProps}>
        {showHeader && <Header />}
        <InnerWrapper {...innerProps}>
          {children}
        </InnerWrapper>
        {(!isLoggedIn && showFooter) && <Footer />}
      </ContentWrapper>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.colors.background,
  },
  innerWrapper: {},
  backgroundImage: {
    width: '100%',
    minHeight: '100vh',
    position: 'relative',
  },
}), true)
