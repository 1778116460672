import { AppStatus, useAppSelector } from '@/redux'
import { Alert } from './Alert'
import { Theme } from '@/app'

export const DeleteJournalReviewModal = () => {

  const { visible, onDelete } = useAppSelector(state => state.AppStatus.modals.deleteJournalReviewDraft)

  const handleConfirm = () => {
    onDelete()
    AppStatus.setModal(['deleteJournalReviewDraft', false])
  }

  const handleCancel = () => {
    AppStatus.setModal(['deleteJournalReviewDraft', false])
  }

  return (
    <Alert
      visible={visible}
      toggle={handleCancel}
      debugName='Leaving page alert'
      title={'Are you sure you want to delete?'}
      body={'Deleting this review will result in the loss of all your current work. '}
      actionButtonProps={{
        debugName: 'Leave page button',
        text: 'Delete',
        variants: ['padding:2', 'flex', 'backgroundColor:destructive2'],
      }}
      dismissButtonProps={{
        text: 'Cancel',
        variants: ['padding:2', 'flex', 'backgroundColor:neutral2'],
        styles: { text: { color: Theme.colors.neutral10 }},
      }}
      type='destructive'
      onAction={handleConfirm}
      onClose={handleCancel}
    />
  )
}
