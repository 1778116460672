import React, { useState } from 'react'
import { memoize, onUpdate, yup } from '@codeleap/common'
import { ActionIcon, Text, TextEditor, View, TextEditorExtensions, ToolBar, Button, TextInput, useJournalCrudContext } from '@/components'
import { JournalQuestionChoice } from '@/types'
import { useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'

const AnswerInput = ({ initialValue, onDelete, choice, onEdit, canDelete }) => {
  const [value, setValue] = useState(initialValue)

  return (
    <View variants={['row']}>
      <TextInput
        placeholder='Enter here...'
        debugName='answer input'
        value={value}
        variants={[canDelete ? 'noError' : '']}
        onChangeText={(v) => {
          setValue(v)
          onEdit(choice, v)
        }}
        validate={!canDelete ? yup.string().required() : false}
      />
      {canDelete ? (
        <ActionIcon
          debugName='answer input delete'
          icon='trash'
          onPress={() => onDelete(choice)}
          variants={['backgroundColor:neutral1', 'destructive2', 'iconSize:2']}
        />
      ) : null}
    </View>
  )
}

const JournalRecommendationQuestionFormCP = () => {
  const {
    recommendationQuestion: question,
    setRecommendationQuestion: setQuestion,
    isCreate,
  } = useJournalCrudContext()
  const [choices, setChoices] = useState([])

  const updateChoices = (data: JournalQuestionChoice[]) => {
    const choice = {
      ...question,
      choices: data,
    }
    setQuestion(choice)
    setChoices(data)

  }

  const onDeleteAnswer = (choice: JournalQuestionChoice) => {
    const copy = choices?.filter(c => c?.id !== choice?.id)

    updateChoices(copy)
  }

  const onAddAnswer = () => {
    const previousChoices = (choices ?? [])

    updateChoices([
      ...previousChoices,
      { id: String(previousChoices?.length + 9999) as any, label: '', value: '' },
    ])
  }

  const onEditAnswer = (choice: JournalQuestionChoice, value: string) => {
    const newChoices = choices?.filter(c => c?.id !== choice?.id)

    updateChoices([
      ...newChoices,
      { ...choice, label: value, value: value },
    ])
  }

  const editor = useEditor({
    content: question?.question ?? '',
    extensions: [
      ...TextEditorExtensions,
      Placeholder.configure({ placeholder: 'Write the question about recommendations here', emptyEditorClass: 'placeholder' }),
    ],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => {
      setQuestion({
        ...question,
        question: editor.getHTML(),
      })
    },
  })

  onUpdate(() => {
    if (!choices?.length && question?.choices?.length) {
      setChoices(question?.choices?.map((i, idx) => ({ ...i, id: String(idx + 9999) }))?.sort((a, b) => Number(a?.id) - Number(b?.id)))
    }

    if (isCreate && question?.question) {
      editor.commands.setContent(question.question)
    }
  }, [isCreate, question])

  return <View variants={['column', 'fullWidth', 'gap:2', 'marginTop:2']}>
    <View variants={['fullWidth', 'column', 'gap:3', 'padding:2', 'border', 'border-radius:small']}>
      <Text text='Recommendation' variants={['h5', 'color:neutral8']} />

      <TextEditor
        // @ts-ignore
        editor={editor}
        toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'fileComponent', 'link', 'textStyles', 'highlight']} />}
      />

      <View variants={['column', 'gap:2']} style={{ maxWidth: '50%' }}>
        <Text text='Answers' variants={['h5', 'color:neutral8']} />
        {choices?.map(c => (
          <AnswerInput
            key={c?.id}
            onEdit={onEditAnswer}
            choice={c}
            onDelete={onDeleteAnswer}
            initialValue={c?.value}
            canDelete={choices?.length > 1}
          />
        ))}
      </View>

      <View variants={['justifyStart', 'fullWidth']}>
        <Button
          variants={[`flat`]}
          text={`Add answer`}
          debugName={'Journals question'}
          icon={'plus'}
          onPress={onAddAnswer}
        />
      </View>
    </View>
  </View>
}

export const JournalRecommendationQuestionForm = memoize(JournalRecommendationQuestionFormCP)
