import { includePresets } from '@codeleap/common'
import { variantProvider } from '..'

type ExpandableViewState = 'disabled' | 'expanded'
type ExpandableViewParts = 'wrapper' | 'header' | 'headerInnerWrapper' | 'title' | 'description' | 'iconWrapper' | 'icon' | 'contentWrapper' | 'contentInnerWrapper'
export type ExpandableViewComposition = ExpandableViewParts | `${ExpandableViewParts}:${ExpandableViewState}`

const createExpandableViewStyle =
  variantProvider.createVariantFactory<ExpandableViewComposition>()

const presets = includePresets((styles) => createExpandableViewStyle(() => ({ wrapper: styles })))

export const ExpandableViewStyles = {
  ...presets,
  default: createExpandableViewStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
    header: {
      ...theme.presets.flex,
      ...theme.presets.row,
      ...theme.presets.center,
      ...theme.spacing.paddingBottom(2),
      ...theme.spacing.gap(2),
      ...theme.border.neutral5({ directions: ['bottom'], width: 1 }),
    },
    headerInnerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignCenter,
    },
    title: {
      fontSize: theme.typography.base.styles.h4.size,
      fontWeight: '600',
      color: theme.colors.neutral10,
    },
    description: {
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: theme.typography.base.styles.p1.weight,
      color: theme.colors.caption,
    },
    iconWrapper: {
      ...theme.spacing.marginLeft('auto'),
    },
    icon: {
      ...theme.sized(3),
      color: theme.colors.neutral10,
    },
    contentWrapper: {
      overflow: 'hidden',
      background: theme.colors.background,
    },
    'contentWrapper:expanded': {
      overflow: 'visible',
    },
    contentInnerWrapper: {
      ...theme.presets.column,
      ...theme.spacing.paddingTop(3),
    },
  })),
}
