import { Theme } from '@/app'
import { useMediaQuery } from '@codeleap/web'

export const useIsMobile = () => {
  const mediaQuery = Theme.media.down('small')

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}

export const useIsTablet = () => {
  const mediaQuery = Theme.media.down('mid')

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}

export const useMediaQueryDown = (breakpoint: string) => {
  const mediaQuery = Theme.media.down(breakpoint)

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}
