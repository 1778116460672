import { I18NRef, makeFetcher, TypeGuards } from '@codeleap/common'
import { Settings } from './Settings'
import { logger } from './logger'
import { getFirebase } from '../services/firebaseApp'
import { LocalStorage } from './localStorage'

const client = makeFetcher(Settings, {
  // logger,
  multipartParser: (body) => {
    const form = new FormData()

    for (const [key, value] of Object.entries(body)) {
      if (value instanceof File) {
        form.append(key, value)
      } else if (TypeGuards.isArray(value) && value.every((v) => v instanceof File)) {
        value.forEach((v) => form.append(key, v))
      } else {
        if (TypeGuards.isNil(value)) continue

        form.append(key, JSON.stringify(value))
      }
    }
    return form
  },
  requestMiddleware: [
    async (req) => {

      if (!req.noAuth) {
        let token = ''
        const firebase = await getFirebase()
        const user = firebase.auth().currentUser

        if (user) {
          token = (await user.getIdTokenResult()).token
          LocalStorage.setItem('LAST_TOKEN', token)
        } else {
          token = LocalStorage.getItem('LAST_TOKEN')
        }

        if (token) {
          req.headers.set('Authorization', token)
        }
      }

      const userLocale = I18NRef.current.locale

      req.headers.set('Accept-Language', userLocale)

      return req
    },
  ],
})

export const api = client

export function readableApiError<T = any>(error: any): T {
  const isAxiosError = error?.axiosError?.name === 'AxiosError'

  if (!isAxiosError) return null

  const data = error?.data

  return data as T
}
