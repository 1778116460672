import { Theme, variantProvider } from '@/app'
import { Modal, Text, View, Avatar, Button } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { PossibleReviewer, Reviewer } from '@/types'
import { onUpdate, useBooleanToggle, useMemo } from '@codeleap/common'
import { useAnimatedStyle } from '@codeleap/web'

type ReviewerDetailProps = {visible: boolean; data: Partial<Reviewer & PossibleReviewer & {onClose?: () => void}>}

const CITATIONS_TO_SHOW = 5

const Tag = ({ field }) => {
  return (
    <View variants={['padding:1', 'backgroundColor:neutral2', 'border-radius:tiny']}>
      <Text variants={['p3', 'color:neutral8']} text={field} />
    </View>
  )
}

const CitationsWrapper = ({ citations, visible }) => {
  const citationsToShow = citations
  const [showFullCitations, toggleFullCitations] = useBooleanToggle(false)
  const height = (24 + Theme.spacing.value(1)) * CITATIONS_TO_SHOW

  const animated = useAnimatedStyle(() => {
    return {
      height: showFullCitations ? 'auto' : height,
      transition: {
        duration: 0.5,
      },
    }
  }, [showFullCitations])

  onUpdate(() => {
    if (!visible) toggleFullCitations(false)
  }, [visible])

  return (
    <View variants={['column', 'gap:1']}>
      <Text variants={['p2', 'color:neutral7']} text='Citations' />
      <View
        variants={['column', 'gap:1']}
        animated
        animatedProps={animated}
        style={{
          overflow: 'hidden',
        }}
      >
        {citationsToShow.map((citation) => <Text variants={['p1', 'color:neutral8']} text={`${citation.year}: ${citation.citations}`} />)}
      </View>
      <Button
        text={showFullCitations ? 'See less citations' : 'See more citations'}
        variants={['minimal', 'small']}
        debugName='citations:see more'
        onPress={() => toggleFullCitations()}
        debounce={500}
      />
    </View>
  )
}

export const ReviewerDetails = () => {

  const { visible, data } = useAppSelector(state => state.AppStatus.modals.reviewerDetails as ReviewerDetailProps)
  const { scientificTags } = APIClient.Session.useSession()

  const metrics = useMemo(() => {
    return {
      email: data?.email,
      status: data?.status,
      invites_received: data?.invites_received || data?.possible_reviewer?.invites_received || '0',
      invites_accepted: data?.invites_accepted || data?.possible_reviewer?.invites_accepted || '0',
      completed_reviews: data?.completed_reviews || data?.possible_reviewer?.completed_reviews || '0',
      review_average_time: data?.review_average_time || data?.possible_reviewer?.review_average_time || '0',
      articles: data?.articles || data?.possible_reviewer?.articles,
      interests: data?.interests || data?.possible_reviewer?.interests,
      organisation: data?.organisation || data?.possible_reviewer?.organisation,
      affiliations: data?.affiliations || data?.possible_reviewer?.affiliations,
      website: data?.website || data?.possible_reviewer?.website,
      tags: data?.tags || data?.possible_reviewer?.tags,
      citations: data?.citations || data?.possible_reviewer?.citations,
    }
  }, [data])
  const articles = useMemo(() => {
    if (!metrics?.articles?.length) return ''
    return metrics.articles.slice(0, 3)
  }, [metrics?.articles, visible])

  const interests = useMemo(() => {
    if (!metrics?.interests?.length) return ''
    return metrics.interests.slice(0, 5).map(e => e.title).join(', ')
  }, [metrics?.interests, , visible])

  const scientificField = useMemo(() => {
    return (id) => scientificTags?.find(tag => tag.id === id)?.label
  }, [scientificTags])

  return (
    <Modal
      title='Reviewer details'
      toggle={() => {
        AppStatus.setModal(['reviewerDetails', false])
        setTimeout(() => {
          AppStatus.setModal(['reviewerDetails', false, { data: null }])
          data?.onClose?.()
        }, 500)
      }}
      visible={visible}
      styles={{
        box: styles.box,
      }}
    >
      <View variants={['gap:3', 'column']}>
        <View variants={['gap:1', 'alignCenter']}>
          <Avatar
            name={data?.full_name}
            debugName={`${data?.full_name} Avatar`}
            variants={['mid']}
          />

          <View variants={['column']}>
            <Text variants={['h4', 'color:neutral8']} text={data?.full_name} />
            <Text variants={['p1', 'color:neutral8']} text={metrics?.organisation || metrics?.affiliations} />
          </View>
        </View>

        <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text='eWorkflow metrics' />
          <View variants={['backgroundColor:neutral2', 'border-radius:tiny', 'padding:2', 'fullWidth']} style={styles.metricsWrapper}>
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral8']} text={'Invites received'}/>
              <Text variants={['h5']} text={metrics?.invites_received}/>
            </View>
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral8']} text={'Invites accepted'}/>
              <Text variants={['h5']} text={metrics?.invites_accepted + '%'}/>
            </View>
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral8']} text={'Completed reviews'}/>
              <Text variants={['h5']} text={metrics?.completed_reviews + '%'}/>
            </View>
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral8']} text={'Review average time'}/>
              <Text variants={['h5']} text={metrics?.review_average_time + ' days'}/>
            </View>
          </View>
        </View>

        <View style={styles.grid}>
          <View variants={['column', 'gap:1']}>
            <Text variants={['p2', 'color:neutral7']} text='Email address' />
            <Text variants={['p1', 'color:neutral8', 'breakWord']} text={metrics?.email} />
          </View>

          {data?.status ? (
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='Invite status' />
              <Text variants={['p1', 'color:neutral8', 'breakWord', 'capitalize']} text={data?.status} />
            </View>
          ) : null}
          {data?.website ? (
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='Website' />
              <Text variants={['p1', 'color:neutral8', 'breakWord']} text={metrics?.website} />
            </View>
          ) : null}
        </View>

        {metrics?.tags?.length ? (
          <View variants={['column', 'gap:1']}>
            <Text variants={['p2', 'color:neutral7']} text='Scientific fields' />
            <View variants={['gap:1', 'wrap']}>
              {metrics?.tags?.map(field => (
                <Tag field={scientificField(field)} />
              ))}
            </View>
          </View>
        ) : null}
        {interests ? (
          <View variants={['column', 'gap:1']}>
            <Text variants={['p2', 'color:neutral7']} text='Interests' />
            <Text variants={['p1', 'color:neutral8']} text={interests} />
          </View>
        ) : null}
        {articles ? (
          <View variants={['column', 'gap:1']}>
            <Text variants={['p2', 'color:neutral7']} text='Manuscripts' />
            <View variants={['column', 'gap:0.5']}>
              {articles.map(article => (
                <View variants={['row', 'fullWidth', 'gap:0.5']}>
                  <Text variants={['p1', 'color:neutral8']} text='•' />
                  <Text variants={['p1', 'color:neutral8']} text={article.title} />
                </View>
              ))}
            </View>
          </View>
        ) : null}
        {metrics?.citations?.length ? (
          <CitationsWrapper citations={metrics?.citations} visible={visible} />
        ) : null}
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 480

const styles = variantProvider.createComponentStyle((theme) => ({
  box: {
    // width: MODAL_WIDTH,
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    ...theme.spacing.gap(2),
    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.media.down('tiny')]: {
      gridTemplateColumns: '1fr',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    ...theme.spacing.paddingBottom(3),
    ...theme.spacing.gap(3),
    ...theme.border.neutral2({ width: 1, directions: ['bottom'] }),
    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr',
    },
  },
}), true)
