/* eslint-disable max-len */
import {
  ExpandableView,
  JournalDisclaimers,
  JournalFieldEditor,
  JournalQuestions,
  JournalRecommendationQuestionForm,
  JournalTab,
  Switch,
  Text,
  useJournalCrudContext,
  View,
} from '@/components'

const fields = [
  { field: 'about_journal', label: 'About journal', placeholder: 'About journal' },
  { field: 'author_instructions', label: 'Author instructions', placeholder: 'Author instructions' },
  { field: 'reviewer_instructions', label: 'Reviewer instructions', placeholder: 'Reviewer instructions' },
  { field: 'contact_info', label: 'Contact info', placeholder: 'Contact info' },
]

export function SettingsTab({ tab }) {

  const { form } = useJournalCrudContext()

  return (
    <JournalTab currentTab={tab} tab='settings' style={{ gap: 40 }}>
      <View variants={['column']}>
        <Text variants={['h4', 'color:neutral10', 'marginBottom:1']} text={`Blinded review process`}/>
        <Text variants={[`p1`, 'color:neutral9', 'marginBottom:3']} text={`Enable this option to require the submission of anonymized files for all reviews, ensuring a blinded review process is mandatory.`}/>
        <Switch
          {...form.register('requires_blindfolded_review')}
          variants={['left']}
          label={null}
        />
      </View>
      <ExpandableView title='Portal content' initialState contentVariants={['fullWidth', 'column']}>
        {fields.map((field) => (
          <JournalFieldEditor {...field} renderErrorSpace excludeIds={['textStyles', 'highlight']} />
        ))}
      </ExpandableView>
      <ExpandableView title='Reviewer submission form' initialState contentVariants={['fullWidth', 'column']}>
        <JournalQuestions />
        <JournalRecommendationQuestionForm />
      </ExpandableView>
      <JournalDisclaimers />
    </JournalTab>
  )
}
