import { CSSObject } from '@emotion/react'

export function applyTextMaxLines(maxLines, styles = {}): CSSObject {
  return {
    display: 'block', // Fix for not working -webkit-box
    //@ts-ignore
    display: '-webkit-box',
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',

    ...styles,
  }
}
