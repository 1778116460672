import { AppIcon, React } from '@/app'
import { ToasterStyles } from '@/app/stylesheets'
import { ToasterComposition } from '@/app/stylesheets/Toaster'
import { ComponentVariants, IconPlaceholder, StylesOf, useBooleanToggle, useDefaultComponentStyle } from '@codeleap/common'
import { View, Text, Icon, ActionIcon, ActionIconProps, ActivityIndicator } from '@codeleap/web'

type ToasterProps = React.PropsWithChildren<{
  title: string
  description?: string
  icon?: AppIcon
  IconComponent?: () => JSX.Element
  isVisible?: boolean
  showClose?: boolean
  closeButtonProps?: Partial<ActionIconProps>
  styles?: StylesOf<ToasterComposition>
  LeftContent?: () => JSX.Element
  RightContent?: () => JSX.Element
} & ComponentVariants<typeof ToasterStyles>>

export function Toaster(props: ToasterProps) {
  const {
    title,
    description,
    icon,
    IconComponent,
    isVisible = false,
    showClose = false,
    variants = [],
    styles = {},
    responsiveVariants = {},
    closeButtonProps,
    children,
    LeftContent,
    RightContent,
  } = props
  const [visible, toggle] = useBooleanToggle(isVisible)

  const variantStyles = useDefaultComponentStyle<'u:Toaster', typeof ToasterStyles>('u:Toaster', {
    variants,
    styles,
    responsiveVariants,
  })

  const iconComponent = IconComponent ? <IconComponent /> : icon ? <Icon debugName='Toaster icon' name={icon} style={variantStyles.icon} /> : null
  const leftContent = LeftContent ? <LeftContent /> : null
  const rightContent = RightContent ? <RightContent /> : null

  if (!visible) return null
  return (
    <View style={variantStyles.wrapper}>
      <View style={variantStyles.innerWrapper}>
        {iconComponent ?? leftContent}
        <View style={variantStyles.contentWrapper}>
          <View style={variantStyles.header}>
            <Text text={title} style={variantStyles.title} />
            {description?.includes('undefined') ? (
              <ActivityIndicator size={24} debugName={'Toaster ActivityIndicator'} />
            ) : (
              <Text text={description} style={variantStyles.description} />
            )}
          </View>
          {children}
        </View>
        {rightContent}
      </View>
      {showClose && <ActionIcon icon='x' variants={['minimal', 'iconSize:3']} onPress={toggle} {...closeButtonProps} />}
    </View>
  )
}
