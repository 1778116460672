// NOTE This is the file were you put functions you don't know where else to put
import { JOURNAL_URL_DOMAIN } from '@/app'
import { useAppSelector } from '@/redux'
import { capitalize, TypeGuards } from '@codeleap/common'
import { DomHandler, Parser } from 'htmlparser2'
import React from 'react'

function formatPath(path: string) {
  return path
    .split('/')
    .filter(part => part !== '')
    .map(part => part
      .replace('-', ' ')
      .replace(/\b\w/g, char => char.toUpperCase()),
    )
    .join(' > ')
}

function formatFileName(path: string) {
  if (!path) return

  path = decodeURIComponent(path)
  const splitted = path.split('/')
  const file = splitted[splitted.length - 1]

  return file
}

function getExtensionFromUrl(url: string) {
  if (!url) return
  const decodedUri = decodeURIComponent(url)
  const pathname = new URL(decodedUri).pathname

  const match = /\.[0-9a-z]+$/i.exec(pathname)

  if (match) {
    return match[0].substring(1)
  }

}

function humanizedFileSize(size = 0) {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  const fullSize = (size / Math.pow(1024, i))
  return fullSize.toFixed(0) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

function getSelectValues<T extends Record<'value' | 'label', string | number>>(values: T[] | (string | number)[], options: T[], getInitialValue = false) {
  if (!values) return null
  if (TypeGuards.isArray(values)) {

    let value = []
    values.map(vl => {
      const res = options?.find?.(opt => opt?.value === vl) as T
      value = [...value, { value: res?.value, label: res?.label }]
    })

    return value
  } else {
    const value = options?.find?.(opt => opt.value === values)
    return getInitialValue ? (value?.value ?? value) : value
  }
}

async function downloadFileFromURL(file, name = 'download', avoidPopUpBlocker = false) {
  try {
    if (avoidPopUpBlocker) {
      const response = await fetch(file, {
        mode: 'cors',
      })

      const blob = await response.blob()

      const extension = file.split('.').pop()

      const downloadName = `${name}.${extension}`
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.download = downloadName
      link.href = url

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } else {
      window.open(file, '_blank')
      return
    }
    // const fileName = formatFileName(url)
    // const pdfUrl = url
    // const link = document.createElement('a')
    // link.href = pdfUrl
    // link.download = fileName
    // link.target = '_blank'
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
  } catch (error) {
    logger.error('Error downloading file:', error)
  }
}

export {
  formatPath,
  formatFileName,
  getExtensionFromUrl,
  humanizedFileSize,
  getSelectValues,
  downloadFileFromURL,
}

export const generateLightColor = () => {
  const red = (Math.floor(Math.random() * 128) + 128).toString(16).padStart(2, '0') // Red component
  const green = (Math.floor(Math.random() * 128) + 128).toString(16).padStart(2, '0') // Green component
  const blue = (Math.floor(Math.random() * 128) + 128).toString(16).padStart(2, '0') // Blue component
  return `#${red}${green}${blue}`
}

export function serializeObject<T extends Record<string, any>>(obj: T, predicate: (key: keyof T, value: any) => any): T {
  const serializedObject = {}

  for (const key in obj) {
    const value = predicate(key, obj?.[key])

    if (value != undefined) {
      serializedObject[key as string] = value
    }
  }

  return serializedObject as T
}

export function useFileState(initialState: File = null) {
  const [file, setFile] = React.useState<File>(initialState)

  return {
    file,
    set: setFile,
    delete: () => setFile(null),
  }
}

export function comingSoon() {
  window.alert('Coming soon! Feature not yet implemented.')
}

export const getJournalDomain = (value: string) => value?.replace?.(JOURNAL_URL_DOMAIN, '')

export const useSideMenuOpen = () => {
  const sideMenuOpen = useAppSelector(state => state.AppStatus.modals.drawer)

  return sideMenuOpen
}

export function humanizeSnakeCase(str = '') {
  return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

export const parseHTMLText = (html, removeHTMLTags = false) => {
  const handler = new DomHandler()
  const parser = new Parser(handler)
  parser.write(html)
  parser.end()

  const extractTextAndTags = (nodes) => {
    let results = []
    nodes.forEach((node) => {
      if (node.type === 'tag') {
        const textContent = node.children?.map(child => child.data).join('').trim()
        if (textContent) {
          if (removeHTMLTags) {
            results.push({ text: textContent })
          } else {
            results.push({ text: `<${node.name}>${textContent}</${node.name}>` })
          }
        }
        if (node.children) {
          const childResults = extractTextAndTags(node.children)
          results = results.concat(childResults)
        }
      }
    })
    return results
  }

  return extractTextAndTags(handler.dom)
}

export const formatMaximumNumber = (num: number, max: number) => {
  if (!TypeGuards.isNumber(num)) return ''
  let res = num.toString()

  if (num > max) {
    res = max + '+'
  }

  return res.toString()
}

let timeoutId = null

export const debounce = (func, delay) => {
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

export async function compressBase64Image(base64: string, quality = 0.7): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = base64
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Failed to get canvas context'))
        return
      }

      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)

      const compressedBase64 = canvas.toDataURL('image/jpeg', quality)
      resolve(compressedBase64)
    }
    img.onerror = (error) => reject(error)
  })
}

export function getBase64Size(base64: string): number {
  const stringLength = base64.length
  const sizeInBytes = Math.ceil(stringLength / 4) * 3
  return sizeInBytes
}

export function base64ToFile(base64: string, originalFile: File): File {
  const byteString = atob(base64.split(',')[1])
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })

  // Create a new File object
  const newFile = new File([blob], originalFile.name, {
    type: mimeString,
    lastModified: originalFile.lastModified,
  })

  return newFile
}

export function scrollToAnchor(id: string) {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export function removeFields(data: string[] | object, fieldsToRemove: string[]) {
  if (TypeGuards.isArray(data)) {
    return data.filter(field => !fieldsToRemove.includes(field))
  } else {
    const result = { ...data }
    fieldsToRemove.forEach(field => {
      delete result[field]
    })
    return result
  }
}

export function filterNonEmptyKeys(array) {
  return array.reduce((acc, obj) => {
    const keys = Object.keys(obj).filter(key => obj[key])
    return acc.concat(keys)
  }, [])
}

