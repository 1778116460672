import { Button, Link, SocialButton, Text, View } from '@/components'
import { AnyFunction, useI18N } from '@codeleap/common'
import { RoutePath } from '@/utils'

type RedirectLinkProps = {
  to?: string
  route?: RoutePath
  text: string
  textAction: string
}

export const RedirectLink = (props: RedirectLinkProps) => {
  const { to, route, text, textAction } = props
  return (
    <Link
      to={to}
      route={route}
      variants={['noUnderline', 'color:primary5', 'marginTop:2', 'fullWidth', 'textCenter', 'paddingVertical:2', 'semibold']}
    >
      {text} <Text component='span' variants={['textCenter', 'color:neutral10', 'bold']} text={textAction} />
    </Link>
  )
}

type RedirectModalProps = {
  text: string
  textAction: string
  onPress: AnyFunction
}

export const RedirectModal = (props: RedirectModalProps) => {
  const { onPress, text, textAction } = props
  return (
    <View variants={['row', 'alignCenter', 'justifyCenter', 'marginTop:2', 'gap:1']}>
      <Text variants={['textCenter', 'color:neutral10']} text={text} />
      <Text
        text={textAction}
        onPress={onPress}
        variants={['color:neutral10', 'cursor:pointer', 'bold']}
      />
    </View>
  )
}

export const SocialProviders = ({ onPress }) => {
  const { t } = useI18N()

  return (
    <View variants={['column', 'gap:2']}>
      <Text
        text={t('Login.social')}
        variants={['p3', 'alignSelfCenter']}
      />

      <View variants={['row', 'gap:2', 'alignCenter', 'justifyCenter', 'alignSelfCenter']}>
        <SocialButton platform={'google'} handleSocial={onPress} />
        <SocialButton platform={'linkedIn'} handleSocial={onPress} />
        <SocialButton platform={'apple'} handleSocial={onPress} />
      </View>
    </View>
  )
}

export const AuthFormComponents = {
  RedirectLink,
  RedirectModal,
  SocialProviders,
}
