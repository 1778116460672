import { includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'
import { ActivityIndicatorComposition } from '@codeleap/web'

type State = ''

export type JournalListComposition =
  | `wrapper${State}`
  | `innerWrapper${State}`
  | `activityIndicator${Capitalize<ActivityIndicatorComposition>}`

const createJournalListStyle = variantProvider.createVariantFactory<JournalListComposition>()

const journalListPresets = includePresets((styles) => createJournalListStyle(() => ({ wrapper: styles })))

export const JournalListStyles = {
  ...journalListPresets,
  default: createJournalListStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
    },
    innerWrapper: {
      display: 'grid',
      gridTemplateColumns: `1fr 1fr`,
      ...theme.spacing.gap(3),
      [theme.media.down('small')]: {
        gridTemplateColumns: '1fr',
      },
    },
    activityIndicatorWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.center,
      ...theme.spacing.marginVertical(15),
    },
  })),
}

