import { codeleapWebCache } from '@codeleap/web'
import { CacheProvider } from '@emotion/react'
import { Root } from './src/Root'

import { PermissionUtils, useFirebaseUser, useInternalPaths } from '@/utils'
import { useEffect } from 'react'
import { APIClient } from '@/services'
import { WrapPageElementBrowserArgs } from 'gatsby'
import { LogoLoading, Redirect, View } from '@/components'
import { AppStatus } from '@/redux'

export const wrapRootElement = ({ element }) => {
  return (
    <CacheProvider value={codeleapWebCache}>

      <Root>
        {element}
      </Root>
    </CacheProvider>
  )
}

const ShowAppStatus = () => {
  useEffect(() => {

    AppStatus.set('splash')

    return () => {
      AppStatus.set('idle')
    }
  }, [])
  return null
}

const DEBUG = false

const debugLog = (...args) => {
  if (!DEBUG) return
  console.log(...args)
}

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
  const isRoutePublic = PermissionUtils.isRoutePublic(props.location.pathname)
  const firebase = useFirebaseUser()
  const user = APIClient.Session.useProfile()
  const permissions = APIClient.Session.useMyPermissions()
  const internalPaths = useInternalPaths()
  const filteredInternalPaths = internalPaths.filter(path => !path.includes('[id]') && path !== '/')

  debugLog('isRoutePublic', isRoutePublic)

  if (isRoutePublic) return element

  const pathname = props.location.pathname

  const loadingVars = [

    user.isLoading,
    permissions.isLoading,
  ]
  debugLog('loadingVars', loadingVars)
  const loading = loadingVars.some(Boolean)

  if (loading || firebase.isLoading) {
    debugLog('loading')
    if (!filteredInternalPaths.includes(pathname) || pathname.includes('auth/linkedIn/')) {
      return (
        <View>
          <LogoLoading />
        </View>
      )
    }

    return <ShowAppStatus />
  }

  const currentRole = user?.data?.current_role
  debugLog({
    firebaseUser: firebase.firebaseUser,
    user: user.data,
  })
  const hasUser = [
    firebase.firebaseUser?.uid,
    user.data?.id,
  ].every(Boolean)
  debugLog('hasUser', hasUser)

  if (!hasUser && pathname !== '/auth/linkedIn/') {
    return <Redirect route={'Auth.Login'} />
  }

  const routeBlocked = PermissionUtils.isRouteBlocked({ role: currentRole, permissions: permissions.data, pathname })
  debugLog('routeBlocked', routeBlocked)

  if (routeBlocked) {
    return <Redirect route={PermissionUtils.getRedirectRouteByRole(currentRole)} />
  }

  return element
}
