import { onUpdate } from '@codeleap/common'
import { useAppSelector, AppStatus } from '@/redux'
import { globalHistory, useLocation } from '@reach/router'
import { Navigation } from '@/utils'

export const useBlocker = () => {
  const locale = useLocation()
  const alertVisible = useAppSelector(state => state.AppStatus.modals.leavePageWarn)

  onUpdate(() => {
    if (!alertVisible) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault()
        event.returnValue = ''
        return
      }

      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [alertVisible])

  onUpdate(() => {
    return globalHistory.listen(({ action, location }) => {
      if (
        (action === 'PUSH' && !location.pathname.includes(locale.pathname)) ||
        action === 'POP'
      ) {
        if (!window.confirm('Are you sure you want to leave?')) {
          Navigation.to(location.pathname)
        }
      }
    })
  }, [])

  return null
}
