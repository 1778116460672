import { createForm, yup } from '@codeleap/common'

export const permissionForm = createForm('permissionForm', {
  journals: {
    type: 'select',
    label: 'Journal',
    placeholder: 'Select a journal',
    options: [],
  },
  permissions: {
    type: 'list',
    label: 'Permissions',
  },
})
