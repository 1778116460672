import { assignTextStyle, createDefaultVariantFactory, includePresets } from '@codeleap/common'
import { ActionIconComposition } from '@codeleap/web'
import { AvatarComposition } from './Avatar'

export type AvatarGroupParts = 'wrapper'

export type AvatarGroupComposition =
  AvatarGroupParts
  | `avatar${Capitalize<AvatarComposition>}`
  | `actionIcon${Capitalize<ActionIconComposition>}`

const createAvatarGroupStyle = createDefaultVariantFactory<AvatarGroupComposition>()

export const AvatarGroupPresets = includePresets((styles) => createAvatarGroupStyle(() => ({ wrapper: styles })),
)

export const AvatarGroupStyles = {
  ...AvatarGroupPresets,
  default: createAvatarGroupStyle((theme) => ({
    wrapper: {

    },
    avatarTouchable: {
      ...theme.border.neutral1(theme.values.borderWidth.medium),
    },
    actionIconTouchableWrapper: {
      width: theme.spacing.value(3),
      height: theme.spacing.value(3),
      backgroundColor: theme.colors.neutral3,
      ...theme.border.neutral1(theme.values.borderWidth.medium),
    },
    actionIconIcon: {
      width: theme.spacing.value(1.5),
      height: theme.spacing.value(1.5),
      color: theme.colors.primary5,
    },
  })),
  publications: createAvatarGroupStyle((theme) => ({
    avatarWrapper: {
      height: theme.spacing.value(3),
      width: theme.spacing.value(3),
    },
    avatarInitials: {
      ...assignTextStyle('p4')(theme).text,
      lineHeight: 1.25,
      fontWeight: '600',
      letterSpacing: -1,
    },
    avatarImage: {
      height: theme.spacing.value(3),
      width: theme.spacing.value(3),
    },
  })),
}

