import React from 'react'
import { Alert } from '@/components'
import { AnyFunction } from '@codeleap/common'

type ChangePermissionAlertProps = {
  visible: boolean
  toggle: AnyFunction
  onAction: AnyFunction
}

export const ChangePermissionAlert = (props: ChangePermissionAlertProps) => {
  const {
    visible,
    toggle,
    onAction,
  } = props

  return <>
    <Alert
      visible={visible}
      toggle={toggle}
      type='destructive'
      title={'Are you sure you want to change your permission?'}
      body={'Doing so will result in losing access to certain areas of the site'}
      onAction={onAction}
      debugName={'change permission alert'}
      variants={['title:text-center']}
      actionButtonProps={{
        text: 'Yes',
        variants: ['fullWidth', 'destructive', 'large'],
      }}
      dismissButtonProps={{
        text: 'Cancel',
        variants: ['fullWidth', 'flat', 'large'],
      }}
    />
  </>
}
