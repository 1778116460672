import { ButtonComposition } from '@codeleap/web'
import { Theme, variantProvider } from '../theme'
import { assignTextStyle } from './Text'

export type SortModalComponentComposition = `button${Capitalize<ButtonComposition>}`

const createSortModalComponentStyle = variantProvider.createVariantFactory<SortModalComponentComposition>()

const WRAPPER_MIN_WIDTH = 100
const WRAPPER_HEIGHT = Theme.values.itemHeight.default

export const SortModalComponentStyles = {
  default: createSortModalComponentStyle((theme) => ({
    buttonWrapper: {
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.tiny,
      minWidth: WRAPPER_MIN_WIDTH,
      height: WRAPPER_HEIGHT,
      ...theme.spacing.paddingHorizontal(2),
      [theme.media.down('large')]: {
        maxWidth: 350,
      },
      '&:hover, &:hover > svg > *': {
        backgroundColor: theme.colors.neutral4,
        stroke: `${theme.colors.neutral10} !important`,
      },
    },
    'buttonWrapper:selected': {
      backgroundColor: theme.colors.neutral10,
    },
    buttonText: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.primary5,
      fontWeight: 600,
      ...theme.spacing.paddingLeft(1),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    'buttonText:selected': {
      color: theme.colors.neutral1,
    },
    buttonIcon: {
      color: theme.colors.primary5,
    },
    'buttonIcon:selected': {
      color: theme.colors.neutral1,
    },
  })),
  dashboard: createSortModalComponentStyle((theme) => ({
    buttonWrapper: {
      borderRadius: theme.borderRadius.tiny,
      height: theme.values.itemHeight.default,
      maxWidth: 250,
      [theme.media.down('large')]: {
        maxWidth: 250,
      },
    },
    buttonText: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.primary5,
      ...theme.spacing.paddingRight(1),
    },
    buttonIcon: {
      color: theme.colors.primary5,
      width: 20,
      height: 20,
    },
  })),
}
