import { AppStatus, useAppSelector } from '@/redux'
import { Alert } from './Alert'

export const LeavePageWarn = () => {
  const visible = useAppSelector(state => state.AppStatus.modals.leavePageWarn)

  const handleConfirm = () => {
    window.location.reload()
    AppStatus.setModal(['leavePageWarn', false])
  }

  const handleCancel = () => {
    AppStatus.setModal(['leavePageWarn', false])
  }

  return (
    <Alert
      visible={visible}
      toggle={handleCancel}
      debugName='Leaving page alert'
      title={'Are you sure you want to leave?'}
      body={'Leaving this page will result in the loss of all your current work.'}
      actionButtonProps={{ debugName: 'Leave page button', text: 'Leave Page' }}
      dismissButtonProps={{ variants: ['bg:neutral10', 'fullWidth'] }}
      type='destructive'
      onAction={handleConfirm}
      onClose={handleCancel}
    />
  )
}
