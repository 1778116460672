import { ComponentVariants, IconPlaceholder, onMount, PropsOf, useBooleanToggle, useDefaultComponentStyle, useLayoutEffect, useRef } from '@codeleap/common'
import { StylesOf, Text, Touchable, Icon, ViewProps } from '@codeleap/web'
import { React, motion } from '@/app'
import { View } from '@/components'
import { ExpandableViewStyles } from '@/app/stylesheets'
import { ExpandableViewComposition } from '@/app/stylesheets/ExpandableView'

type IconTypes = Record<'open' | 'closed', IconPlaceholder>

export type ExpandableViewProps = React.PropsWithChildren<{
  title: string
  initialState?: boolean
  icon?: IconTypes
  description?: string
  disabled?: boolean
  styles?: StylesOf<ExpandableViewComposition>
  contentVariants?: PropsOf<typeof View>['variants']
  id?: ViewProps<'div'>['id']
} & ComponentVariants<typeof ExpandableViewStyles>>
const expandingSectionVariants = {
  init: {
    height: 0,
    duration: 125,
  },
  show: {
    height: 'auto',
    duration: 150,
  },
}
export const ExpandableView = (props: ExpandableViewProps) => {
  const {
    children,
    variants = [],
    styles = {},
    responsiveVariants = {},
    initialState = false,
    icon = { open: 'chevron-up', closed: 'chevron-down' },
    title,
    description,
    disabled,
    contentVariants = [],
    id,
  } = props
  const [expanded, toggle] = useBooleanToggle(initialState)
  const [shouldRenderChildren, toggleShouldRenderChildren] = useBooleanToggle(false)
  const variantStyles = useDefaultComponentStyle<'u:ExpandableView', typeof ExpandableViewStyles>('u:ExpandableView', {
    variants,
    styles,
    responsiveVariants,
  })

  useLayoutEffect(() => toggleShouldRenderChildren(), [])

  const headerStyles = { wrapper: variantStyles.header, 'wrapper:disabled': variantStyles['header:disabled'] }
  const contentstyles = { ...variantStyles.contentWrapper, ...(expanded ? variantStyles['contentWrapper:expanded'] : {}) }
  const snakeCaseTitle = title?.toLowerCase()?.replaceAll(' ', '_')
  return (
    <View style={variantStyles.wrapper} id={id}>
      <Touchable id={`ExpandableView:${snakeCaseTitle}`} disabled={disabled} styles={headerStyles} onPress={toggle}>
        <View style={variantStyles.headerInnerWrapper}>
          <Text style={variantStyles.title} text={title}/>
          {description && <Text style={variantStyles.description} text={description}/>}
        </View>
        <View style={variantStyles.iconWrapper}>
          {expanded ? (
            <Icon name={icon?.open} style={variantStyles.icon} debugName={'expanded icon'}/>
          ) : (
            <Icon name={icon?.closed} style={variantStyles.icon} debugName={'closed icon'}/>
          )}
        </View>
      </Touchable>
      <motion.View
        variants={expandingSectionVariants}
        animate={expanded && shouldRenderChildren ? 'show' : 'init'}
        transition={{ bounce: 0.3 }}
        style={contentstyles}
        id={`ExpandableMotionView:${snakeCaseTitle}`}
      >
        {shouldRenderChildren ? (
          <View variants={contentVariants} style={variantStyles.contentInnerWrapper}>
            {children}
          </View>
        ) : null}
      </motion.View>
    </View>
  )
}
