import { Settings, variantProvider } from '@/app'
import { Image, ImagePlaceholder, RenderRichText, Text, View } from '@/components'
import { Journal } from '@/types'

export function JournalHero({ journal }: { journal: Journal }) {
  const infos = [
    journal.about_journal && {
      label: 'About the Journal',
      value: journal.about_journal,
    },
    journal.aims_scope && {
      label: 'Aims and Scope',
      value: journal.aims_scope,
    },
    journal.editorial_board && {
      label: 'Editorial Board',
      value: journal.editorial_board,
    },
    journal.issn && {
      label: 'ISSN',
      value: journal.issn,
    },
  ].filter(Boolean)

  return (
    <View variants={['fullWidth', 'column', 'backgroundColor:neutral2', 'border-radius:small']}>
      {journal?.cover ? (
        <View css={styles.coverWrapper}>
          <Image objectFit={'cover'} alt='cover image' style={styles.cover} source={journal?.cover} />
        </View>
      ) : (
        <ImagePlaceholder style={styles.coverPlaceholderWrapper} showLogo={false} />
      )}

      <View variants={['row', 'padding:3', 'gap:3']}>
        <View css={styles.logoWrapper}>
          {journal?.image ? (
            <Image objectFit={'cover'} alt={'journal logo'} source={journal?.image} style={styles.journalLogo} />
          ) : (
            <ImagePlaceholder />
          )}
        </View>

        <View variants={['column', 'gap:4']}>
          <Text text={journal?.title} variants={['h1', 'color:neutral10']} />
          {infos.map(({ label, value }) => (
            <View key={label} variants={['column', 'gap:1']}>
              <Text text={label} variants={['h4', 'color:neutral9']} />
              <RenderRichText content={value} />
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}

const aspectRatio = {
  journalLogo: [176, 240],
  cover: [1920, 224],
}

const IMAGE_HEIGHT = Settings.CropPickerConfigs.default.height
const IMAGE_WIDTH = Settings.CropPickerConfigs.default.width

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    coverWrapper: {
      ...theme.presets.fullWidth,
      borderTopLeftRadius: theme.borderRadius.small,
      borderTopRightRadius: theme.borderRadius.small,
      overflow: 'hidden',
      position: 'relative',
    },
    cover: {
      ...theme.presets.fullWidth,
      height: aspectRatio.cover[1],
      aspectRatio: Settings.CropPickerConfigs.cover.aspect,
      objectPosition: 'center',
      textIndent: -9999,
      zIndex: 1,
    },
    logoWrapper: {
      height: 'auto',
      minWidth: IMAGE_WIDTH,
      maxWidth: IMAGE_WIDTH,
      flex: 1,
      overflow: 'hidden',
      maxHeight: IMAGE_HEIGHT,
      minHeight: IMAGE_HEIGHT,

      borderRadius: theme.borderRadius.tiny,
      position: 'relative',
      ...theme.border.neutral4({ width: 2 }),
    },
    journalLogo: {
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      maxWidth: IMAGE_WIDTH,
      maxHeight: IMAGE_HEIGHT,
      borderRadius: 8,
      textIndent: -9999,
      zIndex: 1,
    },
    coverPlaceholderWrapper: {
      height: 224,
      overflow: 'hidden',
    },
  }),
  true,
)
