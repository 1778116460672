import React from 'react'
import { Mapper, memoize, useBooleanToggle } from '@codeleap/common'
import { ActionIcon, Text, TextEditor, View, TextEditorExtensions, ToolBar, Button, Modal, useJournalCrudContext } from '@/components'
import { JournalQuestion } from '@/types'
import { useEditor } from '@tiptap/react'
import { uuid4 } from '@sentry/utils'
import Placeholder from '@tiptap/extension-placeholder'

type JournalQuestionItemProps = {
  onEdit: (question: JournalQuestion) => void
  onDelete: (question: JournalQuestion) => void
  question: JournalQuestion
}

const MODAL_MAX_WIDTH = 642

const JournalQuestionItem = (props: JournalQuestionItemProps) => {
  const { question, onDelete, onEdit } = props

  return (
    <View variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter', 'paddingHorizontal:1', 'paddingVertical:0']}>
      <Text dangerouslySetInnerHTML={{ __html: question.question }} />

      <View variants={['row', 'gap:2']}>
        <ActionIcon
          name={'edit-2'}
          onPress={() => onEdit(question)}
          debugName={'edit journal question icon'}
          variants={['backgroundColor:neutral1', 'primary5', 'iconSize:2', 'normalize']}
        />

        <ActionIcon
          name={'x'}
          onPress={() => onDelete(question)}
          debugName={'delete journal question icon'}
          variants={['backgroundColor:neutral1', 'destructive2', 'iconSize:2', 'normalize']}
        />
      </View>
    </View>
  )
}

const QuestionEditor = ({ value, onChangeText }) => {
  const editor = useEditor({
    content: value,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => onChangeText(prev => ({ ...prev, question: editor.getHTML() })),
  })

  return (
    <TextEditor
      // @ts-ignore
      editor={editor}
      toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'fileComponent']} />}
      value={value}
      variants={['editor:adaptiveHeight']}
    />
  )
}

const EmptyPlaceholder = () => (
  <View variants={['gap:2', 'column', 'fullWidth', 'center', 'paddingVertical:8']}>
    <ActionIcon
      icon='article'
      variants={['backgroundColor:neutral2', 'border-radius:rounded', 'neutral9', 'size:3']}
      debugName={'Journal Questions - List empty placeholder icon'}
      onPress={null}
    />
    <Text text={`You don't have any questions for now.`} variants={['p1', 'color:neutral7']} />
  </View>
)

const JournalQuestionsComponent = () => {
  const { questions, setQuestions, journalId } = useJournalCrudContext()

  const [currentQuestion, setCurrentQuestion] = React.useState<Partial<JournalQuestion>>(null)
  const [visible, toggle] = useBooleanToggle(false)

  const onEdit = (question: JournalQuestion) => {
    setCurrentQuestion(question)
    toggle(true)
  }

  const onDelete = (question: JournalQuestion) => {
    setQuestions(questions?.filter(q => q?.id !== question?.id))
  }

  const onAdd = () => {
    setCurrentQuestion({
      question: '',
      journal: journalId,
      id: ('temp-' + uuid4()) as any,
    })

    toggle(true)
  }

  const onCancel = () => {
    setCurrentQuestion(null)
    toggle(false)
  }

  const onSave = () => {
    const newQuestions = questions?.filter(q => q?.id !== currentQuestion?.id)

    setQuestions([...newQuestions, currentQuestion as JournalQuestion])
    toggle(false)
    setCurrentQuestion(null)
  }

  const hasQuestions = questions?.length >= 1

  return <View variants={['column', 'fullWidth', 'gap:2']}>
    <View variants={['fullWidth', 'column', 'gap:2', 'padding:2', 'border', 'border-radius:small']}>
      <Text text='Questionnaire' variants={['h5', 'color:neutral8']} />

      {hasQuestions ? <>
        <Text text='QUESTION TITLE' variants={['p4', 'marginLeft:1']} />

        <Mapper
          data={questions}
          renderItem={({ item, isLast }) => (
            <View variants={['column', 'gap:2']}>
              <JournalQuestionItem question={item} onDelete={onDelete} onEdit={onEdit} />
              {isLast ? null : <View variants={['borderBottom']} />}
            </View>
          )}
        />
      </> : <EmptyPlaceholder />}

      <View variants={['justifyStart', 'fullWidth', 'marginTop:2']}>
        <Button
          variants={[`flat`]}
          text={`Add Question`}
          debugName={'Journals question'}
          icon={'plus'}
          onPress={onAdd}
        />
      </View>

    </View>

    <Modal
      variants={['centered']}
      visible={visible}
      showClose={false}
      closable={false}
      closeOnEscape={false}
      style={{ width: MODAL_MAX_WIDTH }}
    >
      {!!currentQuestion ? <>
        <Text text='Question' variants={['h3', 'marginBottom:2']} />

        <Text text='Question title' variants={['p2', 'color:neutral6', 'marginBottom:1']} />

        <QuestionEditor value={currentQuestion?.question} onChangeText={setCurrentQuestion} />

        <View variants={['center', 'fullWidth', 'gap:2', 'marginTop:2']}>
          <Button
            text={'Save'}
            icon='save'
            disabled={!currentQuestion?.question}
            debugName={'Journals question'}
            onPress={onSave}
            variants={['large']}
          />

          <Button
            variants={[`flat`, 'large']}
            text={`Cancel`}
            debugName={'Journals question'}
            onPress={onCancel}
          />
        </View>
      </> : null}
    </Modal>
  </View>
}

export const JournalQuestions = memoize(JournalQuestionsComponent)
