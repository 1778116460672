import React from 'react'
import { motion as FMotion } from 'framer-motion'
export * from './Settings'
export * from './logger'
export * from './theme'
export * from './performance'
export * from './constants'
export * from './api'
export * from './localStorage'

export type { AppIcon } from './assets/icons'
export { IconNames, iconImages } from './assets/icons'
import * as AppForms from './forms'
import * as AppImages from './assets/images'
import * as ComponentStyleSheets from './stylesheets'

export { I18N } from './i18n'

export { default as licenses } from './license.json'
export { default as timestamp } from './timestamp.json'
import { CreateOSAlert } from '@codeleap/common'

export { assignTextStyle } from './stylesheets/Text'

export const OSAlert = CreateOSAlert()

export const motion = {
  ...FMotion,
  View: FMotion.div,
}

export {
  React,
  AppForms,
  AppImages,
  ComponentStyleSheets,
}
