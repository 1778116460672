import { Attachment } from '@/components'
import { UploadFileState, uploadFilesReducer } from '@/reducer'
import { FileScanResponse, Publication, ScanId, ScanResult } from '@/types'
import { AnyFunction, useBooleanToggle } from '@codeleap/common'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useReducer, useState } from 'react'

type UploadFilesProviderData = {
  currentModal: keyof UploadFileState
  setCurrentModal: Dispatch<SetStateAction<keyof UploadFileState>>
  clearFiles: () => void
  isMiddleStepsVisible: boolean
  toggleMiddleStepsVisible: (value?: boolean) => void
  scanCompleted: boolean
  toggleScanCompleted: (value?: boolean) => void
  scanId: ScanId[]
  setScanId: Dispatch<SetStateAction<string[]>>
  scanResults: ScanResult[]
  setScanResults: Dispatch<SetStateAction<ScanResult[]>>

  internalFiles: Attachment[]
  setInternalFiles: Dispatch<SetStateAction<Attachment[]>>

  publicationId: number
  setPublicationId: Dispatch<SetStateAction<number>>

  reducerState: UploadFileState
  reducerDispatch: AnyFunction
  resetScanFlow: () => void
}

const UploadFilesContext = createContext<UploadFilesProviderData>({} as UploadFilesProviderData)

type UploadFilesProviderProps = {
  children: ReactNode
}

const initialUploadFilesState = {
  manuscripts: {
    acceptedFiles: [],
    rejectedFiles: [],
  },
  attachments: {
    acceptedFiles: [],
    rejectedFiles: [],
  },
  version: {
    acceptedFiles: [],
    rejectedFiles: [],
  },
  decision: {
    acceptedFiles: [],
    rejectedFiles: [],
  },
  review: {
    acceptedFiles: [],
    rejectedFiles: [],
  },
}

export const UploadFilesProvider = (props: UploadFilesProviderProps) => {
  const { children } = props

  //General stuff
  const [state, dispatch] = useReducer(uploadFilesReducer, initialUploadFilesState)
  const [currentModal, setCurrentModal] = useState<keyof UploadFileState>(null)
  const [isMiddleStepsVisible, toggleMiddleStepsVisible] = useBooleanToggle(true)
  const [scanCompleted, toggleScanCompleted] = useBooleanToggle(false)
  const [scanId, setScanId] = useState<ScanId[]>(null)
  const [scanResults, setScanResults] = useState<FileScanResponse['results']>([])

  //UploadAttachments stuff
  const [internalFiles, setInternalFiles] = useState<Attachment[]>([])

  //New Version stuff
  const [publicationId, setPublicationId] = useState<Publication['id']>(null)

  function resetScanFlow() {
    dispatch({ type: 'CLEAR_FILES' })
    setCurrentModal(null)
    toggleMiddleStepsVisible(true)
    toggleScanCompleted(false)
    setScanId(null)
    setScanResults([])
  }

  const providerData: UploadFilesProviderData = {
    currentModal,
    setCurrentModal,
    clearFiles: () => dispatch({ type: 'CLEAR_FILES' }),
    isMiddleStepsVisible,
    toggleMiddleStepsVisible,
    scanId,
    setScanId,
    scanCompleted,
    toggleScanCompleted,
    scanResults,
    setScanResults,

    internalFiles,
    setInternalFiles,

    publicationId,
    setPublicationId,

    reducerState: state,
    reducerDispatch: dispatch,

    resetScanFlow,
  }

  return (
    <UploadFilesContext.Provider value={providerData}>
      {children}
    </UploadFilesContext.Provider>
  )
}

export const useUploadFilesContext = () => {
  return useContext(UploadFilesContext)
}
