import { api } from '@/app'
import { Journal, Publisher } from '@/types'
import { queryClient } from '../queryClient'
import { PaginationResponse, QueryManager, ReactQuery, RetrieveOptions, UsePaginationTypes } from '@codeleap/common'
import { GetFlatlistPropsOptions, useFlatlistProps, useTableProps } from '@/utils'

const BASE_URL = 'publisher/journal/'

export const updateReviewerPermission = (id: Journal['id']) => api.post('profiles/member/', {
  journal: id,
})

export const journalsManager = new QueryManager({
  itemType: {} as Journal,
  name: 'journals',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Journal>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  createItem: async (data) => {
    const response = await api.post<Journal>(BASE_URL, {
      data: data,
      image: data.image,
      cover: data.cover,
      publisher_logo: data.publisher_logo,
    }, {
      multipart: true,
      debug: true,
    })

    return response.data
  },
  retrieveItem: async (id) => {
    if (!id) return null

    const response = await api.get<Journal>(`${BASE_URL}${id}/`)

    return response.data
  },
  deleteItem: async (data) => {
    const response = await api.delete<Journal>(`${BASE_URL}${data?.id}/`)

    return response.data
  },
  updateItem: async (data) => {
    const response = await api.patch<Journal>(`${BASE_URL}${data?.id}/`, {
      data: data,
      image: data.image,
      cover: data.cover,
      publisher_logo: data.publisher_logo,
    }, {
      multipart: true,
      debug: true,
    })

    return response.data
  },

  actions: {
    checkURLDomain: async (manager, domain): Promise<boolean> => {
      if (!domain) return null

      try {
        const response = await api.get<PaginationResponse<Journal[]>>(BASE_URL + 'is_url_domain_available/', { params: { url_domain: domain }})

        return response?.data?.available
      } catch (err) {
        // @note there is no journal with this domain
        return true
      }
    },
  },
})

type ListParams = UsePaginationTypes.PaginationParams & {
  publisher?: Publisher['id']
  title?: Journal['title']
}

export type UseJournalsProps = Partial<ListParams> & {}

type UseReviewersProps = Partial<UsePaginationTypes.PaginationParams> & {
  publisher?: Publisher['id']
  title?: Journal['title']
  only_publisher?: boolean
  listOptions?: GetFlatlistPropsOptions
}

export const useJournals = (props: UseReviewersProps = {}) => {
  const { listOptions, ...rest } = props
  const journals = journalsManager.use({
    filter: rest,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  })

  const tableProps = useTableProps(journals, { compareVersionLists: false })
  const listProps = useFlatlistProps(journals, listOptions)

  return { journals, tableProps, listProps }
}

export const useRetrieve = ({ id, ...rest }: RetrieveOptions<Journal>) => {
  const data = journalsManager.useRetrieve({
    id,
    queryOptions: { enabled: !!id, refetchOnWindowFocus: false },
    ...rest,
  })

  return data
}

export * from './disclaimers'

async function getJournalOptions() {
  const response = await api.get<Journal[]>('publisher/journal/options/')

  return response.data

}

export function useJournalOptions() {
  const query = ReactQuery.useQuery({
    queryKey: ['journalOptions'],
    queryFn: getJournalOptions,
    initialData: [],
  })

  return {
    loading: query.isLoading,
    options: query.data,
  }
}
