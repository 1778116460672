import { React } from '@/app'
import { View, Text, Touchable, JournalRecommendationInput, StateAction, ActionIcon } from '@/components'
import { TypeGuards, useMemo, useState } from '@codeleap/common'
import { SectionWrapper } from './SectionWrapper'
import { NamePreview } from './NamePreview'
import { AppStatus } from '@/redux'
import { EmptyPlaceholder } from '@codeleap/web'

const NumberChoice = ({ answer, item, onPress, disabled }) => {
  const isItemSelected = answer == item.value

  const bgColor = !isItemSelected ? `backgroundColor:neutral1` : 'backgroundColor:neutral10'
  const textColor = !isItemSelected ? `color:neutral10` : 'color:neutral1'

  const ITEM_SIZE = 47

  const value = TypeGuards.isNull(item?.value) ? 'N/A' : item?.value

  return (
    <Touchable
      variants={[bgColor as any, 'padding:2', 'border-radius:tiny', 'marginBottom:1']}
      debugName={`Number ${item}`}
      style={{ width: ITEM_SIZE, height: ITEM_SIZE, cursor: disabled && 'not-allowed' }}
      onPress={onPress}
      disabled={disabled}
    >
      <Text variants={[textColor as any]} text={value} />
    </Touchable>
  )
}

type ReviewFormAnswersProps = {
  formAnswers: any[]
  setFormAnswers: StateAction<ReviewFormAnswersProps['formAnswers']>
  recommendations: any[]
  setRecommendations: StateAction<ReviewFormAnswersProps['recommendations']>
  isAuthor: boolean
  disabled: boolean
  reviewers?: any[]
  showEmptyPlaceholder?: boolean
}

export const ReviewFormAnswers = (props: ReviewFormAnswersProps) => {

  const { formAnswers, setFormAnswers, recommendations, setRecommendations, isAuthor, disabled, reviewers, showEmptyPlaceholder = false } = props

  const onPressNumber = (props) => {
    const { item, index, question, shouldRenderMultipleReviews } = props
    setFormAnswers((state) => {
      const items = [...state]
      if (shouldRenderMultipleReviews) {
        items[index][question].value = item
      } else {
        items[index].value = item
      }
      return items.filter(Boolean)
    })
  }

  const NumbereredAnswer = ({ answers, index: _index }) => {

    const shouldRenderMultipleReviews = TypeGuards.isArray(answers)

    const nullValue = {
      created_datetime: new Date().toISOString(),
      id: null,
      label: 'N/A',
      question: answers?.question?.id,
      value: null,
    }

    const answerWithNullValues = [...(answers?.question?.choices ?? []), (nullValue ?? [])]

    if (shouldRenderMultipleReviews) {
      return (
        <View variants={['backgroundColor:neutral2', 'column', 'marginBottom:2', 'border-radius:small']}>
          {answers?.map?.((answer, index) => {
            const _answer = formAnswers[_index].find(item => item?.value === answer?.value)
            const currentReviewerColor = reviewers?.find(reviewer => reviewer?.reviewer === answer?.id)?.color

            const multipleAnswerWithNullValues = [...(answer?.question?.choices ?? []), (nullValue ?? [])]
            return (
              <View variants={['padding:2', 'column']}>
                {index === 0 ? <Text
                  // text={answer?.question?.question}
                  dangerouslySetInnerHTML={{ __html: answer?.question?.question }}
                  variants={['h5', 'semiBold', 'marginBottom:3']}
                /> : null}
                <SectionWrapper
                  key={`Review-numeric-form-answers-${answer?.question?.question}-${index}`}
                  variants={['column']}
                  isHtml
                >
                  <View variants={['fullWidth', 'column', 'gap:1']}>
                    <NamePreview
                      reviewer={answer}
                      index={index}
                      color={currentReviewerColor}
                      amount={reviewers?.length}
                      onCloseInfo={() => AppStatus.setModal(['reviewsOverview', true])}
                    />
                    <View variants={['row', 'gap:1']}>
                      {multipleAnswerWithNullValues?.map?.((item) => (
                        <NumberChoice
                          answer={_answer?.value}
                          item={item}
                          index={index}
                          onPress={() => {
                            onPressNumber({
                              item: item?.value,
                              index: _index,
                              question: index,
                              shouldRenderMultipleReviews,
                            })
                          }}
                          disabled={disabled}
                        />
                      ))}
                    </View>
                  </View>
                </SectionWrapper>
              </View>
            )
          })}
        </View>
      )
    }

    return (
      <View variants={['padding:2', 'column', 'backgroundColor:neutral2', 'marginBottom:2', 'border-radius:small']}>
        <Text
          variants={['h5', 'semiBold', 'marginBottom:3']}
          dangerouslySetInnerHTML={{ __html: answers?.question?.question }}
        />
        <SectionWrapper
          key={`Review-numeric-form-answers-${answers?.question?.question}-${_index}`}
          variants={['column']}
          isHtml
        >
          <View variants={['fullWidth', 'column', 'gap:1']}>
            <NamePreview reviewer={answers} onCloseInfo={() => AppStatus.setModal(['reviewsOverview', true])} />
            <View variants={['row', 'gap:1']}>
              {answerWithNullValues?.map?.((item) => {
                return (
                  <NumberChoice
                    answer={answers?.value}
                    item={item}
                    index={_index}
                    onPress={() => onPressNumber({ item: item?.value, index: _index, question: _index })}
                    disabled={disabled}
                  />
                )
              })}
            </View>
          </View>
        </SectionWrapper>
      </View>
    )

  }

  const onPressRecommendation = ({ val, index }) => {
    const choice = recommendations[index]?.question?.choices?.find(choice => choice?.value === val)
    setRecommendations((state) => {
      const items = [...state]
      items[index].choice = choice?.id
      return items
    })
  }

  const RecommendationAnswer = ({ question, index }) => {

    const answer = recommendations[index].question?.choices?.filter(item => item?.id === question?.choice)[0]?.value
    const currentReviewerColor = reviewers?.find(reviewer => reviewer?.reviewer === question?.id)?.color

    return (
      <View variants={['column']}>
        {index === 0 ? <Text text={'Recommendation'} variants={['h5', 'semiBold']} /> : null}
        <SectionWrapper
          key={`Review-recommendation-form-answers-${question?.question?.question}-${index}`}
          variants={['column', 'marginTop:3']}
        >
          <View variants={['fullWidth', 'column', 'gap:1']}>
            <NamePreview
              reviewer={question}
              index={index}
              color={currentReviewerColor}
              amount={reviewers?.length}
              onCloseInfo={() => AppStatus.setModal(['reviewsOverview', true])}
            />
            {isAuthor ? (
              <Text variants={['p3']} text={question.choices?.find(c => c.id === question?.choice)} />
            ) : (
              <View variants={['column']}>
                {question.question.choices.map(choice => {
                  return (
                    <JournalRecommendationInput
                      value={answer === choice?.label}
                      label={choice?.label}
                      onPress={() => onPressRecommendation({ val: choice?.label, index })}
                      disabled={disabled}
                      key={choice?.id}
                    />
                  )
                })}
              </View>
            )}
          </View>
        </SectionWrapper>
      </View>
    )
  }

  const Placeholder = showEmptyPlaceholder ? <EmptyPlaceholder icon={'clipboard-list'} variants={['table']} title={!recommendations?.length ? 'No recommendation found.' : 'No submission found.'} /> : null

  return (
    <View variants={['column']}>
      {formAnswers?.length ? <View variants={['marginBottom:2', 'column']}>
        {formAnswers?.map?.((item, index) => <NumbereredAnswer answers={item} index={index} />)}
      </View> : null}

      {recommendations?.length ? (
        <View variants={['fullWidth', 'column', 'backgroundColor:neutral2', 'padding:2', 'border-radius:small']}>
          {recommendations?.map?.((item, index) => <RecommendationAnswer question={item} index={index} />)}
        </View>
      ) : null}

      {Placeholder}
    </View>
  )

}
