import { AppStatus, useAppSelector } from '@/redux'
import { Button, Modal, View, Text, TextEditor, TextEditorExtensions, ToolBar, ActionIcon } from '@/components'
import { useEditor } from '@tiptap/react'
import { onUpdate } from '@codeleap/common'
import Placeholder from '@tiptap/extension-placeholder'

export const ReviewComment = () => {

  const { visible, data } = useAppSelector(state => state.AppStatus.modals.reviewComment)

  const editor = useEditor({
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
  })

  onUpdate(() => {
    if (visible) {
      editor?.commands?.setContent?.(data?.comment)
    }
  }, [data?.comment, visible])

  const handleClose = () => {
    AppStatus.setModal(['reviewComment', false, { data: {} }])
    data?.onClose?.(data?.comment || '')
    editor?.commands.setContent('')
  }

  const handleSave = () => {
    AppStatus.setModal(['reviewComment', false, { data: {} }])
    data?.onSave?.(editor.getHTML()) // we always need html
    editor?.commands.setContent('')

  }

  return (
    <Modal
      visible={visible}
      showClose={false}
      variants={['centered', 'reviewComment']}
    >

      <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween', 'marginBottom:3']}>
        <Text variants={[`h3`]} text={'Comment'} />
        <ActionIcon
          icon='x'
          debugName='Submit manuscript Version Modal - close modal icon on press'
          variants={['filterIcon', 'primary5', 'iconSize:3']}
          onPress={handleClose}
        />
      </View>

      <View variants={['gap:3', 'column', 'fullWidth']}>
        <TextEditor
          editor={editor}
          toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'link', 'fileComponent']} />}
          styles={{ editor: { minHeight: 280 } }}
          variants={['reviewCommentModal']}
        />

        <View variants={['fullWidth', 'row', 'justifyCenter', 'gap:2']}>
          <Button
            variants={['large']}
            icon='save'
            text='Save'
            debugName='Save editor comment'
            onPress={handleSave}
            disabled={!editor?.getText?.().length}
          />
          <Button
            variants={['large', 'flat']}
            text='Cancel'
            debugName='Cancel editor comment'
            onPress={handleClose}
          />
        </View>
      </View>
    </Modal>
  )
}
