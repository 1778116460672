import { AppForms, React, variantProvider } from '@/app'
import { Button, Modal, View, Text, TextInput, Select, ActivityIndicator, Pager, PlacesAutocomplete, PhoneTextInput } from '@/components'
import { onUpdate, useForm, useMemo, useState } from '@codeleap/common'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { findRegionById, getRegionShortName, getSelectValues, Navigation, ProfileUtils, useIsMobile } from '@/utils'
import { ScientificTag } from '@/types'
import { useLocation } from '@reach/router'

const READY_PROFILE_TIMEOUT = 400

const Description = () => {
  return (
    <>
      <Text variants={['p1', 'color:neutral7', 'marginBottom:1']}>
        An ORCID iD is a nonproprietary alphanumeric code that uniquely identifies an academic author. It is a 16-digit code, in the format: 0000-0000-0000-000X. Publishers use it to unambiguously attribute any published work to the correct authors.
        <br />
        <br />
        ORCID is a not-for-profit association of publishers and academic organizations that maintains the central registry of these personal identifiers (ORCID iDs) and associated public profiles for authors in academic publishing. Please visit <a href='http://orcid.org/' target='_blank' rel='noopener noreferrer'>http://orcid.org/</a> for more information.
      </Text>
    </>
  )
}

const Step1 = ({ form }) => {
  const { genders, regions } = APIClient.Session.useSession()

  return (
    <View variants={['column', 'gap:1']}>
      <Select
        {...form.register('region')}
        variants={['left']}
        options={regions}
      />
      <Select
        {...form.register('gender')}
        variants={['left']}
        options={genders}
      />
      <PhoneTextInput
        {...form.register('telephone_number')}
        placeholder='Enter your phone number'
        regionValue={form?.values?.region}
        isOptional
      />
      <PhoneTextInput
        {...form.register('mobile_number')}
        placeholder='Enter your phone number'
        regionValue={form?.values?.region}
      />
      <TextInput {...form.register('degree')} />
    </View>
  )
}

const Step2 = ({ form, regionShortName, profile }) => {
  const { scientificTags } = APIClient.Session.useSession()

  const filterOptions = (inputValue: string) => {
    return scientificTags.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
  }

  const loadOptions = async (inputValue: string) => {
    return filterOptions(inputValue)
  }

  const handleAddressValue = (address, postcode) => {
    form.setFormValues({ address, postcode })
  }

  const tagsValues = profile?.tags?.length > 0 ? profile?.tags : form?.values?.tags
  const tags = useMemo(() => getSelectValues<ScientificTag>(tagsValues, scientificTags), [profile?.tags?.length, scientificTags?.length, form?.values?.tags])

  return (
    <View variants={['column', 'gap:1']}>
      <Select
        {...form.register('tags')}
        searchable
        multiple
        variants={['left']}
        loadOptionsOnMount
        loadOptions={loadOptions}
        value={tags}
      />
      <PlacesAutocomplete
        handleAddressValue={handleAddressValue}
        textInputProps={{ ...form.register('address') }}
        restrictions={{
          componentRestrictions: {
            country: regionShortName ? [regionShortName] : null,
          },
        }}
      />
      <TextInput
        {...form.register('postcode')}
        variants={['description:p3']}
      />
      <TextInput {...form.register('position')} />
      <TextInput {...form.register('department')} />
      <TextInput {...form.register('organisation')} />
      <TextInput
        {...form.register('orcid')}
        description={
          <Description />
        }
      />
    </View>
  )
}

export const AditionalProfileInfo = () => {
  const visible = useAppSelector(state => state.AppStatus.modals.aditionalProfileInfo)
  const { editProfile, profile } = APIClient.Session.useEdit()
  const { isLoggedIn, regions } = APIClient.Session.useSession(true)
  const initialState = profile
  const form = useForm(AppForms.aditionalInfo, {
    initialState,
  })
  const [ready, setReady] = useState(false)
  const [step, setStep] = useState(0)
  const location = useLocation()
  const pathname = location?.pathname
  const isOnJournalPage = pathname?.startsWith('/journals/')

  const firstStep = step === 0

  const isMobile = useIsMobile()

  const region = findRegionById({ regions: regions, value: form?.values?.region })

  const regionShortName = getRegionShortName(region?.title)
  const telephoneNumber = ProfileUtils.cleanPhoneNumberMask(form?.values?.telephone_number)
  const mobileNumber = ProfileUtils.cleanPhoneNumberMask(form?.values?.mobile_number)

  const handleSubmit = () => {
    if (step === 0) {
      setStep(1)
      return
    } else {
      editProfile({
        ...profile,
        ...form.values as Partial<Profile>,
        phone: telephoneNumber,
        mobile: mobileNumber,
      })
      // AppStatus.setModal('aditionalProfileInfo')

      if (isOnJournalPage) {
        Navigation.navigate('Manuscripts.List')
      }
      setStep(0)
    }
  }

  onUpdate(() => {
    if (profile?.id && !ready) {
      form.setFormValues(initialState)

      setTimeout(() => {
        setReady(true)
      }, READY_PROFILE_TIMEOUT)
    }
  }, [initialState])

  if (!profile?.id && isLoggedIn) {
    return (
      <View>
        <ActivityIndicator debugName='aditional profile:loader' />
      </View>
    )
  }

  const steps = [
    { key: 'step1', children: <Step1 form={form} /> },
    { key: 'step2', children: <Step2 form={form} regionShortName={regionShortName} profile={profile} /> },
  ]

  const firstStepValidation = form.validateMultiple(['region', 'gender', 'telephone_number', 'mobile_number', 'degree'])
  const isDisabled = firstStep ? !firstStepValidation.valid : !form.isValid

  return (
    <Modal
      visible={visible}
      toggle={() => AppStatus.setModal('aditionalProfileInfo')}
      variants={['overflow:visible']}
      showClose={false}
      closable={false}
      closeOnEscape={false}
    >
      <View variants={['column']} style={styles.formWrapper}>
        <Text
          text={'Additional Information'}
          variants={['h1', 'marginBottom:1', isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
        />

        <Text
          text={'Please finish providing your information so that you can fully enjoy all the features of the website'}
          variants={['p1', `marginBottom:${isMobile ? 4 : 5}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
        />

        {steps.map(({ key, children }, index) => {
          return (
            <View variants={['fullWidth', 'column']} key={key} style={{ display: index === step ? 'block' : 'none' }}>
              {children}
            </View>
          )
        })}
        <View variants={['marginTop:2', 'marginBottom:3', 'gap:2', 'fullWidth']}>
          {!firstStep && (
            <Button text={'Previous'} variants={['fullWidth', 'large', 'flat']} debugName={'Previous page AditionaProfileInfo'} onPress={() => setStep(0)} />
          )}
          <Button
            onPress={handleSubmit}
            disabled={isDisabled}
            debugName={`submit aditional info`}
            text={firstStep ? 'Next' : 'Submit'}
            variants={['marginHorizontal:auto', 'fullWidth', 'large']}
          />
        </View>
      </View>
    </Modal>

  )
}

const FORM_WIDTH = 480

const styles = variantProvider.createComponentStyle((theme) => ({
  formWrapper: {

    [theme.media.up('small')]: {
      width: FORM_WIDTH,
    },
  },
}), true)
