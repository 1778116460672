import { View, Text, RenderRichText } from '@/components'
import { PropsOf } from '@codeleap/common'
import { PropsWithChildren } from 'react'

type SectionWrapperProps = {
  title?: string
  isHtml?: boolean
  variants?: PropsOf<typeof View>['variants']
} & PropsWithChildren

export const SectionWrapper = (props: SectionWrapperProps) => {
  const { children, title = '', isHtml = false, variants = [] } = props
  return (
    <View variants={['gap:3', 'fullWidth', ...variants]}>
      {title ?
        isHtml ? <RenderRichText content={title}/> : <Text variants={['h5', 'color:neutral9']} text={title}/>
        : null}

      {children}
    </View>
  )
}
