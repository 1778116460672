import { React } from '@/app'
import { TextInput, usePublicationForm } from '@/components'

export const DetailsForm = () => {
  const { forms, validateDraftForm, isPublicationEditable } = usePublicationForm()

  return (
    <>
      <TextInput
        // _error={validateDraftForm('ethical_approval', forms.detail)}
        {...forms.detail.register('ethical_approval')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('consent', forms.detail)}
        {...forms.detail.register('consent')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('funding_source', forms.detail)}
        {...forms.detail.register('funding_source')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('conflicts_of_interest', forms.detail)}
        {...forms.detail.register('conflicts_of_interest')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('guarantor', forms.detail)}
        {...forms.detail.register('guarantor')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('provenance_peer_review', forms.detail)}
        {...forms.detail.register('provenance_peer_review')}
        disabled={!isPublicationEditable}
      />
      <TextInput
        // _error={validateDraftForm('data_availability_statement', forms.detail)}
        {...forms.detail.register('data_availability_statement')}
        disabled={!isPublicationEditable}
      />
    </>
  )
}
