import React from 'react'
import { View, Text, ActivityIndicator, Icon } from '@/components'
import { Theme } from '@/app'
import { TypeGuards } from '@codeleap/common'

type OverviewCardProps = {
  description: string
  value: number
  percentage: number
  isDays?: boolean
  isLoading?: boolean
}

export const DashboardOverviewCard = (props: OverviewCardProps) => {
  const { description, value: _value, percentage, isDays = false, isLoading = false } = props

  if (_value < 0) {
    return
  }
  const value = TypeGuards.isNil(_value) || TypeGuards.isNil(percentage) ? '0 days' : (isDays ? (_value > 1 || _value == 0 ? `${_value} days` : `${_value} day`) : _value)
  const isPercentagePositive = percentage > 0

  const roundedPercentage = Math.round(percentage * 10) / 10

  return (
    <View
      variants={['padding:2', 'border-radius:small', 'border-style:solid', 'border-color:neutral2', 'border-width:medium', 'column', 'gap:1', 'fullWidth']}
      style={{ minHeight: 115 }}
    >
      <View variants={['row', 'justifySpaceBetween', 'alignCenter']}>
        {isLoading ? (
          <View variants={['fullWidth', 'alignCenter', 'justifyStart']}>
            <ActivityIndicator size={40} debugName={'Dashboard Overview ActivityIndicator'} />
          </View>
        ) : (
          <>
            <View variants={['row', 'alignCenter', 'gap:0.5']}>
              <Icon name='calendar' size={Theme.values.iconSize[2]} color={Theme.colors.light.neutral6} debugName={'Overview card calendar icon'} />
              <Text variants={['h3', 'color:neutral10']} text={isDays ? `${value}` : `${_value}`} />
            </View>
            {percentage == 0 || TypeGuards.isNil(percentage) ? null : (
              <View variants={['border-radius:nanoish', 'padding:0.5', 'alignCenter', isPercentagePositive ? 'bg:positive1' : 'bg:destructive1']}>
                <Icon
                  debugName={'Overview arrow'}
                  name={isPercentagePositive ? 'chevron-up-fill-positive' : 'chevron-down-fill-negative'}
                  variants={['marginHorizontal:0.5']}
                  color={isPercentagePositive ? Theme.colors.light.positive2 : Theme.colors.light.destructive2}
                />
                <Text text={`${roundedPercentage}%`} variants={['h5', 'color:neutral9']} />
              </View>
            )}
          </>
        )}
      </View>
      <Text variants={['p3', 'color:neutral8']} text={description} />
    </View>
  )
}
