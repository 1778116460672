import { Journal, Publication } from '@/types'
import {
  ExternalRequestClientConfig,
  PaginationResponse,
  QueryManager,
  ReactQuery,
  RetrieveOptions,
  TypeGuards,
  UsePaginationTypes,
} from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api, OSAlert } from '@/app'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Navigation, formatFileName } from '@/utils'
import useDownloader from 'react-use-downloader'

const BASE_URL = 'article/'

export function sortSections(data: Publication) {
  let sections = []
  if (data?.sections && TypeGuards.isObject(data?.sections)) {
    const sortedObject = Object.entries(data.sections).sort(([, a], [, b]) => a.index - b.index)
    sections = sortedObject
  }

  return { breakdown: sections }
}

export const onRejectAndTransferManuscript = async ({ article, journal }: { article: Publication[ 'id']; journal: Journal['id']}) => {
  const response = await api.post(`${BASE_URL}transfer/`, { article, journal })
  return response.status.toString()?.startsWith('2')
}

export function handleDeletion(publication: Publication) {
  OSAlert.custom({
    type: 'destructive',
    title: 'Are you sure you want to delete?',
    body: 'Deleting this manuscript will result in the loss of all your current work.',
    onAction: async () => {
      AppStatus.set('splash')
      await remove(publication)
      await APIClient.Publications.publicationsManager.refresh()
      Navigation.navigate('Manuscripts.List')
      setTimeout(() => {
        AppStatus.set('idle')
      }, 500)
    },
  })
}

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Publication>>(BASE_URL, {
    params,
  })

  return response.data
}

type NewPublicationParams = Pick<Publication, 'file'> & { data: { journal: Publication['journal']['id'] } }
async function create(newPublication: NewPublicationParams, params?: ExternalRequestClientConfig) {
  const publicationData = { ...newPublication, data: newPublication.data }
  const response = await api.post(BASE_URL, publicationData, { multipart: true, ...params })

  return response.data
}

async function update(publication: Publication) {
  const response = await api.patch<Publication>(`${BASE_URL}${publication.id}/`, { data: publication }, { multipart: true })
  return response.data
}

async function remove(publication: Publication) {
  await api.delete(`${BASE_URL}${publication.id}/`)

  return publication
}

async function retrieve(publicationId: Publication['id']) {
  if (!publicationId) throw new Error('Publication ID is required')
  const response = await api.get<Publication>(`${BASE_URL}${publicationId}/`)
  return response.data
}

export const publicationsManager = new QueryManager<Publication>({
  itemType: {} as Publication,
  name: 'publications',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
  /*@ts-ignore*/
  createItem: async (data: { journal?: Publication['journal']; files: File[] }) => {
    const { files, journal } = data

    const requestData = {
      data: {
        journal,
      },
      file: null,
    }
    if (!!files?.[0] && !TypeGuards.isString(files?.[0])) {
      requestData.file = files[0]
      const response = await create(requestData)
      return response
    }
  },

  retrieveItem: async (id: Publication['id']) => {
    const response = await retrieve(id)
    return response || null
  },

  updateItem: async (data: Publication) => {
    const response = await update(data)
    return response
  },

  deleteItem: async (data: Publication) => {
    const response = await remove(data)
    return response
  },
})

export const useRetrieve = ({ id, ...rest }: RetrieveOptions<Publication>) => {
  const data = publicationsManager.useRetrieve({
    id,
    queryOptions: { enabled: !!id, refetchOnWindowFocus: false },
    ...rest,
  })
  return data
}

export const usePublicationSummary = (id?: Publication['id']) => {

  const query = ReactQuery.useQuery({
    queryKey: ['publicationSummary', id],
    queryFn: () => APIClient.Articles.getArticleSummary(id),
    enabled: !!id,
    refetchInterval: (q) => {
      if (!!q?.url) return false
      return 5000
    },
  })
  const downloader = useDownloader()

  const isLoading = query.data?.status === 'processing' || query.isLoading

  const url = query?.data?.url

  const warnUnavailable = () => {
    OSAlert.info({
      title: 'Download not available',
      body: 'The file is not available for download yet. Please try again later.',
    })
  }
  const download = () => {

    if (!url) {
      warnUnavailable()
      return
    }
    const filename = formatFileName(url)
    downloader.download(url, filename)
  }

  return {
    url,
    isLoading,
    download,
    isDownloading: downloader.isInProgress,
    warnUnavailable,
  }
}
