import { variantProvider } from '@/app/theme'
import { includePresets } from '@codeleap/common'
import { assignTextStyle } from '../Text'
import { CSSObject } from '@emotion/react'
import { CheckboxComposition } from '@codeleap/web'

export type TableStates = '' | ':main' | ':header' | 'selected'

type RowParts =
  | 'wrapper'
  | 'itemWrapper'
  | 'icon'
  | 'leftIcon'
  | 'rightIcon'
  | 'itemText'
  | 'itemInnerWrapper'
  | 'componentWrapper'
  | `bulkSelection${Capitalize<CheckboxComposition>}`

export type TableRowComposition = `${RowParts}${TableStates}`

const createListRowStyle = variantProvider.createVariantFactory<TableRowComposition>()

const rowPresets = includePresets((styles) => createListRowStyle(() => ({ wrapper: styles })))

const breakText: CSSObject = {
  wordBreak: 'break-all',
  textOverflow: 'initial',
  whiteSpace: 'initial',
}

const overflowHidden: CSSObject = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

export const TableRowStyles = {
  ...rowPresets,
  default: createListRowStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.alignCenter,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      ...theme.presets.row,
      ...theme.spacing.gap(3),
      ...theme.spacing.padding(2),
      paddingLeft: 0,
      paddingRight: 0,
      ...theme.border.neutral3({
        width: 1,
        directions: ['bottom'],
      }),
      '&:last-of-type': {
        border: 'none',
      },

      [theme.media.down('small')]: {
        ...theme.spacing.gap(2),
        ...theme.spacing.paddingVertical(1),
      },
    },
    'wrapper:header': {
      ...theme.spacing.paddingVertical(1),
      border: 'none',
      background: 'transparent !important',
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.neutral2,
    },

    itemWrapper: {
      ...theme.presets.column,
      alignItems: 'center',
      justifyContent: 'center',
      ...theme.presets.fullHeight,
    },

    itemInnerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,

      ...theme.presets.row,
      ...theme.presets.center,
      ...theme.spacing.gap(2),
      [theme.media.down('small')]: {
        ...theme.spacing.gap(1),
      },
    },
    'itemInnerWrapper:main': {
      ...theme.presets.justifyStart,
    },

    componentWrapper: {
      ...theme.presets.center,
    },
    'componentWrapper:main': {
      ...theme.presets.justifyStart,
    },

    itemText: {
      ...theme.presets.fullWidth,
      ...theme.presets.textCenter,
      ...overflowHidden,
      ...theme.spacing.marginVertical('auto'),

      [theme.media.down('small')]: {
        ...assignTextStyle('p3')(theme).text,
        ...overflowHidden,
      },
    },
    'itemText:header': {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.neutral7,
      textTransform: 'uppercase',
      [theme.media.down('small')]: {
        ...assignTextStyle('p5')(theme).text,
      },
    },
    'itemText:main': {
      ...theme.presets.textLeft,
      ...breakText,
    },

    icon: {
      minWidth: 'min-content',
      ...theme.sized(2.5),
    },

    bulkSelectionBox: {
      borderRadius: theme.borderRadius.tiny / 2,
      minWidth: 'min-content',
      ...theme.sized(2.5),
    },

    bulkSelectionCheckmark: {
      minWidth: 'min-content',
      ...theme.sized(2),
    },

    bulkSelectionErrorMessage: {
      display: 'none',
    },

    'bulkSelectionBox:checked': {
      backgroundColor: theme.colors.primary5,
      ...theme.border.primary5({ width: 1 }),
    },
  })),
}
