import { variantProvider } from '@/app'
import {
  NumberIncrement,
  View,
  Text,
  ActivityIndicator,
} from '@/components'
import { TypeGuards } from '@codeleap/common'
import React, { Dispatch, SetStateAction } from 'react'

type ReviewersIncrementProps = {
  reviewersQtd: number
  handleChangeReviewerQtd: (value: number) => void
  debounce?: number
  setValue: Dispatch<SetStateAction<number>>
}

const MIN_REVIEWERS_PER_PUBLICATION = 1

export const ReviewersIncrement = (props: ReviewersIncrementProps) => {
  const {
    reviewersQtd,
    handleChangeReviewerQtd,
    debounce,
    setValue,
  } = props

  const setIncrementTimeout = React.useRef<NodeJS.Timeout | null>(null)

  const handleValueChange = (value: number) => {
    setValue(value)

    if (TypeGuards.isNil(debounce)) {
      handleChangeReviewerQtd?.(value)
    } else {
      if (!TypeGuards.isNil(setIncrementTimeout.current)) {
        clearTimeout(setIncrementTimeout.current)
      }
      setIncrementTimeout.current = setTimeout(() => {
        handleChangeReviewerQtd(value)
      }, debounce ?? 0)
    }
  }

  return (
    <View variants={['column', 'gap:1', 'flex']}>
      <Text
        text='Quantity of Reviewers'
        variants={['p2', 'color:neutral8']}
      />
      <Text
        text='Number of reviewers that accepted and submitted a review. Not only accepted invitation'
        variants={['p4', 'regular', 'color:neutral7']}
      />
      {TypeGuards.isNumber(reviewersQtd) ? (
        <NumberIncrement
          debugName='Reviewers Quantity Input'
          value={reviewersQtd}
          onValueChange={handleValueChange}
          min={MIN_REVIEWERS_PER_PUBLICATION}
          variants={['flex', 'noError']}
          styles={{
            label: styles.numberIncrementLabel,
          }}
        />
      ) : (
        <View variants={['fullWidth', 'center']} style={styles.loading}>
          <ActivityIndicator variants={['medium']} debugName='ReviewersQtd:Loading'/>
        </View>
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  numberIncrementLabel: {
    fontWeight: '600',
  },
  loading: {
    height: theme.values.itemHeight.default,
  },
}), true)
