import { Theme } from '@/app'
import { AppStatus, ModalName } from '@/redux'
import { APIClient } from '@/services'
import { AnyFunction, useI18N } from '@codeleap/common'
import { Button, Text, View, AuthFormComponents } from '@/components'
import { navigate } from 'gatsby'
import { useMediaQuery } from '@codeleap/web'

type AfterForgotPasswordFormProps = {
  isModal?: boolean
  title?: string
  visible?: boolean
  modalName?: ModalName
  onRequest?: AnyFunction
  email?: string
}

const BREAKPOINT = 'mid'

export const AfterForgotPasswordForm = ({ isModal = true, email }: AfterForgotPasswordFormProps) => {
  const isMobile = useMediaQuery(Theme.media.down('mid'), { getInitialValueInEffect: false })

  const { t } = useI18N()

  const { requestPasswordReset } = APIClient.Session.useSession()

  const onRequestReset = async () => {
    await requestPasswordReset(email)
  }

  const onLogin = async () => {
    if (isModal) {
      AppStatus.transitionModals(['beforeForgot', 'login'])
    } else {
      navigate('/auth/login/')
    }
  }

  return (
    <View variants={['flex', 'column']}>
      {!isModal && <Text variants={['h4', 'color:neutral8', 'fullWidth']} text={t('ForgotPassword.step2')} />}

      <Text
        text={t('ForgotPassword.title2')}
        variants={['h1', 'marginBottom:1', isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />

      <Text
        text={t('ForgotPassword.sub1')}
        variants={['p1', `marginBottom:${isMobile ? 2 : 3}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />

      <Text
        text={t('ForgotPassword.sub2')}
        variants={['p1', `marginBottom:${isMobile ? 2 : 3}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />
      <Text
        text={t('ForgotPassword.sub3')}
        variants={['p1', `marginBottom:${isMobile ? 2 : 3}`, isMobile && 'marginHorizontal:auto', isMobile && 'textCenter']}
      />

      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <Button
          onPress={onRequestReset}
          debugName={`submit resend reset password`}
          variants={['marginHorizontal:auto', 'fullWidth', 'large', 'flat', 'gap:1']}
        >
          <Text text={t('ForgotPassword.txt1')} variants={['p1', 'color:primary5']} />
          <Text text={t('ForgotPassword.txt2')} variants={['p1', 'color:primary5', 'bold']} />
        </Button>

        <AuthFormComponents.RedirectModal
          text={t('ForgotPassword.logintxt')}
          textAction={t('ForgotPassword.login')}
          onPress={onLogin}
        />
      </View>
    </View>
  )
}
