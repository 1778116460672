import { Publication, PublicationAuthor } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes, deepEqual } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { api } from '@/app'

const BASE_URL = 'article/'

export function verifyAuthorExistence(authors: Partial<PublicationAuthor>[], initialState: PublicationAuthor[]) {
  if (!authors?.length) return
  return authors.reduce<{toUpdate: Partial<PublicationAuthor>[]; new: Partial<PublicationAuthor>[]}>((acc, curr) => {
    // if (!curr?.email || !curr?.full_name || !curr?.organisation || !curr?.contribution || !curr?.region || !curr?.tags?.length) return acc
    if (curr?.id) {
      const originalAuthor = initialState.find(el => el.id === curr.id)
      const hasChanges = !deepEqual(originalAuthor, curr)
      return {
        ...acc,
        toUpdate: hasChanges ? [...acc.toUpdate, curr] : [...acc.toUpdate],
      }
    } else {
      return { ...acc, new: [...acc.new, curr] }
    }
  }, { toUpdate: [], new: [] })
}

async function listAuthors(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<PublicationAuthor>>(`${BASE_URL}author/`, { params })

  return response.data
}

async function retrieveAuthor(id: Publication['id']) {
  const response = await api.get<PublicationAuthor>(`${BASE_URL}author/${id}`)

  return response.data
}

async function createAuthors(publicationId: Publication['id'], authors: PublicationAuthor[]) {
  const response = await api.post<PublicationAuthor[]>(
    `${BASE_URL}author/`,
    { article: publicationId, authors },
  )

  return response.data
}

async function updateAuthor(data: PublicationAuthor) {
  const response = await api.patch<PublicationAuthor>(`${BASE_URL}author/${data.id}/`, data)

  return response.data
}

async function removeAuthor(author: PublicationAuthor) {
  await api.delete(`${BASE_URL}author/${author.id}/`)

  return author
}

export const authorsManager = new QueryManager<PublicationAuthor>({
  itemType: {} as PublicationAuthor,
  name: 'authors',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: {article: Publication['id']}) => {
    const response = await listAuthors({ limit, offset, ...filter })
    return response
  },
  /*@ts-ignore*/
  createItem: async (data: {publicationId: number; authors: PublicationAuthor[]}) => {
    const response = await createAuthors(data.publicationId, data.authors)
    return response
  },

  retrieveItem: async (id: PublicationAuthor['id']) => {
    const response = await retrieveAuthor(id)
    return response || null
  },
  /*@ts-ignore*/
  updateItem: async (data: PublicationAuthor[]) => {
    const responses = []
    for (const author of data) {
      const resp = await updateAuthor(author)
      responses.push(resp)
    }

    return responses
  },

  deleteItem: async (data: PublicationAuthor) => {
    const response = await removeAuthor(data)
    return response
  },
})
