import { Theme, variantProvider } from '@/app'
import { format, sub } from 'date-fns'
import { DatePicker, View, Text, ActionIcon } from '@/components'
import { DatePickerProps } from '@codeleap/web'

type DashboardDatePickerProps = Partial<DatePickerProps>

function Header(props) {
  const { formattedDate, increaseMonth, decreaseMonth, nextMonthButtonDisabled, prevMonthButtonDisabled } = props

  return (
    <View variants={['fullWidth', 'column']}>
      <View variants={['row', 'alignCenter', 'justifySpaceBetween', 'marginBottom:1.5', 'fullWidth']}>
        <Text text={formattedDate} variants={['p2', 'color:neutral10']} />
        <View variants={['row', 'alignCenter', 'gap:0.5']}>
          <ActionIcon
            name={'chevron-left'}
            debugName={'Decrease month'}
            variants={['minimal', 'neutral7', 'iconSize:3']}
            onPress={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            styles={{
              touchableWrapper: styles.touchableWrapper,
            }}
          />
          <ActionIcon
            name={'chevron-right'}
            debugName={'Increase month'}
            variants={['minimal', 'neutral7', 'iconSize:3']}
            onPress={increaseMonth}
            disabled={nextMonthButtonDisabled}
            styles={{
              touchableWrapper: styles.touchableWrapper,
            }}
          />
        </View>
      </View>
      <View variants={['fullWidth', 'separator', 'bg:neutral2', 'marginBottom:1.5']} />
    </View>
  )
}

export const DashboardDatePicker = (props: DashboardDatePickerProps) => {
  return (
    <DatePicker
      variants={['fullWidth', 'dashboard']}
      leftIcon={{ name: 'calendar' }}
      placeholder='DD/MM/YYYY'
      minDate={sub(new Date(), { years: 15 })}
      style={Theme.effects.light}
      headerComponent={(params) => {
        const { date } = params
        const formattedDate = format(date, 'MMMM yyyy')

        return (
          <Header
            formattedDate={formattedDate}
            {...params}
          />
        )
      }
      }
      {...props}
    />
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  touchableWrapper: {
    width: theme.values.iconSize[3],
    height: theme.values.iconSize[3],
  },
}), true)
