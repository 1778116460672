import { React, assignTextStyle, variantProvider } from '@/app'
import { Text } from '@/components'
import { AppTheme, Fonts, PropsOf, ThemeColorScheme, onUpdate } from '@codeleap/common'
import { useId } from 'react'

type HTMLRenderProps = {
  html: string
  openLinksInNewTab?: boolean
} & PropsOf<typeof Text>

export const HTMLRender = ({ html, openLinksInNewTab = true, ...rest }: HTMLRenderProps) => {
  const randomId = useId()
  const id = `html-render-${randomId}`

  const setLinkTabInfo = () => {
    const element = document.getElementById(id)

    const links = element.querySelectorAll('a')

    links.forEach(link => {
      if (openLinksInNewTab) link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })
  }

  onUpdate(() => {
    if (!!html) {
      const timeoutId = setTimeout(() => {
        setLinkTabInfo()
      }, 300)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [html])

  return <Text
    {...rest}
    id={id}
    dangerouslySetInnerHTML={{ __html: html }}
    css={styles.wrapper}
  />

}

function overwriteStyles(font: Fonts, theme: ThemeColorScheme<AppTheme>) {
  const textStyles = assignTextStyle(font)(theme).text

  for (const key in textStyles) {
    textStyles[key] = String(textStyles[key]) + ' !important'
  }

  return textStyles
}

// Todo - add styles
const styles = variantProvider.createComponentStyle(theme => {
  return ({
    wrapper: {
      a: {
        ...overwriteStyles('p1', theme),
        color: `${theme.colors.primary3} !important`,
        textDecoration: 'none !important',
      },
      p: {
        ...overwriteStyles('p1', theme),
      },
      li: {
        ...overwriteStyles('p1', theme),
      },
      h1: {
        ...overwriteStyles('h1', theme),
      },
      h2: {
        ...overwriteStyles('h2', theme),
      },
      h3: {
        ...overwriteStyles('h3', theme),
      },
      h4: {
        ...overwriteStyles('h4', theme),
      },
      h5: {
        ...overwriteStyles('h5', theme),
      },
      em: {
        ...overwriteStyles('p1', theme),
      },
      i: {
        ...overwriteStyles('p1', theme),
      },
      strong: {
        fontWeight: '700',
      },
      span: {
        color: 'inherit !important',
      },
      'p:empty::before': {
        content: "''",
        display: 'inline-block',
      },
    },
    li: {
      marginLeft: theme.spacing.value(2),
    },
  })
}, true)
