import { api } from '@/app'
import { Disclaimer, Profile } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'publisher/disclaimers/'

type PostDisclaimerProps = {
  title: string
  body: string
  order: number
}

export async function listDisclaimer(params?: {journal: Profile['current_journal']}) {
  const response = await api.get<Disclaimer[]>(BASE_URL, {
    params,
  })

  return response.data
}

export const DeleteDisclaimer = async ({ id, journal }) => {
  const response = await api.delete(`${BASE_URL}${id}/`, { params: { journal }})
  return String(response.status).startsWith('20')
}

export const journalsDisclaimerManager = new QueryManager({
  itemType: {} as Disclaimer,
  name: 'disclaimers',
  queryClient: queryClient.client,

  retrieveItem: async (id) => {
    const response = await listDisclaimer({ journal: id })

    return response
  },

  createItem: async ({ journal, data }: { journal: string; data: PostDisclaimerProps[] }) => {
    const sendDisclaimer = async (data: any, index: number) => {
      const response = await api.post(`${BASE_URL}`, data, { params: { journal }})

      return response
    }

    const responses = await Promise.all(data.map(sendDisclaimer))

    return responses
  },

  deleteItem: async ({ id, journal }) => {
    const response = await api.delete(`${BASE_URL}${id}/`, { params: { journal }})
    return response.data
  },

  updateItem: async ({ journal, data }: { journal: string; data: PostDisclaimerProps[] }) => {
    const response = await api.post(`${BASE_URL}update_many/`, data, { params: { journal }})
    return response.data
  },

})
