/* eslint-disable max-lines */
/* eslint-disable max-len */
import { createContext, useContext, useRef } from 'react'
import { AppForms, OSAlert, React } from '@/app'
import { APIClient } from '@/services'
import { useForm, useState, useMemo, onUpdate, TypeGuards, useDebounce } from '@codeleap/common'
import { Disclaimer, PossibleReviewer, ProfileRole, Publication, PublicationAuthor, PublicationMedia, PublicationStatus, SubCategory } from '@/types'
import { Navigation, PublicationUtils, formatFileName } from '@/utils'
import { DropzoneFile } from '@codeleap/web'
import { AppStatus } from '@/redux'
import { PublicationStatusBooleans, removeMedia } from '@/services/api/publication'
import { sendAcceptedDisclaimers } from '@/services/api/publishers'
import { DefinedUseQueryResult } from '@tanstack/react-query'
import { compareAsc, parseISO } from 'date-fns'
import { authorValidationRules, reviewerValidationRules } from '@/app/forms'

export type Attachment = { id?: any; file: DropzoneFile; file_category?: number } //NOTE - Why? Remove and fix fucking types

export type StateAction<T> = React.Dispatch<React.SetStateAction<T>> // NOTE - move to a better place

type SelectRefs = {
  [key: string]: any
}

export type TPublicationContext = {
  article: Publication['id']
  fileName: string
  isNew: boolean
  isError: boolean
  isLoading: boolean
  isLoadingQuery: boolean
  isPublicationArchived?: boolean
  isReady: boolean
  canAuthorEdit: boolean
  canEditorEdit: boolean
  isPublicationEditable: boolean
  // areFormsValid: boolean
  disclaimersAccepted: boolean
  disclaimers: Disclaimer[]
  disclaimersTitle: string[]
  forms: Record<'coverLetter' | 'publication' | 'detail', any>
  publication: Publication
  authorDisclaimers: Disclaimer[]
  acceptedDisclaimers: { [x: number]: boolean }
  setAcceptedDisclaimers: StateAction<TPublicationContext['acceptedDisclaimers']>
  handleDisclaimerAcceptance: (id: Disclaimer['id'], vl: boolean) => void
  sectionBreakdown: Record<'breakdown', any[]>
  sections: { [key: string]: string }
  setSections: StateAction<TPublicationContext['sections']>
  attachments: Attachment[]
  setAttachments: StateAction<TPublicationContext['attachments']>
  isSavePublicationEnabled: { [x in ProfileRole]?: boolean }
  attachmentsMedia: PublicationMedia[]
  setAttachmentsMedia: StateAction<TPublicationContext['attachmentsMedia']>
  mediaDeleteArray: PublicationMedia['id'][]
  setMediaDeleteArray: StateAction<TPublicationContext['mediaDeleteArray']>
  authorForms: Partial<PublicationAuthor>[]
  setAuthorForms: StateAction<TPublicationContext['authorForms']>
  suggestReviewerForms: Partial<PossibleReviewer>[]
  setSuggestReviewerForms: StateAction<TPublicationContext['suggestReviewerForms']>
  savePublication: () => void
  submitPublication: () => void
  refetchPublication: () => void
  rejectPublication: () => void
  updatePublication: (status: 'rejected' | 'accepted' | 'revision_requested') => void
  subcategoriesFiltered: SubCategory[]
  publicationLoaded: boolean
  updateStatus: (status: Publication['status']) => Promise<Publication>
  setAuthorsIsValid: StateAction<boolean>
  setSuggestReviewersIsValid: StateAction<boolean>
  setCoverLetterIsValid: StateAction<boolean>
  validateDraftForm: (t: string, k: object) => boolean | string
  handleGoToReviewers: () => void
  publicationQuery: DefinedUseQueryResult<Publication, unknown>
  handleSubmitPublication: (validateForms: () => void, toggleAlert: () => void, fieldsValues: string[]) => void
  validateForms: () => void
  selectRefs: SelectRefs
} & PublicationStatusBooleans

export type PublicationFormProps = {
  children: React.ReactNode
  article: Publication['id']
  isNew: boolean
}

export const authorInitialState = {
  full_name: '',
  contribution: '',
  email: '',
  organisation: '',
  tags: [],
  region: '',
}

export const reviewerInitialState = {
  full_name: '',
  email: '',
  organisation: '',
  tags: [],
}

const PublicationFormContext = createContext({} as TPublicationContext)

export const PublicationFormProvider = (props: PublicationFormProps) => {

  const { children } = props
  const { profile, isAuthor, isEditor, isPublisher, subcategories, plagiarismEula } = APIClient.Session.useSession()
  const { data: publication, query: publicationQuery } = APIClient.Publications.useRetrieve({ id: props?.article })
  const possibleReviewers = APIClient.Publications.possibleReviewersManager.useList({ filter: { article: props?.article, suggestion: true }, queryOptions: { refetchOnWindowFocus: false }})
  const authors = APIClient.Publications.authorsManager.useList({ filter: { article: props?.article }, queryOptions: { refetchOnWindowFocus: false }})
  const mediaList = APIClient.Publications.mediaManager.useList({ filter: { publication: props?.article }, queryOptions: { refetchOnWindowFocus: false }})

  const selectRefs = useRef({})

  const publications = APIClient.Publications.publicationsManager.useUpdate()
  const possibleReviewerUpdate = APIClient.Publications.possibleReviewersManager.useUpdate()
  const authorUpdate = APIClient.Publications.authorsManager.useUpdate()
  const mediaUpdate = APIClient.Publications.mediaManager.useUpdate()

  const possibleReviewerCreation = APIClient.Publications.possibleReviewersManager.useCreate()
  const authorCreation = APIClient.Publications.authorsManager.useCreate()
  const mediaCreation = APIClient.Publications.mediaManager.useCreate()

  const [sections, setSections] = useState({})

  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [attachmentsMedia, setAttachmentsMedia] = useState<PublicationMedia[]>([])
  const [mediaDeleteArray, setMediaDeleteArray] = useState<PublicationMedia['id'][]>([])

  const [publicationLoaded, setPublicationLoaded] = useState(false)
  const [authorDisclaimers, setAuthorDisclaimers] = useState([])

  const [authorsIsValid, setAuthorsIsValid] = useState(false)
  const [suggestReviewersIsValid, setSuggestReviewersIsValid] = useState(false)
  const [coverLetterIsValid, setCoverLetterIsValid] = useState(false)

  const [authorForms, setAuthorForms] = useState<Partial<PublicationAuthor>[]>([authorInitialState])
  const [suggestReviewerForms, setSuggestReviewerForms] = useState<Partial<PossibleReviewer>[]>([reviewerInitialState])

  const [acceptedDisclaimers, setAcceptedDisclaimers] = useState<{ [x: number]: boolean }>({})

  const coverLetterForm = useForm(AppForms.editCoverLetter, { validateOn: 'change' })
  const publicationForm = useForm(AppForms.editPublication, { validateOn: 'change' })
  const detailsForm = useForm(AppForms.editPublicationDetails, { validateOn: 'change' })

  const publicationStatus = useMemo(() => APIClient.Publications.getPublicationBooleans(publication?.status), [publication?.status])

  const disclaimers = useMemo(() => {
    if (!authorDisclaimers?.length) return []

    if ((isEditor || isPublisher) && acceptedDisclaimers) {
      return Object.entries(acceptedDisclaimers).filter(([, v]) => v)?.map(([k]) => {
        return authorDisclaimers.find(a => a.id == k)
      })
    }

    return authorDisclaimers
  }, [isEditor, isPublisher, acceptedDisclaimers, authorDisclaimers?.length])

  const isPublicationArchived = useMemo(() => {
    const { isAccepted, isRejected, isRejected_resubmit, isWithdraw } = publicationStatus || {}
    return isAccepted || isRejected || isRejected_resubmit || isWithdraw
  }, [publicationStatus])

  onUpdate(() => {
    if ((isEditor || isPublisher) && publicationStatus?.isSaved_in_drafts) {
      Navigation.navigate('Manuscripts.List')
    }
  }, [isEditor, isPublisher, publicationStatus?.isSaved_in_drafts])

  onUpdate(() => {
    if (!!publication?.journal) {
      try {
        APIClient.Journals.listDisclaimer({ journal: publication?.journal?.id }).then((_disclaimers) => {
          if (!!plagiarismEula) {
            _disclaimers.push({
              id: 'plagiarism-disclaimer',
              title: ' Plagiarism check',
              url: plagiarismEula?.url,
            })
          }
          setAuthorDisclaimers(_disclaimers as Disclaimer[])
        })
      } catch (err) {
        logger.error('Error fetching disclaimers', err, 'Publications')
      }
    }
  }, [publication?.journal])

  onUpdate(() => {
    if (!publication) return

    publicationForm.setFormValues({
      title: publication?.title,
      short_title: publication?.short_title,
      region: publication?.region,
      article_type: publication?.article_type?.id,
      category: publication?.category,
      subcategory: publication?.subcategory,
      keywords: Array.isArray(publication.keywords) ? publication.keywords.join(', ') : '',
    })

    setPublicationLoaded(true)
  }, [publication?.title, publication?.short_title, publication?.region, publication?.article_type?.id, publication?.category, publication?.subcategory, publication?.keywords])

  onUpdate(() => {
    if (!publication) return
    detailsForm.setFormValues({
      conflicts_of_interest: publication?.conflicts_of_interest,
      consent: publication?.consent,
      data_availability_statement: publication?.data_availability_statement,
      ethical_approval: publication?.ethical_approval,
      funding_source: publication?.funding_source,
      guarantor: publication?.guarantor,
      provenance_peer_review: publication?.provenance_peer_review,
    })
  }, [publication?.conflicts_of_interest, publication?.consent, publication?.data_availability_statement, publication?.ethical_approval, publication?.funding_source, publication?.guarantor, publication?.provenance_peer_review])

  onUpdate(() => {
    if (!publication) return
    coverLetterForm.setFormValues({ cover_letter: publication.cover_letter || '' })
  }, [publication?.cover_letter])

  onUpdate(() => {
    if (authorDisclaimers?.length) {
      setAcceptedDisclaimers(authorDisclaimers.reduce((acc, i) => {
        const isChecked = !!publication?.disclaimers_accepted?.find(disclaimer => {
          if (disclaimer.title === 'plagiarism-disclaimer') return disclaimer.title === i.id
          return disclaimer.disclaimer__id === i.id
        })
        return ({ ...acc, [i.id]: isChecked })
      }, {}))
    }
  }, [authorDisclaimers?.length, publication?.disclaimers_accepted])

  onUpdate(() => {
    if (authors?.items?.length) {
      const sortedAuthors = [...authors.items]?.sort((a, b) => {
        return compareAsc(parseISO(a.created_datetime), parseISO(b.created_datetime))
      })

      setAuthorForms(sortedAuthors)
    } else if (profile) {
      setAuthorForms([{
        ...authorInitialState,
        full_name: profile.first_name + ' ' + profile.last_name,
        email: profile.email,
        tags: profile.tags,
        organisation: profile.organisation,
        region: profile.region,
      }])
    }
  }, [authors?.items?.length, profile?.id, publication])

  onUpdate(() => {
    if (possibleReviewers?.items?.length) {
      setSuggestReviewerForms([...possibleReviewers.items])
    }
  }, [possibleReviewers?.items])

  onUpdate(() => {
    if (mediaList?.items?.length) {
      setAttachmentsMedia(mediaList?.items)
    }
  }, [mediaList?.items?.length])

  onUpdate(() => {
    if (publication?.sections) {
      const sectionsData = Object.fromEntries(
        Object.entries(publication.sections).map(([key, { text }]) => [key, text]),
      )
      setSections(sectionsData)
    }
  }, [publication?.sections])

  const validateDraftForm = (field, forms) => {
    if (!props?.isNew && !forms.validateField(field).valid) {
      return forms.validateField(field).message
    } else {
      return false
    }
  }

  const handleKeywords = () => {
    const { keywords = '' } = publicationForm.values
    const newKeywords = Array.from(new Set(keywords.split(/[,.;_/\\|]/).map(i => i.trim()))).sort()
    const originalKeywords = publication.keywords?.sort()
    const keywordsChanged = JSON.stringify(newKeywords) !== JSON.stringify(originalKeywords)
    return { newKeywords, keywordsChanged }
  }

  function verifyPublicationChanges(...args: any[]) {
    if (!publication) return {}
    const changes = args.reduce((acc, cur) => ({ ...acc, ...cur }), {})

    return Object.entries(changes).reduce((acc, [key, val]) => {
      const value = publication?.[key]

      if (TypeGuards.isArray(val) && val.every((v) => value?.includes(v)) && val.length === value.length) return acc
      if (JSON.stringify(value) === JSON.stringify(val) || value === val) return acc
      if (TypeGuards.isNil(value) && TypeGuards.isNil(val)) return acc
      else return { ...acc, [key]: val }
    }, {})
  }

  const handleDisclaimerAcceptance = (id: Disclaimer['id'], vl: boolean) => {
    setAcceptedDisclaimers(prev => ({ ...prev, [id]: vl }))
  }

  const handleAuthors = async () => {
    const filteredAuthors = APIClient.Publications.verifyAuthorExistence(authorForms, authors?.items)
    if (filteredAuthors?.new?.length) {
      await authorCreation.create({ publicationId: Number(props?.article), authors: filteredAuthors.new } as Partial<PublicationAuthor>)
    }
    if (filteredAuthors?.toUpdate?.length) {
      await authorUpdate.update(filteredAuthors.toUpdate as Partial<PublicationAuthor>)
    }
  }

  const handleSuggestedReviewers = async () => {
    const filteredReviewers = APIClient.Publications.verifyPossibleReviewerExistence(suggestReviewerForms, possibleReviewers?.items)
    if (filteredReviewers?.new?.length) {
      await possibleReviewerCreation.create({ publicationId: Number(props?.article), possibleReviewers: filteredReviewers.new } as Partial<PossibleReviewer>)
    }
    if (filteredReviewers?.toUpdate?.length) {
      await possibleReviewerUpdate.update(filteredReviewers.toUpdate as Partial<PossibleReviewer>)
    }
  }

  const handleAttachments = async () => {
    if (!!attachments.length) {
      await mediaCreation.create({ publication: props?.article, files: attachments })
      setAttachments([])
    }

    if (!!attachmentsMedia?.length) {
      await mediaUpdate.update(attachmentsMedia)
    }

    if (!!mediaDeleteArray?.length) {
      await Promise.all(mediaDeleteArray.map(async (id) => {
        await removeMedia(id)
      }))
      setMediaDeleteArray([])
    }
  }

  const updatePublication = async (status: keyof typeof PublicationStatus) => {
    await savePublication(status)
  }

  const handleGoToReviewers = async () => {
    try {
      if (isAuthor) {
        AppStatus.set('loading')
        await APIClient.Publications.searchReviewers(publication.id)
      }
    } catch { }
    AppStatus.set('idle')
    Navigation.navigate('Manuscripts.ReviewersAndInvites', { routeParams: { id: String(publication?.id) }})
  }

  const submitPublication = async () => {
    window.scrollTo(0, 0)
    await savePublication('submitted_for_review')
    await APIClient.Publications.searchReviewers(publication.id)
    await APIClient.Articles.checkPlagiarismStatus(publication.id)
    Navigation.navigate('Manuscripts.List', { state: { isNew: true }})
  }

  const refetchPublication = async () => {
    await publicationQuery.refetch()
  }

  const rejectPublication = async () => {
    await savePublication('rejected')
  }

  const updateStatus = async (status: keyof typeof PublicationStatus = publication?.status) => {
    await savePublication(status)
    return publication
  }

  const savePublication = async (status: keyof typeof PublicationStatus = publication?.status) => {
    AppStatus.set('loading')

    try {
      const { newKeywords, keywordsChanged } = handleKeywords()
      const updatedSections = Object.keys(sections).reduce((acc, item) => ({
        ...acc,
        [item]: { ...publication.sections[item], text: sections[item] },
      }), {})

      const publicationFormData = {
        ...publicationForm.values,
        status,
        keywords: newKeywords,
        title: publicationForm.values.title?.trim(),
        short_title: publicationForm.values.short_title?.trim(),
        sections: updatedSections,
      }

      const publicationFieldsChanges = verifyPublicationChanges(detailsForm.values, publicationFormData, coverLetterForm.values)

      if (Object.keys(publicationFieldsChanges)?.length) {
        if (isAuthor) {
          await handleSuggestedReviewers()
          await handleAuthors()
          await handleAttachments()
          await sendAcceptedDisclaimers(publication.id, Object.keys(acceptedDisclaimers).filter(key => acceptedDisclaimers[key]))
          await publications.update({
            id: publication.id,
            ...publicationFieldsChanges,
          })
        } else if ((isEditor || isPublisher)) {
          await publications.update({
            id: publication.id,
            ...publicationFieldsChanges,
          })
        }
      }

      await publicationQuery.refetch()
      await possibleReviewers.query.refetch()
      await authors.query.refetch()
      await mediaList.query.refetch()
      AppStatus.set('done')
    } catch (err) {
      AppStatus.set('idle')
      logger.error('Error updating publication', err, 'Publications')
      logger.slack.echo('ERROR - manuscript submittion', { err })

      OSAlert.error({
        title: 'Something went wrong',
        body: 'Looks like we’ve hit a problem - sorry about that! We’ll investigate and try to stop it from happening again.',
      })
    }
  }

  const handleSubmitPublication = (validateForms, toggleAlert, fieldsValues) => {
    const authorRules = authorValidationRules
    const reviewerRules = reviewerValidationRules

    const allValid = authorForms.every(({ contribution, email, region, full_name, tags, organisation }) => {
      return (
        contribution?.trim().length >= authorRules.contribution.min &&
        PublicationUtils.validateEmail(email) &&
        region &&
        full_name?.trim().length >= authorRules.full_name.min &&
        organisation?.trim().length >= authorRules.organisation.min &&
        Array.isArray(tags) && tags.length > 0
      )
    })

    const hasThreeReviewers = suggestReviewerForms.length === 3

    const hasRepeatedReviewersEmail = suggestReviewerForms.some((reviewer, index) => {
      const emails = suggestReviewerForms.map(({ email }) => email?.toLowerCase())
      return emails.indexOf(reviewer.email) !== index
    })

    const isReviewerEmailSameAsAuthor = suggestReviewerForms.some(reviewer => reviewer.email?.toLowerCase() === profile?.email)

    const reviewersValid = hasThreeReviewers && !hasRepeatedReviewersEmail && !isReviewerEmailSameAsAuthor && suggestReviewerForms.every(({ full_name, email, organisation, tags }) => {
      return (
        full_name?.trim().length >= reviewerRules.full_name.min &&
        PublicationUtils.validateEmail(email) &&
        organisation?.trim().length >= reviewerRules.organisation.min &&
        Array.isArray(tags) && tags.length > 0
      )
    })

    const areFormsValid = publicationValid && detailsForm.isValid && coverLetterIsValid && !!allValid && !!reviewersValid && disclaimersAccepted

    if (!areFormsValid) {
      validateForms()
      setTimeout(() => {
        const areFieldsEmpty = fieldsValues.some(obj => Object.values(obj)?.some(value => TypeGuards.isNil(value)))
        const shouldShowAlert = areFieldsEmpty || disclaimersTitle.length > 0 || !areFormsValid || !reviewersValid

        if (shouldShowAlert) {
          let errorMessage = ''

          if (!hasThreeReviewers && !areFieldsEmpty) {
            errorMessage = 'Please suggest at least 3 reviewers to proceed'
          } else if (hasRepeatedReviewersEmail) {
            errorMessage = 'Reviewer emails must be unique'
          } else if (isReviewerEmailSameAsAuthor) {
            errorMessage = 'Reviewer email must be different from author email'
          }

          toggleAlert({ errorMessage })
          return
        }
      }, 1000)

      return
    }
    submitPublication()
  }

  const validateForms = () => {
    coverLetterForm.validateAll(true)
    publicationForm.validateAll(true)
    detailsForm.validateAll(true)
  }

  const subcategoriesFiltered = useMemo(() => subcategories ? subcategories.filter(subcategory => subcategory?.category === publicationForm.values?.category) : [], [publicationForm.values?.category, subcategories])

  const [debouncedPublicationTitle] = useDebounce(publicationForm?.values?.title, 400)
  const [debouncedPublicationKeywords] = useDebounce(publicationForm?.values?.keywords, 400)

  const publicationValid = useMemo(() => {
    const hasSubcategories = subcategoriesFiltered?.length >= 1
    const subcategoryValid = hasSubcategories ? publicationForm?.values?.subcategory !== null : true
    const valid = publicationForm?.validateMultiple?.(['title', 'short_title', 'keywords', 'region', 'article_type', 'category'])?.valid && subcategoryValid

    return valid
  }, [
    publicationForm?.values?.subcategory,
    publicationForm?.values?.category,
    debouncedPublicationTitle,
    debouncedPublicationKeywords,
    publicationForm.isValid,
    subcategoriesFiltered,
  ])

  const publicationFormData = {
    ...publicationForm.values,
    ...coverLetterForm.values,
    ...detailsForm.values,
    keywords: Array.from(new Set(publicationForm.values?.keywords.split(/[,.;_/\\|]/).map(i => i.trim()))).sort(),
    title: publicationForm.values.title?.trim(),
    short_title: publicationForm.values.short_title?.trim(),
  }
  const publicationChanged = Object?.keys(verifyPublicationChanges(publicationFormData))?.length

  const isSavePublicationEnabled: { [x in ProfileRole]?: boolean } = {
    author: true,
    publisher: !!publicationChanged && coverLetterIsValid,
    editor: !!publicationChanged && coverLetterIsValid,
  }
  // const isSavePublicationEnabled = authorsIsValid && suggestReviewersIsValid
  const sectionBreakdown = APIClient.Publications.sortSections(publication)
  const disclaimersAccepted = !Object.values(acceptedDisclaimers)?.includes(false)
  // const areFormsValid = publicationValid && detailsForm.isValid && coverLetterIsValid && !!authorForms[0]?.email && disclaimersAccepted
  const fileName = useMemo(() => formatFileName(publication?.file), [publication?.file])
  const isLoading = publicationQuery.isLoading && !!publication && !!publication?.sections && !!publication?.keywords
  const isError = publicationQuery.isError
  const isReady = !isLoading && !isError && !!publication
  const isLoadingQuery = isLoading && !publication && !isError
  const canAuthorEdit = isAuthor && (publicationStatus?.isSaved_in_drafts || publicationStatus?.isRevision_requested)
  const canEditorEdit = (isEditor || isPublisher) && !(publicationStatus?.isSaved_in_drafts || publicationStatus?.isAccepted || publicationStatus?.isRejected)
  const isPublicationEditable = canAuthorEdit || canEditorEdit
  const disclaimersTitle = disclaimers
    ?.filter(disclaimer => acceptedDisclaimers[disclaimer?.id] === false)
    ?.map(disclaimer => disclaimer?.title)

  const value: TPublicationContext = {
    article: props.article,
    fileName,
    isNew: props.isNew,
    isLoading,
    isLoadingQuery,
    isError,
    isReady,
    ...publicationStatus,
    // areFormsValid,
    disclaimersAccepted,
    disclaimers,
    disclaimersTitle,
    forms: { coverLetter: coverLetterForm, publication: publicationForm, detail: detailsForm },
    publication,
    canAuthorEdit,
    canEditorEdit,
    isPublicationEditable,
    authorDisclaimers: authorDisclaimers,
    acceptedDisclaimers,
    setAcceptedDisclaimers,
    handleDisclaimerAcceptance,
    sectionBreakdown,
    sections,
    setSections,
    attachments,
    setAttachments,
    attachmentsMedia,
    setAttachmentsMedia,
    isSavePublicationEnabled,
    mediaDeleteArray,
    setMediaDeleteArray,
    authorForms,
    setAuthorForms,
    suggestReviewerForms,
    setSuggestReviewerForms,
    savePublication: savePublication,
    submitPublication: submitPublication,
    refetchPublication,
    rejectPublication,
    updatePublication,
    subcategoriesFiltered,
    publicationLoaded,
    updateStatus,
    setAuthorsIsValid,
    setSuggestReviewersIsValid,
    setCoverLetterIsValid,
    validateDraftForm,
    handleGoToReviewers,
    isPublicationArchived,
    publicationQuery,
    handleSubmitPublication,
    validateForms,
    selectRefs,
  }

  return (
    <PublicationFormContext.Provider value={value}>
      {children}
    </PublicationFormContext.Provider>
  )
}

export const usePublicationForm = () => {
  const ctx = useContext(PublicationFormContext)

  return ctx
}
