import { Publication } from '@/types'
import { api } from '@/app'

const BASE_URL = 'article/automatic_invite/'

export async function automaticInvite(article: Publication['id'], run: boolean) {
  const response = await api.post(BASE_URL, { article, run })

  return response.data
}
