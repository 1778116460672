/* eslint-disable max-len */
import { React } from '@/app'
import { ComponentVariants, PropsOf, TypeGuards, getNestedStylesByKey, onUpdate, useBooleanToggle, useDefaultComponentStyle, useState } from '@codeleap/common'
import { ActionIcon, Button, Text, View } from '.'
import { ButtonProps, ItemProps, Modal, ModalProps, SectionFilters, SectionFiltersProps, TouchableProps } from '@codeleap/web'
import { SortModalComponentStyles } from '@/app/stylesheets'
import { useConditionalState } from '@/utils'

export type SortComponentProps = React.PropsWithChildren<{
  wrapperProps?: Omit<TouchableProps, 'debugName'>
  modalProps?: Pick<SectionFiltersProps, 'selectedItems' | 'setSelectedItems' | 'draftItems' | 'setDraftItems'> & ModalProps
  icon?: string
  text?: string
  choicesLimit?: number
  filterData: ItemProps[]
  buttonProps?: PropsOf<ButtonProps>
  modalTitle?: string
  sectionFiltersVariants?: SectionFiltersProps['variants']
  sentenceText?: string
  showSentenceText?: boolean
  hideSentenceTextValue?: boolean
}> & ComponentVariants<typeof SortModalComponentStyles> & Partial<Omit<SectionFiltersProps, 'variants'>>

export const SortModalComponent = (props: SortComponentProps) => {

  const {
    wrapperProps,
    modalProps,
    icon = 'arrow-up-down',
    text = 'Sorting',
    choicesLimit = 3,
    responsiveVariants,
    variants,
    filterData,
    buttonProps = null,
    modalTitle = 'Sorting',
    sectionFiltersVariants = [],
    sentenceText,
    hideSentenceTextValue = false,
    children,
    ...sectionFilterProps
  } = props

  const variantStyles = useDefaultComponentStyle<'u:SortComponent', typeof SortModalComponentStyles>(
    'u:SortComponent',
    {
      responsiveVariants,
      variants,
      styles: wrapperProps?.styles,
      rootElement: 'wrapper',
    },
  )

  const buttonStyles = getNestedStylesByKey('button', variantStyles)

  const [_selectedItems, _setSelectedItems] = useConditionalState(modalProps?.selectedItems, modalProps?.setSelectedItems, { fallbackValue: {} })
  const [_draft, _setDraft] = useConditionalState(modalProps?.draftItems, modalProps?.setDraftItems, { fallbackValue: {} })
  const [_visible, _toggle] = useConditionalState<boolean>(modalProps?.visible, modalProps?.toggle, { fallbackValue: false, hook: (init) => useBooleanToggle(init) })

  const [buttonText, setButtonText] = useState(text)

  const sortEntries = _selectedItems && Object?.entries?.(_selectedItems)
  const sortLength = sortEntries?.length

  const isSortSelected = !!sortLength

  const hasNoSelectedItems = Object.keys(_selectedItems)?.length === 0
  const allValuesAreNull = Object.values(_selectedItems)?.every(value => value == null)

  onUpdate(() => {
    if (_selectedItems) {
      let sentence = text
      let index = 0

      for (const [_, value] of sortEntries) {

        if (TypeGuards.isNil(value)) {
          setButtonText(text)
          _setSelectedItems({})
          return
        }

        const separator = index === 0 ? ' by: ' : ''
        const defaultSentence = sentenceText
          ? `${sentenceText} ${hideSentenceTextValue ? '' : value?.label}`
          : sentence + separator + `${value?.label}`

        const isAlreadyAbbreviated = sentence?.includes('...')
        const shouldAbbreviateChoices = sortLength > choicesLimit

        const shouldAddSemiColon = index < choicesLimit ?
          (index >= 0 && sortLength !== 1 && sortLength > index + 1) :
          (index === 0 && sortLength === choicesLimit)

        if (shouldAbbreviateChoices) {
          if (index < choicesLimit) {
            sentence = defaultSentence + (shouldAddSemiColon ? '; ' : '')
          } else {
            sentence = sentence + separator + (isAlreadyAbbreviated ? '' : ' ...')
          }
        } else {
          sentence = defaultSentence + (shouldAddSemiColon ? '; ' : ' ')
        }

        index += 1

      }

      if (sentenceText) {
        sentence = sentenceText
      }

      setButtonText(sentence)
    }
  }, [_selectedItems, choicesLimit, sortEntries])

  function handleToggle() {
    _toggle()

    // clear selected items only if filters are not applied
    if (hasNoSelectedItems || allValuesAreNull) {
      _setDraft({})
      _setSelectedItems({})
    }
  }

  return (
    <>

      <Button
        text={buttonText}
        icon={icon}
        debugName={`Sorting Modal Press`}
        onPress={_toggle}
        styles={buttonStyles}
        selected={isSortSelected || !!sentenceText}
        {...buttonProps}
      />

      <Modal
        variants={['sectionFiltersModal', 'centered']}
        showClose={false}
        {...modalProps}
        visible={_visible}
        toggle={handleToggle}
      >
        <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween', 'marginBottom:1']}>
          <Text variants={[`h3`, 'neutral-9', 'medium']} text={modalTitle} />
          <ActionIcon
            icon='x'
            debugName='Sort modal component - close modal on press'
            variants={['transparent', 'iconSize:3', 'neutral9']}
            onPress={handleToggle}
          />
        </View>
        {children}
        <SectionFilters
          toggle={handleToggle}
          title='Sorting'
          applyButtonText='Sort'
          clearButtonText='Clear all'
          selectedItems={_selectedItems}
          setSelectedItems={_setSelectedItems}
          draftItems={_draft}
          setDraftItems={_setDraft}
          onApplyItems={_toggle}
          data={filterData}
          variants={sectionFiltersVariants}
          {...sectionFilterProps}
        />
      </Modal>

    </>
  )
}
