import { Theme, variantProvider, AppImages, assignTextStyle } from '@/app'
import { View, Logo, Text } from '@/components'
import { CenterWrapper } from './CenterWrapper'
import { useMediaQueryDown } from '@/utils'

export const Footer = () => {
  const isMobile = useMediaQueryDown('small')

  return (
    <CenterWrapper variants={['bg:neutral10', 'paddingVertical:5', 'marginTop:auto']}>
      <View variants={['flex', 'column']}>
        <Logo
          style={styles.logoImage}
          responsive={false}
          switchServerOnPress
          image={AppImages.LogoWhite}
        />

        {!isMobile ? (

          <View up={'mid'} variants={['row', 'flex', 'gap:5', 'fullWidth', 'justifySpaceBetween', 'alignCenter']}>
            <View variants={'column'}>
              <Text variants={['p3', 'color:neutral4']} style={{ maxWidth: '75%' }} text='Eworkflow Ltd. is registered in England.' />
              <Text variants={['p3', 'color:neutral4']} style={{ maxWidth: '75%' }} text='This website uses third-party assets and software. By using our website you agree to the terms of use.' />
            </View>
          </View>

        ) : (

          <View down={'mid'} variants={['center', 'column', 'gap:5']}>
            <View variants={'column'}>
              <Text variants={['p3', 'color:neutral4', 'center', 'textCenter']} text='Eworkflow Ltd. is registered in England.' />
              <Text variants={['p3', 'color:neutral4', 'center', 'textCenter']} text='This website uses third-party assets and software. By using our website you agree to the terms of use.' />
            </View>
          </View>
        )}
      </View>
    </CenterWrapper>
  )
}

const logoSize = 32
const minHeight = 420

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    backgroundColor: theme.colors.primary3,
    width: '100%',
    minHeight: minHeight,
    ...theme.spacing.paddingHorizontal(10),
    ...theme.spacing.paddingVertical(5),

    [Theme.media.down('mid')]: {
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(5),
    },
  },
  disclaimerText: {
    ...assignTextStyle('p3')(theme).text,
    color: theme.colors.neutral9,

    [Theme.media.down('mid')]: {
      alignSelf: 'center',
      ...theme.presets.textCenter,
    },
  },
  footerNavText: {
    ...assignTextStyle('h5')(theme).text,
    color: theme.colors.neutral10,
    textDecoration: 'none',
  },
  logoImage: {
    width: logoSize * 4,
    marginBottom: theme.spacing.value(5),
    alignSelf: 'flex-start',

    [Theme.media.down('mid')]: {
      alignSelf: 'center',
    },
  },
  contactText: {
    ...assignTextStyle('p1')(theme).text,
    color: theme.colors.neutral10,
    fontWeight: '300',
    textDecoration: 'none',
  },
  disclaimersWrapper: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
  },
}), true)
