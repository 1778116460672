import { ColumnMapItem } from '../types'
import { View, Checkbox } from '@/components'
import { CheckboxProps, stopPropagation } from '@codeleap/web'

type BulkCheckboxProps = {
  item: any
  col: ColumnMapItem
} & Omit<CheckboxProps, 'debugName' | 'value' | 'onValueChange' | 'variants' | 'responsiveVariants'>

export const BulkCheckbox = ({ item, col, ...props }: BulkCheckboxProps) => {
  const { bulkValues, setBulkValues } = col
  const value = !!bulkValues?.find(({ id }) => id === item.id)

  const onValueChange = () => {
    if (!value) setBulkValues((prev) => [...(prev || []), item])
    else setBulkValues((prev) => prev?.filter(({ id }) => id !== item.id))
  }

  return (
    <View onClick={stopPropagation}>
      <Checkbox {...props} value={value} onValueChange={onValueChange} debugName='Bulk Selection Checkbox' />
    </View>
  )
}
