import { React, AppForms } from '@/app'
import { DropzoneFile, DropzoneFileRejection, DropzoneRef, Dropzone, DropzoneProps } from '@codeleap/web'
import { Toaster, View } from '@/components'
import { FileScanResponse } from '@/types'

type SelectFilesProps = {
  ref?: React.MutableRefObject<DropzoneRef>
  acceptedFiles?: DropzoneFile[]
  setAcceptedFiles?: React.Dispatch<React.SetStateAction<DropzoneFile[]>>
  rejectedFiles?: DropzoneFileRejection[]
  setRejectedFiles?: React.Dispatch<React.SetStateAction<DropzoneFileRejection[]>>
  dropzoneProps?: Partial<DropzoneProps>
  scannedFiles?: FileScanResponse['results']
}

const SelectFilesComponent = (props: SelectFilesProps, ref) => {
  const {
    acceptedFiles,
    rejectedFiles,
    dropzoneProps,
    setAcceptedFiles,
    setRejectedFiles,
    scannedFiles,
  } = props

  const dropzoneRef = ref
  const isInfected = scannedFiles?.some((file) => file.result === 'infected')

  return (
    <View variants={['column', 'gap:3']} id='SelectFiles'>

      {isInfected ? (
        <Toaster
          title='A problem has been identified in the files you uploaded, please re-upload to continue.'
          description='One of more of your files contains a virus or malware, please reupload.'
          isVisible={true}
          icon={'shield-alert'}
          variants={['alert']}
        />
      ) : null}

      <Dropzone
        ref={dropzoneRef}
        icon={'add-file'}
        withImagePreview={false}
        acceptedFiles={acceptedFiles}
        setAcceptedFiles={setAcceptedFiles}
        rejectedFiles={rejectedFiles}
        setRejectedFiles={setRejectedFiles}
        //onDrop={setAcceptedFiles}
        multiple={true}
        validator={(f) => AppForms.fileSizeValidator(f)}
        {...dropzoneProps}
      />
    </View>
  )
}

export const SelectFiles = React.forwardRef(SelectFilesComponent) as unknown as (props: SelectFilesProps, ref?: SelectFilesProps['ref']) => JSX.Element
