import { AppStatus, useAppSelector } from '@/redux'
import { ActionIcon, Attachment, Button, Dropzone, Icon, Modal, RenderRichText, Select, Text, View, useUploadFilesContext } from '..'
import { useMemo, useState } from 'react'
import { TypeGuards, onUpdate } from '@codeleap/common'
import { APIClient } from '@/services'
import { OSAlert, variantProvider, React, Settings } from '@/app'
import { getSelectValues, PublicationUtils } from '@/utils'
import { DropzoneRef } from '@codeleap/web'
import { Publication } from '@/types'

const requiredCategories = ['refers_to_main_document', 'response_to_reviewer_or_editor', 'clean_version_of_revised_article_with_changes_accepted']

export const SubmitNewPublicationVersion = ({ publication }: { publication: Publication }) => {

  const {
    visible,
    data,
  } = useAppSelector(state => state.AppStatus.modals.submitNewPublicationVersion)

  const {
    comment = 'roin interdm. Bibendum at ultrices at diam mauris felis cursus diam convallis. Eget sit arcu risus imperdiet bibendum aliquet facilisis orci.',
    files,
    onSubmit = () => null,
  } = data
  const ref = React.useRef<DropzoneRef>()
  const [newComment, setNewComment] = useState()

  const [_attachments, setAttachments] = useState([])

  const {
    setCurrentModal,
    publicationId,
  } = useUploadFilesContext()

  const attachments = _attachments ?? []

  const publications = APIClient.Publications.publicationsManager.useUpdate()

  const historyList = APIClient.Publications.publicationHistoryManager.useList({
    filter: {
      article: publication?.id,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: false,
    },
  })

  const { file_categories } = APIClient.Session.useSession()

  const mainDocumentCategory = useMemo(() => {
    return file_categories?.find(e => !!e.refers_to_main_document)
  }, [file_categories?.length])

  const fileCategoryValues = useMemo(() => {

    return attachments.map((attachment, index) => {
      const category = attachment?.category || attachment?.file_category
      return getSelectValues(category, file_categories)

    })

  }, [attachments, file_categories?.length, mainDocumentCategory?.id])

  const categoriesWithoutMainDocument = file_categories?.filter(c => !c.refers_to_main_document)

  onUpdate(() => {
    if (files) setAttachments(files)
    if (comment) setNewComment(comment)
  }, [files, comment])

  const toggle = () => AppStatus.setModal('submitNewPublicationVersion')

  const onSaveNewComment = (comment) => {
    setNewComment(comment)
    AppStatus.setModal(['submitNewPublicationVersion', true])
  }

  const onCloseNewComment = () => AppStatus.setModal(['submitNewPublicationVersion', true])

  const onOpenReviewCommentModal = () => {
    toggle()
    AppStatus.setModal(['reviewComment', true, {
      data: {
        comment: newComment,
        onClose: onCloseNewComment,
        onSave: onSaveNewComment,
        type: 'text',
      },
    },
    ])
  }

  const onDeleteComment = () => {
    toggle()
    OSAlert.ask({
      title: 'Are you sure you want to delete this comment?',
      body: `This action can't be undone.`,
      options: [
        { text: 'Cancel', onPress: () => AppStatus.setModal(['uploadVersionFiles', true]), variants: ['flat', 'large'] },
        { text: 'Delete', onPress: () => onSaveNewComment(''), variants: ['large'] },
      ],
    })
  }

  const onRemoveAttachment = (attachment) => {
    setAttachments((state) => {
      const items = { ...state }
      const index = state?.findIndex(item => JSON.stringify(item) === JSON.stringify(attachment))
      delete items[index]
      return Object.values(items)
    })
  }

  const onSetCategory = ({ category, index }) => {
    setAttachments((state) => {
      if (state) {
        const newState = [...state]
        newState[index] = { ...newState[index], category: category }

        return newState
      }
      return state
    })
  }

  const onSubmitVersion = async () => {
    AppStatus.set('loading')
    try {
      const response = await APIClient.Replica.PostReplica({ comment: newComment, article: publicationId })
      if (response) {
        publications.update({
          id: publication?.id,
          status: 'revision_completed',
        })
        await APIClient.Replica.PostReplicaMedia({ files: attachments, reply: response?.id })
        await APIClient.Replica.updateReplica({ id: response?.id, ready: true })
        setAttachments([])
        onSubmit?.(setAttachments)
        await historyList.query.refetch()
        toggle()
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({ title: 'Error', body: 'Error while submitting new manuscript version. Please try again later' })
    }
  }

  const Comment = () => {

    if (!newComment) {
      return (
        <Button
          variants={['small', 'backgroundColor:neutral2', 'text:p2', 'text:primary5', 'icon:primary5', 'marginBottom:3', 'marginTop:1', 'border-radius:nano']}
          text={`Add Comment`}
          icon={'plus'}
          debugName={`Submit new Manuscript version Modal - Add comment`}
          onPress={onOpenReviewCommentModal}
        />
      )
    }

    return (
      <View variants={['fullWidth', 'backgroundColor:neutral2', 'border-radius:smallish', 'marginBottom:3', 'padding:2', 'column']} style={{ minHeight: 144 }}>

        <Text variants={['p2', 'color:neutral8']} text={`Author comments`} />
        <RenderRichText content={newComment} />

        <View variants={['marginTop:3', 'row', 'gap:1', 'border-radius:nano']}>

          <Button
            variants={['backgroundColor:neutral1', 'small', 'text:p2', 'icon:primary5', 'text:primary5']}
            text={`Edit`}
            icon={'edit'}
            debugName={`Submit new Manuscript version Modal - Edit comment`}
            onPress={onOpenReviewCommentModal}
          />

          <Button
            text={`Delete`}
            variants={['backgroundColor:neutral1', 'small', 'text:p2', 'icon:destructive2', 'text:destructive2']}
            icon={'trash'}
            debugName={`Submit new Manuscript version Modal - Delete comment`}
            onPress={onDeleteComment}
          />

        </View>

      </View>
    )
  }

  const AttachmentItem = ({ item, index }) => {

    const isLocal = item instanceof File
    const fileCategoryValue = fileCategoryValues[index]
    const isMainFile = fileCategoryValues?.find?.(category => category?.refers_to_main_document && category.id === item.file_category)

    const name = isLocal ? item?.name ?? (TypeGuards.isArray(item) && item[0]?.name) : item?.file?.name

    return (
      <View variants={['fullWidth', 'gap:2', 'marginBottom:2']}>

        <View variants={['paddingHorizontal:2', 'backgroundColor:neutral2', 'border-radius:small', 'justifySpaceBetween', 'fullWidth']}
          style={{ overflow: 'hidden' }}
        >

          <View variants={['gap:1', 'alignCenter', 'fullWidth']} style={{ overflow: 'hidden' }}>
            <Icon name='file' style={{ flexShrink: 0 }} size={20} debugName={'Attachment Item - file icon'} />
            <Text variants={[`p1`, 'color:neutral7', 'ellipsis']} text={name} />
          </View>

          {!isMainFile ? (
            <ActionIcon
              icon='x'
              debugName='Submit Manuscript Version Modal - close modal icon on press'
              variants={['transparent', 'iconSize:2', 'neutral8']}
              onPress={() => onRemoveAttachment(item)}
            />
          ) : null}
        </View>

        <View style={styles.selectWrapper}>
          <Select
            debugName={`Item ${item} Select file category`}
            value={fileCategoryValue}
            options={isMainFile ? file_categories : categoriesWithoutMainDocument}
            disabled={isMainFile}
            loadInitialValue
            variants={['fullWidth', 'noError', 'filePreview']}
            placeholder={'Select a category'}
            onValueChange={(category) => onSetCategory({ category, index })}
            searchable
          />
        </View>

      </View>
    )
  }

  const onMalwareCheck = (files) => {
    AppStatus.setModal(['uploadFiles', false, {}])
    toggle()
    const newfiles = files.map((file) => {
      file.file.category = file?.file_category
      return file
    })
    setAttachments((state) => {
      if (state && Object.keys?.(state)?.length) {
        return [...state, ...newfiles]
      } else {
        return newfiles
      }
    })
  }

  const validateOnCategoryChange = (files: Attachment[]) => {

    const fileWithoutCategory = files?.find(file => !file.file_category)

    if (fileWithoutCategory) {
      return { message: `All files must have a category selected`, valid: false }
    }

    return { message: ``, valid: true }
  }

  const validateOnChange = (file: File | Attachment) => {
    const extension = file.name.split('.').pop()?.toLowerCase()
    const isExtValid = Settings.FilePickerConfigs.CreatePublication.acceptedExtensions.includes(extension)

    if (!isExtValid) {
      return { message: `Main manuscript must be .docx, .pdf, or .doc`, valid: false }
    }

    return { message: ``, valid: true }
  }

  const categories = file_categories?.filter?.(file => {
    return requiredCategories.some((category) => file[category] === true)
  })

  const missingCategories = categories?.filter((category) => {
    return attachments?.every((file) => {
      if (file?.category) {
        return file?.category !== category?.id
      } else {
        return file?.file_category !== category?.id
      }
    })
  })?.map((category) => category?.title)

  const mainDocCategory = PublicationUtils.findCategory({ categories: file_categories, key: 'refers_to_main_document' })
  const cleanVersionCategory = PublicationUtils.findCategory({ categories: file_categories, key: 'clean_version_of_revised_article_with_changes_accepted' })
  const responseToReviewerEditorCategory = PublicationUtils.findCategory({ categories: file_categories, key: 'response_to_reviewer_or_editor' })

  const mainDocFiles = PublicationUtils.filterFilesByCategory({ attachments, categoryValue: mainDocCategory?.value })
  const cleanVersionOfRevisedFiles = PublicationUtils.filterFilesByCategory({ attachments, categoryValue: cleanVersionCategory?.value })
  const responseToReviewerOrEditorFiles = PublicationUtils.filterFilesByCategory({ attachments, categoryValue: responseToReviewerEditorCategory?.value })

  const hasDuplicateCategories = mainDocFiles?.length > 1 || cleanVersionOfRevisedFiles?.length > 1 || responseToReviewerOrEditorFiles?.length > 1

  const submitButtonDisabled = fileCategoryValues?.some((item) => !item) || missingCategories?.length > 0 || hasDuplicateCategories

  const openUploadModal = () => {
    setCurrentModal('version')
    toggle()
    AppStatus.setModal(['uploadFiles', true, {
      visible: true,
      onSubmit: onMalwareCheck,
      showCategory: true,
      validateOnCategoryChange,
      validateOnChange,
      file_categories: categoriesWithoutMainDocument,
      initialStep: null,
    }])
  }

  return (
    <Modal
      toggle={toggle}
      debugName='Submit new Manuscript version Modal'
      variants={['replicaOverviewModal', 'overflow:visible']}
      visible={visible}
      showClose={false}
    >

      <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}>
        <Text variants={[`h3`]} text={'Submit new version'} />
        <ActionIcon
          icon='x'
          debugName='Submit Manuscript Version Modal - close modal icon on press'
          variants={['filterIcon', 'primary5', 'iconSize:3']}
          onPress={toggle}
        />
      </View>

      <Comment />

      <Dropzone
        ref={ref}
        variants={['marginBottom:3', 'fullWidth']}
        icon={attachments?.length ? null : 'add-file'}
        onDrop={() => { }}
        placeholder={`Please choose files to upload with your new version.`}
        noClick={attachments?.length}
        multiple
        styles={{
          'dropzone': {
            height: 'unset',
            flex: 1,
            justifyContent: attachments?.length > 0 ? 'flex-start' : 'center',
            overflow: 'auto',
          },
          'wrapper': {
            height: '100%',
            flex: 1,
            overflow: 'hidden',
          },
        }}
      >
        {/* NOTE - add validation for file type and */}
        {attachments?.length > 0 && (
          <View style={styles.attachments}>
            {attachments?.map?.((item, index) => <AttachmentItem item={item} index={index} />)}
          </View>
        )}

        <Button
          debugName='Add more button on new version modal'
          text='Add more'
          icon='add-file'
          variants={['gap:1', 'flat', 'marginHorizontal:auto']}
          onClick={openUploadModal}
        />
      </Dropzone>

      {missingCategories?.length > 0 && (
        <View variants={['fullWidth', 'justifyCenter', 'textCenter']}>
          <Text variants={['p1', 'color:destructive2', 'fullWidth', 'paddingVertical:1']}>
            <Text
              variants={['p1', 'color:destructive2']}
              text={`Missing the following ${missingCategories?.length > 1 ? 'categories' : 'category'} for the files:`}
            />
            {missingCategories?.map?.((category) => (
              <Text
                text={`• "${category}"`}
                variants={['p1', 'color:destructive2']}
              />
            ))}
          </Text>
        </View>
      )}

      {(hasDuplicateCategories) && (
        <View variants={['fullWidth', 'justifyCenter', 'textCenter', 'column', 'paddingVertical:1']}>
          {mainDocFiles?.length > 1 && (
            <Text
              variants={['p1', 'color:destructive2']}
              text={`There cannot be more than one: "${mainDocCategory?.label}"`}
            />
          )}
          {cleanVersionOfRevisedFiles?.length > 1 && (
            <Text
              variants={['p1', 'color:destructive2']}
              text={`There cannot be more than one: "${cleanVersionCategory?.label}"`}
            />
          )}
          {responseToReviewerOrEditorFiles?.length > 1 && (
            <Text
              variants={['p1', 'color:destructive2']}
              text={`There cannot be more than one: "${responseToReviewerEditorCategory?.label}"`}
            />
          )}
        </View>
      )}

      <Button
        variants={['border-radius:tiny', 'fullWidth', 'marginTop:1']}
        text={`Submit`}
        debugName={`Submit new Manuscript version Modal - Continue Button`}
        disabled={submitButtonDisabled}
        onPress={onSubmitVersion}
      />

    </Modal>
  )
}

const MAX_WIDTH = 200

const styles = variantProvider.createComponentStyle((theme) => ({
  attachments: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
  },
  selectWrapper: {
    maxWidth: MAX_WIDTH,
    ...theme.presets.fullWidth,
  },
}), true)
