import { PaginationButtons, PaginationButtonsProps, TableProps } from '@/components'
import React from 'react'

export function TableFooter(props: Partial<TableProps>) {
  const {
    FooterComponent = null,
    fetchNextPage,
    fetchPage,
    fetchPreviousPage,
    currentPage,
    setCurrentPage,
    numberOfPages,
    paginationButtonsProps,
    showFooter = false,
    PaginationButtonsComponent = PaginationButtons,
    hidePaginationButtons,
    items,
  } = props

  const paginationProps: PaginationButtonsProps = {
    pages: numberOfPages,
    value: currentPage,
    onValueChange: setCurrentPage,
    onFetchNextPage: fetchNextPage,
    onFetchPreviousPage: fetchPreviousPage,
    onFetchPage: (page: number) => fetchPage(page),
    ...paginationButtonsProps,
  }

  const hasItems = !!items && items?.length >= 1
  const hidePagination = hidePaginationButtons || numberOfPages <= 1 || !hasItems

  return <>
    {hidePagination ? null : <PaginationButtonsComponent {...paginationProps} />}

    {showFooter ? <FooterComponent /> : null}
  </>
}
