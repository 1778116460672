import { SubHeaderComposition, SubHeaderStyles } from '@/app/stylesheets'
import { View, Text, Button } from '@/components'
import {
  ComponentVariants,
  PropsOf,
  StylesOf,
  useDefaultComponentStyle,
} from '@codeleap/common'
import { ReactNode } from 'react'

export type SubHeaderProps = ComponentVariants<typeof SubHeaderStyles> &
  PropsOf<typeof View> & {
    styles?: StylesOf<SubHeaderComposition>
    title: string
    children?: ReactNode
    buttons?: PropsOf<typeof Button>[]
  }

export const SubHeader = ({
  title,
  responsiveVariants,
  variants,
  styles,
  children,
  buttons = [],
  ...rest
}: SubHeaderProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:SubHeader',
    typeof SubHeaderStyles
  >('u:SubHeader', {
    responsiveVariants,
    variants,
    styles,
  })

  return (
    <View css={variantStyles.wrapper} {...rest}>
      <Text text={title} css={variantStyles.title} />

      {!!buttons.length && (
        <View variants={['gap:2']}>
          {buttons.map((button) => (
            <Button key={button.text} {...button} />
          ))}
        </View>
      )}

      {children}
    </View>
  )
}
