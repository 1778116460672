import { api } from '@/app'
import { Profile, Badge, Publication, PublicationHistory, InvitationReviewer, BadgeList } from '@/types'
import { PaginationResponse, ReactQuery } from '@codeleap/common'

const BASE_URL = `profiles/`

export async function list() {
  const response = await api.get<BadgeList>(BASE_URL + 'badges/')
  return response.data
}

type ReadBadgeOptions =
  { type: 'article'; id: Publication['id'] } |
  { type: 'history'; id: PublicationHistory['id'] } |
  { type: 'invite'; id: InvitationReviewer['id'] } |
  { type: 'badge_id'; id: Badge['id'] }

export async function readBadge({ type, id }: ReadBadgeOptions) {
  const response = await api.get<PaginationResponse<Badge>>(BASE_URL + 'read_badge/', {
    params: {
      [type]: id,
    },
  })
  return response.status.toString().startsWith('2')
}

export const useBadges = (profile: Profile) => {
  const q = ReactQuery.useQuery({
    queryKey: ['badges'],
    queryFn: list,
    enabled: !!profile,
    initialData: null,
    // refetchInterval: 3000,
    refetchOnMount: true,
  })

  return { badges: q.data, refresh: q.refetch }
}
