import { createForm, yup } from '@codeleap/common'

export const createManualInvite = () => createForm('createManualInvite', {
  email: {
    type: 'text',
    placeholder: 'Reviewer email here',
    validate: yup.string().email().required(),
  },
  full_name: {
    label: 'Full name',
    type: 'text',
    placeholder: 'Reviewer full name here',
    validate: yup.string().max(30).required(),
  },
  subject: {
    type: 'text',
    placeholder: 'Add your subject here',
    validate: yup.string().max(150).required(),
  },
  message: {
    type: 'text',
    multiline: true,
    validate: yup.string().notRequired()
      .min(22, 'Minimum of 15 characters')
      .max(10000, 'Maximum of 10000 characters'),
  },
})

export const createInvite = () => createForm('createInvite', {
  subject: {
    type: 'text',
    placeholder: 'Add your subject here',
    validate: yup.string().max(150).required(),
  },
  message: {
    type: 'text',
    multiline: true,
    validate: yup.string().notRequired()
      .min(22, 'Minimum of 15 characters')
      .max(10000, 'Maximum of 10000 characters'),
  },
})
