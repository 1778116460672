import { DropzoneComposition, DropzonePresets } from '@codeleap/web'
import { assignTextStyle, variantProvider } from '..'
import { SelectComposition } from '@codeleap/common'

type DropzoneParts =
  'wrapperSelect' |
  'actionWrapper'
  | `select${Capitalize<SelectComposition>}`
  | 'fileSize'
  | DropzoneComposition

const createDropzoneStyle =
  variantProvider.createVariantFactory<DropzoneParts>()

const SELECT_FILE_CATEGORY_WIDTH = 200

export const DropzoneStyles = {
  ...DropzonePresets,
  default: createDropzoneStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      scrollbarWidth: 'thin',
      overflowY: 'auto',
      maxHeight: '60vh',
    },
    dropzone: {
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(3),
      ...theme.border.neutral4({ style: 'dashed', width: 1.5 }),
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
      cursor: 'pointer',
      ...theme.spacing.gap(2),
    },
    iconWrapper: {
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.neutral2,
      ...theme.sized(7),
      ...theme.presets.justifyCenter,
      ...theme.presets.alignCenter,
    },
    icon: {
      ...theme.sized(3),
    },
    placeholder: {
      ...assignTextStyle('p3')(theme).text,
      fontWeight: 'normal',
      width: '78%',
      color: theme.colors.neutral7,
      ...theme.presets.textCenter,
    },
    filesWrapper: {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      marginBottom: theme.spacing.value(2),
      gap: theme.spacing.value(1),
    },
    fileWrapper: {
      ...theme.presets.row,
      ...theme.presets.fullWidth,
      height: theme.spacing.value(6),
      paddingRight: theme.spacing.value(2),
      paddingLeft: theme.spacing.value(2),
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(1),

      [theme.media.down('small')]: {
        ...theme.presets.column,
        ...theme.presets.alignStart,
        height: 'auto',
      },
    },
    'fileWrapper:error': {
      border: `1.5px solid ${theme.colors.destructive1}`,
    },
    fileError: {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.destructive2,
      display: 'none',

      '&:last-of-type': {
        display: 'block',
      },
    },
    fileNameWrapper: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      [theme.media.down('small')]: {
        ...theme.presets.row,
      },
    },
    fileName: {
      ...theme.presets.flex,
      ...theme.presets.fullWidth,
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.media.down('small')]: {
        textOverflow: 'unset',
        overflow: 'unset',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
      },
    },
    fileLeftIcon: {
      marginRight: theme.spacing.value(1),
      color: theme.colors.neutral7,
      ...theme.sized(2.5),
    },
    fileRightIconTouchableWrapper: {
      marginLeft: theme.spacing.value(1),
      background: 'none',
      ...theme.sized(2.5),
    },
    fileRightIconIcon: {
      color: theme.colors.neutral8,
    },
    fileImage: {
      ...theme.sized(4),
      borderRadius: theme.borderRadius.rounded,
      marginRight: theme.spacing.value(1),
      objectFit: 'cover',
    },
    actionWrapper: {
      ...theme.spacing.gap(2),
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      [theme.media.down('small')]: {
        ...theme.presets.justifySpaceBetween,
        ...theme.presets.fullWidth,
        ...theme.presets.wrap,
      },
    },
    wrapperSelect: {
      minWidth: SELECT_FILE_CATEGORY_WIDTH,
      maxWidth: SELECT_FILE_CATEGORY_WIDTH,
      marginRight: 'auto',
    },
    selectWrapper: {
      border: '2px solid red',
    },
    selectErrorStyle: {
      ...theme.presets.hidden,
    },
    fileSize: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
      whiteSpace: 'nowrap',
    },
  })),
  ellipsis: createDropzoneStyle((theme) => ({
    fileName: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    fileNameWrapper: {
      display: 'grid', //wtf, it works!
    },
  })),
  media: createDropzoneStyle((theme) => ({
    dropzone: {
      ...theme.spacing.padding(2),
    },
    fileWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(2),
      ...theme.border.neutral3({ width: 1, directions: ['bottom'] }),
      borderRadius: 0,
      height: theme.spacing.value(10),
    },
    fileNameWrapper: {
      ...theme.presets.row,
      ...theme.presets.center,
      ...theme.presets.justifyStart,
    },
    fileRightIconIcon: {
      color: theme.colors.destructive2,
    },
    fileSize: {
      color: theme.colors.neutral8,
    },
    fileName: {
      color: theme.colors.neutral8,
    },
  })),
  'hide-icon': createDropzoneStyle((theme) => ({
    iconWrapper: {
      display: 'none',
    },
  })),
  'submitEditorReview': createDropzoneStyle((theme) => ({
    dropzone: {
      border: 0,
    },
  })),
  'dropzone:hidden': createDropzoneStyle((theme) => ({
    dropzone: {
      display: 'none',
    },
  })),
  'overflow:visile': createDropzoneStyle((theme) => ({
    wrapper: {
      overflow: 'visible',
    },
    dropzone: {
      overflowY: 'visible',
    },
  })),
}

