import { createForm } from '@codeleap/common'

export const unavailableDateForm = createForm('unavailableDate', {
  title: {
    type: 'text',
    label: 'Date name',
  },
  start_date: {
    type: 'text',
    label: 'Start date',
  },
  end_date: {
    type: 'text',
    label: 'End date',
  },
})
