import { LocalStorage } from './../localStorage'
import { make18n } from '@codeleap/common'
import enGB from './en-GB/en-GB.json'

export const I18NDictionary = {
  'en-GB': enGB,

}

export const I18N = make18n({
  initialLocale: 'en-GB',
  persistor: {
    getLocale: async () => await LocalStorage?.getItem('LOCALE', false) || null,
    setLocale: async (locale) => await LocalStorage?.setItem('LOCALE', locale) as any,
  },
  languageDictionary: I18NDictionary,
})
