import { React, variantProvider } from '@/app'
import { Modal, Text, View, Button, TextInput } from '@/components'
import { useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { ReauthenticationStore } from '@/utils'
import { PropsOf, useI18N, useState } from '@codeleap/common'

type ReauthenticationModalProps = Pick<PropsOf<typeof Modal>, 'visible' | 'toggle'> & {
  onSubmit?: (password: string) => any
  onCancel?: () => any
}

export const ReauthenticationModal = (props: ReauthenticationModalProps) => {
  const { onSubmit, onCancel } = props

  const status = useAppSelector(store => store.AppStatus.status)
  const [password, setPassword] = useState('')
  const { reauthenticate } = APIClient.Session.useSession()
  const { t } = useI18N()

  const onContinue = async () => {
    try {
      await reauthenticate(password)
      onSubmit(password)
      ReauthenticationStore.set(true)
    } catch (e) {
      logger.error(e)
      alert('Could not authenticate. Please check your credentials.')
    }
  }

  return (
    <Modal
      title={t('Reauthetication.title')}
      debugName={'Profile reauthentication modal'}
      showClose={false}
      closable={false}
      closeOnEscape={false}
      {...props}
      variants={['centered', 'boxPadding:4']}
      visible={props?.visible && !(status === 'loading' || status === 'done')}
      styles={{
        body: styles.body,
      }}
    >
      <Text
        variants={['p1', 'textCenter']}
        text={t('Reauthetication.body')}
      />

      <TextInput
        value={password}
        onChangeText={setPassword}
        debugName={'Profile edit password input'}
        leftIcon={{ icon: 'key', debugName: 're-auth password icon' }}
        password
        visibilityToggle
        placeholder={t('Reauthetication.inputPlaceholder')}
        variants={['noError']}
      />

      <View variants={['row', 'gap:2', 'marginTop:0.5']}>
        <Button
          text={t('Reauthetication.buttonCancel')}
          debugName={'Cancel reauthentication'}
          onPress={onCancel}
          variants={['flex', 'flat']}
        />
        <Button
          text={t('Reauthetication.buttonSubmit')}
          debugName={'Reauthenticate user to update profile'}
          onPress={onContinue}
          variants={['flex']}
          disabled={!password.trim()}
        />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  body: {
    ...theme.spacing.gap(2),
  },
}), true)
